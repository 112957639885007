import React, { useRef, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useEventListener } from '../../libs/hooksLib';


/**
 * Provides a function that can be called to create a right-click menu for a given parent element ref and given list of menu items.
 * Menu items consist of { title, icon, onClick }.
 * @returns A function to be called upon a user's right-click, and a RightClickMenu element to include in the page.
 */
export function useRightClickMenu() {
  const [rightClickMenu, setRightClickMenu] = useState(null);  // null when not showing

  // Return a function that can invoke the right click menu
  return {
    onRightClick: ({ parentRef, mouseEvent, menuItems }) => {
      mouseEvent.preventDefault();
      if (!parentRef.current || !mouseEvent) return null;

      const bounds = parentRef.current.getBoundingClientRect();

      // If the mouse click is too close to the right of the screen, shift the menu to the left so we can see it
      const xAdjustment = mouseEvent.clientX > window.innerWidth - 190 ? -180 : 0;

      const newRightClickMenu = (
        <RightClickMenu
          xPos={mouseEvent.clientX - bounds.left + xAdjustment}
          yPos={mouseEvent.clientY - bounds.top}
          menuItems={menuItems}
          closeMenu={() => setRightClickMenu(false)}
        />
      );
      setRightClickMenu(newRightClickMenu);
      return newRightClickMenu;
    },

    rightClickMenu: rightClickMenu,
  }
}


export default function RightClickMenu({ xPos, yPos, menuItems, closeMenu }) {

  const menuRef = useRef(null);

  useEventListener(window, 'click', e => {
    // Listen for mouse click outside of the menu in order to close the menu
    const bounds = menuRef.current && menuRef.current.getBoundingClientRect();
    const clickX = e.clientX;
    const clickY = e.clientY;
    if (!bounds || clickX < bounds.left || clickX > bounds.right || clickY < bounds.top || clickY > bounds.bottom) {
      closeMenu();
    }
  });
  useEventListener(window, 'contextmenu', e => {
    // Listen for mouse click outside of the menu in order to close the menu
    const bounds = menuRef.current && menuRef.current.getBoundingClientRect();
    const clickX = e.clientX;
    const clickY = e.clientY;
    if (!bounds || clickX < bounds.left || clickX > bounds.right || clickY < bounds.top || clickY > bounds.bottom) {
      closeMenu();
    }
  });

  return (
    <ListGroup ref={menuRef} style={{
      zIndex: 99999999,
      position: 'absolute',
      top: yPos,
      left: xPos,
      minWidth: '180px',
    }}>
      {menuItems.map(({ title, icon, onClick, className='' }, idx) => (
        <ListGroup.Item
          className={'px-3 py-1 ' + className}
          action
          key={'rightClickMenu-' + idx}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            closeMenu();
            onClick();
          }}
        >
          {icon}
          <span className={icon ? 'ms-2' : ''}>{title}</span>
        </ListGroup.Item>
      ))}

    </ListGroup>
  );
}