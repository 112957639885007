


// Which overall "space" the user is in (may have different color aesthetics, etc.)
export const SPACES = {
  CONSTRUCT: 'construct',
  PLAY: 'play',
  SPLASH: 'splash',   // very first splash page
  INTERSTITIAL: 'interstitial',   // anything outside play or construct, such as the About page
};





/**
 * Returns the location of the top of the given elem after considering scroll in document, etc.
 * @param {*} elem DOM node
 * @returns {number} top
 */
export function getTop(elem) { // crossbrowser version
  const box = elem.getBoundingClientRect();

  const body = document.body;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;

  const clientTop = docEl.clientTop || body.clientTop || 0;

  const top = box.top +  scrollTop - clientTop;

  return Math.round(top);
}

/**
 * Returns iff the smallElem's bounding client rect is fully within the bigElem's bounding client rect.
 * @param {*} smallElem 
 * @param {*} bigElem 
 * @returns {bool}
 */
export function isEntirelyInside(smallElem, bigElem) {
  const smallBox = smallElem.getBoundingClientRect();
  const bigBox = bigElem.getBoundingClientRect();

  return smallBox.top >= bigBox.top && smallBox.bottom <= bigBox.bottom && smallBox.left >= bigBox.left && smallBox.right <= bigBox.right;
}


export function possiblyPluralString(number, unit) {
  return `${number} ${unit}${number === 1 ? '' : 's'}`;
}


export function randomString(length, prefix='') {
  var id = '';
  while (id.length < length) {
    id += Math.random().toString(36).replace(/0\./, '').replace(/[^a-z0-9]+/g, '');
  }
  return prefix + id.slice(0, length);
}



/**
 * Returns a user-friendly lowercase string describing the date given relative to now (e.g. 'x minutes ago' or 'yesterday').
 * @param {Date | number} date
 * @returns {string}
 */
export function userFriendlyDateString(date) {
  date = (date instanceof Date) ? date : new Date(date);  // convert to Date if it's a plain number

  var userFriendlyString;

  // Determine if we're in the same calendar day
  const givenDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const now = new Date(Date.now());
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  const daysDelta = Math.round((today - givenDay) / (24*3600*1000));
  if (daysDelta === 0) {
    const secondsDelta = Math.round((now - date) / 1000);
    
    if (secondsDelta < 60) {
      userFriendlyString = 'just now';
    } else if (secondsDelta < 3600) {
      const mins = Math.round(secondsDelta / 60);
      userFriendlyString = `${mins} minute${mins > 1 ? 's' : ''} ago`;
    } else {
      const hrs = Math.round(secondsDelta / 3600);
      userFriendlyString = `${hrs} hour${hrs > 1 ? 's' : ''} ago`;
    }
  } else if (daysDelta > 0) {
    if (daysDelta === 1) {
      userFriendlyString = 'yesterday'
    } else if (daysDelta <= 7) {
      userFriendlyString = `${daysDelta} days ago`;
    } else {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      userFriendlyString = givenDay.toLocaleDateString('en-US', options);
    }
  } else {
    userFriendlyString = 'in the future'
  }
  
  return userFriendlyString;
}