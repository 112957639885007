import React, { useCallback, useContext, useState } from 'react';
import { randomString } from './miscLib';
import { Toast } from 'react-bootstrap';
import { BiCommentError } from 'react-icons/bi';

const ToastNotificationContext = React.createContext();

export function useToastNotifications() {
  return useContext(ToastNotificationContext);
}


class ToastNotification {
  constructor({ variant, icon, headline, content, labels, removeSelfFromQueue }) {
    this.variant = variant;
    this.icon = icon;
    this.headline = headline;
    this.content = content;
    this.labels = [...labels];
    this.removeSelfFromQueue = removeSelfFromQueue;    // function callback that will remove this toast from the parent toast queue
    
    // Internal
    this.toastId = randomString(9, 'toast-');
    this.timestamp = Date.now();
  }

  get toastComponent() {
    return (
      <Toast key={this.toastId} id={this.toastId} onClose={this.removeSelfFromQueue}>
        <Toast.Header style={{ color: 'black', backgroundColor: this.variant === 'chill' ? '#abf7c2' : '#cf5b5b' }}>
          {this.icon}
          <strong className='me-auto'>{this.headline}</strong>
        </Toast.Header>
        <Toast.Body className='pt-0 pb-1' style={{ backgroundColor: this.variant === 'chill' ? '#cbf7d8' : '#e3baba' }}>
          {this.content}
        </Toast.Body>
      </Toast>
    );
  }
}


export function ToastQueue({ children }) {

  const [toastQueue, setToastQueue] = useState([]);     // list of ToastNotification objects

  const postNotification = useCallback(({
    variant = 'chill',
    icon,
    headline,
    content,
    labels,
  }) => {
    if (labels && !Array.isArray(labels)) labels = [labels];   // handle the case if labels is just given as a string rather than a list of strings

    // Create a new toast notification
    const newToastNotification = new ToastNotification({
      variant,
      icon: React.cloneElement(icon, {className: 'me-2'}),
      headline,
      content,
      labels: labels || [],
    });
    newToastNotification.removeSelfFromQueue = () => setToastQueue(tq => tq.filter(t => t.toastId !== newToastNotification.toastId));
    
    // Add to queue
    setToastQueue(tq => tq.concat([newToastNotification]));

    // Return toastId
    return newToastNotification.toastId;
  }, []);

  /**
   * Convenience wrapper around postNotification.
   * Displays a notification through the App's toast queue system with the given headline (title) and message.
   * @param {string} headline 
   * @param {string} message 
   */
  const postErrorNotification = useCallback((headline, message, labels) => {
    return postNotification({
      variant: 'angry',
      icon: <BiCommentError />,
      headline: headline,
      content: message,
      labels,
    });
  }, [postNotification]);

  const clearAllNotifications = useCallback(() => setToastQueue([]), []);

  const removeNotification = useCallback((toastId) => {
    setToastQueue(tq => tq.filter(t => t.toastId !== toastId));
  }, []);

  const removeNotificationsWithLabels = useCallback(labels => {
    if (!Array.isArray(labels)) labels = [labels];    // allow passing of an individual label string as well as a list

    setToastQueue(tq => tq.filter(toast => labels.every(labelString => !toast.labels.includes(labelString))));   // only keep toasts that do not have any of the labels
  }, []);


  return (
    <ToastNotificationContext.Provider value={{ postNotification, postErrorNotification, clearAllNotifications, removeNotification, removeNotificationsWithLabels }}>
      <>
        <div style={{position: 'fixed', top: 10, right: 10, zIndex: 100000}}>
          {toastQueue.map(t => t.toastComponent)}
        </div>
        {children}
      </>
    </ToastNotificationContext.Provider>
  );


}