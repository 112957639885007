import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CellStyleName, getStyleFromCellStyleName } from '../../../libs/formatLib';
import Cell from '../../board/Cell';


export default function CellStylePicker({
  onSelect, // (newSelectedCellStyleName) => {}
  selectedCellStyleName = CellStyleName.DEFAULT,
  ...props
}) {

  // Convenience
  const numCells = Object.keys(CellStyleName).length;
  const cellSize = 100 / numCells * 0.9; // pct of viewbox width
  const verticalPadding = cellSize * 0.1;

  const cells = Object.values(CellStyleName).map((cellStyleName, idx) => {
    const { cellFontStyle, cellFontColor, cellBackgroundColor } = getStyleFromCellStyleName(cellStyleName);
    return (
      <>
        <OverlayTrigger
          key={`cellstylepicker-overlaytrigger-${idx}`}
          placement='bottom'
          overlay={
            <Tooltip id={`cellstylepicker-tooltip-${idx}`}>
              {cellStyleName}
            </Tooltip>
          }
        >
          <Cell
            cellSize={cellSize}
            x={cellSize / 0.9 * idx + 100/numCells*0.05}
            y={verticalPadding}
            cellContent={cellStyleName.charAt(0).toUpperCase()}
            cornerNumber={idx + 1}
            respondToHover={false}
            cellFontStyle={cellFontStyle}
            cellFontColor={cellFontColor}
            cellBackgroundColor={cellBackgroundColor}
            onClick={() => {
              if (onSelect) {
                onSelect(cellStyleName);
              }
            }}
          />
        </OverlayTrigger>

        {cellStyleName === selectedCellStyleName ? (
          <rect
            x={cellSize / 0.9 * idx + 100/numCells*0.05}
            y={verticalPadding}
            width={cellSize}
            height={cellSize}
            fill="none"
            stroke="black"
            strokeWidth="1.0"
          />
        ) : null}
      </>
    );
  });


  return (
    <svg viewBox={`0 0 100 ${cellSize + 2*verticalPadding}`} {...props}>
      {cells}
    </svg>
  )
}