import React, { useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useCursor } from './BoardInteractionContext';
import { useEventListener } from '../../libs/hooksLib';
import ConstructorBoard from './ConstructorBoard';
import ConstructorSidePanel, { TAB_KEY } from './ConstructorSidePanel';
import ConstructorToolbar from './toolbar/ConstructorToolbar';
import ConstructorMetadataForm from './ConstructorMetadataForm';


/**
 * The highest-level layout container in the "Crossworthy Construct" feature within a BoardInteractionContext.
 * Responsible for the grid, the constructor toolbar, and the constructor side panel, as well as handling unfocusing clicks and displaying toasts/modals.
 */
export default function ConstructorEasel() {

  // Get DOM nodes of board and suggestions panel, the two elements that can be clicked *without* removing the Board's cursor
  const boardRef = useRef(null);
  const sidePanelRef = useRef(null);

  const [tabKey, setTabKey] = useState(TAB_KEY.FILL);   // which "tab" (clue or fill) is the user on?

  const constructorBoard = <ConstructorBoard
    showMobileKeyboard={tabKey === TAB_KEY.FILL}
    ref={boardRef}
  />;
  const sidePanel = <ConstructorSidePanel
    tabKey={tabKey}
    setTabKey={setTabKey}
    pxHeight={boardRef.current?.getBoundingClientRect()?.height}
    ref={sidePanelRef}
  />;

  const { handleClickOnLoc } = useCursor();

  /** Decides if click was outside the Board area so it can set the focus off the Board (set cursor to null). */
  function listenForClickOutside(e) {
    // Get bounding rects of board and suggestions panel
    const boardBounds = boardRef.current && boardRef.current.getBoundingClientRect();
    const sidePanelBounds = sidePanelRef.current && sidePanelRef.current.getBoundingClientRect();

    // Get mouse click location
    const clickX = e.clientX;
    const clickY = e.clientY;

    // If click isn't in either the board or the suggestions panel, we'll unfocus the board
    /* Note that we can't predict the bottom boundary of the various tabs (e.g. the "Clues" tab of the side panel) so this is ignored.
    https://www.notion.so/Bug-click-on-the-last-down-clue-the-grid-slot-gets-unhighlighted-Possibly-to-do-with-grid-boundar-2de0f3532b514f5785beca31be4896f1 for more.
    */
    if (
      (!boardBounds || clickY > boardBounds.bottom || clickY < boardBounds.top || clickX < boardBounds.left || clickX > boardBounds.right) && 
      (!sidePanelBounds || clickY < sidePanelBounds.top || clickX < sidePanelBounds.left || clickX > sidePanelBounds.right) // bottom ignored
    ) {
      handleClickOnLoc(null);
    }
  }
  useEventListener(window, 'click', listenForClickOutside);

  return (
    <div className='position-relative'>
      <ConstructorToolbar />

      <Container className='mt-3'>
        <Row>
          <Col xs={12} md={5} className='p-0'>
            <ConstructorMetadataForm className='mb-2' />
            {constructorBoard}
          </Col>

          <Col xs={12} md={7} className='p-0'>
            {sidePanel}
          </Col>
        </Row>
      </Container>
    </div>
  );
}