import React, { useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useEventListener } from '../../libs/hooksLib';
import PlayBoard from './PlayBoard';
import PlayClues from './PlayClues';
import { usePlayCursor } from './PlayInteractionContext';
import PlayToolbar from './PlayToolbar';
import { useAppContext } from '../../App';


/**
 * The highest-level layout container in the "Crossworthy Play" feature within a PlayInteractionContext.
 * Responsible for the grid, the player toolbar, and the clues side panel, as well as handling unfocusing clicks and displaying toasts/modals.
 */
export default function PlayEasel() {

  const { windowWidth, bottomPadding } = useAppContext();

  // Get DOM nodes of board and suggestions panel, the two elements that can be clicked *without* removing the Board's cursor
  const boardRef = useRef(null);
  const sidePanelRef = useRef(null);

  const maxHeight = bottomPadding > 200 ? window.innerHeight - 246 - 56 : window.innerHeight - bottomPadding - 56; // hard-coded to avoid constant resizing as bottomPadding changes
  const playBoard = <PlayBoard ref={boardRef} style={{ maxHeight, maxWidth: '96vw' }} />;
  const sidePanel = <PlayClues
    pxHeight={boardRef.current?.getBoundingClientRect()?.height}
    ref={sidePanelRef}
  />;

  const { handleClickOnLoc } = usePlayCursor();

  /** Decides if click was outside the Board area so it can set the focus off the Board (set cursor to null). */
  function listenForClickOutside(e) {

    // If you're on a mobile device, you don't really expect to be able to tap outside of the grid anyway
    if (windowWidth <= 600) return;

    // Get bounding rects of board and suggestions panel
    const boardBounds = boardRef.current && boardRef.current.getBoundingClientRect();
    const sidePanelBounds = sidePanelRef.current && sidePanelRef.current.getBoundingClientRect();

    // Get mouse click location
    const clickX = e.clientX;
    const clickY = e.clientY;

    // If click isn't in either the board or the suggestions panel, we'll unfocus the board
    /* Note that we can't predict the bottom boundary of the various tabs (e.g. the "Clues" tab of the side panel) so this is ignored.
    https://www.notion.so/Bug-click-on-the-last-down-clue-the-grid-slot-gets-unhighlighted-Possibly-to-do-with-grid-boundar-2de0f3532b514f5785beca31be4896f1 for more.
    */
    if (
      (!boardBounds || clickY > boardBounds.bottom || clickY < boardBounds.top || clickX < boardBounds.left || clickX > boardBounds.right) && 
      (!sidePanelBounds || clickY < sidePanelBounds.top || clickX < sidePanelBounds.left || clickX > sidePanelBounds.right) // bottom ignored
    ) {
      handleClickOnLoc(null);
    }
  }
  useEventListener(window, 'click', listenForClickOutside);



  return (
    <div>
      <PlayToolbar />
      <Container className={windowWidth > 600 ? 'mt-4' : 'mt-2'} fluid={windowWidth < 776}>
        <Row>
          <Col className='d-flex flex-column'>
            {playBoard}
          </Col>
          {windowWidth > 600 &&
            <Col>
              {sidePanel}
            </Col>
          }
        </Row>
      </Container>
    </div>
  );
}