import React, { useEffect, useState } from 'react';
import { Badge, Button, Dropdown, DropdownButton, FormCheck, FormControl, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { get, post } from 'aws-amplify/api';
import { produce } from 'immer';
import TruckLoadingAnimation from '../../reusable/TruckLoadingAnimation';
import { useToastNotifications } from '../../../libs/toastLib';
import { getAuthenticatedUsername } from '../../../libs/authLib';


export function EmailWithBadge({ className='', email, myEmail, ...props }) {
  return (
    <div className={'d-flex ' + className} {...props}>
      <div>{email}</div>
      {email && myEmail === email && <Badge className='ms-2 my-auto' bg='success'>ME</Badge>}
    </div>
  )
}


export default function SharePuzzleModal({
  puzzleId,
  show,
  onHide,
}) {

  const { postErrorNotification } = useToastNotifications();

  const [isLoading, setIsLoading] = useState(true);
  
  const [ownerEmail, setOwnerEmail] = useState(null);
  const [myEmail, setMyEmail] = useState(null);
  const [permissions, setPermissions] = useState(null);

  // States to keep track of entered values prior to adding a new user via the API
  const [newCollaboratorEmail, setNewCollaboratorEmail] = useState('');
  const [newCollaboratorPermissionsLevel, setNewCollaboratorPermissionsLevel] = useState('MANAGE');
  const [notifyNewCollaborator, setNotifyNewCollaborator] = useState(true);
  const newCollaboratorEmailIsValid = (
    newCollaboratorEmail !== ownerEmail && 
    newCollaboratorEmail !== myEmail &&
    permissions && 
    permissions.users &&               // need this in case only public permissions are available
    !Object.keys(permissions.users).includes(newCollaboratorEmail) && 
    /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/.test(newCollaboratorEmail)
  );

  var myPermissionsLevel = null;
  if (permissions) {
    if (myEmail === ownerEmail) {
      myPermissionsLevel = 'MANAGE';
    } else if (permissions.users) {
      myPermissionsLevel = permissions.users[myEmail];
      if (myPermissionsLevel === 'VIEW' && permissions.public.edit) {
        myPermissionsLevel = 'EDIT';
      }
    } else {
      if (permissions.public.edit) {
        myPermissionsLevel = 'EDIT';
      } else if (permissions.public.view) {
        myPermissionsLevel = 'VIEW';
      }
    }
  }

  // Load permissions object from the server
  useEffect(() => {
    if (show) {
      async function onLoadSharing() {
        setIsLoading(true);
        try {
          const response = await get({
            apiName: 'userPuzzles',
            path: `/puzzleSharing/${puzzleId}`,
          }).response;
          const { ownerEmail, permissions } = await response.body.json();

          setOwnerEmail(ownerEmail);
          setPermissions(permissions);

          if (ownerEmail) {
            const username = await getAuthenticatedUsername();
            setMyEmail(username);
          } else {
            setMyEmail(null);
          }
        } catch (e) {
          console.log(e);
          postErrorNotification('Error loading sharing permissions', 'Oops! There was an error loading this puzzle\'s permissions. Please let me know if this continues.');
          onHide();
        }
        setIsLoading(false);
      }

      onLoadSharing();
    }
  }, [show, puzzleId, onHide, postErrorNotification]);


  // Calls the API to update the given user with permissions, along with any client-side checks; if newPermissionsLevel is null, removes their permissions.
  async function updatePermissionsFor(email, newPermissionsLevel, currentPermissionsLevel) {
    if (currentPermissionsLevel && newPermissionsLevel && newPermissionsLevel === currentPermissionsLevel) return;   // do nothing

    // Update permissions state so it displays as updated
    setPermissions(produce(permissions, draft => {
      if (newPermissionsLevel) {
        draft.users[email] = newPermissionsLevel;
      } else {
        delete draft.users[email];
      }
    }));
    setIsLoading(true);

    // API call
    try {
      await post({
        apiName: 'userPuzzles',
        path: `/puzzleSharing/${puzzleId}`,
        options: {
          body: {
            action: newPermissionsLevel ? 'UPDATE_USER' : 'REMOVE_USER',
            payload: {
              userEmail: email,
              permissionsLevel: newPermissionsLevel,
              notify: notifyNewCollaborator,
            },
          },
        },
      }).response;
    } catch (e) {
      console.log(e);
      postErrorNotification('Error updating permissions', 'Oops! There was an error setting puzzle permissions. Please let me know if this continues.');
      onHide();
    }

    setIsLoading(false);
  }

  async function updatePublicPermissions(view, edit) {   // view and edit should be Boolean values
    // Update local state
    setPermissions(produce(permissions, draft => {
      draft.public = { view, edit };
    }));
    setIsLoading(true);

    // API call
    try {
      await post({
        apiName: 'userPuzzles',
        path: `/puzzleSharing/${puzzleId}`,
        options: {
          body: {
            action: 'UPDATE_PUBLIC_PERMISSIONS',
            payload: { view, edit },
          },
        },
      }).response;
    } catch (e) {
      console.log(e);
      postErrorNotification('Error updating permissions', 'Oops! There was an error setting puzzle permissions. Please let me know if this continues.');
      onHide();
    }

    setIsLoading(false);
  }



  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header className='purple-2-bkgd' closeButton>
        <div className='w-100 d-flex'>
          <div><strong>Collaboration Settings</strong></div>
          <div className='my-auto ms-auto'>
            {isLoading ? <TruckLoadingAnimation size={20} /> : <span className='small text-muted'>all settings saved</span>}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {permissions && <>
          
          {permissions.users ? <>

            {ownerEmail ? (
              <div className='d-flex pb-2'>
                <EmailWithBadge email={ownerEmail} myEmail={myEmail} />
                <div className='ms-auto my-auto text-secondary small'>PUZZLE OWNER</div>
              </div>
            ) : (
              <>
                <div>Puzzle owner not available</div>
                <div className='small text-muted'>You may not have permissions to view who owns the puzzle, or the owner's account may not be set up properly yet.</div>
              </>
            )}

            {Object.entries(permissions.users).sort((a, b) => a[0].localeCompare(b[0])).map(([email, permissionsLevel], idx) => (
              <div className='d-flex border-top py-2'>
                <EmailWithBadge className='my-auto' email={email} myEmail={myEmail} />
                {myPermissionsLevel === 'MANAGE' ? (
                  <DropdownButton
                    className='ms-auto'
                    id={`permissions-dropdown-${idx}`}
                    title={permissionsLevel}
                    disabled={myEmail && myEmail === email}
                    variant='outline-info'
                    size='sm'
                    menuAlign='right'
                  >
                    <Dropdown.Item className='text-end' onClick={() => updatePermissionsFor(email, 'MANAGE', permissionsLevel)}>Edit puzzle &amp; <strong>manage</strong> sharing</Dropdown.Item>
                    <Dropdown.Item className='text-end' onClick={() => updatePermissionsFor(email, 'EDIT', permissionsLevel)}><strong>Edit</strong> puzzle</Dropdown.Item>
                    <Dropdown.Item className='text-end' onClick={() => updatePermissionsFor(email, 'VIEW', permissionsLevel)}><strong>View</strong> puzzle</Dropdown.Item>
                    <Dropdown.Item
                      className='text-danger text-end'
                      onClick={() => updatePermissionsFor(email, null, permissionsLevel)}
                    ><strong>Remove</strong> this collaborator</Dropdown.Item>
                  </DropdownButton>
                ) : (
                  <span className='ms-auto my-auto small text-info' id={`permissions-label-${idx}`}>{permissionsLevel}</span>
                )}
              </div>
            ))}

            {myPermissionsLevel === 'MANAGE' && (
              <div className='d-flex mt-3'>
                <FormControl
                  className='me-1'
                  type='email'
                  size='sm'
                  placeholder='Add collaborator email'
                  value={newCollaboratorEmail}
                  onChange={e => setNewCollaboratorEmail(e.target.value)}
                  isInvalid={newCollaboratorEmail !== '' && !newCollaboratorEmailIsValid}
                />

                <DropdownButton className='me-3' size='sm' menuAlign='right' variant='outline-secondary' title={newCollaboratorPermissionsLevel}>
                  <Dropdown.Item className='text-end' onClick={() => setNewCollaboratorPermissionsLevel('MANAGE')}>Edit puzzle &amp; <strong>manage</strong> sharing</Dropdown.Item>
                  <Dropdown.Item className='text-end' onClick={() => setNewCollaboratorPermissionsLevel('EDIT')}><strong>Edit</strong> puzzle</Dropdown.Item>
                  <Dropdown.Item className='text-end' onClick={() => setNewCollaboratorPermissionsLevel('VIEW')}><strong>View</strong> puzzle</Dropdown.Item>
                </DropdownButton>

                <OverlayTrigger placement='top' overlay={
                  <Tooltip>Send them an email with the puzzle link</Tooltip>
                }>
                  <FormCheck className='my-auto me-3 small text-muted' id='notify-new-collaborator-check' label='Notify' checked={notifyNewCollaborator} onChange={() => setNotifyNewCollaborator(nnc => !nnc)} />
                </OverlayTrigger>

                <Button
                  variant='secondary'
                  size='sm'
                  disabled={newCollaboratorEmail === '' || !newCollaboratorEmailIsValid}
                  onClick={() => {
                    updatePermissionsFor(newCollaboratorEmail, newCollaboratorPermissionsLevel, null);
                    setNewCollaboratorEmail('');
                    setNewCollaboratorPermissionsLevel('MANAGE');
                    setNotifyNewCollaborator(true);
                  }}
                >
                  Add
                </Button>
              </div>
            )}

          </> : <div className='text-center'>
            You do not have permissions to manage sharing.
          </div>}

          <div className='text-center mt-3'>
            {permissions.public.edit ? (
              <>
                <div style={{width: '100%', height: '15px', borderBottom: '1px solid #adadad', textAlign: 'center', marginBottom: '5px', marginTop: '3px'}}>
                  <span style={{fontSize: '12px', color: '#707070', backgroundColor: 'white', padding: '0 10px'}}>
                    Anyone with the link can <strong>edit</strong> this puzzle.
                  </span>
                </div>
                <div className='mt-4' hidden={myPermissionsLevel !== 'MANAGE'}>
                  <Button className='me-2' variant='outline-primary' size='sm' onClick={() => updatePublicPermissions(true, false)}>
                    Change public access to <strong>view</strong> only
                  </Button>
                  <Button variant='outline-danger' size='sm' onClick={() => updatePublicPermissions(false, false)}>Revoke all public access</Button>
                </div>
              </>
            ) : permissions.public.view ? (
              <>
                <div style={{width: '100%', height: '15px', borderBottom: '1px solid #adadad', textAlign: 'center', marginBottom: '5px', marginTop: '3px'}}>
                  <span style={{fontSize: '12px', color: '#707070', backgroundColor: 'white', padding: '0 10px'}}>
                    <strong>Anyone</strong> with the link can <strong>view</strong> this puzzle.
                  </span>
                </div>
                <div className='mt-4' hidden={myPermissionsLevel !== 'MANAGE'}>
                  <Button className='me-2' variant='outline-primary' size='sm' onClick={() => updatePublicPermissions(true, true)}>
                    Allow anyone with the link to <strong>edit</strong>
                  </Button>
                  <Button variant='outline-danger' size='sm' onClick={() => updatePublicPermissions(false, false)}>Revoke public access</Button>
                </div>
              </>
            ) : (
              <>
                <div style={{width: '100%', height: '15px', borderBottom: '1px solid #adadad', textAlign: 'center', marginBottom: '5px', marginTop: '3px'}}>
                  <span style={{fontSize: '12px', color: '#707070', backgroundColor: 'white', padding: '0 10px'}}>
                    This puzzle is <strong>private</strong>. Only these accounts have access.
                  </span>
                </div>
                <Button
                  className='mt-3 mx-auto'
                  hidden={myPermissionsLevel !== 'MANAGE'}
                  size='sm'
                  variant='outline-primary'
                  onClick={() => updatePublicPermissions(true, false)}
                >
                  Allow anyone with the link to view or edit
                </Button>
              </>
            )}
          </div>
        </>}
      </Modal.Body>
    </Modal>
  );
}