import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, ButtonGroup, Dropdown, DropdownButton, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoPause } from 'react-icons/io5';
import { PuzzleMetadataKey } from '../../libs/directionsLib';
import { PlayPreferenceKey, PlayStatus, usePlayPreferences, usePlayPuzzleData, usePlayState, usePlayStatus } from './PlayInteractionContext';
import { useAppContext } from '../../App';
import { FaVoteYea } from 'react-icons/fa';
import { IoMdRefresh } from 'react-icons/io';
import { possiblyPluralString } from '../../libs/miscLib';
// import { getAllPlaySessions } from '../../libs/playSessionLib';


function msToDisplayTime(ms) {
  const secs = ms / 1000;
  const totalSeconds = Math.floor(Math.max(0, secs - 0.5));
  const hours = Math.floor(totalSeconds / 3600);
  const mins = Math.floor((totalSeconds - 3600*hours) / 60);
  const seconds = totalSeconds % 60;
  return hours > 99 ? '99h+' : hours > 0 ? `${hours}:${('0' + mins).slice(-2)}:${('0' + seconds).slice(-2)}` : `${mins}:${('0' + seconds).slice(-2)}`;
}



export default function PlayToolbar() {

  const { windowWidth, brandIsShowing } = useAppContext();

  const { getPlayPreference, setPlayPreference } = usePlayPreferences();
  const { gridIsFullAndCorrect, gridIsFull, incorrectLocs, emptyLocs, revealLocs } = usePlayState();
  const { playStatus, play, pause, reset, getCurrentPlayTime } = usePlayStatus();
  const { /*puzzleId, */ puzzleMetadata } = usePlayPuzzleData();
  const title = puzzleMetadata.get(PuzzleMetadataKey.TITLE);
  const author = puzzleMetadata.get(PuzzleMetadataKey.AUTHOR);
  const date = puzzleMetadata.get(PuzzleMetadataKey.DATE);
  const editor = puzzleMetadata.get(PuzzleMetadataKey.EDITOR);
  const publisher = puzzleMetadata.get(PuzzleMetadataKey.PUBLISHER);
  const copyright = puzzleMetadata.get(PuzzleMetadataKey.COPYRIGHT);


  // Start & pause modals just display as a function of the playStatus, but the wrong & end modals need to have their own state
  const [showWrongModal, setShowWrongModal] = useState(false);
  const [revealNumberOfWrongLetters, setRevealNumberOfWrongLetters] = useState(false);
  const [showEndModal, setShowEndModal] = useState(false);

  // // Show number of plays that the puzzle has gotten
  // const [allPlaySessions, setAllPlaySessions] = useState(null);

  // Display the game timer
  const [displayTime, setDisplayTime] = useState('0:00');
  const gameTimerInterval = useRef(null);


  // useEffect(() => {
  //   // Load allPlaySessions
  //   if (puzzleId) {
  //     getAllPlaySessions(puzzleId, aps => setAllPlaySessions(aps));
  //   }
  // }, [puzzleId]);

  useEffect(() => {
    if (playStatus === PlayStatus.PLAY) {
      // It just turned to PLAY
      if (gameTimerInterval.current) clearInterval(gameTimerInterval.current);
      gameTimerInterval.current = setInterval(() => setDisplayTime(msToDisplayTime(getCurrentPlayTime())), 1000);
    } else if (playStatus === PlayStatus.END) {
      // It just turned to END
      setDisplayTime(msToDisplayTime(getCurrentPlayTime()));
      setShowEndModal(true);
    }

    return () => {
      if (gameTimerInterval.current) clearInterval(gameTimerInterval.current);
    }
  }, [playStatus, getCurrentPlayTime]);

  useEffect(() => {
    if (gridIsFull && !gridIsFullAndCorrect) {
      setShowWrongModal(true);
    }
  }, [gridIsFull, gridIsFullAndCorrect]);



  // Start modal
  const startModal = (
    <Modal show={playStatus === PlayStatus.START} fullscreen='sm-down' onHide={() => play()}>
      <Modal.Header className='teal-1-bkgd' closeButton>
        <strong>Start puzzle</strong>
      </Modal.Header>
      <Modal.Body className='text-center'>
        <div className='fw-bold'>{title}</div>
        <div className='text-secondary small'>
          {author && <div>by {author}</div>}
          {date && <div>{date}</div>}
          {editor && <div>Edited by {editor}</div>}
          {publisher && <div>{publisher}</div>}
          {copyright && <div>&copy; {copyright}</div>}
        </div>
        <hr />
        {puzzleMetadata.get(PuzzleMetadataKey.START_MESSAGE) && <>
          <Badge className='mb-2' bg='success'>Constructor note</Badge>
          <p className='balanced-text'>{puzzleMetadata.get(PuzzleMetadataKey.START_MESSAGE)}</p>
          <hr />
        </>}
        <Button
          className='mt-2 px-3'
          variant='outline-success'
          onClick={() => play()}
        >
          <img className='me-2' src='/android-chrome-256x256.png' alt='Crossworthy' width='25px' />
          Play!
        </Button>
      </Modal.Body>
    </Modal>
  );

  // Pause modal
  const pauseModal = (
    <Modal show={playStatus === PlayStatus.PAUSE} fullscreen='sm-down' onHide={() => play()}>
      <Modal.Header className='teal-1-bkgd' closeButton>
        <strong>Paused</strong>
      </Modal.Header>
      <Modal.Body className='text-center'>
        <div>Current time: {displayTime}</div>
        <hr />
        <Button
          className='mt-2 px-3'
          variant='outline-success'
          onClick={() => play()}
        >Resume</Button>
        <div />
        <Button
          className='mt-3'
          variant='outline-danger'
          size='sm'
          onClick={() => {
            if (window.confirm('Reveal all the answers?')) {
              revealLocs(incorrectLocs.concat(emptyLocs));
            }
          }}
        >Reveal answers</Button>
      </Modal.Body>
    </Modal>
  );
  
  // Wrong modal
  const wrongModal = (
    <Modal show={showWrongModal} fullscreen='sm-down' onHide={() => {
      setShowWrongModal(false);
      setRevealNumberOfWrongLetters(false);
    }}>
      <Modal.Header className='teal-1-bkgd' closeButton>
        <strong>Almost there!</strong>
      </Modal.Header>
      <Modal.Body className='text-center'>
        <div>You've almost got it, but some letters are incorrect.</div>
        {revealNumberOfWrongLetters ? (
          <div className='text-info mt-3'>You have {possiblyPluralString(incorrectLocs.length, 'wrong letter')}.</div>
        ) : (
          <Button
            className='mt-3'
            variant='outline-info'
            size='sm'
            onClick={() => setRevealNumberOfWrongLetters(true)}
          >
            See number of wrong letters
          </Button>
        )}
      </Modal.Body>
    </Modal>
  );

  // End modal
  const endModal = (
    <Modal show={showEndModal} fullscreen='sm-down' onHide={() => setShowEndModal(false)}>
      <Modal.Header className='teal-2-bkgd' closeButton>
        <strong>Bravo!</strong>
      </Modal.Header>
      <Modal.Body className='text-center'>
        <div className='d-flex'>
          <img className='ms-auto me-3 my-auto' src='/android-chrome-256x256.png' alt='Crossworthy' width='34px' height='34px' />
          <div className='me-auto'>
            You completed this puzzle in {displayTime}.<br />
            Thanks for playing!
          </div>
          {puzzleMetadata.get(PuzzleMetadataKey.END_MESSAGE) && <>
            <hr />
            <Badge className='mb-2' bg='success'>Constructor note</Badge>
            <p className='balanced-text'>{puzzleMetadata.get(PuzzleMetadataKey.END_MESSAGE)}</p>
          </>}
        </div>
      </Modal.Body>
    </Modal>
  );


  return (
    <>
      <div
        className={'w-100 d-flex mb-1 teal-1-bkgd ' + (brandIsShowing ? 'ms-3' : '')}
        style={{position: 'sticky', top: '0', paddingLeft: brandIsShowing ? '60px' : '6px', paddingRight: brandIsShowing ? '75px' : '50px', paddingTop: '3px', paddingBottom: '4px', zIndex: 100, boxShadow: '0 0 4px black'}}
      >
        <OverlayTrigger
          placement='bottom'
          trigger={['hover', 'focus', 'click']}
          rootClose
          overlay={
            <Tooltip>
              <strong>{title || 'Untitled puzzle'}</strong>{author && <em className='ms-2'>by {author}</em>}
            </Tooltip>
          }
        >
          <div className={'mt-auto ' + (gridIsFullAndCorrect ? 'text-success' : 'text-dark')} style={{ minWidth: 0, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
            <span className='me-3 fw-bold block-text' style={{fontSize: windowWidth > 767 ? '20px' : '16px'}}>{title || 'Untitled Puzzle'}</span>
            {author && <span style={{fontSize: windowWidth > 767 ? '14px' : '13px'}}>by {author}</span>}
          </div>
        </OverlayTrigger>
        <div className='ms-auto my-auto text-end d-flex'>
          <span className='small text-muted me-1 my-auto'>{playStatus === PlayStatus.END ? 'completed in ' : ''}{displayTime}</span>
          {playStatus === PlayStatus.END ? (
            <Button
              className='p-1 mx-2 d-inline-flex'
              variant='outline-info'
              size='sm'
              onClick={() => {
                if (window.confirm('Restart this puzzle? This will remove all your progress.')) {
                  reset();
                }
              }}
            >
              <IoMdRefresh className='my-auto' />
              {brandIsShowing && <span className='ms-1'>Start over</span>}
            </Button>
          ) : (
            <>
              <Button
                className='p-1 d-inline-flex mx-2'
                variant='outline-info'
                size='sm'
                disabled={playStatus === PlayStatus.END}
                onClick={() => playStatus === PlayStatus.PLAY ? pause() : play()}
              >
                <IoPause className='my-auto' />
                {brandIsShowing && <span className='ms-1'>Pause</span>}
              </Button>

              <DropdownButton title={<span><FaVoteYea />{brandIsShowing && ' Reveal'}</span>} as={ButtonGroup} size='sm' variant='outline-danger' disabled={playStatus === PlayStatus.END}>
                <Dropdown.Item
                  onClick={() => setPlayPreference(PlayPreferenceKey.SHOW_MISTAKES_INSTANTLY, !getPlayPreference(PlayPreferenceKey.SHOW_MISTAKES_INSTANTLY))}
                >Turn {getPlayPreference(PlayPreferenceKey.SHOW_MISTAKES_INSTANTLY) ? 'off' : 'on'} instant checking</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={() => {
                    if (window.confirm('Reveal all the answers?')) {
                      revealLocs(incorrectLocs.concat(emptyLocs));
                    }
                  }}
                >Reveal all answers</Dropdown.Item>
              </DropdownButton>
            </>
          )}
        </div>
      </div>

      {startModal}
      {pauseModal}
      {wrongModal}
      {endModal}
    </>
  );

}