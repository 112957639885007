import React from 'react';
import { Form, Col, Button } from 'react-bootstrap';

export default function LegacyHome() {
  return (
    <div>
      <div style={{padding: '20px 0', textAlign: 'center'}}>
        <h1>
          <img src="/android-chrome-256x256.png" alt="crossworthy logo" width="100" style={{paddingRight: 12}} />
          Crossworthy
        </h1>

        <p className="text-muted">A free, indie crossword site by May Huang and Kevin Trickey</p>

        <iframe 
          style={{width: '100%', height: 600, padding: '15px 0', border: 'none'}}
          allowFullScreen={true}
          title="amuselabs-panel"
          src="https://amuselabs.com/pmm/date-picker?set=1158052475352fb529072fe3bc67f87990be16bd6a30b174f8bbcee7db9b8bef&limit=69&embed=1"
        />        
      
      
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Form
            action='https://tinyletter.com/CrossWorthy'
            method='post'
            target='popupwindow'
            // onSubmit={(e) => {
            //   window.open('https://tinyletter.com/CrossWorthy', 'popupwindow', 'scrollbars=yes,width=800,height=600');
            //   return true;
            // }}
          >
            <Form.Row className="align-items-center" style={{margin: 0, padding: 0}}>
              <Col xs="auto">
                <Form.Label htmlFor="inlineEmailInput">
                  Receive a puzzle in your inbox every few weeks! 
                </Form.Label>
              </Col>
              <Col xs="auto">
                <Form.Control
                  className="mb-2"
                  id="inlineEmailInput"
                  name="email"
                  placeholder="Email"
                />
              </Col>
              <Col xs="auto">
                <Button 
                  variant="info"
                  type="submit"
                  className="mb-2"
                >
                  Subscribe
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </div>

      </div>
    </div>
  );
}