import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { CgSpinnerAlt } from 'react-icons/cg';
import { BsCheckCircle, BsXSquare, BsQuestion, BsSlashCircle } from 'react-icons/bs';
import { MdOutlineTimer } from 'react-icons/md';
import { WORD_SUGGESTION_STATUS } from '../../libs/autofillLib';



export default function WordSuggestion({
  word,
  score,
  showIcon = true,         // if false, no icon will be displayed at all
  isEvaluating = false,    // if true (and showIcon), will show a spinning icon to indicate it's currently searching for a fill
  status = WORD_SUGGESTION_STATUS.NOT_EVALUATED,   // determines what icon to display assuming (showIcon && !isEvaluating)
  ...props
}) {

  return (
    <ListGroup.Item {...props}>
      <div className='d-flex'>
        <span className={status !== WORD_SUGGESTION_STATUS.FILLABLE ? 'text-muted' : ''}>{word.toLowerCase()}</span>
        <span className='text-muted ms-auto'>{score}</span>
        {showIcon && (
          isEvaluating ? (
            <CgSpinnerAlt className='spinning my-auto ms-2' />
          ) : status === WORD_SUGGESTION_STATUS.TIMEOUT ? (
            <MdOutlineTimer className='my-auto ms-2 text-secondary' />
          ) : status === WORD_SUGGESTION_STATUS.FILLABLE ? (
            <BsCheckCircle className='my-auto ms-2 text-success' />
          ) : status === WORD_SUGGESTION_STATUS.UNFILLABLE ? (
            <BsXSquare className='my-auto ms-2 text-danger' />
          ) : status === WORD_SUGGESTION_STATUS.IGNORED ? (
            <BsSlashCircle className='my-auto ms-2 text-secondary' />
          ) : (
            <BsQuestion className='my-auto ms-2 text-secondary' />
          )
        )}
      </div>
    </ListGroup.Item>
  );
}