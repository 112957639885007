import React, { useEffect, useState } from 'react';
import { produce } from 'immer';
import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { useAppContext } from '../App';
import { get, post } from 'aws-amplify/api';
import LoadingAnimation from '../components/reusable/LoadingAnimation';
import CollectionIcon from '../components/reusable/CollectionIcon';
import { GiWalrusHead } from 'react-icons/gi';
import { randomString } from '../libs/miscLib';
import SwitchButton from '../components/reusable/SwitchButton';
import LoaderButton from '../components/reusable/LoaderButton';
import CollectionLineItem from '../components/collections/CollectionLineItem';
import { MdOutlineHome } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';


const COLLECTION_METADATA_KEY = {
  NAME: 'name',
}

const firstRandomString = randomString(8, 'c-');



export default function ManageCollections() {

  const { isAuthenticated, showLoginPage, setDocumentTitle, resetDocumentTitle } = useAppContext();
  const navigate = useNavigate();

  // Set tab title
  useEffect(() => {
    setDocumentTitle('Manage Collections');
    return resetDocumentTitle;
  }, [setDocumentTitle, resetDocumentTitle]);



  // Load myCollections
  const [myCollections, setMyCollections] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!isAuthenticated) return;

    async function loadMyCollections() {
      try {
        const res = await (await get({
          apiName: 'userPuzzles',
          path: '/collections'
        }).response).body.json();
        setMyCollections(res);
      } catch (e) {
        setMyCollections(null);
        console.log(e);
      }
      setIsLoading(false);
    }

    setIsLoading(true);
    loadMyCollections();
  }, [isAuthenticated]);


  // Create new collection
  const [newCollectionModalShowing, setNewCollectionModalShowing] = useState(false);
  const [createIsLoading, setCreateIsLoading] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState('');
  const [newCollectionId, setNewCollectionId] = useState(firstRandomString)
  const [newCollectionPublicView, setNewCollectionPublicView] = useState(true);
  const [newCollectionErrorMessage, setNewCollectionErrorMessage] = useState('');
  function resetNewCollectionModalValues() {
    setNewCollectionName('');
    setNewCollectionId(randomString(8, 'c-'));
    setNewCollectionPublicView(true);
    setNewCollectionErrorMessage('');
  }
  useEffect(() => {
    setNewCollectionErrorMessage('');   // reset the error message anytime something is edited
  }, [newCollectionName, newCollectionId, newCollectionPublicView]);
  const createCollectionModal = (
    <Modal show={newCollectionModalShowing} onHide={() => setNewCollectionModalShowing(false)}>
      <Modal.Header className='purple-2-bkgd' closeButton>
        <strong>Create new collection</strong>
      </Modal.Header>
      <Modal.Body>
        <InputGroup>
          <InputGroup.Text>Name</InputGroup.Text>
          <FormControl id='new-collection-name-form' placeholder='Collection Name' value={newCollectionName} onChange={e => setNewCollectionName(e.target.value)} />
        </InputGroup>
        
        <InputGroup className='mt-3' size='sm'>
          <InputGroup.Text>id</InputGroup.Text>
          <FormControl id='new-collection-id-form' placeholder='id' value={newCollectionId} onChange={e => {
            if (!e.target.value || /^[a-zA-Z][a-zA-Z0-9-]{0,32}$/.test(e.target.value)) {
              setNewCollectionId(e.target.value);
            }
          }} />
        </InputGroup>
        <p className='small text-muted lineheight-12 mt-1'>This id will appear in your collection's URL when you share it. It must be unique and cannot be changed later.</p>

        <div>Who can play your collection?</div>
        <SwitchButton
          onCheck={() => setNewCollectionPublicView(true)}
          onUncheck={() => setNewCollectionPublicView(false)}
          isOn={newCollectionPublicView}
          onText='Anyone with the link (public)'
          offText='Only specific accounts'
          variant='outline-info'
        />

        <div className='mt-3 d-flex'>
          <span className='small text-danger'>{newCollectionErrorMessage}</span>
          <LoaderButton
            className='ms-auto'
            isLoading={createIsLoading}
            variant='success'
            onClick={async () => {
              try {
                // Submit to API
                setCreateIsLoading(true);
                const newCollection = await (await post({
                  apiName: 'userPuzzles',
                  path: '/collections',
                  options: {
                    body: {
                      collectionId: newCollectionId,
                      publicViewPermissions: newCollectionPublicView,
                      collectionMetadata: {
                        [COLLECTION_METADATA_KEY.NAME]: newCollectionName,
                      },
                      collectionPuzzles: {},
                    },
                  },
                }).response).body.json();

                // Check if it was a duplicate collectionId
                if (newCollection.errorCode) {
                  if (newCollection.errorCode) {
                    setNewCollectionErrorMessage('This collection ID already exists!');
                  } else {
                    console.log('Unknown error code ' + newCollection.errorCode);
                    setNewCollectionErrorMessage('Unknown error');
                  }
                } else {
                  // Get the updated collections and add it to the state
                  const res = await (await get({
                    apiName: 'userPuzzles',
                    path: '/collections',
                  }).response).body.json();
                  setMyCollections(res);
                }
                resetNewCollectionModalValues();
                setCreateIsLoading(false);
                setNewCollectionModalShowing(false);
              } catch (e) {
                console.log('Server error during new collection write.');
                console.log(e);
                setCreateIsLoading(false);
              }
            }}
          >Create</LoaderButton>
        </div>
      </Modal.Body>
    </Modal>
  );



  return (
    <div>
      <div
        className='d-flex small purple-1-bkgd'
        style={{position: 'sticky', height: '24px', top: '0', paddingLeft: '70px', paddingRight: '60px', paddingTop: '3px', paddingBottom: '3px', zIndex: 100, boxShadow: '0 0 4px black'}}
      />

      <div className='mb-5 mt-3'>
        <div className='d-flex'>
          <div className='h3 block-text mx-auto'>Manage Collections</div>
        </div>
        <div className='small text-center mb-5'>Become a self-published puzzle curator by creating collections.</div>

        {!isAuthenticated ? (
          <div className='d-flex'>
            <div className='h5 mx-auto mt-3 text-center balanced-text'>
              Please <a href='/account' onClick={e => {
                e.preventDefault();
                showLoginPage();
              }}>log in or sign up</a> to start publishing your puzzle collections!
            </div>
          </div>
        ) : isLoading ? (
          <div className='d-flex'>
            <LoadingAnimation className='mx-auto' size={15} />
          </div>
        ) : !myCollections ? (
          <div className='d-flex'>
            <div className='mx-auto d-flex' style={{maxWidth: '30rem'}}>
              <GiWalrusHead className='me-4 my-auto' size={66} />
              <p className='text-center my-auto'>
                We're having trouble retrieving your collections at the moment.
                If the problem persists, please <a href='/feedback'>let us know</a>.
              </p>
            </div>
          </div>
        ) : Object.keys(myCollections).length === 0 ? (
          <>
            <div className='d-flex'>
              <div className='mx-auto d-flex' style={{maxWidth: '30rem'}}>
                <CollectionIcon className='me-4 my-auto' size={50} />
                <p className='text-center my-auto'>
                  Collections are groups of puzzles you can publish as a series.
                  You can share or link to the whole collection.
                </p>
              </div>
            </div>
            <div className='d-flex mt-3'>
              <Button
                className='mx-auto mt-3 zoomable'
                variant='success'
                onClick={() => setNewCollectionModalShowing(true)}
              >Create a collection</Button>
            </div>
          </>
        ) : (
          <>
            {Object.values(myCollections).sort((a, b) => b.modifiedAt - a.modifiedAt).map(collectionObj => (
              <div className='d-flex' key={`collection-${collectionObj.collectionId}`}>
                <CollectionLineItem
                  collectionObj={collectionObj}
                  onDelete={() => {
                    setMyCollections(produce(myCollections, draft => {
                      delete draft[collectionObj.collectionId];
                    }));
                  }}
                />
              </div>
            ))}

            <div className='d-flex'>
              <Button
                className='mx-auto mt-3 zoomable'
                variant='success'
                disabled={Object.keys(myCollections).length >= 20}
                onClick={() => setNewCollectionModalShowing(true)}
              >Create another collection</Button>
            </div>
            {Object.keys(myCollections).length >= 20 && <div className='small mx-auto'>Users are currently capped at 20 collections. Please reach out to us if you have need of more!</div>}

            <div className='d-flex'>
              <Button
                className='mx-auto mt-3'
                size='sm'
                variant='outline-info'
                href='/construct'
                onClick={e => {
                  e.preventDefault();
                  navigate('/construct');
                }}
              >
                <MdOutlineHome className='me-2' size={18} />
                Construct home
              </Button>
            </div>
          </>
        )}
      </div>

      {createCollectionModal}
    </div>
  );
}