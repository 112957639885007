import { getCurrentUser, signUp, signIn, confirmSignIn, signOut } from 'aws-amplify/auth';
import { post } from 'aws-amplify/api';



export async function getAuthenticatedUsername() {
  try {
    const { username } = await getCurrentUser();
    return username;
  } catch (e) {
    console.log('Could not fetch current username.');
    console.log(e);
    return undefined;
  }
}



export async function signup(email, { onSuccess, onPreexistingAccountError, onError }) {
  try {
    let dummyNumber;
    if (window.crypto && window.crypto.getRandomValues) {
      var dummyArray = new Uint32Array(2);
      window.crypto.getRandomValues(dummyArray);
      dummyNumber = dummyArray[0] / (dummyArray[0] + dummyArray[1]);
    } else {
      dummyNumber = Math.random();
    }
    const { isSignUpComplete } = await signUp({
      username: email,
      password: dummyNumber.toString(36).slice(-8),
      options: {
        userAttributes: { email },
      }
    });

    if (!isSignUpComplete) {
      if (onError) onError();
    } else {
      if (onSuccess) onSuccess();
    }
  } catch (e) {
    if (e.name === 'UsernameExistsException') {
      if (onPreexistingAccountError) onPreexistingAccountError();
    } else {
      if (onError) onError();
    }
  }
}



export async function loginSubmitEmail(email, { onSuccess, onNonexistentAccountError, onError }) {
  try {
    await signIn({
      username: email,
      options: {
        authFlowType: 'CUSTOM_WITHOUT_SRP',     // reference: https://github.com/aws-amplify/amplify-js/issues/13049
      },
    });
    if (onSuccess) onSuccess();
  } catch (e) {
    if (e.name && e.name === 'UserNotFoundException') {
      if (onNonexistentAccountError) onNonexistentAccountError();
    } else {
      if (onError) onError();
    }
  }
}



export async function loginSubmitOTP(oneTimePassword, { onSuccess, onWrongPasswordError, onPostLoginError, onError }) {
  try {
    const res = await confirmSignIn({ challengeResponse: oneTimePassword });
    if (res.isSignedIn) {
      // Send temporary login code to link to Cognito ID (https://www.notion.so/Linking-User-Pool-sub-email-to-IdP-user-ID-8db74f8064d34aca9712b23b626cf186)
      // Note: this must happen BEFORE we call onSuccess, since some effects triggered by the onSuccess (e.g. listing myConstructions) depend upon proper backend linkage
      try {
        await post({
          apiName: 'loginTable',
          path: '/postLogin',
          options: {
            body: {
              tempLoginCode: oneTimePassword,
            },
          },
        }).response;
      
        // Success
        if (onSuccess) onSuccess();
      } catch (postLoginError) {
        console.log(postLoginError);
        if (onPostLoginError) onPostLoginError();
      }
    } else {
      if (onWrongPasswordError) onWrongPasswordError();
    }
  } catch (e) {
    if (onError) onError();
  }
}


/**
 * Note: this function should [only] be called in the handleLogout function in the AppContext, so that it may properly set app context variables like isAuthenticated.
 * @param {*} param0 
 */
export async function logout({ onSuccess, onError }) {
  try {
    await signOut();
    if (onSuccess) onSuccess();
  } catch (e) {
    if (onError) onError();
  }
}

