import { get } from 'aws-amplify/api';
import { stageConfig } from '../index';

// All these are global variables that persist as long as the web socket is open (reset to null in closeWsConnection method).


var wsConnection;
var puzzleId;
var callbacks;
var displayParameters;   // { displayName, cursorColor }

var connectionHasBeenVerified;
var openConnectionTimestamps = [];   // this is a list of timestamps indicating when attempts to connect were made: helps determine when to stop retrying

var changeEventQueueToSubmit;
var submissionTimeout;    // the setTimeout ID of the next event submission, so that it may be canceled by the event-submission throttling system
var lastActivityTimestamp;  // this is compared with Date.now() to determine timeouts



/**
 * Opens a live Web Socket connection for editing a puzzle.
 * @param {String} puzzleId_ The puzzleId string.
 * @param {Object} callbacks_ { onConnect, onError, handleReceiveUpdate, handleCursorUpdate }. Callback functions.
 * @param {Object} displayParameters_ { cursorColor, displayName }. Optional specifications.
 */
export async function openNewWsConnection(puzzleId_, callbacks_, displayParameters_) {
  connectionHasBeenVerified = false;
  changeEventQueueToSubmit = [];
  submissionTimeout = null;
  lastActivityTimestamp = Date.now();
  openConnectionTimestamps.push(Date.now());

  // Unpack parameters
  const { onConnect, handleReceivedUpdate, handleCursorUpdate, onError } = callbacks_;
  puzzleId = puzzleId_;   // save puzzleId globally
  callbacks = callbacks_;   // save callbacks as well, in case the connection closes and we need to re-open with this function
  if (displayParameters_) displayParameters = displayParameters_;  // only overwrite if available: re-connections should preserve old display parameters
  const { displayName, cursorColor } = displayParameters;  // if undefined, it's okay; server will assign randomly

  // Get ticket
  var ticketId;
  try {
    const resp = await (await get({
      apiName: 'ticketGenerator',
      path: '/ticketGenerator'
    }).response).body.json();
    ticketId = resp.ticketId;
  } catch (e) {
    console.log('Could not get ticket while opening new Ws Connection. Aborting.')
    console.log(e);
    onError({ cause: 'no ticket' });
    return;
  }

  // Open WS connection
  const wsUrl = `${stageConfig.webSocket.url}?ticketId=${ticketId}&puzzleId=${puzzleId}`
  wsConnection = new WebSocket(wsUrl);

  // On successful connect, verify the connection
  wsConnection.onopen = () => {
    try {
      wsConnection.send(JSON.stringify({ action: 'verifyConnection', data: { puzzleId, ticketId, displayName, cursorColor } }));
    } catch (e) {
      // If the websocket is tried to open multiple times in quick succession, this can lead to (harmless in production) errors if onopen is called after the object is no longer usable
      console.log(e);
    }
  };

  // Log any errors, for debugging purposes
  wsConnection.onerror = e => {
    console.log('websocket.onerror:');
    console.log(e);
  }

  // If the web socket connection closes, diagnose why it closed (because of an error?) and handle appropriately. This is more reliable than using the onerror handler.
  wsConnection.onclose = e => {
    console.log('Handling a websocket close with event:');
    console.log(e);

    if (e.code === 3456) {
      // This was an intentional closure called by the customer, so don't do anything. The other cases thus signify errors and unintentional closures.
    } else if (e.reason === 'Going away' || lastActivityTimestamp < Date.now() - 8 * 60 * 1000) {    // API Gateway gives this reason on their 10 minute timeout
      // If the last user activity was over 8 minutes ago, this counts as a timeout error - so terminate and leave up to BoardInteractionContext about when it wants to reinitiate
      onError({ cause: 'timeout' });
    } else if (openConnectionTimestamps.length > 3 && openConnectionTimestamps[openConnectionTimestamps.length - 3] > Date.now() - 10000) {
      // If we've tried to (re)connect 3+ times in the last 10 seconds, there's something wrong, so we just terminate by calling onError()
      onError({ cause: 'error' });
    } else {
      // Otherwise, we'll try to reconnect by creating a new websocket connection
      openNewWsConnection(puzzleId_, callbacks_, displayParameters_);
    }
  }

  wsConnection.onmessage = (e) => {
    const { message, payload } = JSON.parse(e.data);
    if (message === 'puzzleUpdate') {
      const { puzzleContent, lastChangeEventId=0, knownConnections=null } = payload;

      if (connectionHasBeenVerified) {
        handleReceivedUpdate({ puzzleContent, serversLastChangeEventId: lastChangeEventId, knownConnections });
      } else {
        // If it's the first time, set the connection to verified and perform the "onConnect" callback
        connectionHasBeenVerified = true;
        onConnect({ puzzleContent, serversLastChangeEventId: lastChangeEventId, knownConnections });
      }

    } else if (message === 'cursorUpdate') {

      const { publicConnectionId, cursorLoc } = payload;
      handleCursorUpdate(publicConnectionId, cursorLoc);

    } else if (message === 'Internal server error') {

      console.log('Internal server error');
      if (wsConnection) wsConnection.close(3012, 'Internal server error');   // this close event will trigger the onclose handler

    } else {
      
      console.log('Warning: message not handled by client:');
      console.log(message);
      console.log(payload);
      if (wsConnection) wsConnection.close(3011, 'Unhandled message to client');   // this close event will trigger the onclose handler

    }
  }
}



/**
 * Called by customers to close the web socket connection. Closes with custom error code 3456 to signify we should not try to reopen.
 */
export function closeWsConnection() {
  if (submissionTimeout) clearTimeout(submissionTimeout);

  if (wsConnection) wsConnection.close(3456, 'User requested');
}


// For use in JSON.stringify to preserve ES6 Map objects
function replacer(key, value) {
  if(value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  } else {
    return value;
  }
}



/**
 * Immediately submits all queued change events to the server, and clears the queue.
 */
function submitAllQueuedChangeEvents() {
  if (!wsConnection || !puzzleId) {
    console.log(`Error: No wsConnection ${wsConnection} or puzzleId ${puzzleId}`);
    return;
  }
  if (wsConnection.readyState !== 1) {
    console.log(`Error: wsConnection readyState is ${wsConnection.readyState} (must be 1)`);
    return;
  }

  wsConnection.send(JSON.stringify({
    action: 'submitChangeEvents',
    data: {
      puzzleId,
      changeEvents: changeEventQueueToSubmit.splice(0, changeEventQueueToSubmit.length),
    },
  }, replacer));
}



/**
 * Should be called any time something happens that should be classified as "user activity" for the purposes of the web socket connection.
 * Ensures that the web socket is still active, and if not, reinstates it.
 */
async function userIsActive() {
  lastActivityTimestamp = Date.now();

  if (!wsConnection || wsConnection.readyState !== 1) {
    // The connection somehow doesn't exist (consider this the last line of defense)
    await openNewWsConnection(puzzleId, callbacks);
  }
}




/**
 * Called to submit a list of change events to the server. To the caller, this can be called at point-of-use without throttling.
 * This function may decide to delay and consolidate the actual submissions for throttling purposes.
 * @param {[ChangeEvent]} changeEvents 
 * @param {number} throttle The maximum throttle time in milliseconds
 */
export async function submitChangeEventsToServer(changeEvents, throttle = 500) {
  await userIsActive();

  changeEventQueueToSubmit.push(...changeEvents);
  if (!submissionTimeout) {
    submissionTimeout = setTimeout(() => {
      submitAllQueuedChangeEvents();
      submissionTimeout = null;
    }, throttle);
  }

}




export async function broadcastCursorMove(cursorLoc) {
  await userIsActive();

  if (!wsConnection || !puzzleId) {
    console.log(`Error: No wsConnection ${wsConnection} or puzzleId ${puzzleId}`);
    return;
  }
  if (wsConnection.readyState !== 1) {
    console.log(`Error: wsConnection readyState is ${wsConnection.readyState} (must be 1)`);
    return;
  }

  wsConnection.send(JSON.stringify({
    action: 'broadcastCursorMove',
    data: {
      puzzleId,
      cursorLoc,
    },
  }));
}
