import React from 'react';
import Browse from '../components/browse/Browse';
import { useTraversal } from '../libs/traversalLib';
import { usePuzzleMover } from '../components/browse/PuzzleMover';
import LoadingAnimation from '../components/reusable/LoadingAnimation';
import { BiConfused } from 'react-icons/bi';
import { useAppContext } from '../App';


export default function MyConstructions() {

  // Include traversal functionality
  const { 
    errorMessage, myConstructions,
    currentDirPath,
    currentDirStructure,
    callbacks,
  } = useTraversal();

  const { isAuthenticated } = useAppContext();

  // Include the puzzle mover modal functionality - separate traversal instance
  const { puzzleMoverModal, setPuzzleBeingMoved } = usePuzzleMover(callbacks.refreshDataFromServer);


  // Display any non-loads or errors
  if (errorMessage) {
    return <div className='my-5 d-flex'>
      <div className='not-too-wide mx-auto my-4 text-center'>
        <BiConfused className='mb-2' size={30} />
        <div className='mb-4'>{errorMessage}</div>
        {isAuthenticated && <a href='/'>Crossworthy Home</a>}
      </div>
    </div>;
  }
  if (!myConstructions) {
    return (
      <div className='text-center mt-5'>
        <LoadingAnimation size={20} />
      </div>
    );
  }

  return (
    <>
      <Browse
        dirPath={currentDirPath}
        folderStructure={currentDirStructure}
        callbacks={{ setPuzzleBeingMoved, ...callbacks }}
      />

      {puzzleMoverModal}
    </>
  );

}