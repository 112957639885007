import React, { useState } from 'react';
import { post } from 'aws-amplify/api'
import { Button } from 'react-bootstrap';
import { TbHandStop } from 'react-icons/tb';
import LoaderButton from '../components/reusable/LoaderButton';
import { useAppContext } from '../App';
import { useToastNotifications } from '../libs/toastLib';
import { getAuthenticatedUsername } from '../libs/authLib';


/**
 * A component showing a Permission Denied message and a form to request permissions from the owner.
 * Meant for puzzles specifically (not collections).
 */
export default function PermissionDenied({ puzzleId }) {

  const { isAuthenticated, showLoginPage } = useAppContext();
  const { postErrorNotification } = useToastNotifications();

  const [isLoading, setIsLoading] = useState(false);
  const [hasRequested, setHasRequested] = useState(false);

  if (!hasRequested) {
    return (
      <div className='d-flex justify-content-center my-5'>
        <div className='not-too-wide'>
          <div className='d-flex justify-content-center my-3'>
            <TbHandStop size={40} />
          </div>
          <div className='text-center h2'>Permission denied!</div>
          <div className='mt-3 balanced-text text-center'>
            The puzzle owner has not given you permissions to view this puzzle.
          </div>
          <div className='d-flex justify-content-center mt-4'>
            {isAuthenticated ? (
              <div className='mx-auto d-flex align-items-center'>
                <span className='me-1 text-end'>If you would like, you may</span>
                <LoaderButton className='my-auto p-1' isLoading={isLoading} onClick={async () => {
                  setIsLoading(true);
                  try {
                    const username = await getAuthenticatedUsername();
                    await post({
                      apiName: 'userPuzzles',
                      path: '/puzzleSharing',
                      options: {
                        body: {
                          requesterEmail: username,
                          puzzleId,
                        },
                      },
                    }).response;
                    setIsLoading(false);
                    setHasRequested(true);
                  } catch (e) {
                    console.log(e);
                    postErrorNotification('Error requesting access', 'Sorry, something went wrong trying to request access! If this continues, please let us know.', ['puzzle-specific']);
                    setIsLoading(false);
                  }
                }}>request access</LoaderButton>
              </div>
            ) : (
              <div className='mx-auto d-flex align-items-center'>
                <span className='me-1'>You may need to</span>
                <Button className='my-auto p-1' onClick={() => showLoginPage()}>login</Button>
                <span className='ms-1'>to view this puzzle.</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='text-center my-5'>
      <div className='h2 mb-4'>Access requested!</div>
      <div className='d-flex justify-content-center'>
        <div className='not-too-wide balanced-text'>
          We've sent an email to the puzzle owner letting them know. You'll be able to refresh &amp; see the puzzle once they share it with you.
        </div>
      </div>
    </div>
  );
}