import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import LoaderButton from '../components/reusable/LoaderButton';
import { useAppContext } from '../App';
import { useFormFields } from '../libs/hooksLib';
import { getAuthenticatedUsername, loginSubmitEmail, loginSubmitOTP, signup } from '../libs/authLib';
import { useToastNotifications } from '../libs/toastLib';


/**
 * An enum to determine which step of the workflow is currently being displayed to the unauthenticated user.
 */
const WORKFLOW = {
  EMAIL: 'email',         // displays a field for the user to enter their email for signup, or login
  PASSWORD: 'password',   // displayed after the user sends their email for login, ready for them to enter their one-time password
}

export default function Account({compressed, redirectOnLogin, ...props}) {

  const { isAuthenticated, userHasAuthenticated, handleLogout } = useAppContext();
  const { postErrorNotification } = useToastNotifications();
  const { navigate } = useNavigate();

  const [currentWorkflowStep, setCurrentWorkflowStep] = useState(WORKFLOW.EMAIL);
  const [isLoading, setIsLoading] = useState(false);
  // const [cognitoUser, setCognitoUser] = useState(null);   // the Cognito user object as maintained between email sending and OTP submission -- DEPRECATED in aws-amplify v6

  const [fields, handleFieldChange] = useFormFields({
    email: '',
    oneTimePassword: '',
  });

  // Get the currently logged-in username
  const [loggedInUserEmail, setLoggedInUserEmail] = useState(null);  // presented if the user is already authenticated
  useEffect(() => {
    async function findCurrentAuthenticatedUser() {
      if (!loggedInUserEmail) {
        setIsLoading(true);

        try {
          const username = await getAuthenticatedUsername();
          setLoggedInUserEmail(username);
        } catch (e) {
          postErrorNotification('Error', 'Unable to find current authenticated user.');
        }

        setIsLoading(false);
      }
    }

    if (isAuthenticated) {
      findCurrentAuthenticatedUser();
    }
  }, [isAuthenticated, loggedInUserEmail, postErrorNotification]);


  // First attempts to sign up. If successful, or if unsuccessful because the account already exists, it will then immediately try to login.
  function handleSubmitEmail(e) {
    e.preventDefault();
    setIsLoading(true);

    function tryLogin() {
      loginSubmitEmail(fields.email, {
        onSuccess: () => {
          setIsLoading(false);
          setCurrentWorkflowStep(WORKFLOW.PASSWORD);
        },
        onNonexistentAccountError: () => {
          postErrorNotification('Oops!', 'This account does not exist in Crossworthy. Please get in touch if you\'re having difficulties.');
          setIsLoading(false);
        },
        onError: () => {
          postErrorNotification('Error logging in', 'We had an issue logging you in. Please get in touch if this continues!');
          setIsLoading(false);
        },
      });
    }

    signup(fields.email, {
      onSuccess: () => tryLogin(),
      onPreexistingAccountError: () => tryLogin(),
      onError: () => {
        postErrorNotification('Error signing up', 'We had an issue with the signup/login. Please get in touch if this continues!');
        setIsLoading(false);
      },
    });
  }

  function handleSubmitOTP(e) {
    e.preventDefault();
    setIsLoading(true);

    loginSubmitOTP(fields.oneTimePassword, {
      onSuccess: () => {
        userHasAuthenticated(true);
        handleFieldChange({ target: { id: 'oneTimePassword', value: '' } })
        setIsLoading(false);
        if (redirectOnLogin) {
          navigate(redirectOnLogin);
        }
      },
      onError: () => {
        postErrorNotification('Error logging in!', 'We had an issue with the login password. The code may have timed out. Please get in touch if this continues!');
        setIsLoading(false);
        handleFieldChange({ target: { id: 'oneTimePassword', value: '' } })
      },
      onPostLoginError: () => {
        postErrorNotification('Account not properly finalized', 'There was an issue finalizing your account post-login. Please get in touch with us to troubleshoot this error!');
        setIsLoading(false);
        handleFieldChange({ target: { id: 'oneTimePassword', value: '' } })
      },
      onWrongPasswordError: () => {
        postErrorNotification('Wrong password!', 'Try again please. If your password is correct, it may have timed out (send a new one using the Reset button).');
        setIsLoading(false);
        handleFieldChange({ target: { id: 'oneTimePassword', value: '' } })
      },
    })
  }



  if (isAuthenticated) {
    return (
      <div {...props}>
        <div>Logged in as <strong>{loggedInUserEmail}</strong>.</div>
        <LoaderButton
          className='mt-2'
          variant='info'
          isLoading={isLoading}
          onClick={handleLogout}
          size='sm'
        >
          Logout
        </LoaderButton>
      </div>
    );
  }


  if (currentWorkflowStep === WORKFLOW.EMAIL) {
    return (
      <div {...props}>
        <div>You are not logged into Crossworthy.</div>
        <div className='small mt-2 mb-4 balanced-text not-too-wide'>
          Once you log in, you can save, play, and share the puzzles you make.
          To sign up or log in, enter your email to receive a one-time passcode:
        </div>

        <Form onSubmit={handleSubmitEmail}>
          <div className='d-flex mx-4'>
            <Form.Group className='m-0' style={{ width: '100%' }} controlId='email'>
              <InputGroup>
                <InputGroup.Text>Email</InputGroup.Text>
                <Form.Control
                  autoFocus
                  type='email'
                  value={fields.email}
                  onChange={handleFieldChange}
                />
              </InputGroup>
            </Form.Group>
            <LoaderButton
              className='ms-2 zoomable'
              style={{ width: '6rem' }}
              variant='success'
              type='submit'
              isLoading={isLoading}
              disabled={fields.email === ''}
            >
              Go!
            </LoaderButton>
          </div>
        </Form>

        <div className='small mt-4'>
          <a href='/privacy' target='_blank' rel='noreferrer' style={{ color: 'grey' }}>Our privacy philosophy</a>
        </div>
      </div>
    );
  }


  if (currentWorkflowStep === WORKFLOW.PASSWORD) {
    return (
      <div {...props}>
        <div className='mb-3 balanced-text'>Sent! Enter the one-time passcode from your email:</div>
        <div className='d-flex'>
          <Form className='mx-auto' onSubmit={handleSubmitOTP} style={{ maxWidth: '20rem' }}>
            <div className='d-flex'>
              <Form.Group className='m-0' style={{ width: '100%' }} controlId='oneTimePassword'>
                <Form.Control
                  autoFocus
                  type='text'
                  value={fields.oneTimePassword}
                  onChange={handleFieldChange}
                />
              </Form.Group>
              <LoaderButton
                className='ms-2 zoomable'
                style={{ width: '100px' }}
                variant='primary'
                type='submit'
                isLoading={isLoading}
                disabled={fields.oneTimePassword === ''}
              >
                Go!
              </LoaderButton>
            </div>

            <Button
              className='mt-2'
              size='sm'
              variant='outline-danger'
              onClick={() => {
                setCurrentWorkflowStep(WORKFLOW.EMAIL);
                setIsLoading(false);
                handleFieldChange({ target: { id: 'oneTimePassword', value: '' } })
              }}
            >Reset</Button>
          </Form>
        </div>
      </div>
    );
  }


}