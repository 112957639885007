// Catch-all library for puzzle-related things that classes like
// BoardInteractionContext and PlayInteractionContext can pull from.

import { emptyCharGrid, PuzzleMetadataKey, SlotStructure } from "./directionsLib";
import { emptyFurnishingsObject } from "./furnishingsLib";




// Define initial values once to avoid re-creating them multiple times
export const DEFAULT_CHAR_GRID = emptyCharGrid(7, 7);
export const DEFAULT_SLOT_STRUCTURE = SlotStructure.buildNewSlotStructure(DEFAULT_CHAR_GRID);
export const DEFAULT_FURNISHINGS = emptyFurnishingsObject();
export const DEFAULT_CLUES = new Map(DEFAULT_SLOT_STRUCTURE.slotNames.map(slotName => [slotName, '']));
export const DEFAULT_PUZZLE_METADATA = new Map([
  [PuzzleMetadataKey.TITLE, ''],
  [PuzzleMetadataKey.AUTHOR, ''],
  [PuzzleMetadataKey.EDITOR, ''],
  [PuzzleMetadataKey.COPYRIGHT, ''],
  [PuzzleMetadataKey.PUBLISHER, ''],
  [PuzzleMetadataKey.DATE, '']
]);




/**
 * Returns a list of strings that are the clueCommands contained by the clue.
 * Clue commands are substrings within the clue text bookended by $ symbols (and not containing any internal $ symbols),
 * and may be chained together (e.g. "This is my clue text $link:4A$link:8A$").
 * This function can also filter the extracted clueCommands and return the ones matching a specified pattern.
 * If given, the internalPattern should use the ^ and $ tokens if it's meant to match the whole clue string.
 * @param {RegExp?} internalPattern optional RegExp specifying the internal pattern to filter for (i.e. the command pattern excluding the start and end $ symbols).
 */
export function extractClueCommands(clueText, internalPattern = /^.+$/) {
  const clueCommands = [];
  const patt = /(?=(\$(.+?)\$))/g;
  let match;
  while ((match = patt.exec(clueText)) !== null) {
    if (internalPattern.test(match[2])) {
      clueCommands.push(match[2]);
    }

    patt.lastIndex = match.index + 1;
  }
  return clueCommands;
}



/**
 * Returns a string without the recognized clue command pattern.
 * @param {string} clueText 
 * @param {RegExp} pattern The RegExp pattern INSIDE the $ symbols that should be matched and removed (the $ will also be removed, but should not be included in the RegExp)
 * @returns {string} the clueText without the recognized RegExp and its bookending $'s
 */
export function removeClueCommands(clueText, pattern) {
  // We want to mark all the matching indexes in the string, including the bookending $'s
  const patternWithBookends = new RegExp('(?=(\\$' + pattern.source + '\\$))', 'gd');

  const indexes = [];
  let match;
  while ((match = patternWithBookends.exec(clueText)) !== null) {
    for (let i = match.indices[1][0]; i < match.indices[1][1]; ++i) {
      if (!indexes.includes(i)) indexes.push(i);
    }
    // Reset regex lastIndex to start from the next character after the current match
    patternWithBookends.lastIndex = match.index + 1;
  }

  // Remove all the characters at the specified indexes
  if (indexes.length === 0) {
    return clueText;
  }

  const charArray = clueText.split('');
  indexes.sort((a, b) => b - a);   // use descending order to avoid shifting problems
  for (const idx of indexes) {
    if (idx >= 0 && idx < charArray.length) {
      charArray.splice(idx, 1);
    }
  }
  return charArray.join('');
}


