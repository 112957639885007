import React, { useRef, useState } from 'react';
import { Button, FormControl, Modal } from 'react-bootstrap';
import { FaDelicious } from 'react-icons/fa';
import { ImFolderOpen } from 'react-icons/im';
import { IoMdOpen } from 'react-icons/io';
import { CgRename } from 'react-icons/cg';
import { HiOutlineTrash } from 'react-icons/hi';
import { userFriendlyDateString } from '../../libs/miscLib';
import { useRightClickMenu } from './RightClickMenu';
import LoaderButton from '../reusable/LoaderButton';
import { useToastNotifications } from '../../libs/toastLib';


export default function FolderLineItem({ dirId, dirStructure, modifiedAt, callbacks, ...props }) {

  const { postErrorNotification } = useToastNotifications();

  const { dirName, puzzles, subdirectories } = dirStructure;
  const { navigateToDir, onRenameDir, onDeleteDir } = callbacks;

  const [newDirName, setNewDirName] = useState(null);  // if not null, the user is currently renaming the directory
  const [isLoading, setIsLoading] = useState(false);
  const clickable = newDirName === null && !isLoading;

  const { onRightClick, rightClickMenu } = useRightClickMenu();
  const lineItemRef = useRef();

  const [isHovering, setIsHovering] = useState(false);


  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const containsThings = Object.keys(subdirectories).length > 0 || Object.keys(puzzles).length > 0;
  const deleteModal = (
    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
      <Modal.Header className='purple-3-bkgd' closeButton><strong>Delete folder</strong></Modal.Header>
      <Modal.Body className='text-center'>
        <div className='balanced-text'>Are you sure you would like to delete your folder "{dirName}"{containsThings ? ' and all subfolders & puzzles' : ''}?</div>
        <div className='small mt-3 balanced-text'>This action cannot be undone.{containsThings ? ' Any links to the puzzles in this folder will no longer work.' : ''}</div>
        <div className='d-flex mt-3 justify-content-center'>
          <LoaderButton
            className='me-4'
            isLoading={isDeleting}
            variant='danger'
            size='sm'
            onClick={async () => {
              setIsDeleting(true);
              await onDeleteDir(dirId, {
                onSuccess: () => {
                  setIsDeleting(false);
                  setShowDeleteModal(false);
                },
                onFailure: () => {
                  setIsDeleting(false);
                  postErrorNotification('Unable to delete', 'Sorry, we couldn\'t delete your folder at this time! Please contact us if this continues or you need help.');
                  setShowDeleteModal(false);
                }
              })
            }}
          >Delete folder</LoaderButton>
          <Button
            variant='outline-info'
            size='sm'
            onClick={() => setShowDeleteModal(false)}
          >Cancel</Button>
        </div>
      </Modal.Body>
    </Modal>
  );



  return (
    <div className='d-flex position-relative rounded' ref={lineItemRef} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} {...props}>
      <div
        className={'d-flex px-2 py-1 w-100 border-0' + (isHovering ? ' purple-1-bkgd' : '')}
        role={clickable ? 'button' : undefined}
        onClick={() => {
          if (clickable) navigateToDir(dirId);
        }}
        onContextMenu={e => {
          if (clickable) onRightClick({ parentRef: lineItemRef, mouseEvent: e, menuItems: [
            { title: 'Open in new tab', icon: <IoMdOpen />, onClick: () => navigateToDir(dirId, true) },
            { title: 'Rename', icon: <CgRename />, onClick: () => setNewDirName(dirName) },
            { title: 'Delete...', icon: <HiOutlineTrash />, onClick: () => setShowDeleteModal(true), className: 'text-danger' },
          ]})
        }}
      >
        <div className='my-auto me-2 text-center' style={{width: '1.5rem'}}>
          <ImFolderOpen className={isLoading ? 'spinning' : ''} />
        </div>

        <div className='me-auto w-50'>
          {newDirName === null ? <div>{dirName || 'untitled folder'}</div> : (
            <FormControl
              placeholder='folder name'
              size='sm'
              value={newDirName}
              disabled={isLoading}
              onChange={e => setNewDirName(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setIsLoading(true);
                  onRenameDir(dirId, newDirName, () => {
                    setNewDirName(null);
                    setIsLoading(false);
                  });
                } else if (e.key === 'Escape') {
                  setNewDirName(null);
                }
              }}
            />
          )}
        </div>

        <div className='d-flex ms-2 my-auto text-end text-muted small'>
          <div className='me-3'>{modifiedAt && userFriendlyDateString(modifiedAt)}</div>

          {Object.keys(subdirectories).length > 0 && (
            <>
              <ImFolderOpen className='my-auto mx-1' />
              <div>{Object.keys(subdirectories).length}</div>
            </>
          )}

          <FaDelicious className='my-auto ms-2 me-1' />
          <div>{Object.keys(puzzles).length}</div>
        </div>
      </div>

      {rightClickMenu}
      {deleteModal}
    </div>
  );



}