import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div className='d-flex'>
      <div className='text-center mx-auto my-4 h4 not-too-wide'>
        <p>
          Sorry, page not found! Looks like we aren't so crossworthy after all.
        </p>
        <p className='h6'>
          If you expected to find something here, <a href='/feedback' onClick={e => {
            e.preventDefault();
            navigate('/feedback');
          }}>let us know</a> and we can sort it out!
        </p>

        <Button className='mt-3' href='/' onClick={e => {
          e.preventDefault();
          navigate('/');
        }}>Crossworthy Home</Button>
      </div>
    </div>
  );
}