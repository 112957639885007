import React, { useEffect, useRef, useState } from 'react';
import KeyboardEventHandler from '../../libs/keyboardLib';
import { useSwipeable } from 'react-swipeable';
import { emptyCharGrid } from '../../libs/directionsLib';
import { BoardStyle, CellStyleName } from '../../libs/formatLib';
import Board from '../board/Board';
import { PuzzleInteractionStatus } from '../construct/BoardInteractionContext';



const ROWS = 17;
const COLS = 17;

const R0 = 7; // initial starting position
const C0 = 10;

const PHRASES = [
  'DIDYOUEVERHEARTHETRAGEDYOFDARTHPLAGUEISTHEWISEITHOUGHTNOTITSNOTASTORYTHEJEDIWOULDTELLYOUITSASITHLEGENDDARTHPLAGUEISWASADARKLORDOFTHESITHSOPOWERFULANDSOWISEHECOULDUSETHEFORCETOINFLUENCETHEMIDICHLORIANSTOCREATELIFEHEHADSUCHAKNOWLEDGEOFTHEDARKSIDETHATHECOULDEVENKEEPTHEONESHECAREDABOUTFROMDYINGTHEDARKSIDEOFTHEFORCEISAPATHWAYTOMANYABILITIESSOMECONSIDERTOBEUNNATURALHEBECAMESOPOWERFULTHEONLYTHINGHEWASAFRAIDOFWASLOSINGHISPOWERWHICHEVENTUALLYOFCOURSEHEDIDUNFORTUNATELYHETAUGHTHISAPPRENTICEEVERYTHINGHEKNEWTHENHISAPPRENTICEKILLEDHIMINHISSLEEPIRONICHECOULDSAVEOTHERSFROMDEATHBUTNOTHIMSELF',
  'FRIENDSROMANSCOUNTRYMENLENDMEYOUREARSICOMETOBURYCAESARNOTTOPRAISEHIMTHEEVILTHATMENDOLIVESAFTERTHEMTHEGOODISOFTINTERREDWITHTHEIRBONESSOLETITBEWITHCAESARTHENOBLEBRUTUSHATHTOLDYOUCAESARWASAMBITIOUSIFITWERESOITWASAGRIEVOUSFAULTANDGRIEVOUSLYHATHCAESARANSWERDITHEREUNDERLEAVEOFBRUTUSANDTHERESTFORBRUTUSISANHONOURABLEMANSOARETHEYALLALLHONOURABLEMENCOMEITOSPEAKINCAESARSFUNERALHEWASMYFRIENDFAITHFULANDJUSTTOMEBUTBRUTUSSAYSHEWASAMBITIOUSANDBRUTUSISANHONOURABLEMANHEHATHBROUGHTMANYCAPTVIESHOMETOROMEWHOSERANSOMSDIDTHEGENERALCOFFERSFILLDIDTHISINCAESARSEEMAMBITIOUS',
  'HOLDYOURGROUNDHOLDYOURGROUNDSONSOFGONDOROFROHANMYBROTHERSISEEINYOUREYESTHESAMEFEARTHATWOULDTAKETHEHEARTOFMEADAYMAYCOMEWHENTHECOURAGEOFMENFAILSWHENWEFORSAKEOURFRIENDSANDBREAKALLBONDSOFFELLOWSHIPBUTITISNOTTHISDAYANHOUROFWOLVESANDSHATTEREDSHIELDSWHENTHEAGEOFMENCOMESCRASHINGDOWNBUTITISNOTTHISDAYTHISDAYWEFIGHTBYALLTHATYOUHOLDDEARONTHISGOODEARTHIBIDYOUSTANDMENOFTHEWEST',
];
function generateRandomLetter(snakeLength, phraseNumber=0) {
  const phrase = PHRASES[phraseNumber % PHRASES.length];
  return phrase[(snakeLength) % phrase.length];
}


const Direction = {
  DOWN: 'down',
  UP: 'up',
  LEFT: 'left',
  RIGHT: 'right'
};


export default function SnakeBoard({
  onLose,  // function that is called when the user loses
  onStart, // function that is called when a game session starts
  isStarted,  // bool - should the game be advancing?
  ...props
}) {


  // Game state variables
  /*
  When isStarted is true, it calls the useEffect which in turn sets gameInterval to non-null.
  When gameInterval is non-null, this means that the game clock is ticking.
  Upon losing, youLose is called and gameInterval is cleared via a second useEffect.
  */


  const phraseNumberRef = useRef(0);

  const [score, setScore] = useState(0);

  const snakeBody = useRef([
    { letter: generateRandomLetter(0), coords: [R0, C0] },
    { letter: generateRandomLetter(1), coords: [R0, C0 + 1] },
    { letter: generateRandomLetter(2), coords: [R0, C0 + 2] },
    { letter: generateRandomLetter(3), coords: [R0, C0 + 3] },
    { letter: generateRandomLetter(4), coords: [R0, C0 + 4] },
  ]); // a list from head to tail of objects { letter, coords: [r, c] }
  const keyEventQueue = useRef([]);
  const currentDirection = useRef(Direction.LEFT);
  const nextFood = useRef({ letter: generateRandomLetter(5), coords: [5, 10] });
  const blackouts = useRef();
  
  const [gameInterval, setGameInterval] = useState(null);    // null if game is not in progress, otherwise it's an interval
  const [youLose, setYouLose] = useState(false);




  // Game time logic
  useEffect(() => {
    if (gameInterval || !isStarted) {
      return;
    }

    // Game is starting: set initial values
    phraseNumberRef.current = Math.floor(Math.random() * PHRASES.length);
    setScore(0);
    setYouLose(false);
    snakeBody.current = [
      { letter: generateRandomLetter(0, phraseNumberRef.current), coords: [R0, C0] },
      { letter: generateRandomLetter(1, phraseNumberRef.current), coords: [R0, C0 + 1] },
      { letter: generateRandomLetter(2, phraseNumberRef.current), coords: [R0, C0 + 2] },
      { letter: generateRandomLetter(3, phraseNumberRef.current), coords: [R0, C0 + 3] },
      { letter: generateRandomLetter(4, phraseNumberRef.current), coords: [R0, C0 + 4] },
    ];
    keyEventQueue.current = [];
    currentDirection.current = Direction.LEFT
    nextFood.current = { letter: generateRandomLetter(5, phraseNumberRef.current), coords: [5, 10] };
    blackouts.current = [];

    if (onStart) {
      onStart();
    }



    function generateNextFoodLocation() {
      const r = Math.floor(Math.random() * ROWS);
      const c = Math.floor(Math.random() * COLS);
      if (
        snakeBody.current.filter(val => val.coords[0] === r && val.coords[1] === c).length > 0 || 
        (r === snakeBody.current[0].coords[0] && c === snakeBody.current[0].coords[1])
      ) {
        return generateNextFoodLocation();   // TODO - check for inifinte recursion, aka winning
      }
      return [r, c];
    }


    

    // Set up game clock
    setGameInterval(setInterval(() => {
      // Every cycle, do the following:

      // Process the next key event in the keyEventQueue, if any
      if (keyEventQueue.current.length > 0) {
        const newDirection = keyEventQueue.current.shift();
        if (!(
          (newDirection === Direction.LEFT && currentDirection.current === Direction.RIGHT) ||
          (newDirection === Direction.RIGHT && currentDirection.current === Direction.LEFT) ||
          (newDirection === Direction.DOWN && currentDirection.current === Direction.UP) ||
          (newDirection === Direction.UP && currentDirection.current === Direction.DOWN)
        )) {
          currentDirection.current = newDirection;
        }
      }

      // Make a copy of the current snakeBody so we can advance
      const newSnakeBody = [...snakeBody.current];

      // Plan out where to advance the head
      let [newR, newC] = newSnakeBody[0].coords;
      switch (currentDirection.current) {
        case Direction.LEFT:
          newC--;
          break;
        case Direction.RIGHT:
          newC++;
          break;
        case Direction.UP:
          newR--;
          break;
        case Direction.DOWN:
          newR++;
          break;
        default:
          break;
      }

      // Check if the snake dies, or if it eats the next food
      if (newR < 0 || newR >= ROWS || newC < 0 || newC >= COLS ||
          newSnakeBody.slice(0, newSnakeBody.length-1).filter(val => val.coords[0] === newR && val.coords[1] === newC).length > 0) {
        setYouLose(true);
        return;
      } else if (newR === nextFood.current.coords[0] && newC === nextFood.current.coords[1]) {
        // Append food to tail
        newSnakeBody.push(nextFood.current);  // coords will be overwritten when the body is advanced, since it's the last element
        // Increment score
        setScore(s => s + 1);
        // Generate next food or declare winner
        if (newSnakeBody.length >= ROWS * COLS - 1) {
          setYouLose(true);
          return;
        } else {
          nextFood.current = {letter: generateRandomLetter(newSnakeBody.length, phraseNumberRef.current), coords: generateNextFoodLocation()};
        }
      }

      let [nextR, nextC] = newSnakeBody[0].coords;    // for the next-in-line
      newSnakeBody[0].coords = [newR, newC];

      // Plan out where to advance the rest of the body
      for (let i = 1; i < newSnakeBody.length; ++i) {
        const [oldR, oldC] = newSnakeBody[i].coords;
        newSnakeBody[i].coords = [nextR, nextC];
        [nextR, nextC] = [oldR, oldC];
      }

      // Actually do the advancing
      setScore(s => s + (-0.5+Math.random()) * 0.000001);  // need this line or it won't render
      snakeBody.current = newSnakeBody;
    }, 120));

    return () => {
      if (gameInterval) {
        clearInterval(gameInterval);
      }
    }
  }, [gameInterval, isStarted, onStart]);


  // Handle losing
  useEffect(() => {
    if (youLose && gameInterval) {
      clearInterval(gameInterval);
      setGameInterval(null);
      onLose(Math.round(score));
    }
  }, [youLose, gameInterval, onLose, score]);
  


  // Construct charGrid from the current snakeBody
  const charGrid = emptyCharGrid(ROWS, COLS);
  for (let i = 0; i < snakeBody.current.length; ++i) {
    const {letter, coords} = snakeBody.current[i];
    const [r, c] = coords;
    charGrid[r][c] = letter;
  }
  charGrid[nextFood.current.coords[0]][nextFood.current.coords[1]] = nextFood.current.letter;


  // Handle keys
  function handleKeyEvent(key, event) {
    event.preventDefault();
    if (key === 'w' || key === 'up') {
      keyEventQueue.current.push(Direction.UP);
    } else if (key === 'a' || key === 'left') {
      keyEventQueue.current.push(Direction.LEFT);
    } else if (key === 's' || key === 'down') {
      keyEventQueue.current.push(Direction.DOWN);
    } else if (key === 'd' || key === 'right') {
      keyEventQueue.current.push(Direction.RIGHT);
    }
  }

  // Handle swipes for mobile
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => keyEventQueue.current.push(Direction.LEFT),
    onSwipedRight: () => keyEventQueue.current.push(Direction.RIGHT),
    onSwipedUp: () => keyEventQueue.current.push(Direction.UP),
    onSwipedDown: () => keyEventQueue.current.push(Direction.DOWN),
    preventDefaultTouchmoveEvent: true,
  });


  // Grid coloring
  const boardStyle = new BoardStyle(snakeBody.current.map(({coords}) => { return { loc: coords, cellStyleName: CellStyleName.EMPHASIS } })
                                                     .concat([{ loc: nextFood.current.coords, cellStyleName: CellStyleName.WARNING }]));


  return (
    <div {...swipeHandlers} {...props}>
      <div className='d-block block-text text-danger text-end fw-bold h4'>Score: {Math.round(score)}</div>
      <Board charGrid={charGrid} puzzleInteractionStatus={PuzzleInteractionStatus.STATUESQUE} boardStyle={boardStyle} />
      <KeyboardEventHandler handleKeys={['w', 'a', 's', 'd', 'left', 'up', 'right', 'down']} onKeyEvent={handleKeyEvent} />
    </div>
  );
}