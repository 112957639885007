import React, { useState } from 'react';
import { CellFontStyle, Color } from '../../libs/formatLib';
import { SHAPE } from '../../libs/furnishingsLib';


function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.toLowerCase());
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}


function stackColors(bottomColorHex, topColorHex, options = {}) {
  const { topWeight = 0.7, whiteningFactor = 0.15 } = options;

  // First add some white to the bottom color
  let { r, g, b } = hexToRgb(bottomColorHex) || { r: 0, g: 0, b: 0 };
  r = Math.min(Math.floor((255-r) * whiteningFactor + r), 255);
  g = Math.min(Math.floor((255-g) * whiteningFactor + g), 255);
  b = Math.min(Math.floor((255-b) * whiteningFactor + b), 255);

  // Next add a certain amount of the top color
  let { r: topR, g: topG, b: topB } = hexToRgb(topColorHex) || { r: 0, g: 0, b: 0 };
  return (rgbToHex(
    Math.floor(r * (1 - topWeight) + topR * topWeight),
    Math.floor(g * (1 - topWeight) + topG * topWeight),
    Math.floor(b * (1 - topWeight) + topB * topWeight),
  ));
}





/**
 * A grid cell that may be a blackout. If it isn't a blackout, it's either empty or has
 * one character filled in, and may or may not have a cornerNumber. If it is a blackout,
 * its cellContent will be 'blackout'.
 */
export default function Cell({ 
  cellSize, 
  row = null, // either row + col must be specified, or x + y (in % of viewbox)
  col = null, 
  x = null,
  y = null,
  boardBorderAdjustment=0,   // how much of a border around the board should be incorporated when calculating the coordinates/cellSize? (in % of board size)
  cellContent, 
  cornerNumber, 
  isCursorCell = false,      // the "main" highlighted cell of the grid
  isSoftHighlighted = false, // the "secondary" highlighted cells (e.g. letters in the same word as the cursor)
  respondToHover = true, // does it highlight on mouse over?
  cellFontStyle = CellFontStyle.NONE,
  cellFontColor = Color.BLACK,
  overlaidShape = null,    // e.g. a circle drawn on top of the cell
  cellBackgroundColor = null,
  strokeWidth = null,
  ...props
}) {

  if (row === null || col === null) {
    if (x === null || y === null) {
      console.warn('Cell.js: either row & col, or x & y must be specified and non-null.');
      x = 0;
      y = 0;
    } // else, x and y are already specified
  } else {
    if (x === null || y === null) {
      x = col * cellSize;
      y = row * cellSize;
    } else {
      console.warn('Cell.js: both row & col, and x & y are specified. Defaulting to x & y.');
    }
  }


  // Do some math to account for a 1% border around the board (squish all the cells together a little)
  x = (100-2*boardBorderAdjustment)/100 * x + boardBorderAdjustment;
  y = (100-2*boardBorderAdjustment)/100 * y + boardBorderAdjustment;
  cellSize = (100-1*boardBorderAdjustment)/100 * cellSize;


  // Calculate text dimensions based on cell size
  const cellFontSize = 0.7 * cellSize;   // the baseline font size for the letter in the cell
  const numberFontSize = '50%';

  // When hovering mouse over the Cell, we want it to be highlighted
  const [mouseHovering, setMouseHovering] = useState(false);

  
  // Construct the background (fill) color with a few layers; first, what's the default background color?
  var fillColor = cellContent === 'blackout' ? '#000000' : (cellBackgroundColor || Color.WHITE);
  // Next, is this a cursor cell or soft highlighted?
  if (isCursorCell) {
    // fillColor = addColors(fillColor, '#70cfff', { topWeight: 0.8 });
  } else if (isSoftHighlighted) {
    fillColor = stackColors(fillColor, '#bee4f7');
  }
  // Next, is the mouse hovering over this cell?
  if (mouseHovering && respondToHover) {
    fillColor = stackColors(fillColor, '#fcfade');
  }


  return (
    <g
      style={{ cursor: 'default', fontSize: `${cellFontSize}px` }}
      onMouseEnter={respondToHover ? (() => setMouseHovering(true)) : null}
      onMouseLeave={respondToHover ? (() => setMouseHovering(false)) : null}
      {...props}
    >
      <rect      // black or white rectangle of cell
        x={x}
        y={y}
        width={cellSize}
        height={cellSize}
        fill={fillColor}
        stroke='black'
        strokeWidth={strokeWidth || 0.1}
      />
      {overlaidShape === SHAPE.CIRCLE ? (
        <circle
          cx={x + cellSize/2}
          cy={y + cellSize/2}
          r={cellSize/2 - (strokeWidth ? strokeWidth * 2 : 0.2)}
          stroke='black'
          fill={fillColor}
          strokeWidth={strokeWidth ? strokeWidth * 2 : 0.2}
        />
      ) : null}
      {cellContent === 'blackout' ? (
        // If the cell has a color furnishing on top of the blackout, draw a slightly smaller, rounded square inside with that color
        cellBackgroundColor && <rect
          x={x+0.6}
          y={y+0.6}
          width={cellSize-1.2}
          height={cellSize-1.2}
          fill={cellBackgroundColor}
          rx={cellSize*0.33}
        />
      ) : (
        <>
          {cornerNumber && (    // corner number
            <text
              x={x}
              y={y+0.12}
              textAnchor = "start"
              dominantBaseline="hanging"
              style={{ fontSize: numberFontSize }}
            >
              {cornerNumber}
            </text>
          )}
          <text         // filled letter
            x={x + 0.5 * cellSize}
            y={y + 0.7 * cellSize}
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              fontFamily: "sans-serif",
              fontStyle: cellFontStyle === CellFontStyle.ITALIC ? "italic" : "normal",
              fontWeight: cellFontStyle === CellFontStyle.BOLD ? "bold" : "normal",
              fill: cellFontColor,
            }}
          >
            {cellContent}
          </text>
        </>
      )}
    </g>
  );
}

