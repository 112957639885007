import React, { useRef } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { FaDelicious } from 'react-icons/fa';
import { ImFolderOpen } from 'react-icons/im';
import { IoMdOpen } from 'react-icons/io';
import { TbTools } from 'react-icons/tb';
import { SORT_STRATEGY } from '../../libs/traversalLib';
import { possiblyPluralString } from '../../libs/miscLib';
import { useRightClickMenu } from './RightClickMenu';
import CollectionIcon from '../reusable/CollectionIcon';
import { useNavigate } from 'react-router-dom';

/*
folderStructure should be modeled like myConstructions
*/
export default function BrowseSidebar({ dirPath, folderStructure, callbacks, ...props }) {

  const { 
    newDir, setNewDir,
    newPuzzle, setNewPuzzle,
    sortStrategy, setSortStrategy,
  } = callbacks;

  const navigate = useNavigate();

  const sidebarRef = useRef();

  const { onRightClick, rightClickMenu} = useRightClickMenu();


  // Some fun stats
  const totals = Object.values(folderStructure.puzzles).reduce((totals, puzz) => {
    return {
      totalSquares: totals.totalSquares + puzz.puzzleContent.grid.length,
      completedSquares: totals.completedSquares + puzz.puzzleContent.grid.filter(sq => sq.length > 0).length,
      totalClues: totals.totalClues + puzz.puzzleContent.clues.across.length + puzz.puzzleContent.clues.down.length,
      completedClues: totals.completedClues + puzz.puzzleContent.clues.across.concat(puzz.puzzleContent.clues.down).filter(clue => /^\d+\. ./.test(clue)).length,
    };
  }, { totalSquares: 0, completedSquares: 0, totalClues: 0, completedClues: 0 });


  return (
    <div className='mb-3 d-grid gap-2' ref={sidebarRef} {...props}>
      <Button
        className='d-flex'
        onClick={() => setNewDir({ dirName: '', status: 'CREATING' })}
        onContextMenu={e => onRightClick({ parentRef: sidebarRef, mouseEvent: e, menuItems: [
          // { title: 'Open in new tab', icon: <IoMdOpen />, onClick: () => setNewDir({ dirName: '', status: 'CREATING', newTab: true }) },
        ]})}
        disabled={newDir !== null}
        variant='outline-success'
        size='sm'
      >
        <ImFolderOpen className='my-auto ms-auto me-2' />
        <span className='me-auto'>New Folder</span>
      </Button>

      <Button
        className='d-flex'
        onClick={() => setNewPuzzle({ puzzleName: '', status: 'CREATING' })}
        onContextMenu={e => onRightClick({ parentRef: sidebarRef, mouseEvent: e, menuItems: [
          { title: 'Create in new tab', icon: <IoMdOpen />, onClick: () => setNewPuzzle({ puzzleName: '', status: 'CREATING', newTab: true }) },
        ]})}
        disabled={newPuzzle !== null}
        variant='success'
        size='sm'
      >
        <FaDelicious className='my-auto ms-auto me-2' />
        <span className='me-auto'>New Puzzle</span>
      </Button>


      <div className='mt-1 text-center small text-muted fst-italic'>
        <div>
          {possiblyPluralString(Object.keys(folderStructure.puzzles).length, 'puzzle')} &middot; {possiblyPluralString(Object.keys(folderStructure.subdirectories).length, 'subfolder')}
        </div>
        {totals.totalClues > 0 && (
          <>
            <div>
              {totals.totalClues} total words
            </div>
            <div>
              {Math.round(totals.completedSquares / totals.totalSquares * 100)}% filled &middot; {Math.round(totals.completedClues / totals.totalClues * 100)}% clued
            </div>
          </>
        )}
      </div>

      <hr className='my-3' />

      <div className='text-center text-muted small fst-italic'>
        Curate your own published puzzle lists!
      </div>
      <Button
        className='d-flex'
        variant='outline-info'
        size='sm'
        style={{ color: 'purple', borderColor: 'purple' }}
        href='/manage-collections'
        onClick={e => {
          e.preventDefault();
          navigate('/manage-collections');   // prevents app from loading again (if just normal href)
        }}
      >
        <CollectionIcon className='my-auto ms-auto me-2 purple-3' size={20} />
        <span className='me-auto'>Collections</span>
      </Button>


      <hr className='my-3' />


      <div className='text-center text-muted small fst-italic'>
        Just want to play around?
      </div>

      <Button
        variant='outline-primary'
        size='sm'
        href='/construct/scratch'
        onClick={e => {
          e.preventDefault();
          navigate('/construct/scratch');
        }}
      >
        <TbTools className='my-auto ms-auto me-2' />
        <span className='me-auto'>Scratch Space</span>
      </Button>


      <hr />

      <Dropdown>
        <Dropdown.Toggle className='w-100' variant='outline-secondary' size='sm' id='sort-dropdown'>Sort: {sortStrategy}</Dropdown.Toggle>
        <Dropdown.Menu>
          {Object.values(SORT_STRATEGY).map((ss, idx) => (
            <Dropdown.Item key={`ss-${idx}`} onClick={() => setSortStrategy(ss)}>{ss}</Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>



      {rightClickMenu}
    </div>
  );

}