import React, { useEffect, useRef } from 'react';
import { Card, Container, Form, InputGroup, ListGroup, Row, Col, Badge } from 'react-bootstrap';
import { ACROSS, DOWN, otherDirection } from '../../libs/directionsLib';
import { PuzzleInteractionStatus, useClues, useCursor, usePuzzleInteractionStatus, useSavePuzzle, useSlotStructure } from './BoardInteractionContext';
import { getTop, isEntirelyInside } from '../../libs/miscLib';
import './CluesPanel.css';
import { useToastNotifications } from '../../libs/toastLib';


export default function CluesPanel({pxHeight=400, ...props}) {
  // Calculate heights of the clues panels
  pxHeight = Math.max((pxHeight || 250) - 60, 250);   // subtract some for the header heights
  const dominantBodyPxHeight = Math.round(pxHeight * 0.66);
  const submissiveBodyPxHeight = Math.round(pxHeight * 0.33);


  // Hook into board interaction context
  const { clues, setCluesAt } = useClues();

  const { cursorLoc, cursorDirection, handleClickOnLoc } = useCursor();
  const slotStructure = useSlotStructure();

  const { puzzleInteractionStatus } = usePuzzleInteractionStatus();

  const { requestPuzzleSave } = useSavePuzzle();

  const { postErrorNotification } = useToastNotifications();


  const primaryHighlightedSlotName = cursorLoc ? slotStructure.getSlotNameContainingCoordinates(cursorLoc, cursorDirection) : null;
  const secondaryHighlightedSlotName = cursorLoc ? slotStructure.getSlotNameContainingCoordinates(cursorLoc, otherDirection(cursorDirection)) : null;

  // Store refs so we can scroll into view when necessary
  const acrossCluesRef = useRef(null);
  const downCluesRef = useRef(null);
  const clueComponentRefs = Array.from(clues.keys()).reduce((acc, value) => {
    acc[value] = React.createRef();
    return acc;
  }, {});

  // When cursor location changes, make sure the apparent clues are visible by scrolling
  useEffect(() => {
    if (primaryHighlightedSlotName && clueComponentRefs[primaryHighlightedSlotName] &&
        clueComponentRefs[primaryHighlightedSlotName].current && acrossCluesRef.current && downCluesRef.current) {
      const target = clueComponentRefs[primaryHighlightedSlotName].current;
      const primaryClueBox = cursorDirection === ACROSS ? acrossCluesRef.current : downCluesRef.current;

      if (!isEntirelyInside(target, primaryClueBox)) {
        primaryClueBox.scrollTo({top: getTop(target) - getTop(primaryClueBox) + primaryClueBox.scrollTop, behavior: 'smooth'});
      }
    }
  }, [clueComponentRefs, primaryHighlightedSlotName, cursorDirection]);
  useEffect(() => {
    if (secondaryHighlightedSlotName && clueComponentRefs[secondaryHighlightedSlotName] && clueComponentRefs[secondaryHighlightedSlotName].current) {
      const target = clueComponentRefs[secondaryHighlightedSlotName].current;
      const secondaryClueBox = cursorDirection === ACROSS ? downCluesRef.current : acrossCluesRef.current;

      if (!isEntirelyInside(target, secondaryClueBox)) {
        secondaryClueBox.scrollTo({top: getTop(target) - getTop(secondaryClueBox) + secondaryClueBox.scrollTop, behavior: 'smooth'});
      }
    }
  }, [clueComponentRefs, secondaryHighlightedSlotName, cursorDirection]);



  let acrossClueComponents = [];
  let downClueComponents = [];
  for (const [slotName, clueText] of clues.entries()) {
    const [slotNumber, slotDirection] = slotName.split('-');
    const extraClassName = (slotName === primaryHighlightedSlotName) ? ' primaryHighlighted' : (
      slotName === secondaryHighlightedSlotName ? ' secondaryHighlighted' : ''
    );

    function handleFocus(e) {
      if (slotName === secondaryHighlightedSlotName) {
        // Switch cursor direction
        handleClickOnLoc(cursorLoc);
      } else if (slotName !== primaryHighlightedSlotName) {
        // "Click" on first square of the focused slot
        const newLoc = slotStructure.getLocFromSlotName(slotName);
        handleClickOnLoc(newLoc, slotDirection);
      }
    }

    function handleBlur() {
      // Don't set the clues if there isn't a change - this leads to unnecessary calls in live mode
      if (clueComponentRefs[slotName].current.value !== clues.get(slotName)) {
        // Ensure clue text is less than 800 characters (arbitrary, but hopefully avoids us ever running into the 4KB limit for dynamoDb puts)
        if (clueComponentRefs[slotName].current.value.length > 800) {
          clueComponentRefs[slotName].current.value = clueComponentRefs[slotName].current.value.slice(0, 800);
          postErrorNotification('Clue too long!', 'We cut your clue down to the first 800 characters to keep our database writes manageable.', ['puzzle-specific']);
        }
        
        // Update clues
        setCluesAt([{ slotName: slotName, clueText: clueComponentRefs[slotName].current.value }]);
      }
    }

    const clueComponent = (
      <ListGroup.Item className='p-0' key={slotName + '-cluecontrol'}>
        <InputGroup key={clueText /*adding this key forces the Form.Control to rerender when clueText (i.e. defaultValue) is changed*/}>
          <InputGroup.Text className={'clueNumberLabel' + (extraClassName === '' ? '' : (extraClassName + 'Dark'))}>
            {slotNumber}
          </InputGroup.Text>
          <Form.Control 
            ref={clueComponentRefs[slotName]}
            className={(clueText.includes('$hide$') ? 'text-secondary ' : '') + 'smallClueInput' + extraClassName}
            type='text'
            name={'clue-' + slotName}
            placeholder={slotName}
            disabled={puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE && puzzleInteractionStatus !== PuzzleInteractionStatus.EDITABLE_GUEST}
            defaultValue={clueText}  // uncontrolled form allows quicker typing without lag
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyDown={e => {
              // Want to prevent default responses inside input component for ctrl/cmd+s
              if ((e.ctrlKey || e.metaKey) && e.key === 's') {
                e.preventDefault();
                setCluesAt([{ slotName: slotName, clueText: clueComponentRefs[slotName].current.value }]);
                requestPuzzleSave();
              } else if (e.key === 'Enter') {
                if (e.shiftKey) {
                  const previousSlotName = slotStructure.getPreviousSlotName(slotName);
                  if (previousSlotName) {
                    clueComponentRefs[previousSlotName].current.focus();
                  } else {
                    clueComponentRefs[slotName].current.blur();
                  }
                } else {
                  const nextSlotName = slotStructure.getNextSlotName(slotName);
                  if (nextSlotName) {
                    clueComponentRefs[nextSlotName].current.focus();
                  } else {
                    clueComponentRefs[slotName].current.blur();
                  }
                }
              }
            }}
          />
          <div className={'d-flex align-items-center ' + extraClassName}>
            {clueText.includes('$hide$') && <Badge bg='secondary'>clue hidden</Badge>}
            <span className='px-1 block-text text-muted small align-middle'>{slotStructure.getWordInSlotName(slotName, '·')}</span>
          </div>
        </InputGroup>
      </ListGroup.Item>
    );
    if (slotDirection === DOWN) {
      downClueComponents.push(clueComponent);
    } else {
      acrossClueComponents.push(clueComponent);
    }
  }


  return (
    <Container className='p-0' {...props}>
      <Row>
        <Col>
          <Card className='cluesCard'>
            <Card.Header>
              <span className={'me-2 block-text' + (pxHeight < 310 ? ' small' : '')}>ACROSS clues</span>
            </Card.Header>

            <Card.Body ref={acrossCluesRef} style={{height: `${cursorDirection === ACROSS ? dominantBodyPxHeight : submissiveBodyPxHeight}px` }}>
              <ListGroup variant='flush'>
                {acrossClueComponents}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className='cluesCard'>
            <Card.Header>
              <span className={'me-2 block-text' + (pxHeight < 310 ? ' small' : '')}>DOWN clues</span>
            </Card.Header>

            <Card.Body ref={downCluesRef} style={{height: `${cursorDirection === DOWN ? dominantBodyPxHeight : submissiveBodyPxHeight}px` }}>
              <ListGroup variant='flush'>
                {downClueComponents}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}