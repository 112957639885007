import React, { useEffect, useRef, useState } from 'react';
import { produce } from 'immer';
import { Badge, Button, FormControl, Modal, OverlayTrigger, Popover, Tooltip, ProgressBar } from 'react-bootstrap';
import CollectionIcon from '../components/reusable/CollectionIcon';
import { get, post } from 'aws-amplify/api';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingAnimation from '../components/reusable/LoadingAnimation';
import { BiGhost, BiSave } from 'react-icons/bi';
import { AiOutlineReload } from 'react-icons/ai';
import { useAppContext } from '../App';
import { GrHalt } from 'react-icons/gr';
import { BsPencil, BsPeopleFill, BsTrash } from 'react-icons/bs';
import { fromPuzzleContentObject } from '../libs/exportLib';
import { PuzzleInteractionStatus } from '../components/construct/BoardInteractionContext';
import { PuzzleMetadataKey } from '../libs/directionsLib';
import Board from '../components/board/Board';
import { FaDelicious } from 'react-icons/fa';
import { MdOpenInNew } from 'react-icons/md';
import MenuButton from '../components/reusable/MenuButton';
import KeyboardEventHandler from '../libs/keyboardLib';
import { ShortcutDefinition } from '../components/reusable/MenuButton';
import { IoGlasses } from 'react-icons/io5';
import { possiblyPluralString, userFriendlyDateString } from '../libs/miscLib';
import { useTraversal } from '../libs/traversalLib';
import MiniBrowse from '../components/browse/MiniBrowse';
import LoaderButton from '../components/reusable/LoaderButton';
import ShareCollectionModal from '../components/construct/toolbar/ShareCollectionModal';
import { useToastNotifications } from '../libs/toastLib';
import { getAuthenticatedUsername } from '../libs/authLib';
import { getAllPlaySessions } from '../libs/playSessionLib';


const ERROR_MESSAGES = {
  DOES_NOT_EXIST: 'Puzzle does not exist.',
  NO_PERMISSION: 'This puzzle is not shared with you.',
  UNSPECIFIED: 'Unknown error occurred.',
};


function CollectionPuzzleLineItem({ puzzleId, publishDate, setPublishDate, onFinishEditingPublishDate, removePuzzleFromCollection, collectionIsPubliclyPlayable }) {

  const { postErrorNotification } = useToastNotifications();

  const [isEditingPublishDate, setIsEditingPublishDate] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [currentErrorMessage, setCurrentErrorMessage] = useState(null);
  const [puzzleItem, setPuzzleItem] = useState(null);
  const [accessRequested, setAccessRequested] = useState(false);

  const [allPlaySessions, setAllPlaySessions] = useState();

  const componentRef = useRef(null);  // ref to the DOM component so we know how wide it is
  const [componentWidth, setComponentWidth] = useState(100);   // width of line item component in pixels, so we know when to break/wrap text etc.
  const compressedWidth = componentWidth < 600;
  useEffect(() => {
    setComponentWidth(componentRef.current.offsetWidth);
  }, []);

  const hasAttemptedLoading = useRef(false)
  const [forceReloadToggle, setForceReloadToggle] = useState(false);   // if this changes, it triggers the reload useEffect; used if there's an error and user reloads it
  function forceReload() {
    hasAttemptedLoading.current = false;
    setForceReloadToggle(!forceReloadToggle);
  }
  useEffect(() => {
    // Load the puzzle
    if (!hasAttemptedLoading.current) {
      hasAttemptedLoading.current = true;
      async function loadPuzzle() {
        setIsLoading(true);
        setCurrentErrorMessage(null);
        try {
          const res = await (await get({
            apiName: 'userPuzzles',
            path: `/userPuzzles/${puzzleId}`
          }).response).body.json();
          setPuzzleItem(res);
          onFinishEditingPublishDate();
        } catch (e) {
          console.log(e);
          if (e.response?.data?.error === 'Item not found.') {
            setCurrentErrorMessage(ERROR_MESSAGES.DOES_NOT_EXIST);
          } else if (e.response?.data?.error === 'Permission denied.') {
            setCurrentErrorMessage(ERROR_MESSAGES.NO_PERMISSION);
          } else {
            setCurrentErrorMessage(ERROR_MESSAGES.UNSPECIFIED);
          }
        }
        setIsLoading(false);
      }

      loadPuzzle();
      getAllPlaySessions(puzzleId, aps => setAllPlaySessions(aps), () => setAllPlaySessions(null));
    }
  }, [puzzleId, forceReloadToggle, onFinishEditingPublishDate]);

  const { puzzleMetadata, charGrid, clues, furnishings } = puzzleItem?.puzzleContent ? fromPuzzleContentObject(puzzleItem.puzzleContent) : {};
  const puzzleTitle = puzzleMetadata?.get(PuzzleMetadataKey.TITLE);
  const puzzleAuthor = puzzleMetadata?.get(PuzzleMetadataKey.AUTHOR);
  
  const previewPopover = (
    <Popover id={'preview-' + puzzleId}>
      <Popover.Body>
        <Board charGrid={charGrid} furnishings={furnishings} puzzleInteractionStatus={PuzzleInteractionStatus.STATUESQUE} />
      </Popover.Body>
      <Popover.Header className='py-1 text-end' style={{ width: '200px' }}>Open in new tab</Popover.Header>
    </Popover>
  );

  return <div className='d-flex w-100 px-2 py-1 border' ref={componentRef}>
    <div className='d-flex me-auto'>
      <div className='text-center my-auto py-1 ms-1 me-3' style={{ position: 'relative', width: 34, height: 34 }}>
        <FaDelicious className='text-secondary' size={29} style={{ opacity: 0.3, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%) rotate(-10deg)' }} />
        <div style={{ fontSize: 12, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontWeight: 'bold' }}>
          {charGrid ? `${charGrid.length}x${charGrid[0].length}` : '?'}
        </div>
      </div>


      {isLoading ? (
        <div className='block-text fst-italic small my-auto'>Loading puzzle details...</div>
      ) : currentErrorMessage ? (
        <div className='text-secondary fst-italic small px-2 my-auto'>
          {currentErrorMessage}
          <div>
            <Button className='p-0 me-3' variant='link' style={{fontSize: 12}} onClick={() => forceReload()}>Reload</Button>
            {currentErrorMessage === ERROR_MESSAGES.DOES_NOT_EXIST && <Button className='p-0 me-3' variant='link' style={{fontSize: 12}} onClick={() => removePuzzleFromCollection()}>Remove from collection</Button>}
            {currentErrorMessage === ERROR_MESSAGES.NO_PERMISSION && <Button className='p-0 me-3' disabled={accessRequested} variant='link' style={{fontSize: 12}} onClick={async () => {
              setAccessRequested(true);    // assumes no errors; that's okay (will post notification if it fails)
              try {
                const username = await getAuthenticatedUsername();
                await post({
                  apiName: 'userPuzzles',
                  path: '/puzzleSharing',
                  options: {
                    body: {
                      requesterEmail: username,
                      puzzleId,
                    },
                  },
                }).response;
              } catch (e) {
                postErrorNotification('Error requesting access', 'Sorry, something went wrong trying to request access! If this continues, please let us know.');
                setAccessRequested(false);
              }
            }}>{accessRequested ? 'Access requested' : 'Request access'}</Button>}
          </div>
        </div>
      ) : !puzzleItem ? (
        <div className='my-auto small fst-italic'>No puzzle info available.</div>
      ) : (
        <div>
          {compressedWidth ? (
            <div>
              <div className='fw-bold me-2'>
                {puzzleTitle}
                {((charGrid && charGrid.some(r => r.some(c => c === ''))) || (clues && Array.from(clues.values()).some(c => !c))) && <Badge className='ms-1' bg='warning'>INCOMPLETE</Badge>}
              </div>
              {puzzleAuthor && <div className='small me-2'>by {puzzleAuthor}</div>}
            </div>
          ) : (
            <div className='d-flex'>
              <span className='fw-bold me-2 my-auto'>{puzzleTitle}</span>
              {puzzleAuthor && <span className='me-2 text-muted my-auto'>by {puzzleAuthor}</span>}
              {charGrid && charGrid.some(r => r.some(c => c === '')) ? (
                <Badge className='my-auto' bg='warning'>INCOMPLETE GRID</Badge>
              ) : clues && Array.from(clues.values()).some(c => !c) ? (
                <Badge className='my-auto' bg='warning'>INCOMPLETE CLUES</Badge>
              ) : undefined}
            </div>
          )}
          <div className='d-flex small'>
            {isEditingPublishDate ? (
              <FormControl
                className='d-inline'
                style={{ maxWidth: '16rem' }}
                type='datetime-local'
                size='sm'
                autoFocus
                value={!publishDate ? '' : new Date(publishDate).toLocaleString('sv-SE', {   // https://dev.to/mendyberger/input-and-js-dates-2lhc
                  year: 'numeric', month: '2-digit', day: '2-digit',
                  hour: '2-digit', minute: '2-digit', second: '2-digit',
                }).replace(' ', 'T')}
                onChange={e => {
                  if (!e.target.value) {
                    setPublishDate(null);
                  } else {
                    setPublishDate(new Date(e.target.value).getTime());
                  }
                }}
                onBlur={() => {
                  setIsEditingPublishDate(false);
                  onFinishEditingPublishDate();
                }}
              />
            ) : publishDate === null || publishDate === undefined ? (
              <Button className='border-0 px-1 py-0' variant='warning' style={{fontSize: '12px'}} onClick={() => {
                setPublishDate(Date.now());
                setIsEditingPublishDate(true);
              }}>
                not published: set publish date
                <BsPencil className='ms-2' />
              </Button>
            ) : (
              <>
                <span className={publishDate <= Date.now() ? 'text-info' : 'text-info me-1'}>{compressedWidth ? 'on ' : `publish${publishDate <= Date.now() ? 'ed ' : 'ing '}on `}</span>
                <Button
                  className='border-0 px-1 py-0'
                  variant={publishDate <= Date.now() ? 'outline-info' : 'info'}
                  style={{fontSize: '12px'}}
                  onClick={() => setIsEditingPublishDate(true)}
                >
                  {new Date(publishDate).toLocaleString('en-US', {
                    weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short'
                  }).replace(' PM ', 'pm ').replace(' AM ', 'am ')}
                  <BsPencil className='ms-2' />
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
    {collectionIsPubliclyPlayable && !puzzleItem?.permissions?.public?.view && (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement='bottom'
        rootClose 
        overlay={<Popover className='text-center p-2 grey-1-bkgd'>This puzzle is not publicly playable. To grant public view permissions, click the Share button while editing the puzzle.</Popover>}
      >
        <Badge className='ms-1 my-auto' bg='warning'>Not public</Badge>
      </OverlayTrigger>
    )}
    {allPlaySessions && (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement='bottom'
        rootClose 
        overlay={<Popover className='text-center p-2'>
          {Math.round(allPlaySessions.reduce((acc, cur) => acc + Number(cur.status === 'solved'), 0) / allPlaySessions.length * 100)}% solved without hints<br />
          {Math.round(allPlaySessions.reduce((acc, cur) => acc + Number(cur.status === 'revealed'), 0) / allPlaySessions.length * 100)}% revealed<br />
          {Math.round(allPlaySessions.reduce((acc, cur) => acc + Number(cur.status === 'incomplete'), 0) / allPlaySessions.length * 100)}% incomplete
        </Popover>}
      >
        <div className='mx-1 my-auto text-end small text-success'>
          <span>{possiblyPluralString(allPlaySessions.length, 'play')}</span>
          
          <ProgressBar style={{ height: '5px', width: '60px' }}>
            <ProgressBar
              variant='success'
              key={1}
              now={Math.round(allPlaySessions.reduce((acc, cur) => acc + Number(cur.status === 'solved'), 0) / allPlaySessions.length * 100)}
              style={{transition: 'none'}}
            />
            <ProgressBar
              variant='warning'
              key={2}
              now={Math.round(allPlaySessions.reduce((acc, cur) => acc + Number(cur.status === 'revealed'), 0) / allPlaySessions.length * 100)}
              style={{transition: 'none'}}
            />
          </ProgressBar>
        </div>
      </OverlayTrigger>
    )}
    <OverlayTrigger trigger={['hover', 'focus']} placement='left' rootClose overlay={previewPopover}>
      <Button className='rounded my-auto mx-1' variant='outline-secondary' size='sm' onClick={() => {
        window.open(`/construct/${puzzleId}`, '_blank');
      }}>
        <MdOpenInNew />
      </Button>
    </OverlayTrigger>
    <OverlayTrigger trigger={['hover', 'focus']} placement='bottom' rootClose overlay={<Tooltip>Remove from collection (puzzle is not deleted)</Tooltip>}>
      <Button className='rounded my-auto me-1' variant='outline-secondary' size='sm' onClick={() => {
        if (window.confirm('Remove this puzzle from the collection? The puzzle can still be accessed through its own URL.')) {
          removePuzzleFromCollection();
        }
      }}>
        <BsTrash />
      </Button>
    </OverlayTrigger>
  </div>
}





export default function ManageCollection({initialCollectionObj}) {

  const navigate = useNavigate();
  const { isAuthenticated, showLoginPage, brandIsShowing } = useAppContext();
  const { postErrorNotification } = useToastNotifications();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const { collectionId } = useParams();
  const [collectionObj, setCollectionObj] = useState({});
  const { collectionMetadata, modifiedAt, permissionsLevel, collectionPuzzles } = collectionObj;

  const [sortedPuzzleIds, setSortedPuzzleIds] = useState([]);
  function sortPuzzleIds() {
    // Called on blur of date inputs, eg so it doesn't keep sorting while the user is typing
    if (collectionPuzzles) {
      setSortedPuzzleIds(Object.entries(collectionPuzzles)
        .sort((a, b) => (
          (a[1].publishDate === null || a[1].publishDate === undefined) ? -1 :
          (b[1].publishDate === null || b[1].publishDate === undefined) ? 1 :
          100 * (b[1].publishDate - a[1].publishDate) + a[0].localeCompare(b[0])))   // puzzleId is the tiebreaker, to keep consistent sorting within same publish date
        .map(([puzzleId, _]) => puzzleId)
      );
    }
  }

  const [isSaved, setIsSaved] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isEditingCurator, setIsEditingCurator] = useState(false);

  // For requesting access if permission denied
  const [isLoadingRequestAccess, setIsLoadingRequestAccess] = useState(false);
  const [hasRequestedAccess, setHasRequestedAccess] = useState(false);

  useEffect(() => {
    if (initialCollectionObj) {
      setCollectionObj(initialCollectionObj);
    } else {
      async function loadCollection() {
        setIsLoading(true);
        setIsError(false);
        try {
          const res = await (await get({
            apiName: 'userPuzzles',
            path: `/collections/${collectionId}`,
          }).response).body.json();
          setCollectionObj(res);
          setIsSaved(true);
          setIsSaving(false);
        } catch (e) {
          console.log(e);
          setIsError(true);
        }
        setIsLoading(false);
      }

      loadCollection();
    }
  }, [initialCollectionObj, collectionId]);


  async function saveCollection() {
    if (isSaving || (permissionsLevel !== 'EDIT' && permissionsLevel !== 'MANAGE')) return;

    setIsSaving(true);
    try {
      // This will return an object including modifiedAt - can use in the future to track potential conflicts
      const { modifiedAt } = await (await post({
        apiName: 'userPuzzles',
        path: `/collections/${collectionId}`,
        options: {
          body: {
            collectionMetadata,
            collectionPuzzles,
          },
        },
      }).response).body.json();
      setIsSaved(true);
      setCollectionObj(cObj => produce(cObj, draft => {
        draft.modifiedAt = modifiedAt;
      }));
    } catch (e) {
      console.log(e);
      postErrorNotification('Error saving collection', 'We\'re having trouble saving your collection right now. Please let us know if this continues.');
    }
    setIsSaving(false);
  }

  // Prompt on refresh; don't let user navigate away if unsaved
  useEffect(() => {
    if (!isSaved) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }, [isSaved]);


  // Share collection functionality
  const [shareCollectionModalShowing, setShareCollectionModalShowing] = useState(false);
  const shareCollectionModal = <ShareCollectionModal collectionId={collectionId} show={shareCollectionModalShowing} onHide={() => setShareCollectionModalShowing(false)} />;
  // TODO: on close of ShareCollectionModal, make sure that the collectionObj's permissions is up-to-date, so that it can make sure the warning badges on puzzles are up to date


  
  // Add puzzle functionality
  const { 
    errorMessage,
    currentDirPath,
    currentDirStructure,
    callbacks,
  } = useTraversal({ modalNavigation: true });
  const [showAddPuzzleModal, setShowAddPuzzleModal] = useState(false);
  const [showPuzzleAlreadyExistsMessage, setShowPuzzleAlreadyExistsMessage] = useState(false);
  const [showPuzzleAddedMessage, setShowPuzzleAddedMessage] = useState(false);
  const addPuzzleModal = (
    <Modal
      show={showAddPuzzleModal}
      onHide={() => {
        setShowAddPuzzleModal(false);
        sortPuzzleIds();
      }}
      onShow={() => {
        setShowPuzzleAlreadyExistsMessage(false);
        setShowPuzzleAddedMessage(false);
        callbacks.refreshDataFromServer();
      }}
    >
      <Modal.Header className='purple-2-bkgd' closeButton>
        <strong>Add a puzzle to your collection</strong>
      </Modal.Header>
      <Modal.Body>
        {showPuzzleAlreadyExistsMessage && <div className='small text-danger fst-italic'>
          This puzzle is already in the collection!
        </div>}
        {showPuzzleAddedMessage && <div className='small text-success fst-italic'>
          Puzzle added to collection!
        </div>}
        {errorMessage || <MiniBrowse dirPath={currentDirPath} folderStructure={currentDirStructure} callbacks={callbacks} hideNewFolderButton onClickOnPuzzle={({puzzleId}) => {
          // Add to collection
          if (Object.keys(collectionObj.collectionPuzzles).includes(puzzleId)) {
            setShowPuzzleAlreadyExistsMessage(true);
            setShowPuzzleAddedMessage(false);
          } else {
            setShowPuzzleAlreadyExistsMessage(false);
            setCollectionObj(produce(collectionObj, draft => {
              draft.collectionPuzzles[puzzleId] = { publishDate: null };
            }));
            setShowPuzzleAddedMessage(true);
            setIsSaved(false);
          }
        }} />}
      </Modal.Body>
    </Modal>
  )





  return (

    <div className='mb-3'>
      <KeyboardEventHandler
        handleKeys={[
          'ctrl+s', 'meta+s',
        ]}
        onKeyEvent={(key, event) => {
          event.preventDefault();
          if (key === 'ctrl+s' || key === 'meta+s') {
            saveCollection();
          }
        }}
      />

      <div
        className='d-flex w-100 purple-1-bkgd'
        style={{position: 'sticky', top: '0', paddingLeft: brandIsShowing ? '70px' : '10px', paddingRight: '60px', paddingTop: '3px', paddingBottom: '4px', zIndex: 100, boxShadow: '0 0 4px black'}}
        id='manage-collection-navbar'
      >
        <div className='me-auto d-flex'>
          <MenuButton name='File' items={[
            {
              label: 'Save',
              shortcutDefinition: new ShortcutDefinition(true, false, 'S'),
              disabled: isSaving || (permissionsLevel !== 'MANAGE' && permissionsLevel !== 'EDIT'),
              onClick: () => saveCollection()
            },
            {
              label: 'Manage all collections',
              onClick: () => navigate('/manage-collections'),
            },
            {
              label: 'Construct Home',
              onClick: () => navigate(isAuthenticated ? '/construct/f' : '/'),
            },
          ]} />
        </div>

        <div className='me-3 mb-1 mt-auto text-muted text-end fst-italic' style={{fontSize: '12px'}}>
          {isSaving ? 'Saving...' : isSaved ? 'All changes saved.' : 'unsaved changes'}
        </div>

        <div className='d-flex'>
          <Button
            className='me-1 green-button'
            variant='outline-success'
            size='sm'
            href={`/collections/${collectionId}`}
            target='_blank'
          >
            {brandIsShowing && <MdOpenInNew className='me-2' />}
            {brandIsShowing ? 'View collection' : 'View'}
          </Button>

          <Button
            className='blue-button me-1'
            size='sm'
            onClick={() => setShareCollectionModalShowing(true)}
          >
            {brandIsShowing && <BsPeopleFill className='me-2' />}
            Share
          </Button>
        </div>
      </div>


      <div className='d-flex justify-content-center'>
        {!isAuthenticated ? (
          <div className='mt-5 text-center'>
            <GrHalt size={40} />
            <div className='mt-2 d-flex'>
              <p className='mx-auto not-too-wide'>
                You'll have to log in (or <a href='/signup'>sign up</a>) first in order to manage your collections.
              </p>
            </div>
            <Button className='mt-3' variant='success' onClick={() => showLoginPage()}>
              Login
            </Button>
          </div>
        ) : isLoading ? (
          <div className='d-flex'>
            <LoadingAnimation className='mt-5 mx-auto' />
          </div>
        ) : permissionsLevel === 'VIEW' ? (
          <div className='mt-5 text-center'>
            <IoGlasses size={40} />
            <div className='mt-2 d-flex'>
              <p className='mx-auto not-too-wide'>
                Looks like you only have view permissions for this collection. A manager of this collection will have to give you edit access.
              </p>
            </div>
            {hasRequestedAccess ? (
              <div className='not-too-wide mx-auto text-secondary fst-italic'>Access requested!</div>
            ) : (
              <LoaderButton
                variant='info'
                isLoading={isLoadingRequestAccess}
                onClick={async () => {
                  setIsLoadingRequestAccess(true);
                  try {
                    const username = await getAuthenticatedUsername();
                    await post({
                      apiName: 'userPuzzles',
                      path: '/collectionSharing',
                      options: {
                        body: {
                          requesterEmail: username,
                          collectionId,
                        },
                      },
                    }).response;
                    setIsLoadingRequestAccess(false);
                    setHasRequestedAccess(true);
                  } catch (e) {
                    postErrorNotification('Error requesting access', 'Sorry, something went wrong trying to request access! If this continues, please let us know.');
                    setIsLoadingRequestAccess(false);
                  }
                }}
              >Request access</LoaderButton>
            )}
          </div>
        ) : isError || !collectionMetadata ? (
          <div className='mt-5 text-center'>
            <BiGhost className='zoomable' size={40} />
            <div className='mt-2 d-flex'>
              <p className='mx-auto not-too-wide'>
                Pardon us, we're having trouble loading your collection{!collectionMetadata ? ' metadata' : ''} right now.
                Double-check the URL, and please get in touch if this problem persists.
              </p>
            </div>
            <Button className='mt-3' variant='warning' onClick={() => window.location.reload()}>
              <AiOutlineReload className='me-2' />
              Reload
            </Button>
          </div>
        ) : (

          <div className='not-obscenely-wide'>
            <div className='d-flex justify-content-center mt-4' id='name-row'>
              <CollectionIcon className='me-2 my-auto' />
              {isEditingName ? (
                <FormControl
                  className='w-auto d-inline'
                  autoFocus
                  placeholder='collection name'
                  value={collectionMetadata.name || ''}
                  onChange={e => {
                    setCollectionObj(produce(collectionObj, draft => { draft.collectionMetadata.name = e.target.value }));
                    setIsSaved(false);
                  }}
                  onFocus={e => e.target.select()}
                  onBlur={() => setIsEditingName(false)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') e.target.blur();
                  }}
                />
              ) : (
                <>
                  <div className='h3 fw-bold my-auto'>{collectionMetadata.name || 'untitled collection'}</div>
                  <Button className='ms-3 my-auto' variant='outline-info border-0' onClick={() => setIsEditingName(true)}>
                    <BsPencil className='my-auto' />
                  </Button>
                </>
              )}
            </div>

            <div className='d-flex justify-content-center mt-2' id='description-row'>
              {isEditingDescription ? (
                <FormControl
                  className='not-too-wide'
                  as='textarea'
                  autoFocus
                  placeholder='description'
                  size='sm'
                  value={collectionMetadata.description || ''}
                  onChange={e => {
                    setCollectionObj(produce(collectionObj, draft => { draft.collectionMetadata.description = e.target.value }));
                    setIsSaved(false);
                  }}
                  onFocus={e => e.target.select()}
                  onBlur={() => setIsEditingDescription(false)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') e.target.blur();
                  }}
                />
              ) : (
                <div className='d-flex' onClick={() => { if (!collectionMetadata.description) setIsEditingDescription(true) }}>
                  {collectionMetadata.description ? (
                    <div className='my-auto not-too-wide text-center small'>
                      <span className='fw-bold purple-3 me-3'>Description</span>
                      <span>{collectionMetadata.description}</span>
                    </div>
                  ) : (
                    <span className='my-auto text-muted'>Add description</span>
                  )}
                  <Button className='ms-3 my-auto' variant='outline-info border-0' onClick={() => setIsEditingDescription(true)}>
                    <BsPencil className='my-auto' size={13} />
                  </Button>
                </div>
              )}
            </div>

            <div className='d-flex justify-content-center' id='curator-row'>
              {isEditingCurator ? (
                <FormControl
                  className='not-too-wide'
                  autoFocus
                  placeholder='constructor(s) or curator(s)'
                  value={collectionMetadata.curator || ''}
                  onChange={e => {
                    setCollectionObj(produce(collectionObj, draft => { draft.collectionMetadata.curator = e.target.value }));
                    setIsSaved(false);
                  }}
                  onFocus={e => e.target.select()}
                  onBlur={() => setIsEditingCurator(false)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') e.target.blur();
                  }}
                />
              ) : (
                <div className='d-flex' onClick={() => { if (!collectionMetadata.curator) setIsEditingCurator(true) }}>
                  {collectionMetadata.curator ? (
                    <div className='my-auto not-too-wide text-center'>
                      <span className='fw-bold purple-3 me-3'>Curated by</span>
                      <span>{collectionMetadata.curator}</span>
                    </div>
                  ) : (
                    <span className='my-auto text-muted'>Add constructor(s) or curator(s)</span>
                  )}
                  <Button className='ms-3 my-auto' variant='outline-info border-0' onClick={() => setIsEditingCurator(true)}>
                    <BsPencil className='my-auto' size={13} />
                  </Button>
                </div>
              )}
            </div>

            <div className='d-flex mb-4 mt-3' id='add-puzzles-button-row'>
              <Button
                className='ms-auto me-2 d-flex'
                variant='info'
                onClick={() => setShowAddPuzzleModal(true)}
              >
                <FaDelicious className='me-2 my-auto' />
                Add puzzle
              </Button>

              <OverlayTrigger placement='bottom' overlay={<Tooltip>Changes become live when you save.</Tooltip>}>
                <LoaderButton
                  className='me-auto d-flex'
                  variant='outline-info'
                  onClick={() => saveCollection()}
                  disabled={isSaved}
                  isLoading={isSaving}
                >
                  <BiSave className='me-2 my-auto' />
                  Save changes
                </LoaderButton>
              </OverlayTrigger>
            </div>

            <div className='mt-2' id='puzzles-row'>
              {Object.entries(collectionPuzzles).sort((a, b) => sortedPuzzleIds.indexOf(a[0]) - sortedPuzzleIds.indexOf(b[0])).map(([puzzleId, { publishDate }]) => (
                <CollectionPuzzleLineItem
                  key={`col-puz-${puzzleId}`}
                  puzzleId={puzzleId}
                  publishDate={publishDate}
                  setPublishDate={(newPublishDate) => {
                    setCollectionObj(cObj => produce(cObj, draft => {
                      draft.collectionPuzzles[puzzleId].publishDate = newPublishDate;   // should be a number (epoch time), not a Date object
                    }));
                    setIsSaved(false);
                  }}
                  onFinishEditingPublishDate={() => sortPuzzleIds()}
                  removePuzzleFromCollection={() => {
                    setCollectionObj(cObj => produce(cObj, draft => {
                      delete draft.collectionPuzzles[puzzleId];
                    }));
                    setIsSaved(false);
                  }}
                  collectionIsPubliclyPlayable={collectionObj?.permissions?.public?.view}
                />
              ))}
            </div>

            <div className='d-flex'>
              <div className='d-flex mx-auto mt-4'>
                <div className='small text-muted fst-italic my-auto me-3 text-center'>
                  <div>{possiblyPluralString(Object.keys(collectionObj?.collectionPuzzles || {}).length, 'puzzle')}</div>
                  <div>Last modified {userFriendlyDateString(modifiedAt)}</div>
                </div>
                <img className='zoomable' src='/android-chrome-256x256.png' alt='crossworthy-logo' width='40px' />
              </div>
            </div>
          </div>

        )}
      </div>

      {addPuzzleModal}
      {shareCollectionModal}

    </div>
  );
}