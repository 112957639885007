import React, { useState } from 'react';
import { post } from 'aws-amplify/api';
import { Button, Modal } from 'react-bootstrap';
import { useTraversal } from '../../libs/traversalLib';
import LoaderButton from '../reusable/LoaderButton';
import MiniBrowse from './MiniBrowse';
import { useAppContext } from '../../App';
import { useToastNotifications } from '../../libs/toastLib';


/**
 * Enables use of the puzzle mover modal.
 * @param {Function} refreshDataFromServer a function that the component may call to force-refresh data from the server (i.e. after the puzzle has moved)
 * @returns {Object} { puzzleMoverModal: a Modal component, setPuzzleBeingMoved: a "setState" function called with the puzzleId, which brings up the modal } 
 */
export function usePuzzleMover(refreshDataFromServer) {
  const [puzzleBeingMoved, setPuzzleBeingMoved] = useState(null);   // null or object containing at least {puzzleId, puzzleTitle}
  return {
    puzzleMoverModal: <PuzzleMover puzzleBeingMoved={puzzleBeingMoved} setPuzzleBeingMoved={setPuzzleBeingMoved} refreshDataFromServer={refreshDataFromServer} />,
    setPuzzleBeingMoved,
  };
}


function PuzzleMover({ puzzleBeingMoved, setPuzzleBeingMoved, refreshDataFromServer }) {

  const { isAuthenticated } = useAppContext();
  const { postErrorNotification } = useToastNotifications();

  const [isLoading, setIsLoading] = useState(false);

  const { 
    errorMessage,
    currentDirPath,
    currentDirStructure,
    callbacks,
  } = useTraversal({ modalNavigation: true });

  async function handleSubmit() {
    try {
      setIsLoading(true);

      await post({
        apiName: 'userPuzzles',
        path: '/folderStructure',
        options: {
          body: {
            action: 'MOVE_PUZZLE',
            payload: {
              puzzleId: puzzleBeingMoved.puzzleId,
              newDirPath: currentDirPath,
            },
          },
          headers: {
            'x-dirpathstring': puzzleBeingMoved.dirPath.join('/'),
          },
        },
      }).response;

      refreshDataFromServer();

      setIsLoading(false);
      setPuzzleBeingMoved(null);
    } catch (e) {
      console.log(e);
      postErrorNotification('Error moving your puzzle', 'So sorry - Please try again later and let us know if the error persists!');
      setIsLoading(false);
      setPuzzleBeingMoved(null);
    }
  }

  return (
    <Modal show={Boolean(puzzleBeingMoved)} onHide={() => setPuzzleBeingMoved(null)} onShow={() => callbacks.refreshDataFromServer()}>
      <Modal.Header className='teal-1-bkgd' closeButton>
        <strong>Move "{puzzleBeingMoved?.puzzleTitle || 'untitled puzzle'}"</strong>
      </Modal.Header>
      <Modal.Body>
        {errorMessage || <MiniBrowse dirPath={currentDirPath} folderStructure={currentDirStructure} callbacks={callbacks} puzzlesDisabled />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={() => setPuzzleBeingMoved(null)}>Cancel</Button>
        <LoaderButton isLoading={isLoading} variant='info' disabled={!isAuthenticated || !currentDirPath} onClick={() => handleSubmit()}>Move</LoaderButton>
      </Modal.Footer>
    </Modal>
  );


}