import React from "react";
import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import LegacyHome from "./containers/LegacyHome";
import Splash from "./containers/Splash";
import About from "./containers/About";
import NotFound from "./containers/NotFound";
import Construct from "./containers/Construct";
import Feedback from "./containers/Feedback";
import Snake from "./containers/Snake";
import Play from "./containers/Play";
import HallOfFame from "./containers/HallOfFame";
import MyConstructions from "./containers/MyConstructions";
import Account from "./containers/Account";
import ManageCollections from "./containers/ManageCollections";
import ManageCollection from "./containers/ManageCollection";
import ViewCollection from "./containers/ViewCollection";
import Privacy from "./containers/Privacy";
import App from "./App";


/*
Note on nesting of routes:
react-router-dom v6 allows route nesting, and most online guides use nesting.
However, this app is not necessarily suited for nesting (the pages/components are not necessarily hierarchical),
and utilizing nesting (e.g. Construct > f > :dirId) would require adding <Outlet /> components to any component that might be a parent.
Due to the added complication this introduces, I've opted to not use any nesting (aside from the singular parent Route that exposes the AppContext).
*/



const crossworthyRouter = createBrowserRouter(createRoutesFromElements(
  <Route element={<App />}>
    <Route path='/' element={<Splash />} />

    <Route path='/about' element={<About />} />
    <Route path='/anniversary' element={<HallOfFame />} />
    <Route path='/privacy' element={<Privacy />} />
    <Route path='/feedback' element={<Feedback />} />
    <Route path='/legacy' element={<LegacyHome />} />
    <Route path='/snake' element={<Snake />} />

    <Route path='/account' element={<div className='d-flex'><Account className='mx-auto my-5 text-center' /></div>} />
    <Route path='/login' element={<Navigate replace to='/account' />} />
    <Route path='/signup' element={<Navigate replace to='/account' />} />

    <Route path='/play' element={<Navigate replace to='/collections/crossworthy' />} />
    <Route path='/play/:id' element={<Play />} />

    <Route path='/scratch' element={<Navigate replace to='/construct/scratch' />} />
    <Route path='/construct' element={<Navigate replace to='/construct/f' />} />
    <Route path='/construct/f' element={<MyConstructions />} />
    <Route path='/construct/f/:dirId' element={<MyConstructions />} />
    <Route path='/construct/:id' element={<Construct />} />

    <Route path='/manage-collections' element={<ManageCollections />} />
    <Route path='/manage-collections/:collectionId' element={<ManageCollection />} />

    <Route path='/collections/:collectionId' element={<ViewCollection />} />
      
    {/* Finally, catch all unmatched routes */}
    <Route path='*' element={<NotFound />} />
  </Route>
));


export default crossworthyRouter;