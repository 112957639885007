// Library that provides cursor functionality (for either playing or constructing crossword grids).

import { useState } from "react";
import { ACROSS, DOWN, SlotStructure, otherDirection } from "./directionsLib";
import { locInList, locsEqual } from "./blackoutsLib";


/** A list of possible directives sent to the cursor on a key press, dependent on previous location
 * Does not include clicks, which would advance cursor to the location clicked. */
export const CursorDirective = {
  // Arrow key events
  UP: 'up',
  DOWN: 'down',
  LEFT: 'left',
  RIGHT: 'right',
  UP_PRESERVE_DIRECTION: 'upPreserveDirection',
  DOWN_PRESERVE_DIRECTION: 'downPreserveDirection',
  LEFT_PRESERVE_DIRECTION: 'leftPreserveDirection',
  RIGHT_PRESERVE_DIRECTION: 'rightPreserveDirection',
  // Next/previous square (e.g. letter or backspace events)
  NEXT_SQUARE: 'nextSquare',
  PREVIOUS_SQUARE: 'previousSquare',
  // Next/previous slot (e.g. tab or shift+tab events), advancing to the first open square in the slot if there is one
  NEXT_WORD: 'nextWord',
  PREVIOUS_WORD: 'previousWord',
  // Play-specific directives: e.g. previous square on a backspace shouldn't go back words
  NEXT_SQUARE_SAME_WORD: 'nextSquareSameWord',
  PREVIOUS_SQUARE_SAME_WORD: 'previousSquareSameWord',
  NEXT_OPEN_SQUARE_CLUEWISE: 'nextOpenSquare',
}




export function useCursorStates({
  charGrid,    // charGrid (or playStateCharGrid)
  cursorHasChanged,   // optional callback that receives the new loc and direction, invoked when cursor is changed (e.g. to broadcast to live collaborators)
  blackoutsFocusable = true,    // can the cursor select blackouts?
  disallowedAcrossLocs = undefined,     // an optional list of [r,c] locs that the cursor should consider "disallowed" when the direction is across (e.g. this is a "hidden" clue)
  disallowedDownLocs = undefined,       // an optional list of [r,c] locs that the cursor should consider "disallowed" when the direction is down
}) {
  const numRows = charGrid.length;
  const numCols = charGrid[0].length;
  disallowedAcrossLocs = disallowedAcrossLocs || [];
  disallowedDownLocs = disallowedDownLocs || [];

  // Definitional state values, with custom setters that handle callbacks
  const [cursorLoc, setCursorLocState] = useState();   // null means no cell is highlighted
  const [cursorDirection, setCursorDirectionState] = useState(ACROSS);   // cursorDirection must be ACROSS or DOWN always.
  function setCursor(newLoc, newDirection) {
    setCursorDirectionState(newDirection);
    setCursorLocState(newLoc);   // note: set the direction before the loc to avoid scrolling issues in clues panel
    if (cursorHasChanged && (cursorDirection !== newDirection || (cursorLoc !== newLoc && (!cursorLoc || !newLoc || !locsEqual(cursorLoc, newLoc))))) cursorHasChanged(newLoc, newDirection); 
  }



  /**
   * With respect to the cursor, deals with a mouse click on a grid loc (or outside the grid, in which case clickedLoc should be given as null).
   * @param {[number, number]?} clickedLoc 
   * @param {string?} overridingDirection Provided if the final cursorDirection after the click should be defined
   */
  function handleClickOnLoc(clickedLoc, overridingDirection = null) {
    if (!clickedLoc) {
      setCursor(null, cursorDirection);
      return;
    }

    if (!blackoutsFocusable && charGrid[clickedLoc[0]][clickedLoc[1]] === 'blackout') return;  // do nothing if clicked on a blackout but blackouts are not focusable

    // Change directions if click is on same non-blackout square
    var newDirection = cursorDirection;
    if (overridingDirection) {
      newDirection = overridingDirection;
    } else if (cursorLoc && clickedLoc && locsEqual(cursorLoc, clickedLoc) && charGrid[clickedLoc[0]][clickedLoc[1]] !== 'blackout') {
      newDirection = otherDirection(cursorDirection);
    }

    if ((newDirection === ACROSS && locInList(clickedLoc, disallowedAcrossLocs)) || (newDirection === DOWN && locInList(clickedLoc, disallowedDownLocs)))
      return;     // do nothing if this is a disallowed loc

    // Set cursor
    setCursor(clickedLoc, newDirection);
  }




  /**
   * Iterates through the charGrid until the next (or previous) blackout or non-blackout (depending on what's specified is found),
   * not including the currentLoc (starting location). The traversal wraps around the grid columnwise or rowwise as if reading a book
   * (or a transposed book), to provide normal expected behavior when a user presses "tab" (e.g.) on a crossword puzzle.
   * Also skips over any disallowedLocs.
   * @param {[r,c]} currentLoc current location to start at
   * @param {boolean} blackout Are we looking for a blackout (true) or a non-blackout (false)?
   * @param {ACROSS/DOWN} direction ACROSS or DOWN - which direction are we traversing?
   * @param {boolean} reverse Are we traversing forward (false) or backward (true)?
   * @param {boolean} wrap Should we wrap to the next line?
   * @returns {[r,c]?} the location of the specified square, or null if not found.
   */
  function getNextBlackoutOrNonBlackoutLoc(currentLoc, blackout, direction, reverse, wrap) {
    let [currentR, currentC] = currentLoc;

    for (let _ = 0; _ < 10000; ++_) {
      // Increment current position; if not incrementable, return null
      if (direction === ACROSS && !reverse) {
        ++currentC;
        if (currentC === numCols) {
          if (!wrap) return null;
          ++currentR;
          if (currentR === numRows) return null;
          currentC = 0;
        }
      } else if (direction === ACROSS && reverse) {
        --currentC;
        if (currentC < 0) {
          if (!wrap) return null;
          --currentR;
          if (currentR < 0) return null;
          currentC = numCols - 1;
        }
      } else if (direction === DOWN && !reverse) {
        ++currentR;
        if (currentR === numRows) {
          if (!wrap) return null;
          ++currentC;
          if (currentC === numCols) return null;
          currentR = 0;
        }
      } else if (direction === DOWN && reverse) {
        --currentR;
        if (currentR < 0) {
          if (!wrap) return null;
          --currentC;
          if (currentC < 0) return null;
          currentR = numRows - 1;
        }
      }

      // Check the current position; if it's what we're looking for, return the position; else continue
      if ((charGrid[currentR][currentC] === 'blackout') === blackout && !locInList([currentR, currentC], direction === ACROSS ? disallowedAcrossLocs : disallowedDownLocs)) return [currentR, currentC];
    }
    console.log('cursorLib.js: Error: likely infinite loop detected in getNextBlackoutLoc');
  }



  /**
   * Modifies the cursorLoc and cursorDirection according to the current loc/direction and the given directive.
   * If cursorLoc is null, does nothing.
   * @param {CursorDirective} cursorDirective 
   */
  function handleCursorDirective(cursorDirective) {
    if (!cursorLoc) return;
    let [currentR, currentC] = cursorLoc, currentDirection = cursorDirection;   // initialize these convenience vars for use in any of the switch cases
    let [newCursorR, newCursorC] = cursorLoc, newCursorDirection = cursorDirection;    // set these within the switch statement to take effect
    let sq;  // just a convenience var to be used within the switch statement however we please
    let currentSlotName, currentIdxInSlot;   // for some more advanced logic in some of the case blocks

    switch (cursorDirective) {
      case CursorDirective.UP:
        newCursorDirection = DOWN;
        /* falls through */
      case CursorDirective.UP_PRESERVE_DIRECTION:
        if (blackoutsFocusable) {
          if (currentR > 0) {
            newCursorR = currentR - 1;
          }
        } else {
          // Find next non-blackout (without wrapping)
          sq = getNextBlackoutOrNonBlackoutLoc(cursorLoc, false, DOWN, true, false);
          if (sq) {
            newCursorR = sq[0];
          }
        }
        break;
      case CursorDirective.DOWN:
        newCursorDirection = DOWN;
        /* falls through */
      case CursorDirective.DOWN_PRESERVE_DIRECTION:
        if (blackoutsFocusable) {
          if (currentR < numRows-1) {
            newCursorR = currentR + 1;
          }
        } else {
          // Find next non-blackout (without wrapping)
          sq = getNextBlackoutOrNonBlackoutLoc(cursorLoc, false, DOWN, false, false);
          if (sq) {
            newCursorR = sq[0];
          }
        }
        break;
      case CursorDirective.LEFT:
        newCursorDirection = ACROSS;
        /* falls through */
      case CursorDirective.LEFT_PRESERVE_DIRECTION:
        if (blackoutsFocusable) {
          if (currentC > 0) {
            newCursorC = currentC - 1;
          }
        } else {
          // Find next non-blackout (without wrapping)
          sq = getNextBlackoutOrNonBlackoutLoc(cursorLoc, false, ACROSS, true, false);
          if (sq) {
            newCursorC = sq[1];
          }
        }
        break;
      case CursorDirective.RIGHT:
        newCursorDirection = ACROSS;
        /* falls through */
      case CursorDirective.RIGHT_PRESERVE_DIRECTION:
        if (blackoutsFocusable) {
          if (currentC < numCols-1) {
            newCursorC = currentC + 1;
          }
        } else {
          // Find next non-blackout (without wrapping)
          sq = getNextBlackoutOrNonBlackoutLoc(cursorLoc, false, ACROSS, false, false);
          if (sq) {
            newCursorC = sq[1];
          }
        }
        break;
      case CursorDirective.NEXT_SQUARE:
        // Advance cursor by one, unless it's the last square in the row/col.
        // Note that this has different behavior in the Constructor and Player worlds. 
        // It is the expected behavior of the cursor after a letter is entered in a cell.
        if (blackoutsFocusable) {
          if (cursorDirection === DOWN) {
            if (currentR < numRows - 1 && charGrid[currentR+1][currentC] !== 'blackout') {
              newCursorR = currentR + 1;
            }
          } else if (cursorDirection === ACROSS) {
            if (currentC < numCols - 1 && charGrid[currentR][currentC+1] !== 'blackout') {
              newCursorC = currentC + 1;
            }
          }
        } else {
          sq = getNextBlackoutOrNonBlackoutLoc(cursorLoc, false, currentDirection, false, false);
          if (sq) {
            [newCursorR, newCursorC] = sq;
          }
        }
        break;
      case CursorDirective.NEXT_OPEN_SQUARE_CLUEWISE:
        // Advance cursor to the next open (empty) square; NOTE that when cursorDirection is DOWN and the cursor loops to the next word,
        // this particular directive will go to the next slot (to the right, not downward).

        // First check that there is indeed an open square (aside from the current cursor loc)
        if (!charGrid.every((r, rIdx) => r.every((c, cIdx) => c !== '' || locsEqual([rIdx, cIdx], cursorLoc) || (locInList([rIdx, cIdx], disallowedAcrossLocs) && locInList([rIdx, cIdx], disallowedDownLocs))))) {

          const slotStructure = SlotStructure.buildNewSlotStructure(charGrid);

          // Get current slot
          currentSlotName = slotStructure.getSlotNameContainingCoordinates(cursorLoc, cursorDirection);
          currentIdxInSlot = slotStructure.slotStructureMap.get(currentSlotName).indexOfLoc(cursorLoc);
          for (let foundIt = 1000; foundIt > 0; --foundIt) {   // "found it" sets foundIt to 0 (just a mechanism for preventing infinite loop in case there's a bug)
            const slotChars = slotStructure.getCharsInSlot(currentSlotName);
            const slotLocs = slotStructure.getLocsInSlot(currentSlotName);
            const disallowedLocs = slotStructure.getDirectionOfSlot(currentSlotName) === ACROSS ? disallowedAcrossLocs : disallowedDownLocs;
            if (slotChars.length === currentIdxInSlot + 1 || slotChars.slice(currentIdxInSlot + 1).every((e, idx) => e !== '') || locInList(slotLocs[0], disallowedLocs)) {
              // No more empty spaces in this slot, or this slot is forbidden: advance to next slot
              currentSlotName = slotStructure.getNextSlotName(currentSlotName);
              currentIdxInSlot = -1;
            } else {
              // There is an empty space: find it (can't use an inline function inside slotChars.findIndex due to unsafe references to variable)
              for (var foundIdx = 0; foundIdx <= currentIdxInSlot || slotChars[foundIdx] !== ''; ++foundIdx);
              [newCursorR, newCursorC] = slotLocs[foundIdx];
              newCursorDirection = slotStructure.getDirectionOfSlot(currentSlotName);
              foundIt = 0;
            }
          }
        }
        
        break;
      case CursorDirective.PREVIOUS_SQUARE:
        // Move back cursor by one, unless it's the first square in the row/col
        if (blackoutsFocusable) {
          if (cursorDirection === DOWN) {
            if (currentR > 0) {
              newCursorR = currentR - 1;
            }
          } else if (cursorDirection === ACROSS) {
            if (currentC > 0) {
              newCursorC = currentC - 1;
            }
          }
        } else {
          sq = getNextBlackoutOrNonBlackoutLoc(cursorLoc, false, currentDirection, true, false);
          if (sq) {
            [newCursorR, newCursorC] = sq;
          }
        }
        break;
      case CursorDirective.NEXT_SQUARE_SAME_WORD:
        // Move the cursor forward by one, or stay still if it's already on the last square in the word
        if (cursorDirection === DOWN) {
          if (currentR < charGrid.length - 1 && charGrid[currentR + 1][currentC] !== 'blackout' && !locInList([currentR + 1, currentC], disallowedDownLocs)) {
            newCursorR = currentR + 1;
          }
        } else if (cursorDirection === ACROSS) {
          if (currentC < charGrid[0].length - 1 && charGrid[currentR][currentC + 1] !== 'blackout' && !locInList([currentR, currentC + 1], disallowedAcrossLocs)) {
            newCursorC = currentC + 1;
          }
        }
        break;
      case CursorDirective.PREVIOUS_SQUARE_SAME_WORD:
        // Move the cursor back by one, unless it's the first square in the word
        if (cursorDirection === DOWN) {
          if (currentR > 0 && charGrid[currentR - 1][currentC] !== 'blackout' && !locInList([currentR - 1, currentC], disallowedDownLocs)) {
            newCursorR = currentR - 1;
          }
        } else if (cursorDirection === ACROSS) {
          if (currentC > 0 && charGrid[currentR][currentC - 1] !== 'blackout' && !locInList([currentR, currentC - 1], disallowedAcrossLocs)) {
            newCursorC = currentC - 1;
          }
        }
        break;
      case CursorDirective.NEXT_WORD:
        // If the grid is entirely blackouts or disallowed, trying to find the next opening would be infinite
        if (charGrid.every((r, rIdx) => r.every((c, cIdx) => c === 'blackout' || (locInList([rIdx, cIdx], disallowedAcrossLocs) && locInList([rIdx, cIdx], disallowedDownLocs))))) return;

        // Advance past the current word
        if (currentDirection === ACROSS) {
          // Find the loc of the next blackout in the same line, if it exists (including current cursor loc if so)
          while (currentC < numCols && charGrid[currentR][currentC] !== 'blackout') {
            ++currentC;
          }
        } else if (currentDirection === DOWN && charGrid[currentR][currentC] !== 'blackout') {
          // Back up to the beginning of the current word
          while (currentR > 0 && charGrid[currentR-1][currentC] !== 'blackout') {
            --currentR;
          }
        }

        // Loop until we find a qualifying loc
        for (let foundIt = false; !foundIt; ) {
          // Advance current square by one, and wrap if necessary
          ++currentC;
          if (currentC >= numCols) {
            currentC = 0;
            ++currentR;
            if (currentR >= numRows) {
              currentR = 0;
              currentDirection = otherDirection(currentDirection);
            }
          }

          // Determine if this is a qualifying loc (i.e. the first loc in a slot, and also not disallowed)
          if (charGrid[currentR][currentC] !== 'blackout') {
            if (currentDirection === ACROSS) {
              if (!locInList([currentR, currentC], disallowedAcrossLocs)) {
                foundIt = true;
              }
            } else if (currentDirection === DOWN) {
              if (currentR === 0 || charGrid[currentR-1][currentC] === 'blackout') {
                if (!locInList([currentR, currentC], disallowedDownLocs)) {
                  foundIt = true;
                }
              }
            }
          }
        }

        newCursorR = currentR;
        newCursorC = currentC;
        newCursorDirection = currentDirection;

        // Now find the first open (empty) loc in the word, unless there isn't any open loc
        while (charGrid[currentR][currentC] !== '' || locInList([currentR, currentC], currentDirection === ACROSS ? disallowedAcrossLocs : disallowedDownLocs)) {
          // Attempt to advance one; if it runs into a wall, reset back to the original location
          if (currentDirection === ACROSS) {
            ++currentC;
          } else {
            ++currentR;
          }
          if (currentR >= numRows || currentC >= numCols || charGrid[currentR][currentC] === 'blackout') {
            break;
          }
        }
        if (currentR < numRows && currentC < numCols && charGrid[currentR][currentC] === '' && !locInList([currentR, currentC], currentDirection === ACROSS ? disallowedAcrossLocs : disallowedDownLocs)) {
          // Only update the cursor if we found a blank square
          newCursorR = currentR;
          newCursorC = currentC;
        }

        break;

      case CursorDirective.PREVIOUS_WORD:
        // If the grid is entirely blackouts or disallowed, trying to find the next opening would be infinite
        if (charGrid.every((r, rIdx) => r.every((c, cIdx) => c === 'blackout' || (locInList([rIdx, cIdx], disallowedAcrossLocs) && locInList([rIdx, cIdx], disallowedDownLocs))))) return;

        // Advance to before the current word
        if (currentDirection === ACROSS) {
          // Find the loc of the next blackout in the same line, if it exists (including current cursor loc if so)
          while (currentC >= 0 && charGrid[currentR][currentC] !== 'blackout') {
            --currentC;
          }
        } else if (currentDirection === DOWN && charGrid[currentR][currentC] !== 'blackout') {
          // Back up to the beginning of the current word
          while (currentR > 0 && charGrid[currentR-1][currentC] !== 'blackout') {
            --currentR;
          }
        }

        // Loop until we find a qualifying loc
        for (let foundIt = false; !foundIt; ) {
          // Advance current square by (minus) one, and wrap if necessary
          --currentC;
          if (currentC < 0) {
            currentC = numCols - 1;
            --currentR;
            if (currentR < 0) {
              currentR = numRows - 1;
              currentDirection = otherDirection(currentDirection);
            }
          }

          // Determine if this is a qualifying loc
          if (charGrid[currentR][currentC] !== 'blackout') {
            if (currentDirection === ACROSS) {
              if (!locInList([currentR, currentC], disallowedAcrossLocs)) {
                foundIt = true;
                // Back up to the first loc in the word
                while (currentC > 0 && charGrid[currentR][currentC-1] !== 'blackout') {
                  --currentC;
                }
              }
            } else if (currentDirection === DOWN) {
              if (currentR === 0 || charGrid[currentR-1][currentC] === 'blackout') {
                if (!locInList([currentR, currentC], disallowedDownLocs)) {
                  foundIt = true;
                }
              }
            }
          }
        }

        newCursorR = currentR;
        newCursorC = currentC;
        newCursorDirection = currentDirection;

        // Now find the first open loc in the word, unless there isn't any open loc
        while (charGrid[currentR][currentC] !== '' || locInList([currentR, currentC], currentDirection === ACROSS ? disallowedAcrossLocs : disallowedDownLocs)) {
          // Attempt to advance one; if it runs into a wall, reset back to the original location
          if (currentDirection === ACROSS) {
            ++currentC;
          } else {
            ++currentR;
          }
          if (currentR >= numRows || currentC >= numCols || charGrid[currentR][currentC] === 'blackout') {
            // IF the entire word is full, we actually want it to go to the last position, so the user can quickly start backspacing
            if (currentDirection === ACROSS) {
              newCursorR = currentR;
              newCursorC = currentC - 1;
            } else {
              newCursorR = currentR - 1;
              newCursorC = currentC;
            }
            break;
          }
        }
        if (currentR < numRows && currentC < numCols && charGrid[currentR][currentC] === '' && !locInList([currentR, currentC], currentDirection === ACROSS ? disallowedAcrossLocs : disallowedDownLocs)) {
          // If we found a blank square, set the cursor to the first blank square position
          newCursorR = currentR;
          newCursorC = currentC;
        }

        break;

      default:
        console.warn('cursorLib.js handleCursorDirective called with directive ' + cursorDirective + '; doing nothing');
    }

    setCursor([newCursorR, newCursorC], newCursorDirection);
  }


  



  /** Package everything into a return value */
  return {
    cursorLoc,
    cursorDirection,
    setCursorLoc: newLoc => setCursor(newLoc, cursorDirection),
    setCursorDirection: newDirection => setCursor(cursorLoc, newDirection),
    handleClickOnLoc,
    handleCursorDirective,
  };
}