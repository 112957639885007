import React, { useEffect, useRef, useState } from 'react';
import { get, post } from 'aws-amplify/api';
import { PuzzleMetadataKey } from '../libs/directionsLib';
import { FaDelicious } from 'react-icons/fa';
import { Button, Container, Row } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppContext } from '../App';
import { AiOutlineReload } from 'react-icons/ai';
import { BiGhost } from 'react-icons/bi';
import { MdOpenInNew } from 'react-icons/md';
import { TbCircleCheckFilled, TbProgressCheck } from 'react-icons/tb';
import LoadingAnimation from '../components/reusable/LoadingAnimation';
import { fromPuzzleContentObject } from '../libs/exportLib';
import { useRightClickMenu } from '../components/browse/RightClickMenu';
import { IoMdOpen } from 'react-icons/io';
import { useToastNotifications } from '../libs/toastLib';
import { getAuthenticatedUsername } from '../libs/authLib';
import { retrieveFromLocalStorage, retrieveFromServer } from '../libs/playSessionLib';

const ERROR_MESSAGES = {
  DOES_NOT_EXIST: 'Puzzle does not exist.',
  NO_PERMISSION: 'This puzzle is not shared with you.',
  UNSPECIFIED: 'Unknown error occurred.',
};

// This is just used internally; it does NOT match the string values in the database
const PLAY_SESSION_STATUS = {
  LOADING: 'loading',
  NOT_STARTED: 'not started',
  INCOMPLETE: 'incomplete',
  COMPLETE: 'complete',
};

function CollectionPuzzleLineItem({ className='', puzzleId, publishDate }) {

  const { windowWidth, isAuthenticated } = useAppContext();
  const { postErrorNotification } = useToastNotifications();

  const navigate = useNavigate();
  const { onRightClick, rightClickMenu } = useRightClickMenu();

  const myRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [currentErrorMessage, setCurrentErrorMessage] = useState(null);
  const [puzzleItem, setPuzzleItem] = useState(null);
  const [accessRequested, setAccessRequested] = useState(false);

  const [playSessionStatus, setPlaySessionStatus] = useState(PLAY_SESSION_STATUS.LOADING);

  const hasAttemptedLoading = useRef(false)
  const [forceReloadToggle, setForceReloadToggle] = useState(false);   // if this changes, it triggers the reload useEffect; used if there's an error and user reloads it
  function forceReload() {
    hasAttemptedLoading.current = false;
    setForceReloadToggle(!forceReloadToggle);
  }
  useEffect(() => {
    // Load the puzzle
    if (!hasAttemptedLoading.current) {
      hasAttemptedLoading.current = true;
      async function loadPuzzle() {
        // First, load the actual puzzle
        setIsLoading(true);
        setCurrentErrorMessage(null);
        try {
          const response = isAuthenticated ? await get({
            apiName: 'userPuzzles',
            path: `/userPuzzles/${puzzleId}`,
          }).response : await get({
            apiName: 'userPuzzles',
            path: `/publicPuzzles/${puzzleId}`,
          }).response;
          const res = await response.body.json();
          setPuzzleItem(res);
        } catch (e) {
          console.log(e);
          if (e.response?.data?.error === 'Item not found.') {
            setCurrentErrorMessage(ERROR_MESSAGES.DOES_NOT_EXIST);
          } else if (e.response?.data?.error === 'Permission denied.') {
            setCurrentErrorMessage(ERROR_MESSAGES.NO_PERMISSION);
          } else {
            setCurrentErrorMessage(ERROR_MESSAGES.UNSPECIFIED);
          }
        }
        setIsLoading(false);

        // Next, load the play session if it exists; need to support both localStorage and network for various situations
        retrieveFromServer(puzzleId, (res) => {
          const networkStatus = res.status;
          if (networkStatus === 'solved' || networkStatus === 'revealed') {
            setPlaySessionStatus(PLAY_SESSION_STATUS.COMPLETE);
          } else {
            const localStatus = retrieveFromLocalStorage(puzzleId)?.status;
            if (localStatus === 'solved' || localStatus === 'revealed') {
              setPlaySessionStatus(PLAY_SESSION_STATUS.COMPLETE);
            } else {
              if (networkStatus === 'incomplete' || localStatus === 'incomplete') {
                setPlaySessionStatus(PLAY_SESSION_STATUS.INCOMPLETE);
              } else {
                setPlaySessionStatus(PLAY_SESSION_STATUS.NOT_STARTED);
              }
            }
          }
        }, () => {
          const localStatus = retrieveFromLocalStorage(puzzleId)?.status;
          if (localStatus === 'solved' || localStatus === 'revealed') {
            setPlaySessionStatus(PLAY_SESSION_STATUS.COMPLETE);
          } else if (localStatus === 'incomplete') {
            setPlaySessionStatus(PLAY_SESSION_STATUS.INCOMPLETE);
          } else {
            setPlaySessionStatus(PLAY_SESSION_STATUS.NOT_STARTED);
          }
        })
      }
      loadPuzzle();
    }
  }, [puzzleId, forceReloadToggle, isAuthenticated]);

  const { puzzleMetadata, charGrid } = puzzleItem?.puzzleContent ? fromPuzzleContentObject(puzzleItem.puzzleContent) : {};
  const puzzleTitle = puzzleMetadata?.get(PuzzleMetadataKey.TITLE);
  const puzzleAuthor = puzzleMetadata?.get(PuzzleMetadataKey.AUTHOR);

  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      className={`${className} d-flex w-100 zoomable-subtle py-2 ${isHovering ? 'teal-2-bkgd' : ''}`}
      style={{ position: 'relative', border: '0.5px solid grey' }}
      ref={myRef}
      role='button'
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={() => {
        navigate(`/play/${puzzleId}`);
      }}
      onContextMenu={e => onRightClick({ parentRef: myRef, mouseEvent: e, menuItems: [
        { title: 'Open in new tab', icon: <IoMdOpen />, onClick: () => window.open(`/play/${puzzleId}`, '_blank').focus() },
      ]})}
    >
      <div className='text-center my-auto py-1 ms-1 me-3' style={{ position: 'relative', width: 34, height: 34 }}>
        <FaDelicious className='text-secondary' size={29} style={{ opacity: 0.3, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%) rotate(-10deg)' }} />
        <div style={{ fontSize: 12, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontWeight: 'bold' }}>
          {charGrid ? `${charGrid.length}x${charGrid[0].length}` : '?'}
        </div>
      </div>

    
      {isLoading ? (
        <div className='block-text fst-italic small my-auto'>Loading puzzle details...</div>
      ) : currentErrorMessage ? (
        <div className='text-secondary fst-italic small px-2 my-auto'>
          {currentErrorMessage}
          <div>
            <Button className='p-0 me-3' variant='link' style={{fontSize: 12}} onClick={e => {
              e.stopPropagation();
              forceReload();
            }}>Reload</Button>
            {isAuthenticated && currentErrorMessage === ERROR_MESSAGES.NO_PERMISSION && <Button className='p-0 me-3' disabled={accessRequested} variant='link' style={{fontSize: 12}} onClick={e => {
              e.stopPropagation();
              setAccessRequested(true);    // assumes no errors; that's okay (will post notification if it fails)
              async function requestAccess() {
                try {
                  const username = await getAuthenticatedUsername();
                  await post({
                    apiName: 'userPuzzles',
                    path: '/puzzleSharing',
                    options: {
                      body: {
                        requesterEmail: username,
                        puzzleId,
                      },
                    },
                  }).response;
                } catch (e) {
                  postErrorNotification('Error requesting access', 'Sorry, something went wrong trying to request access! If this continues, please let us know.');
                  setAccessRequested(false);
                }
              }
              requestAccess();
            }}>{accessRequested ? 'Access requested' : 'Request access'}</Button>}
          </div>
        </div>
      ) : !puzzleItem ? (
        <div className='my-auto small fst-italic'>No puzzle info available.</div>
      ) : (
        <>
          <div className='flex-grow-1 my-auto'>
            <div className='teal-4 me-2 my-auto'>{puzzleTitle}</div>
            <div className='small text-secondary'>
            {windowWidth > 330 ? (
              <span>{new Date(publishDate).toLocaleString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}{puzzleAuthor && <em> &middot; by {puzzleAuthor}</em>}</span>
            ) : (
              <>
                <div>{puzzleAuthor && <em>by {puzzleAuthor}</em>}</div>
                <div>{new Date(publishDate).toLocaleString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}</div>
              </>
            )}
            </div>
          </div>
          <div className='d-flex align-items-center'>
            {playSessionStatus === PLAY_SESSION_STATUS.COMPLETE ? (
              <TbCircleCheckFilled className='text-success' size={26} />
            ) : playSessionStatus === PLAY_SESSION_STATUS.INCOMPLETE ? (
              <TbProgressCheck className='text-secondary' size={26} />
            ) : (
              <div />
            )}
          </div>
        </>
      )}

      {rightClickMenu}
    </div>
  );
}



// Just the list of puzzle line items (no title or anything)
export function CollectionPuzzles({className='', lineItemClassName='', showMetadata=true, collectionId, initialCollectionObj, setPermissionsLevel, ...props}) {

  const { isAuthenticated } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [collectionObj, setCollectionObj] = useState({});

  const { collectionMetadata, collectionPuzzles } = collectionObj;
  const filteredSortedPuzzleIds = !collectionPuzzles ? [] : (Object.entries(collectionPuzzles)
    .filter(e => e[1].publishDate !== null && e[1].publishDate !== undefined && e[1].publishDate <= Date.now())
    .sort((a, b) => (
      (a[1].publishDate === null || a[1].publishDate === undefined) ? -1 :
      (b[1].publishDate === null || b[1].publishDate === undefined) ? 1 :
      100 * (b[1].publishDate - a[1].publishDate) + a[0].localeCompare(b[0])))   // puzzleId is the tiebreaker, to keep consistent sorting within same publish date
    .map(([puzzleId, _]) => puzzleId)
  );

  useEffect(() => {
    if (initialCollectionObj) {
      setCollectionObj(initialCollectionObj);
    } else {
      async function loadCollection() {
        setIsLoading(true);
        setIsError(false);
        try {
          const res = await (await get({
            apiName: 'userPuzzles',
            path: isAuthenticated ? `/collections/${collectionId}` : `/publicCollections/${collectionId}`, // attempt the publicCollections API if not logged in
          }).response).body.json();
          setCollectionObj(res);
          if (setPermissionsLevel) {
            setPermissionsLevel(res.permissionsLevel);
          }
        } catch (e) {
          console.log(e);
          setIsError(true);
        }
        setIsLoading(false);
      }

      loadCollection();
    }
  }, [initialCollectionObj, collectionId, setPermissionsLevel, isAuthenticated]);


  return isLoading ? (
    <div className={'d-flex ' + className} {...props}>
      <LoadingAnimation className='mt-5 mx-auto' />
    </div>
  ) : isError || !collectionMetadata ? (
    <div className={'mt-5 text-center ' + className} {...props}>
      <BiGhost className='zoomable' size={40} />
      <div className='mt-2 d-flex'>
        <p className='mx-auto not-too-wide'>
          Pardon us, we're having trouble loading your collection{!collectionMetadata ? ' metadata' : ''} right now.
          The collection owner may have made this a private collection.
          Double-check the URL, and please get in touch if this problem persists.
        </p>
      </div>
      <Button className='mt-3' variant='warning' onClick={() => window.location.reload()}>
        <AiOutlineReload className='me-2' />
        Reload
      </Button>
    </div>
  ) : (
    <Container className={'not-obscenely-wide ' + className} {...props}>
      {showMetadata && (
        <>
          <Row id='name-row' className='mt-4'>
            <div className='h3 fw-bold mx-auto text-center'>{collectionMetadata.name || 'untitled collection'}</div>
          </Row>
          {collectionMetadata.curator && <Row>
            <div className='mx-auto text-center balanced-text not-too-wide'>{collectionMetadata.curator}</div>
          </Row>}
          {collectionMetadata.description && <Row>
            <div className='fw-bold mx-auto text-center balanced-text not-too-wide'>{collectionMetadata.description}</div>
          </Row>}
        </>
      )}
      <Row className='my-3' id='puzzles-row'>
        {Object.entries(collectionPuzzles).filter(([pId, _]) => filteredSortedPuzzleIds.includes(pId)).sort((a, b) => filteredSortedPuzzleIds.indexOf(a[0]) - filteredSortedPuzzleIds.indexOf(b[0])).map(([puzzleId, { publishDate }]) => (
          <CollectionPuzzleLineItem
            className={lineItemClassName}
            key={`col-puz-${puzzleId}`}
            puzzleId={puzzleId}
            publishDate={publishDate}
          />
        ))}
      </Row>
    </Container>
  );
}



export default function ViewCollection({initialCollectionObj}) {

  const { windowWidth, isAuthenticated } = useAppContext();

  const { collectionId } = useParams();

  const [permissionsLevel, setPermissionsLevel] = useState();

  return (
    <div>
      <div
        className='d-flex w-100 teal-2-bkgd'
        style={{position: 'sticky', top: '0', paddingLeft: '70px', paddingRight: '60px', paddingTop: '3px', paddingBottom: '4px', minHeight: '25px', zIndex: 100, boxShadow: '0 0 4px black'}}
        id='view-collection-navbar'
      >
        {isAuthenticated && permissionsLevel && (permissionsLevel === 'MANAGE' || permissionsLevel === 'EDIT') && <Button
          className='me-1 ms-auto green-button'
          variant='outline-success'
          size='sm'
          href={`/manage-collections/${collectionId}`}
          target='_blank'
        >
          {windowWidth > 250 && <MdOpenInNew className='me-2' />}
          Manage{windowWidth > 330 ? ' collection' : ''}
        </Button>}
      </div>

      <div style={{ marginLeft: '3%', marginRight: '3%' }}>
        <CollectionPuzzles collectionId={collectionId} initialCollectionObj={initialCollectionObj} setPermissionsLevel={setPermissionsLevel} />
      </div>
    </div>
  );

}