import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { produce } from 'immer';
import { useCursorStates } from '../../libs/cursorLib';
import { fromPuzzleContentObject } from '../../libs/exportLib';
import { DEFAULT_CHAR_GRID, DEFAULT_CLUES, DEFAULT_FURNISHINGS, DEFAULT_PUZZLE_METADATA, DEFAULT_SLOT_STRUCTURE } from '../../libs/puzzleLib';
import { ACROSS, DOWN, PuzzleMetadataKey, SlotStructure } from '../../libs/directionsLib';
import { locInList } from '../../libs/blackoutsLib';
import { useAppContext } from '../../App';
import { retrieveFromLocalStorage, retrieveFromServer, submitToLocalStorage, submitToServer } from '../../libs/playSessionLib';


// Define contexts available to children
const PuzzleDataPlayContext = React.createContext();   // provides fixed data, such as the (correct) charGrid, clues, puzzle furnishings and metadata
const PlayStateContext = React.createContext();        // provides details on the playEvents (what letters have been entered where)
const PlayStatusContext = React.createContext();       // provides timer, play/pause/end functionality
const CursorPlayContext = React.createContext();       // provides cursor details
const PlayPreferencesContext = React.createContext();  // exposes user options not inherent to the puzzle, such as whether the user likes answers to be auto-checked

export function usePlayPuzzleData() {
  return useContext(PuzzleDataPlayContext);
}
export function usePlayState() {
  return useContext(PlayStateContext);
}
export function usePlayStatus() {
  return useContext(PlayStatusContext);
}
export function usePlayCursor() {
  return useContext(CursorPlayContext);
}
export function usePlayPreferences() {
  return useContext(PlayPreferencesContext);
}



var currentPlayEventId = 1
/**
 * Quick definition just to help me repeatedly reference these play event structures that I'll be handling a lot, sending up to the server, etc.
 */
class PlayEvent {
  constructor(loc, fill, timestamp=null, reveal=false) {
    this.id = currentPlayEventId++;
    this.l = loc;
    this.f = fill;
    this.t = timestamp || Date.now();
    this.r = reveal;
  }
}


/**
 * Describes the current state that the puzzle is in, just in terms of gameplay (not whether answers are correct).
 */
export const PlayStatus = {
  START: 'start',     // before gameplay has commenced
  PLAY: 'play',       // while gameplay is happening
  PAUSE: 'pause',     // while the user has paused gameplay
  END: 'end',         // once the user has completed gameplay
};




/**
 * Calculates all the convenience play state variables based on the play event history.
 */
function getCalculatedPlayStateValues(playEventHistory, charGrid) {

  const playStateCharGrid = charGrid.map(r => r.map(c => c === 'blackout' ? 'blackout' : ''));  // copy charGrid, but only the blackouts
  const correctLocs = [];
  const revealedLocs = [];   // note that revealedLocs is a subset of correctLocs
  const incorrectLocs = [];

  // Iterate backward along the playEventHistory, since the most recent event for any given loc is the one that we should display
  const exploredLocs = [];  // just a local list to keep track of which locs have been explored already, since some might be deletion events
  for (let i = playEventHistory.length - 1; i >= 0; --i) {
    const { l: loc, f: fill, r: reveal } = playEventHistory[i];
    if (!locInList(loc, exploredLocs)) {
      // Ok, this is the most recent even on this loc
      exploredLocs.push(loc);
      const [r, c] = loc;
      
      playStateCharGrid[r][c] = fill;

      if (fill === charGrid[r][c]) {
        correctLocs.push(loc);
      } else if (fill !== '') {
        incorrectLocs.push(loc);   // not blank, but not correct
      }

      if (reveal) {
        revealedLocs.push(loc);
      }
    }
  }


  const emptyLocs = playStateCharGrid.flatMap((row, r) => row.map((cell, c) => cell === '' ? [r, c] : false).filter(Boolean));

  return {
    playStateCharGrid,
    correctLocs,
    incorrectLocs,
    revealedLocs,
    emptyLocs,
    gridIsFull: emptyLocs.length === 0,
    gridIsFullAndCorrect: emptyLocs.length === 0 && incorrectLocs.length === 0,
  };


}




export const PlayPreferenceKey = {
  SHOW_MISTAKES_INSTANTLY: 'showMistakesInstantly',
};
const DEFAULT_PLAY_PREFERENCES = new Map([
  [PlayPreferenceKey.SHOW_MISTAKES_INSTANTLY, false],
]);



export default function PlayInteractionContext({ initialPuzzleItem, children }) {

  const { setDocumentTitle, resetDocumentTitle } = useAppContext();

  
  // Although these values are "constant" (unlike during construction), we still have to use states to handle async loading from the server
  const [charGrid, setCharGrid] = useState(DEFAULT_CHAR_GRID);
  const [slotStructure, setSlotStructure] = useState(DEFAULT_SLOT_STRUCTURE);   // will become helpful for handling clues, etc.
  const [furnishings, setFurnishings] = useState(DEFAULT_FURNISHINGS);
  const [clues, setClues] = useState(DEFAULT_CLUES);
  const [puzzleMetadata, setPuzzleMetadata] = useState(DEFAULT_PUZZLE_METADATA);



  // Play preferences (analogous to puzzlePreferences in BoardInteractionContext)
  const [playPreferences, setPlayPreferences] = useState(DEFAULT_PLAY_PREFERENCES);


  // Keep track of play events: this is the fundamental "play state"  (https://www.notion.so/Crossworthy-Play-dc096800a5714157afe8763d796c674d?pvs=4#5f1b1105f1e94dbc87c40db6fc07b52c)
  const [playEventHistory, setPlayEventHistory_] = useState([]);
  const playSessionRef = useRef();  // this value is slightly redundant as it contains the playEvents attribute, but also other metadata about the play session. It mirrors DyanamoDb / localStorage
  const throttleTimerRef = useRef(null);
  function setPlayEventHistory(newPlayEventHistory, doItNow = false) {
    playSessionRef.current.playEvents = newPlayEventHistory;
    // Also update the playTime
    playSessionRef.current.playTime = getCurrentPlayTime();

    // Submit to local storage as well as server, on a throttled basis (unless doItNow is specified)
    submitToLocalStorage(initialPuzzleItem.puzzleId, playSessionRef.current);

    if (throttleTimerRef.current) clearTimeout(throttleTimerRef.current);
    if (doItNow) {
      submitToServer(initialPuzzleItem.puzzleId, playSessionRef.current);
    } else {
      throttleTimerRef.current = setTimeout(() => {
        submitToServer(initialPuzzleItem.puzzleId, playSessionRef.current);
      }, 10000);
    }

    setPlayEventHistory_(newPlayEventHistory);
  }

  // Expose some calculated values for convenience; these are calculated with each render (may want to move to useEffect if renders become more frequent)
  const {
    playStateCharGrid,
    correctLocs,
    incorrectLocs,
    revealedLocs,
    emptyLocs,
    gridIsFull,
    gridIsFullAndCorrect,
  } = getCalculatedPlayStateValues(playEventHistory, charGrid);


  // Play status: use functions below (e.g. play, pause, end) to both set play status and handle timer
  const [playStatus, setPlayStatus] = useState(PlayStatus.START);   // this is owned here instead of PlayInteractionContext because it only has implications for Modals here

  // Timer handled by refs; timer advancing does not inherently trigger rerenders (child components must do this via useInterval)
  const timerRef = useRef({ lastStartTime: undefined, previouslyAccumulatedTime: 0 });   // note that these values are from Date.now(), i.e. ms precision
  const getCurrentPlayTime = useCallback(() => (timerRef.current.previouslyAccumulatedTime + (timerRef.current.lastStartTime ? (Date.now() - timerRef.current.lastStartTime) : 0)), []);

  // Also keep a timeout function to auto-pause the puzzle if the user is afk for 2 mins
  const afkTimeoutRef = useRef();
  function restartAfkTimeout() {    // called any time there's any activity
    if (afkTimeoutRef.current) clearTimeout(afkTimeoutRef.current);
    afkTimeoutRef.current = setTimeout(() => {
      if (timerRef.current.lastStartTime) pause();   // lastStartTime is only defined if the game is currently in progress (could be ended)
    }, 120 * 1000);
  }


  // Cursor
  const { cursorLoc, cursorDirection, handleClickOnLoc, handleCursorDirective } = useCursorStates({
    charGrid: playStateCharGrid,
    blackoutsFocusable: false,
    cursorHasChanged: restartAfkTimeout,
    disallowedAcrossLocs: Array.from(clues.entries())
        .filter(([slotName, clueText]) => clueText.includes('$hide$') && slotStructure.getDirectionOfSlot(slotName) === ACROSS)
        .reduce((acc, [slotName, clueText]) => acc.concat(slotStructure.getLocsInSlot(slotName)), []),
    disallowedDownLocs: Array.from(clues.entries())
        .filter(([slotName, clueText]) => clueText.includes('$hide$') && slotStructure.getDirectionOfSlot(slotName) === DOWN)
        .reduce((acc, [slotName, clueText]) => acc.concat(slotStructure.getLocsInSlot(slotName)), []),
  });
  const [temporaryCursorCache, setTemporaryCursorCache] = useState();   // just a variable to store the cursor loc in between pause-play



  // Functions to control game flow (play, pause, end). Handles the playStatus and timer, plus other odds and ends.
  // Note that child components may add their own functionality by tracking playStatus in a useEffect.

  function play() {
    // This function is called when the user clicks "Play" when first starting or after a pause.
    timerRef.current.lastStartTime = Date.now();
    restartAfkTimeout();
    setPlayStatus(PlayStatus.PLAY);
    // Auto set cursor
    if (temporaryCursorCache) {
      const { cursorLoc, cursorDirection } = temporaryCursorCache;
      handleClickOnLoc(cursorLoc, cursorDirection);
    } else {
      setTimeout(() => {
        handleClickOnLoc(emptyLocs[0] || null, ACROSS);
      }, [30]);
    }
  }

  function pause() {
    setTemporaryCursorCache({ cursorLoc, cursorDirection });
    timerRef.current.previouslyAccumulatedTime += (timerRef.current.lastStartTime ? Date.now() - timerRef.current.lastStartTime : 0);
    timerRef.current.lastStartTime = undefined;
    if (afkTimeoutRef.current) clearTimeout(afkTimeoutRef);
    setPlayEventHistory(playSessionRef.current.playEvents);   // just so they get submitted to the server
    setPlayStatus(PlayStatus.PAUSE);
  }

  // "Start over"
  function reset() {
    timerRef.current.previouslyAccumulatedTime = 0;
    timerRef.current.lastStartTime = undefined;
    playSessionRef.current.playEvents = [];
    playSessionRef.current.startTime = Date.now();
    playSessionRef.current.endTime = null;
    playSessionRef.current.status = 'incomplete';
    playSessionRef.current.playTime = 0;
    setPlayEventHistory([], true);   // this will submit the change to the server as well
    setPlayStatus(PlayStatus.START);
  }




  // On load from database, set all the fundamental data state values
  // Note that it does not START the game/timer (leaves that to PlayToolbar, actually) - just loads up the data so it's ready
  useEffect(() => {
    if (initialPuzzleItem) {
      const { puzzleContent, puzzleId } = initialPuzzleItem;
      const { charGrid, furnishings, clues, puzzleMetadata } = fromPuzzleContentObject(puzzleContent);

      // Set document title
      setDocumentTitle(`${puzzleMetadata.get(PuzzleMetadataKey.TITLE)}`);

      // Set initial state values
      setCharGrid(charGrid);
      setSlotStructure(SlotStructure.buildNewSlotStructure(charGrid));
      setFurnishings(furnishings);
      setClues(clues);
      setPuzzleMetadata(puzzleMetadata);

      // Finally, check if there's a playSession - both on the server database or in localStorage, and take the most updated
      function handleRetrievedPlaySession(serverPlaySession) {
        var playSession = retrieveFromLocalStorage(puzzleId) || serverPlaySession;
        if (serverPlaySession && playSession) {
          // See if server is more up-to-date
          const serverPlayEvents = serverPlaySession.playEvents || [];
          const localPlayEvents = playSession.playEvents || [];
          if (serverPlayEvents.length > 0 && localPlayEvents.length > 0 && serverPlayEvents[serverPlayEvents.length - 1]?.t > localPlayEvents[localPlayEvents.length - 1]?.t) {
            playSession = serverPlaySession;
          }
        }

        if (playSession) {
          playSessionRef.current = playSession;
          timerRef.current.previouslyAccumulatedTime = playSession.playTime;
          setPlayEventHistory_(playSession.playEvents);   // DON'T use the wrapper, as we don't want to update the database with this value

          if (playSession.endTime) {
            setPlayStatus(PlayStatus.END);
          }
        } else {
          playSessionRef.current = {
            playEvents: [],
            startTime: Date.now(),
            endTime: null,
            status: 'incomplete',
            playTime: 0, 
          }
          setPlayEventHistory_([]);
        }
      }
      retrieveFromServer(puzzleId, handleRetrievedPlaySession, () => handleRetrievedPlaySession(null));
    }

    // Cleanup function
    // const timerRefCopy = timerRef.current;
    // const playSessionRefCopy = playSessionRef.current;
    return () => {
      resetDocumentTitle();
      
      // // Commit latest playEvents to localStorage and the server
      // if (initialPuzzleItem?.puzzleId && playSessionRefCopy && timerRefCopy) {
      //   playSessionRefCopy.playTime = timerRefCopy.previouslyAccumulatedTime + (timerRefCopy.lastStartTime ? Date.now() - timerRefCopy.lastStartTime : 0);

      //   // Submit to local storage as well as server
      //   submitToLocalStorage(initialPuzzleItem.puzzleId, playSessionRefCopy);
      //   submitToServer(initialPuzzleItem.puzzleId, playSessionRefCopy);
      // }

      if (throttleTimerRef.current) clearTimeout(throttleTimerRef.current);
      if (afkTimeoutRef.current) clearTimeout(afkTimeoutRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPuzzleItem]);



  // Handle full correct completion of puzzle (ending) - note this does NOT handle incorrect completion (as timer is still going and game is still active)
  useEffect(() => {
    if (initialPuzzleItem?.puzzleId && playStatus === PlayStatus.PLAY && gridIsFullAndCorrect) {
      timerRef.current.previouslyAccumulatedTime += (timerRef.current.lastStartTime ? Date.now() - timerRef.current.lastStartTime : 0);
      timerRef.current.lastStartTime = undefined;
      if (afkTimeoutRef.current) clearTimeout(afkTimeoutRef);
      playSessionRef.current.playTime = timerRef.current.previouslyAccumulatedTime;

      playSessionRef.current.endTime = Date.now();
      playSessionRef.current.status = revealedLocs.length === 0 ? 'solved' : 'revealed';

      // Submit to local storage as well as server
      submitToLocalStorage(initialPuzzleItem.puzzleId, playSessionRef.current);
      if (throttleTimerRef.current) clearTimeout(throttleTimerRef.current);
      submitToServer(initialPuzzleItem.puzzleId, playSessionRef.current);

      setPlayStatus(PlayStatus.END);
    }
  }, [gridIsFullAndCorrect, playStatus, initialPuzzleItem?.puzzleId, revealedLocs?.length]);



  /**
   * Adds a letter to the playState at the given loc.
   * @param {[r,c]} loc 
   * @param {string} fillValue The letter to fill the loc in the playState
   */
  function addToPlayState(loc, fillValue) {
    if (playStatus !== PlayStatus.PLAY) return;
    setPlayEventHistory([...playEventHistory, new PlayEvent(loc, fillValue)]);
  }
  /**
   * Removes any letters from the playState at the given loc.
   * @param {[r,c]} loc 
   */
  function removeFromPlayState(loc) {
    if (playStatus !== PlayStatus.PLAY) return;
    addToPlayState(loc, '');
  }

  /**
   * Adds a loc to revealedLocs, and to playState, if not already in it.
   * @param {[[r,c]]} locs 
   */
  function revealLocs(locs) {
    if (playStatus !== PlayStatus.PLAY) return;

    if (!Array.isArray(locs)) {
      locs = [locs];
    }

    const timestamp = Date.now();
    setPlayEventHistory(playEventHistory.concat(locs.map(loc => new PlayEvent(loc, charGrid[loc[0]][loc[1]], timestamp, true))));
  }



  /**
   * Returns the play preference of the given PlayPreferenceKey.
   * @param {PlayPreferenceKey} playPreferenceKey 
   * @returns {*}
   */
  function getPlayPreference(playPreferenceKey) {
    return playPreferences.get(playPreferenceKey);
  }

  /**
   * Sets the play preference of the given PlayPreferenceKey.
   * Note that calling this repeatedly within the same render cycle will only keep one of the requested changes.
   * @param {PlayPreferenceKey} playPreferenceKey 
   * @param {*} newPlayPreference 
   */
  function setPlayPreference(playPreferenceKey, newPlayPreference) {
    const newPlayPreferences = produce(playPreferences, (draft) => {
      draft.set(playPreferenceKey, newPlayPreference);
    });
    
    setPlayPreferences(newPlayPreferences);
  }


  return (
    <PuzzleDataPlayContext.Provider value={{puzzleId: initialPuzzleItem?.puzzleId, charGrid, slotStructure, furnishings, clues, puzzleMetadata}}>
      <PlayStateContext.Provider value={{playStateCharGrid, addToPlayState, removeFromPlayState, correctLocs, incorrectLocs, emptyLocs, gridIsFull, gridIsFullAndCorrect, revealedLocs, revealLocs}}>
        <PlayStatusContext.Provider value={{playStatus, play, pause, reset, getCurrentPlayTime}}>
          <CursorPlayContext.Provider value={{cursorLoc, cursorDirection, handleClickOnLoc, handleCursorDirective}}>
            <PlayPreferencesContext.Provider value={{getPlayPreference, setPlayPreference}}>
              {children}
            </PlayPreferencesContext.Provider>
          </CursorPlayContext.Provider>
        </PlayStatusContext.Provider>
      </PlayStateContext.Provider>
    </PuzzleDataPlayContext.Provider>
  );
}