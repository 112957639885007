import React, { useRef, useState } from 'react';
import { useFormFields } from '../../../libs/hooksLib';
import { PuzzleMetadataKey, charGridWithoutLetters } from '../../../libs/directionsLib';
import { Button, Col, Form, FormCheck, Modal, Nav, Row, Tab } from 'react-bootstrap';
import { toPng } from 'html-to-image';
import { getNYTsubmissionPDF, getPrintablePDF, getPuzFile } from '../../../libs/exportLib';
import { PuzzleInteractionStatus, useCharGrid, useClues, useFurnishings, usePuzzleMetadata } from '../BoardInteractionContext';
import Board from '../../board/Board';
import { FurnishingType, furnishingsObjectToList } from '../../../libs/furnishingsLib';



export default function ExportModal({ show, onHide }) {

  const charGrid = useCharGrid();
  const { clues } = useClues();
  const { puzzleMetadata } = usePuzzleMetadata();
  const { furnishings } = useFurnishings();


  const [includeAnswers, setIncludeAnswers] = useState(false);
  const [includeClues, setIncludeClues] = useState(true);
  const [exportFormFields, handleExportFormFieldChange] = useFormFields({
    printOption: 'Blank (for playing)',
    printShowAdvanced: false,
    printClueFont: 'Helvetica',
    printClueFontSize: 10,
    nytAuthor: '',
    nytAddressLine1: '',
    nytAddressLine2: '',
    nytEmail: '',
  });

  
  const exportBoardRef = useRef(null);   // used for taking a "screenshot" in order to print
  const exportBoard = <Board
    charGrid={includeAnswers ? charGrid : charGridWithoutLetters(charGrid)}
    furnishings={furnishings}
    puzzleInteractionStatus={PuzzleInteractionStatus.STATUESQUE}
    ref={exportBoardRef}
  />;


  return (
    <Modal
      show={show}
      onHide={onHide}
      onShow={() => {
        // Automatically fill any applicable fields
        handleExportFormFieldChange({target: {id: 'nytAuthor', value: puzzleMetadata.get(PuzzleMetadataKey.AUTHOR)}});
      }}
    >

      <Modal.Header className='purple-3-bkgd' closeButton>
        <strong>Export your masterpiece</strong>
      </Modal.Header>

      <Modal.Body>
        <Tab.Container defaultActiveKey='pdf'>
          <Row>
            <Col sm={4}>
              <Nav variant='pills' className='flex-column mb-3'>
                <Nav.Item>
                  <Nav.Link className='p-1 pl-2' eventKey='pdf' onClick={() => {
                    handleExportFormFieldChange({target: {id: 'printOption', value: 'Blank (for playing)'}});
                    setIncludeAnswers(false);
                    setIncludeClues(true);
                  }}>
                    PDF
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className='p-1 pl-2' eventKey='png' onClick={() => setIncludeAnswers(true)}>
                    PNG
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className='p-1 pl-2' eventKey='puz'>
                    .puz
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className='p-1 pl-2' eventKey='nyt' onClick={() => setIncludeAnswers(true)}>
                    For NYT
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {exportBoard}
            </Col>
            
            <Col sm={8}>
              <Tab.Content>
                <Tab.Pane eventKey='hidden-image'>
                  {exportBoard}
                </Tab.Pane>
                
                <Tab.Pane eventKey='pdf'>
                  <div>
                    <div className='text-center mb-2'>
                      <div className='m-0 h4'>Export a PDF</div>
                      <span className='small text-muted fst-italic'>for playing or sharing</span>
                    </div>

                    <Form className='d-flex flex-column' onSubmit={async (e) => {
                      e.preventDefault();
                      var doc;

                      // If there are noncolor furnishings, generate a png imgData; otherwise, leave it undefined (so getPrintablePDF will generate its own table)
                      var gridPngData = undefined;
                      if (furnishingsObjectToList(furnishings).some(f => f.furnishingType !== FurnishingType.COLOR)) {
                        gridPngData = await toPng(exportBoardRef.current, { cacheBust: true, width: 707, height: Math.round(707 / charGrid[0].length * charGrid.length)});
                      }

                      doc = getPrintablePDF({ gridPngData, puzzleMetadata, charGrid, furnishings, clues, options: {
                        includeAnswers,
                        includeClues,
                        clueFont: exportFormFields.printClueFont,
                        clueFontSize: exportFormFields.printClueFontSize,
                      }});
                      doc.save((puzzleMetadata.get(PuzzleMetadataKey.TITLE) || 'untitled puzzle') + '.pdf');
                    }}>
                      <Form.Select
                        className='mb-3'
                        id='printOption'
                        value={exportFormFields.printOption}  // need this to be controlled like this in case user navigates to another option and comes back, needs to be reset
                        onChange={e => {
                          handleExportFormFieldChange(e);
                          if (e.target.value === 'Blank (for playing)') {
                            setIncludeAnswers(false);
                            setIncludeClues(true);
                          } else if (e.target.value === 'Solution') {
                            setIncludeAnswers(true);
                            setIncludeClues(true);
                          } else if (e.target.value === 'Grid only') {
                            setIncludeAnswers(true);
                            setIncludeClues(false);
                          }
                        }}
                      >
                        <option>Blank (for playing)</option>
                        <option>Solution</option>
                        <option>Grid only</option>
                      </Form.Select>

                      {exportFormFields.printShowAdvanced && (
                        <div className='border-top pt-2 mt-2 mb-4'>
                          <Form.Group className='d-flex m-0 small'>
                            <Form.Label className='my-auto p-0 text-nowrap'>Font size</Form.Label>
                            <Form.Range
                              className='mx-2'
                              id='printClueFontSize'
                              value={exportFormFields.printClueFontSize}
                              onChange={handleExportFormFieldChange}
                              min={6}
                              max={18}
                              step={1}
                            />
                            <span className='my-auto text-muted'>{exportFormFields.printClueFontSize}</span>
                          </Form.Group>

                          <Form.Group className='d-flex m-0 mt-2 small'>
                            <Form.Label className='my-auto p-0'>Font</Form.Label>
                            <Form.Select
                              className='ms-2'
                              id='printClueFont'
                              size='sm' 
                              value={exportFormFields.printClueFont}
                              onChange={handleExportFormFieldChange}
                              style={{fontFamily: exportFormFields.printClueFont}}
                            >
                              <option style={{fontFamily: 'Helvetica'}}>Helvetica</option>
                              <option style={{fontFamily: 'Times'}}>Times</option>
                              <option style={{fontFamily: 'Courier'}}>Courier</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                      )}

                      <div className='d-flex'>
                        <Button
                          variant={exportFormFields.printShowAdvanced ? 'secondary' : 'outline-secondary'}
                          onClick={() => handleExportFormFieldChange({target: {id: 'printShowAdvanced', value: !exportFormFields.printShowAdvanced}})}
                        >
                          Advanced
                        </Button>

                        <Button className='ms-auto' type='submit' variant='info'>
                          Get PDF
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey='png'>
                  <div>
                    <div className='text-center mb-2'>
                      <div className='m-0 h4'>Export a PNG image</div>
                      <span className='small text-muted fst-italic'>of the board</span>
                      <div className='d-flex'>
                        <FormCheck
                          className='mx-auto my-3'
                          id='png-include-answers-switch'
                          type='switch'
                          label='Include answers'
                          checked={includeAnswers}
                          onChange={e => setIncludeAnswers(e.target.checked)}
                        />
                      </div>
                    </div>
                    <div className='d-flex'>
                      <Button
                        className='mx-auto'
                        variant='info'
                        onClick={async () => {
                          if (!exportBoardRef.current) {
                            console.log('Error exporting - aborting export');
                            return;
                          }

                          // https://www.notion.so/Export-to-PNG-PDF-notes-w-furnishings-3a391bac741941a5ba35b048578d8369?pvs=4

                          toPng(exportBoardRef.current, { cacheBust: true, width: 707, height: Math.round(707 / charGrid[0].length * charGrid.length)}).then(dataUrl => {
                            const link = document.createElement('a');
                            link.download = `${puzzleMetadata.get(PuzzleMetadataKey.TITLE) || 'My Crossworthy Puzzle'}.png`
                            link.href = dataUrl;
                            link.click();
                          }).catch(err => {
                            console.log(err);
                          });
                        }}
                      >Get PNG</Button>
                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey='puz'>
                  <div>
                    <div className='text-center mb-2'>
                      <div className='m-0 h4'>Export as .puz</div>
                      <span className='small text-muted fst-italic'>for Across Lite and other software</span>
                      <p className='small text-muted mt-2'>
                        You can play .puz files for free using programs like <em>Across Lite</em>, or online at sites 
                        like <a href='https://downforacross.com/' target='_blank' rel='noreferrer'>Down for a Cross</a>
                        , <a href='https://www.crosswordnexus.com/solve/' target='_blank' rel='noreferrer'>Crossword Nexus</a>
                        , <a href='https://confuzzle.app/' target='_blank' rel='noreferrer'>Confuzzle</a>
                        , or <a href='https://squares.io/' target='_blank' rel='noreferrer'>Squares.io</a>.
                      </p>
                      {furnishingsObjectToList(furnishings).length > 0 && <p className='small text-danger'>Custom colors and other ornamental furnishings can't currently be exported to the .puz file.</p>}
                    </div>
                    <Form className='d-flex flex-column' onSubmit={(e) => {
                      e.preventDefault();
                      const puzBytes = getPuzFile({ puzzleMetadata, charGrid, clues });

                      const a = window.document.createElement('a');
                      a.href = window.URL.createObjectURL(new Blob([puzBytes], { type: 'application/octet-stream' }));
                      a.download = `${puzzleMetadata.get(PuzzleMetadataKey.TITLE) || 'My Crossworthy Puzzle'}.puz`
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                    }}>
                      <Button className='m-auto' variant='info' type='submit'>
                        Get .puz file
                      </Button>
                    </Form>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey='nyt'>
                  <div>
                    <div className='text-center mb-2'>
                      <div className='m-0 h4'>Export a PDF</div>
                      <span className='small text-muted fst-italic'>formatted for New York Times submission</span>
                    </div>
                    <Form className='d-flex flex-column' onSubmit={async (e) => {
                      e.preventDefault();

                      var gridPngData = undefined;
                      if (furnishingsObjectToList(furnishings).some(f => f.furnishingType !== FurnishingType.COLOR)) {
                        gridPngData = await toPng(exportBoardRef.current, { cacheBust: true, width: 707, height: Math.round(707 / charGrid[0].length * charGrid.length)});
                      }
                      getNYTsubmissionPDF({
                        author: exportFormFields.nytAuthor,
                        addressLine1: exportFormFields.nytAddressLine1,
                        addressLine2: exportFormFields.nytAddressLine2,
                        email: exportFormFields.nytEmail,
                        gridPngData,
                        charGrid,
                        clues,
                        furnishings
                      }).save(`${puzzleMetadata.get(PuzzleMetadataKey.TITLE)} (NYT submission).pdf`);
                    }}>
                      <Form.Group>
                        <Form.Label className='small mb-0'>Name in header</Form.Label>
                        <Form.Control size='sm' type='text' placeholder='Name' id='nytAuthor' value={exportFormFields.nytAuthor} onChange={handleExportFormFieldChange} />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label className='small mb-0'>Contact info in header (optional)</Form.Label>
                        <Form.Control size='sm' type='text' placeholder='Address line 1' id='nytAddressLine1' value={exportFormFields.nytAddressLine1} onChange={handleExportFormFieldChange} />
                        <Form.Control size='sm' type='text' placeholder='Address line 2' id='nytAddressLine2' value={exportFormFields.nytAddressLine2} onChange={handleExportFormFieldChange} />
                        <Form.Control className='mt-2' size='sm' type='email' placeholder='Email' id='nytEmail' value={exportFormFields.nytEmail} onChange={handleExportFormFieldChange} />
                      </Form.Group>

                      <Button className='ms-auto mt-2' variant='info' type='submit'>
                        Get PDF
                      </Button>
                    </Form>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
    </Modal>
  )

}