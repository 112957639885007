import React, { useState } from 'react';
import { post } from 'aws-amplify/api';
import { Button, Form } from 'react-bootstrap';
import LoaderButton from '../components/reusable/LoaderButton';
import { useFormFields } from '../libs/hooksLib';
import { HiOutlineSparkles } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { IoChatbubble } from 'react-icons/io5';
import { useToastNotifications } from '../libs/toastLib';


export default function Feedback({ formOnly=false }) {

  const navigate = useNavigate();
  const { postErrorNotification } = useToastNotifications();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [fields, handleFieldChange] = useFormFields({
    email: '',
    name: '',
    message: '',
  });

  function postFeedback() {
    return post({
      apiName: 'userSupport',
      path: '/feedback',
      options: {
        body: {
          email: fields.email,
          name: fields.name,
          content: fields.message
        }
      },
    }).response;
  }

  function renderAuthenticatedPage() {

    async function handleSubmit(e) {
      e.preventDefault();

      setIsSubmitting(true);

      try {
        await postFeedback();
        setIsSubmitting(false);
        setHasSubmitted(true);
      } catch (e) {
        postErrorNotification(
          'Oops!', 
          'There was an error submitting your feedback - guess we need some meta-feedback :/ You can still get in touch at crossworthypuzzles@gmail.com!'
        );
        setIsSubmitting(false);
      }
    }

    return (
      <div className='d-flex'>
        <div className='not-too-wide mx-auto my-4 p-1'>
          {!formOnly && <>
            <div className='mb-4 d-flex h5'>
              <IoChatbubble className='my-auto me-2' size={50} />
              Got a question, found a bug, or hoping for a particular feature?
            </div>
            <p>
              Let us know what you think!
              We're on a mission to make the best, most accessible tool we can, so we always appreciate any feedback and will follow up with you as soon as we can.
            </p>
            <p className='text-end'>~Kevin and May</p>
          </>}

          <Form onSubmit={handleSubmit}>
            <Form.Group className='mb-3' controlId='email'>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                autoFocus
                type='email'
                placeholder='Email (optional)'
                value={fields.email}
                onChange={handleFieldChange}
              />
              <Form.Text className='text-muted'>
                Enter your email if you'd like us to be able to follow up with you!
              </Form.Text>
            </Form.Group>

            <Form.Group className='mb-3' controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Name (optional)'
                value={fields.name}
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group className='mb-3' controlId='message'>
              <Form.Label>Message</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                value={fields.message}
                onChange={handleFieldChange}
              />
            </Form.Group>

            <div className='d-flex justify-content-end'>
              <LoaderButton
                style={{ width: '150px' }}
                type='submit'
                disabled={fields.message.length === 0}
                isLoading={isSubmitting}
              >
                Submit
              </LoaderButton>
            </div>
          </Form>
        </div>
      </div>
    );
  }

  function renderThankYou() {
    return (
      <div className='d-flex p-1'>
        <div className='my-4 mx-auto not-too-wide'>
          <div className='mb-4 h4 d-flex'>
            <HiOutlineSparkles className='my-auto me-2' />
            Thank you for your feedback!
          </div>
          <p>
            We'll get back to you as soon as we can, if you want us to. All the best and enjoy your puzzling!
          </p>
          <div>
            <Button className='mt-3' variant='outline-info' size='sm' onClick={() => setHasSubmitted(false)}>Submit another note</Button>
          </div>
          <Button className='mt-3' variant='primary' size='sm' href='/' onClick={e => {
            e.preventDefault();
            navigate('/');
          }}>Crossworthy Home</Button>
        </div>
      </div>
    )
  }

  return hasSubmitted ? renderThankYou() : renderAuthenticatedPage();
}