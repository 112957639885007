import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { get } from 'aws-amplify/api';
import { Button } from 'react-bootstrap';
import { BiGhost } from 'react-icons/bi';
import { GrHalt } from 'react-icons/gr';
import { BsPencil } from 'react-icons/bs';
import BoardInteractionProvider from '../components/construct/BoardInteractionContext';
import ConstructorEasel from '../components/construct/ConstructorEasel';
import PermissionDenied from './PermissionDenied';
import { useAppContext } from '../App';
import LoadingAnimation from '../components/reusable/LoadingAnimation';
import { useToastNotifications } from '../libs/toastLib';


/**
 * The highest-level container for Crossworthy Construct. Wraps a BoardInteractionContext and all its children.
 * This component loads the puzzle data from the server and passes this as "initial" puzzle data to the BoardInteractionContext.
 */
export default function Construct() {

  const { id } = useParams();
  const location = useLocation();
  // When the page is refreshed manually, it should force loading from DynamoDB
  const forceReloadPuzzleItem = sessionStorage.getItem('forceReloadPuzzleItem') === 'true' || false;
  window.addEventListener('beforeunload', () => {     // before refresh, set this guy to true
    sessionStorage.setItem('forceReloadPuzzleItem', 'true');
  });

  const { isAuthenticated, showLoginPage } = useAppContext();
  const { postNotification, postErrorNotification, removeNotificationsWithLabels } = useToastNotifications();
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [puzzleDoesNotExist, setPuzzleDoesNotExist] = useState(false);
  const [initialPuzzleItem, setInitialPuzzleItem] = useState((!forceReloadPuzzleItem && location.state) ? location.state.puzzleItem : null);
  const [isLoading, setIsLoading] = useState(false);

  // Load puzzle using id
  useEffect(() => {
    if (id !== 'scratch' && (!initialPuzzleItem || initialPuzzleItem.puzzleId !== id) && isAuthenticated) {
      async function onLoad() {
        setIsLoading(true);
        try {
          const response = await get({
            apiName: 'userPuzzles',
            path: `/userPuzzles/${id}`,
          }).response;
          const puzzleItem = await response.body.json();
          setInitialPuzzleItem(puzzleItem);
        } catch (e) {
          if (e.response?.statusCode === 403) {
            setPermissionDenied(true);
          } else if (e.response?.body && JSON.parse(e.response.body).error === 'Item not found.') {
            setPuzzleDoesNotExist(true);
          } else {
            postErrorNotification('Oops!', 'We experienced an issue loading your puzzle. Try again later or contact us to let us know.', ['puzzle-specific']);
          }
        }
        setIsLoading(false);
      }

      onLoad();
    } else if (id === 'scratch') {
      postNotification({
        variant: 'chill',
        icon: <BsPencil />,
        headline: isAuthenticated ? 'Scratch space' : 'Login to save',
        content: (
          <span>
            {isAuthenticated ? (
              <span>You're in the Scratch Space, which isn't saved automatically, but you can click File &gt; "Save As..." anytime to keep your work.</span>
            ) : (
              <span>
                <a href='/account' onClick={e => {
                  e.preventDefault();
                  showLoginPage();
                }}>Create an account</a> (no password needed) or <a href='/account' onClick={e => {
                  e.preventDefault();
                  showLoginPage();
                }}>login</a> to start saving, publishing, and collaborating on your puzzles! 
              </span>
            )}
          </span>
        ),
        labels: (isAuthenticated ? ['request-save-as'] : ['request-login']).concat(['puzzle-specific']),
      });
    }
  }, [id, initialPuzzleItem, isAuthenticated, postErrorNotification, postNotification, showLoginPage]);


  // Cleanup function to remove any puzzle-specific notifications - https://www.notion.so/Toast-System-235a221e3b6e4953879e9bf545b3855b?pvs=4
  useEffect(() => {
    return () => removeNotificationsWithLabels(['puzzle-specific']);
  }, [removeNotificationsWithLabels]);


  if (id !== 'scratch' && !isAuthenticated) {
    return (
      <div className='d-flex m-2'>
        <div className='mx-auto mt-5 text-center not-too-wide'>
          <h2 className='mb-3 pb-3 zoomable'>Hold up! <GrHalt /></h2>
          <hr />
          <p>
            You're seeing this because you're trying to edit a puzzle that either doesn't exist or needs login
            credentials. <a href='/account' onClick={e => {
              e.preventDefault();
              showLoginPage();
            }}>Sign up or login</a> to Crossworthy to continue.
          </p>
          <p>
            You don't need to log in to access <a href='/construct/scratch'>Crossworthy Construct</a>, but creating an 
            account will allow you to save your puzzles and come back to them later.
          </p>
        </div>
      </div>
    );
  }

  if (permissionDenied) {
    return <PermissionDenied puzzleId={id} />
  }

  if (puzzleDoesNotExist) {
    return (
      <div className='justify-content-center my-5'>
        <div className='d-flex my-3'>
          <BiGhost className='mx-auto' size={40} />
        </div>
        <div className='d-flex'>
          <div className='mx-auto h2'>Puzzle does not exist!</div>
        </div>
        <div className='d-flex mt-3'>
          <div className='mx-auto d-flex align-items-center'>
            <div>Check that the URL is correct.</div>
          </div>
        </div>
        <div className='d-flex mt-3'>
          <Button className='mx-auto' href='/construct'>Construct Home</Button>
        </div>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className='text-center mt-5'>
        <LoadingAnimation size={20} />
      </div>
    );
  }

  return (
    <BoardInteractionProvider initialPuzzleItem={initialPuzzleItem}>
      <ConstructorEasel />
    </BoardInteractionProvider>
  );
}