/**
 * This library functions as a sort of change event generator.
 * It will generate change events with ordered IDs and ensure consistent generation.
 */

import { ACROSS, DOWN } from "./directionsLib";


const DELIMITER = '///';   // an impossibly rare combination of characters used as a delimiter within entity strings


/**
 * The type of change event.
 */
export const ChangeType = {
  GRID: 'grid',
  FURNISHING: 'furnishing',
  CLUE: 'clue',
  METADATA: 'metadata',
  OVERHAUL: 'overhaul',
  CLUE_OVERHAUL: 'clueOverhaul',
};


var currentChangeEventId = 1;
var currentChangeEventGroupId = 1;

/**
 * One atomic change event; i.e. one loc, one clue, one metavalue, or an overhaul.
 */
class ChangeEvent {
  constructor({
    changeType,
    entityString,
    oldValue,
    newValue,

    isConditionalOnOldValue = false,
    requiredGridSize = null,
  }) {
    this.changeEventId = currentChangeEventId;
    currentChangeEventId += 1;

    this.changeType = changeType;
    this.entityString = entityString;
    this.oldValue = oldValue;
    this.newValue = newValue;

    this.isConditionalOnOldValue = isConditionalOnOldValue;
    this.requiredGridSize = requiredGridSize;
  }
}

// Functions to generate new change events

export function newGridChangeEvent(loc, oldValue, newValue, gridSize) {
  return new ChangeEvent({
    changeType: ChangeType.GRID,
    entityString: JSON.stringify(loc),
    oldValue,
    newValue,
    requiredGridSize: gridSize,
  });
}

export function getSlotIndexFromSlotName(slotName, clues) {
  const acrossSlotNames = Array.from(clues.keys()).filter(sn => /cross/.test(sn));
  const downSlotNames = Array.from(clues.keys()).filter(sn => !(/cross/.test(sn)));
  const slotIndex = /cross/.test(slotName) ? acrossSlotNames.indexOf(slotName) : downSlotNames.indexOf(slotName);
  return slotIndex;
}

export function newFurnishingChangeEvent(furnishingType, loc, oldValue, newValue, gridSize) {
  return new ChangeEvent({
    changeType: ChangeType.FURNISHING,
    entityString: furnishingType + DELIMITER + JSON.stringify(loc),
    oldValue,
    newValue,
    requiredGridSize: gridSize,
  });
}

export function newClueChangeEvent(slotName, slotIndex, oldValue, newValue, gridSize) {
  return new ChangeEvent({
    changeType: ChangeType.CLUE,
    entityString: slotName + DELIMITER + slotIndex, // we have to maintain both the slotName for client-side & the index within the across/down list for dynamodb
    oldValue,
    newValue,
    requiredGridSize: gridSize,
  });
}

export function newMetadataChangeEvent(metavalueName, oldValue, newValue) {
  return new ChangeEvent({
    changeType: ChangeType.METADATA,
    entityString: metavalueName,
    oldValue,
    newValue,
  });
}

export function newOverhaulChangeEvent(oldCharGrid, oldFurnishings, oldClues, oldMetadata, newCharGrid, newFurnishings, newClues, newMetadata) {
  return new ChangeEvent({
    changeType: ChangeType.OVERHAUL,
    entityString: 'overhaul',  // don't think this will matter
    oldValue: { charGrid: oldCharGrid, furnishings: oldFurnishings, clues: oldClues, puzzleMetadata: oldMetadata },
    newValue: { charGrid: newCharGrid, furnishings: newFurnishings, clues: newClues, puzzleMetadata: newMetadata },
  });
}

export function newClueOverhaulChangeEvent(oldClues, newClues, gridSize) {
  return new ChangeEvent({
    changeType: ChangeType.CLUE_OVERHAUL,
    entityString: 'clueOverhaul',   // doesn't matter
    oldValue: oldClues,
    newValue: newClues,
    requiredGridSize: gridSize,
  });
}




// Merging change events with existing puzzles

/**
 * Merges the given change events into the given puzzle
 * @param {{charGrid, furnishings, puzzleMetadata, clues}} puzzleParams charGrid, furnishings, puzzleMetadata, and clues
 * @param {[ChangeEvent]} changeEvents List of changeEvents to merge into the puzzle, in order
 * @returns {{charGrid, puzzleMetadata, clues, cursorLoc?, cursorDirection?}} Deep-copies of the original with updated data based on change events,
 *   with suggested cursor info (or null) based on the change events (can be ignored if this is a merge from remote collaborators)
 * 
 * NOTE: this function is duplicated on the server in processChangeEventQueue.js. If the logic must be updated, be sure to duplicate the changes there.
 */
export function mergePuzzleWithChangeEvents(puzzleParams, changeEvents) {
  var { charGrid, furnishings = {}, clues, puzzleMetadata } = puzzleParams;
  var cursorLoc = null;
  var cursorDirection = null;

  // Make copies so we're not modifying in-place
  charGrid = JSON.parse(JSON.stringify(charGrid));
  furnishings = JSON.parse(JSON.stringify(furnishings));
  clues = new Map(clues);
  puzzleMetadata = new Map(puzzleMetadata);

  for (const changeEvent of changeEvents) {
    switch (changeEvent.changeType) {
      case ChangeType.GRID:
        const [r, c] = JSON.parse(changeEvent.entityString);
        if (!changeEvent.isConditionalOnOldValue || changeEvent.oldValue === charGrid[r][c]) {   // ensure that the old value matches or it isn't required
          if (!changeEvent.requiredGridSize || (changeEvent.requiredGridSize[0] === charGrid.length && changeEvent.requiredGridSize[1] === charGrid[0].length)) {
            charGrid[r][c] = changeEvent.newValue;

            // Update cursorDirection if there have been multiple GRID changeEvents in the same row or column
            if (cursorLoc && cursorLoc[0] === r && cursorLoc[1] !== c) {
              cursorDirection = ACROSS;
            } else if (cursorLoc && cursorLoc[1] === c && cursorLoc[0] !== r) {
              cursorDirection = DOWN;
            }
            // Update cursorLoc
            cursorLoc = [r, c];
          }
        }
        break;
      case ChangeType.FURNISHING:
        const [furnishingType, stringifiedLoc] = changeEvent.entityString.split(DELIMITER);
        if (
          !changeEvent.isConditionalOnOldValue || 
          (!changeEvent.oldValue && !furnishings[furnishingType]?.[stringifiedLoc]) || 
          changeEvent.oldValue === furnishings[furnishingType]?.[stringifiedLoc]
        ) {
          if (!changeEvent.requiredGridSize || (changeEvent.requiredGridSize[0] === charGrid.length && changeEvent.requiredGridSize[1] === charGrid[0].length)) {
            if (!changeEvent.newValue) {
              // Signifies a furnishing that should be deleted
              delete furnishings[furnishingType]?.[stringifiedLoc];
            } else {
              // Signifies a furnishing that should be updated, overwritten if necessary
              if (furnishings[furnishingType]) {
                furnishings[furnishingType][stringifiedLoc] = changeEvent.newValue;
              } else {
                furnishings[furnishingType] = { [stringifiedLoc]: changeEvent.newValue };
              }
            }
          }
        }
        break;
      case ChangeType.CLUE:
        const slotName = changeEvent.entityString.split(DELIMITER)[0];
        if (!changeEvent.isConditionalOnOldValue || changeEvent.oldValue === clues.get(slotName)) {
          if (!changeEvent.requiredGridSize || (changeEvent.requiredGridSize[0] === charGrid.length && changeEvent.requiredGridSize[1] === charGrid[0].length)) {
            clues.set(slotName, changeEvent.newValue);
          }
        }
        break;
      case ChangeType.METADATA:
        const key = changeEvent.entityString;
        if (!changeEvent.isConditionalOnOldValue || changeEvent.oldValue === puzzleMetadata.get(key)) {
          puzzleMetadata.set(key, changeEvent.newValue);
        }
        break;
      case ChangeType.OVERHAUL:
        // Can't be conditional on old value
        ( { charGrid, furnishings, clues, puzzleMetadata } = changeEvent.newValue );

        // Make copies so we're not modifying in-place
        charGrid = JSON.parse(JSON.stringify(charGrid));
        furnishings = JSON.parse(JSON.stringify(furnishings));
        clues = new Map(clues);
        puzzleMetadata = new Map(puzzleMetadata);
        break;
      case ChangeType.CLUE_OVERHAUL:
        if (!changeEvent.requiredGridSize || (changeEvent.requiredGridSize[0] === charGrid.length && changeEvent.requiredGridSize[1] === charGrid[0].length)) {
          clues = new Map(changeEvent.newValue);
        }
        break;
      default:
        console.error('mergePuzzleWithChangeEvents called with invalid changetype ' + changeEvent.changeType);
    }
  }

  return { charGrid, furnishings, clues, puzzleMetadata, cursorLoc, cursorDirection };
}




// Change event groups (recording simultaneous change events for undo/redo functionality)

export class ChangeEventGroup {
  constructor({
    changeEventList,
    undoneChangeEventGroupIds = null,
    redoneChangeEventGroupIds = null,
  }) {
    this.changeEventGroupId = currentChangeEventGroupId;
    currentChangeEventGroupId += 1;

    this.changeEventList = changeEventList;
    
    if (!undoneChangeEventGroupIds) {
      undoneChangeEventGroupIds = [];
    }
    if (!redoneChangeEventGroupIds) {
      redoneChangeEventGroupIds = [];
    }
    this.undoneChangeEventGroupIds = undoneChangeEventGroupIds;   // possibly empty list of integers identifying corresponding ChangeEventGroups that were undone
    this.redoneChangeEventGroupIds = redoneChangeEventGroupIds;   // possibly empty list of integers identifying corresponding ChangeEventGroups that were redone

    this.timestamp = Date.now();
    this.isSaved = false;
  }

  get isUndo() {
    return this.undoneChangeEventGroupIds.length > 0;
  }

  get isRedo() {
    return this.redoneChangeEventGroupIds.length > 0;
  }

}




// Creating Undo events

/**
 * Returns a new ChangeEvent that "undoes" the given ChangeEvent.
 * Because this would be called as an "undo" function, also sets isConditionalOnOldValue = true.
 * @param {ChangeEvent} changeEvent 
 * @returns {ChangeEvent}
 */
function undoneChangeEvent(changeEvent) {
  return new ChangeEvent({
    changeType: changeEvent.changeType,
    entityString: changeEvent.entityString,
    oldValue: changeEvent.newValue,
    newValue: changeEvent.oldValue,
    isConditionalOnOldValue: true,
    requiredGridSize: changeEvent.requiredGridSize,
  })
}

/**
 * Returns a new ChangeEvent that "redoes" the given ChangeEvent (copies it, as a new ChangeEvent with a new ID).
 * Because this would be called after an "undo" function, also sets isConditionalOnOldValue = true.
 * @param {ChangeEvent} changeEvent 
 * @returns {ChangeEvent}
 */
function redoneChangeEvent(changeEvent) {
  return new ChangeEvent({
    changeType: changeEvent.changeType,
    entityString: changeEvent.entityString,
    oldValue: changeEvent.oldValue,
    newValue: changeEvent.newValue,
    isConditionalOnOldValue: true,
    requiredGridSize: changeEvent.requiredGridSize,
  })
}


/**
 * Creates a new ChangeEventGroup that should undo the given list of consecutive ChangeEventGroups.
 * @param {[ChangeEventGroup]} changeEventGroups Frequently a list of length one, unless trying to undo multiple minor changes together
 * @returns {ChangeEventGroup}
 */
export function undoneChangeEventGroups(changeEventGroups) {
  const flattenedChangeEventArray = [].concat.apply([], changeEventGroups.map(ceg => ceg.changeEventList));  // 1D list of ChangeEvents to undo
  const undoneChangeEvents = flattenedChangeEventArray.map(ce => undoneChangeEvent(ce)).reverse();

  return new ChangeEventGroup({
    changeEventList: undoneChangeEvents,
    undoneChangeEventGroupIds: changeEventGroups.map(ceg => ceg.changeEventGroupId),
  });
}

/**
 * Creates a new ChangeEventGroup that should redo the given list of consecutive ChangeEventGroups.
 * @param {[ChangeEventGroup]} changeEventGroups Frequently a list of length one, unless trying to redo multiple minor changes together
 * @returns {ChangeEventGroup}
 */
export function redoneChangeEventGroups(changeEventGroups) {
  const flattenedChangeEventArray = [].concat.apply([], changeEventGroups.map(ceg => ceg.changeEventList));  // 1D list of ChangeEvents to undo
  const redoneChangeEvents = flattenedChangeEventArray.map(ce => redoneChangeEvent(ce));

  return new ChangeEventGroup({
    changeEventList: redoneChangeEvents,
    redoneChangeEventGroupIds: changeEventGroups.map(ceg => ceg.changeEventGroupId),
  });
}







