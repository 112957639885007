import React from 'react';
import { Breadcrumb, Button } from 'react-bootstrap';
import { MdDriveFolderUpload } from 'react-icons/md';
import BrowseList from './BrowseList';
import { BiGhost } from 'react-icons/bi';


/** A mini version of Browse, meant for things like Save-As modals without advanced options like puzzle creation. */
export default function MiniBrowse({
  dirPath,
  folderStructure,
  callbacks,
  puzzlesDisabled = false,
  hideNewFolderButton = false,
  onClickOnPuzzle, // ({puzzleId, modifiedAt, puzzleContent, permissions}) => {}: if not provided, defaults to opening that puzzle; if provided, executes instead
}) {

  const { setNewDir, navigateToDir, getBreadcrumbData } = callbacks;
  

  if (!dirPath) {
    return (
      <div className='w-100 d-flex'>
        <div className='d-flex mx-auto mt-5 not-too-wide'>
          <BiGhost className='my-auto me-2' size={60} />
          <div className='text-center my-auto block-text'>
            This directory doesn't exist (or you don't have access to it). Please double-check the URL.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className='d-flex mb-2'>

        <Breadcrumb className='small' listProps={{className: 'p-1 pr-3 m-0 me-2'}}>
          <Button
            className='py-0 px-2 me-2 border-0 d-flex'
            variant='outline-info'
            size='sm'
            disabled={dirPath.length === 0}
            onClick={() => navigateToDir(dirPath.length <= 1 ? null : dirPath[dirPath.length-2])}
          ><MdDriveFolderUpload className='my-auto'/></Button>

          {getBreadcrumbData().map(({ onClick, dirName, active }, idx) => (
            <Breadcrumb.Item key={`breadcrumb-${idx}`} onClick={onClick} active={active}>
              {dirName || 'untitled folder'}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>

        {!hideNewFolderButton && <Button className='ms-auto' variant='outline-success' size='sm' onClick={() => setNewDir({ dirName: '', status: 'CREATING' })}>New Folder</Button>}
      </div>
      <BrowseList dirPath={dirPath} folderStructure={folderStructure} callbacks={callbacks} puzzlesDisabled={puzzlesDisabled} onClickOnPuzzle={onClickOnPuzzle} compressPuzzles />
    </div>
  );
}