import React from 'react';
import { Button, Card } from 'react-bootstrap';


function PuzzleCard({
  puzzleId,
  puzzleCommentary,
  className='',
  ...props
}) {
  return (
    <Card {...props} style={{maxWidth: '250px', minWidth: '250px'}} className={className + ' my-2'}>
      <Card.Header className='d-flex'>
        <div className='me-auto my-auto'>
          UNKNOWN TITLE
        </div>
        <Button
          variant='outline-primary'
          href={`/play/${puzzleId}`}
          className='my-auto ms-3'
        >
          Play
        </Button>
      </Card.Header>
      <Card.Body className='small p-3'>
        {puzzleCommentary}
      </Card.Body>
    </Card>
  )
}



/**
 * A page with links to milestone puzzles in Crossworthy.
 */
export default function HallOfFame() {
  return (
    <div>
      <div className='border-bottom'>
        <h5>It's officially been a year since we started giving weekly puzzle-writing a shot back in June 2020... how time flies!</h5>
        <p>
          To all of you who have played one of our puzzles, we thank you so much!
          We decided to celebrate by picking out a few of our favorites from the 50-some puzzles we've run:
        </p>
      </div>

      <div className='mt-4 mb-5 justify-content-center'>
        <PuzzleCard puzzleId='kingdom-of-animals' puzzleCommentary='Gotta start somewhere! Our very first 15x15 puzzle, published June 7, 2020. Seeing it again is a bit like seeing your middle school pictures.' />
        <PuzzleCard puzzleId='partner-mode' puzzleCommentary='Our first fully-handmade puzzle, which we created during our wedding time! Published Aug 16, 2020.' />
        <PuzzleCard puzzleId='dads-time-to-shine' puzzleCommentary={'Our first and only 21x21 puzzle, with perhaps Kevin\'s favorite theme. Published Jan 17, 2021.'} />
        <PuzzleCard puzzleId='presidents-ponderings' puzzleCommentary={'My dad seemed to enjoy this one, and it was fun to write. Published after President Biden\'s inauguration on Jan 24, 2021.'} />
        <PuzzleCard puzzleId='eeesy-peezy' puzzleCommentary={'I\'d dreamed of making this one for a while, but only pulled it off on Feb 7, 2021.'} />
        <PuzzleCard puzzleId='valentine-poem' puzzleCommentary='Another fun one to celebrate our love, love for crosswords, and love in general! Published Feb 14, 2021.' />
        <PuzzleCard puzzleId='good-cooking' puzzleCommentary={'Our most-played puzzle in our first year, for some reason! Published March 6, 2021.'} />
        <PuzzleCard puzzleId='the-voice' puzzleCommentary={'We got super into "The Voice" this year, so May created this puzzle, and now it\'s one of my favorites. Published Apr 11, 2021.'} />
        <PuzzleCard puzzleId='smiley' puzzleCommentary='We keep thinking about experimenting with interesting grid shapes and designs. Maybe this is our gateway? Published May 23, 2021.' />
        <PuzzleCard puzzleId='crossworthy-special' puzzleCommentary='Our one-year anniversary puzzle! Published June 6, 2020!' />
      </div>
    </div>
  );
}