import { locInList } from "./blackoutsLib";


/** A list of available puzzle metadata set by user. These include things that would be included with the puzzle beyond crossworthy.net. */
export const PuzzleMetadataKey = {
  TITLE: 'title',
  AUTHOR: 'author',
  EDITOR: 'editor',
  COPYRIGHT: 'copyright',
  PUBLISHER: 'publisher',
  DATE: 'date',
  START_MESSAGE: 'startMessage',
  END_MESSAGE: 'endMessage',
};


/**
 * Returns a map from each PuzzleMetadataKey to an empty string.
 */
export function emptyPuzzleMetadata() {
  return new Map([
    [PuzzleMetadataKey.TITLE, ''],
    [PuzzleMetadataKey.AUTHOR, ''],
    [PuzzleMetadataKey.EDITOR, ''],
    [PuzzleMetadataKey.COPYRIGHT, ''],
    [PuzzleMetadataKey.PUBLISHER, ''],
    [PuzzleMetadataKey.DATE, ''],
    [PuzzleMetadataKey.START_MESSAGE, ''],
    [PuzzleMetadataKey.END_MESSAGE, ''],
  ]);
}




/** Represents the Down direction in a crossword board. */
export const DOWN = 'down';
/** Represents the Across direction in a crossword board. */
export const ACROSS = 'across';

/** Returns DOWN if given ACROSS and vice versa. */
export function otherDirection(direction) {
  return direction === DOWN ? ACROSS : direction === ACROSS ? DOWN : null;
}


/**
 * Just returns an empty charGrid (for the first render).
 * @param {number} numRows 
 * @param {number} numCols 
 * @returns {[[string]]}
 */
 export function emptyCharGrid(numRows, numCols) {
  let charGrid = [];
  for (let r = 0; r < numRows; ++r) {
    charGrid[r] = [];
    for (let c = 0; c < numCols; ++c) {
      charGrid[r][c] = '';
    }
  }
  return charGrid;
}


/**
 * Returns a string representation of the charGrid, e.g. for use in comparison operations or as keys in a map object.
 * If two charGrid strings are equal, then the two charGrids have the same contents.
 * @param {[[string]]} charGrid 
 * @returns {string} a string representation of the charGrid
 */
export function charGridAsString(charGrid) {
  return charGrid.map(row => row.map(c => c === 'blackout' ? '.' : c === '' ? ' ' : c).join('')).join('\n');
}


/**
 * Returns a new charGrid that's an empty version of the given one (but contains the same blackouts)
 * @param {[[string]]} charGrid 
 * @returns A new charGrid that has all the same blackouts but none of the filled letters
 */
export function charGridWithoutLetters(charGrid) {
  return charGrid.map(row => row.map(c => c === 'blackout' ? 'blackout' : ''));
}


/**
 * Returns true iff the two charGrids are the same except for the contents of non-blackout squares.
 * @param {[[string]]} charGrid1 
 * @param {[[string]]} charGrid2 
 * @returns {boolean}
 */
export function areBlackoutsDifferent(charGrid1, charGrid2) {
  if (charGrid1.length !== charGrid2.length || charGrid1[0].length !== charGrid2[0].length) {
    return true;
  }
  for (let r = 0; r < charGrid1.length; ++r) {
    for (let c = 0; c < charGrid1[0].length; ++c) {
      if ((charGrid1[r][c] === 'blackout' || charGrid2[r][c] === 'blackout') && (charGrid1[r][c] !== 'blackout' || charGrid2[r][c] !== 'blackout')) {
        return true;
      }
    }
  }
  return false;
}


/**
 * Returns the list of differences between two equally-sized charGrids. If not equally sized, returns null.
 * @param {[[string]]} charGrid1 
 * @param {[[string]]} charGrid2 
 * @returns {[{loc, char1, char2}]}
 */
export function getCharGridDifferences(charGrid1, charGrid2) {
  if (charGrid1.length !== charGrid2.length || charGrid1[0].length !== charGrid2[0].length) {
    return null;
  }
  const differences = [];    // list of objects e.g. {loc: [r,c], char1: '', char2: 'blackout'}
  for (let r = 0; r < charGrid1.length; ++r) {
    for (let c = 0; c < charGrid1[0].length; ++c) {
      if (charGrid1[r][c] !== charGrid2[r][c]) {
        differences.push({ loc: [r, c], char1: charGrid1[r][c], char2: charGrid2[r][c] });
      }
    }
  }
  return differences;
}




/**
 * Returns the standard string name of a slot, given the number and the direction, e.g. to use as a single key in a map of slots.
 * @param {number} slotNumber : integer >= 1
 * @param {string} slotDirection : DOWN or ACROSS
 * @returns {string}
 */
export function getSlotName(slotNumber, slotDirection) {
  return slotNumber.toString() + '-' + slotDirection;
}

/**
 * The inverse of the getSlotName function.
 * @param {string} slotName 
 * @returns {slotNumber, slotDirection}
 */
export function unpackSlotName(slotName) {
  const [num, dir] = slotName.split('-');
  return { slotNumber: parseInt(num), slotDirection: dir };
}


/**
 * Returns a list of the unique locs in the given list.
 * @param {[[r,c]]} listOfLocs 
 * @returns {[[r,c]]}
 */
export function uniqueLocs(listOfLocs) {
  const uniqueList = [];
  for (let i = 0; i < listOfLocs.length; ++i) {
    const loc = listOfLocs[i];
    if (!locInList(loc, uniqueList)) {
      uniqueList.push(loc);
    }
  }
  return uniqueList;
}





class Slot {

  /**
   * Simple setter constructor.
   */
  constructor(direction, locs, chars, crossSlotNames, crossIndexes) {
    this.direction = direction;
    this.locs = locs;
    this.chars = chars;
    this.crossSlotNames = crossSlotNames;
    this.crossIndexes = crossIndexes;
  }

  /**
   * Creates and returns a new Slot object with direction, chars, and locs attributes assigned based on the given startLoc, direction, and charGrid.
   * crossSlotNames and crossIndexes are set to empty arrays.
   * @param {[number, number]} startLoc 
   * @param {string} direction 
   * @param {[[string]]} charGrid 
   * @returns {Slot}
   */
  static delineateNewSlot(startLoc, direction, charGrid) {
    const locs = [];
    const chars = [];
    const crossSlotNames = [];
    const crossIndexes = [];

    var [r, c] = startLoc;
    while (r < charGrid.length && c < charGrid[0].length && charGrid[r][c] !== 'blackout') {
      locs.push([r, c]);
      chars.push(charGrid[r][c]);

      if (direction === ACROSS) {
        c += 1;
      } else {
        r += 1;
      }
    }
    return new Slot(direction, locs, chars, crossSlotNames, crossIndexes);
  }

  /** The number of grid squares in this slot */
  get length() {
    return this.locs.length;
  }

  /** Boolean representing whether all locs in this slot contain an entered character */
  get isFull() {
    return this.chars.every(c => c !== '');
  }

  /**
   * Returns a deep (enough) copy of the Slot.
   */
  clone() {
    return new Slot(this.direction, [...this.locs], [...this.chars], [...this.crossSlotNames], [...this.crossIndexes]);
  }

  /**
   * Checks whether the given loc is part of this slot, and if so returns its index in this slot.
   * @param {[number]} loc [r, c]
   * @returns {number} The index of the specified loc in the slot's array of locs, or -1 if not present.
   */
  indexOfLoc(loc) {
    for (let i = 0; i < this.locs.length; ++i) {
      if (this.locs[i][0] === loc[0] && this.locs[i][1] === loc[1]) {
        return i;
      }
    }
    return -1;
  }

}



/**
 * A wrapper around a Map from slotName to slot object.
 * Adds some methods for modifying in-place based on charGrid, finding slots, etc.
 */
export class SlotStructure {

  /**
   * Instantiates a SlotStructure object. Intended for private use; other classes should use a static factory method.
   * @param {Map} slotStructureMap 
   */
  constructor(slotStructureMap) {
    this.slotStructureMap = slotStructureMap;
  }
  
  /**
   * Builds and returns a SlotStructure object (basically a Map from slotName -> Slot object) from scratch using the given charGrid.
   * Slot objects contain its direction, locs, chars, crossSlots, and crossIndices.
   * @param {[[string]]} charGrid 
   */
  static buildNewSlotStructure(charGrid) {
    let slotStructureMap = new Map();    // map from slotName -> Slot object

    // Iterate once row-wise through the grid to get corner numbers in order
    let currentNumber = 1;
    let isSlot = false;
    let currentAcrossSlotName;   // currentAcrossSlotName and currentDownSlotName are used to assign crossSlots
    let currentDownSlotName;
    for (let r = 0; r < charGrid.length; ++r) {
      for (let c = 0; c < charGrid[r].length; ++c) {
        // If it's the start of an across or down word, assign it a cornerNumber
        if (charGrid[r][c] !== 'blackout') {
          if (r === 0 || charGrid[r-1][c] === 'blackout') {
            currentDownSlotName = getSlotName(currentNumber, DOWN);
            slotStructureMap.set(currentDownSlotName, Slot.delineateNewSlot([r, c], DOWN, charGrid));
            isSlot = true;
          } else {
            currentDownSlotName = [...slotStructureMap.entries()].filter(([_, s]) => s.direction === DOWN && s.indexOfLoc([r, c]) !== -1)[0][0];
          }
          if (c === 0 || charGrid[r][c-1] === 'blackout') {
            currentAcrossSlotName = getSlotName(currentNumber, ACROSS);
            slotStructureMap.set(currentAcrossSlotName, Slot.delineateNewSlot([r, c], ACROSS, charGrid));
            isSlot = true;
          }

          if (isSlot) {
            currentNumber++;
            isSlot = false;
          }


          // Now assign the crossSlotNames and crossIndexes of the intersecting slots at this grid loc
          const currentAcrossSlot = slotStructureMap.get(currentAcrossSlotName);
          const currentDownSlot = slotStructureMap.get(currentDownSlotName);

          currentAcrossSlot.crossSlotNames.push(currentDownSlotName);     // push() should work because we arrive at each slot's locations in order
          currentAcrossSlot.crossIndexes.push(currentDownSlot.indexOfLoc([r, c]));

          currentDownSlot.crossSlotNames.push(currentAcrossSlotName);
          currentDownSlot.crossIndexes.push(currentAcrossSlot.indexOfLoc([r, c]));
        }
      }
    }

    return new SlotStructure(slotStructureMap);
  }

  /**
   * Returns a deep copy of this SlotStructure.
   */
  clone() {
    return new SlotStructure(new Map(
      Array.from(this.slotStructureMap.entries()).map(([slotName, slot]) => [slotName, slot.clone()])
    ));
  }

  // UPDATE METHODS

  /**
   * Returns a NEW (deep-copied) SlotStructure with updated chars in the given locations.
   * Does not change the structure of the slots (i.e. the blackouts), only the non-blackout characters in the existing slot locs.
   * @param {[{loc, char1, char2}]} charGridDifferences list of "char difference" objects as returned by getCharGridDifferences, where char1 is the old char and char2 is desired
   * @returns {SlotStructure} A new SlotStructure with the requested updates.
   */
  withReplacedChars(charGridDifferences) {
    const newSlotStructure = this.clone();
    charGridDifferences.forEach(({loc, char1, char2}) => {
      const acrossSlot = newSlotStructure.slotStructureMap.get(newSlotStructure.getSlotNameContainingCoordinates(loc, ACROSS));
      const acrossIdx = acrossSlot.indexOfLoc(loc);
      if (acrossIdx !== -1 && char1 === acrossSlot.chars[acrossIdx]) { // these conditions just make sure there wasn't a mistake
        acrossSlot.chars[acrossIdx] = char2;
      }

      const downSlot = newSlotStructure.slotStructureMap.get(newSlotStructure.getSlotNameContainingCoordinates(loc, DOWN));
      const downIdx = downSlot.indexOfLoc(loc);
      if (downIdx !== -1 && char1 === downSlot.chars[downIdx]) { // these conditions just make sure there wasn't a mistake
        downSlot.chars[downIdx] = char2;
      }
    });
    return newSlotStructure;
  }


  // GET METHODS

  /**
   * An array of the slotName strings in this slotStructure.
   */
  get slotNames() {
    return [...this.slotStructureMap.keys()];
  }

  get isCompletelyFull() {
    return [...this.slotStructureMap.values()].every(slot => slot.isFull);
  }

  /**
   * Returns true iff there is a complete slot that contains the specified word.
   * @param {string} word 
   * @returns {boolean}
   */
  containsWord(word) {
    return [...this.slotStructureMap.values()].some(slot => slot.isFull && slot.chars.join('') === word);
  }

  /**
   * Returns an array of the chars in the slot, including empty chars, e.g. ['a', '', 'c', '', 'e']
   * @param {string} slotName 
   * @returns {[string]}
   */
  getCharsInSlot(slotName) {
    return this.slotStructureMap?.get(slotName)?.chars;
  }

  /**
   * Returns the direction of the given slot name if it exists.
   * @param {string} slotName 
   * @returns {string} DOWN or ACROSS (or undefined)
   */
  getDirectionOfSlot(slotName) {
    return this.slotStructureMap?.get(slotName)?.direction;
  }

  /**
   * Returns an array of the [r,c] locs in the slot.
   * @param {string} slotName 
   * @returns {[[number, number]]}
   */
  getLocsInSlot(slotName) {
    return this.slotStructureMap?.get(slotName)?.locs;
  }

  /**
   * Returns an array of the unique [r,c] locs in the list of slotNames.
   * @param {[string]} slotNames 
   * @returns {[[number, number]]}
   */
  getLocsInSlots(slotNames) {
    return uniqueLocs(slotNames.reduce((locList, slotName) => locList.concat(this.slotStructureMap.get(slotName).locs), []));
  }

  /**
   * Returns the slotName for the slot of the specified direction that contains the specified [r, c] loc.
   * Returns null if it doesn't exist (e.g. [r,c] is a blackout).
   * @param {[number, number]} loc 
   * @param {string} direction 
   * @returns {string}
   */
  getSlotNameContainingCoordinates(loc, direction) {
    const filtered = [...this.slotStructureMap.entries()].filter(([_, slot]) => slot.direction === direction && slot.indexOfLoc(loc) !== -1);
    if (filtered.length > 0) {
      return filtered[0][0];
    }
    return null;
  }

  /**
   * Returns the [r, c] loc of the first cell for the given slotName.
   * @param {string} slotName 
   * @returns {[r,c]}
   */
  getLocFromSlotName(slotName) {
    return this.slotStructureMap.get(slotName).locs[0];
  }

  /**
   * Returns the word present in charGrid at the given slotName. Does not put any spacers for empty cells; just shortens word instead.
   * If slotName is not present, returns an empty string.
   * @param {string} slotName e.g. '1-across'
   * @param {string} emptyCharacter the character (or string) that will go into blank squares (default is empty string)
   * @returns {string} the concatenated contents of the given slot based on charGrid.
   */
  getWordInSlotName(slotName, emptyCharacter='') {
    return this.slotStructureMap.get(slotName)?.chars?.map(c => c === '' ? emptyCharacter : c).join('') || '';
  }


  /**
   * Returns a list of Slots (not slotNames) that have the given length.
   * @param {number} slotLength 
   * @returns {[Slot]}
   */
  getSlotsWithLength(slotLength) {
    return Array.from(this.slotStructureMap.values()).filter(slot => slot.locs.length === slotLength);
  }


  /**
   * Returns a list of strings containing all the complete words (i.e. no empty cells) in the grid.
   * @returns {[string]}
   */
  getAllCompleteWords() {
    return Array.from(this.slotStructureMap.values()).filter(slot => slot.chars.every(c => c !== '')).map(slot => slot.chars.join(''));
  }


  /**
   * Returns a list of Slot objects in order that represent the cross-slots for the given slotName.
   * @param {string} slotName 
   * @returns {[Slot]}
   */
  getCrossSlotsForSlotName(slotName) {
    return this.slotStructureMap.get(slotName).crossSlotNames.map(sn => this.slotStructureMap.get(sn));
  }


  /**
   * Yields the previous slot name in the puzzle according to user expectations (e.g. when cluing the puzzle).
   * Don't think this is the most efficient implementation for super high-throughput applications.
   * @param {string} slotName 
   * @returns The "previous" slot name in the puzzle (or the last Across slot name if given 1-Down).
   */
  getPreviousSlotName(slotName) {
    const currentDirection = this.slotStructureMap.get(slotName).direction;
    const currentNumber = parseInt(/\d+/.exec(slotName)[0]);
    for (let i = this.slotNames.length - 1; i >= 0; --i) {
      const prevSlotName = this.slotNames[i];
      if (this.slotStructureMap.get(prevSlotName).direction === currentDirection) {
        const prevNumber = parseInt(/\d+/.exec(prevSlotName)[0]);
        if (prevNumber < currentNumber) {
          return prevSlotName;
        }
      }
    }
    // It's either the first across slot or first down slot
    if (currentDirection === DOWN) {
      // Return the last across slot
      return this.slotNames.filter(sn => this.slotStructureMap.get(sn).direction === ACROSS).pop();
    }
    return null;
  }
  /**
   * Yields the next slot name in the puzzle according to user expectations (e.g. when cluing the puzzle).
   * Don't think this is the most efficient implementation for super high-throughput applications.
   * @param {string} slotName 
   * @returns The "next" slot name in the puzzle (or 1-Down if given the last Across slot name).
   */
  getNextSlotName(slotName) {
    const currentDirection = this.slotStructureMap.get(slotName).direction;
    const currentNumber = parseInt(/\d+/.exec(slotName)[0]);
    for (const nextSlotName of this.slotNames) {
      if (this.slotStructureMap.get(nextSlotName).direction === currentDirection) {
        const nextNumber = parseInt(/\d+/.exec(nextSlotName)[0]);
        if (nextNumber > currentNumber) {
          return nextSlotName;
        }
      }
    }
    // It's either the last across slot or last down slot
    if (currentDirection === ACROSS) {
      // Return the first down slot
      return this.slotNames.filter(sn => this.slotStructureMap.get(sn).direction === DOWN)[0];
    } else {
      // Return the first across slot
      return this.slotNames.filter(sn => this.slotStructureMap.get(sn).direction === ACROSS)[0];
    }
  }

}





