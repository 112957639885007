import React, { useEffect, useState } from 'react';
import { produce } from 'immer';
import Cell from '../board/Cell';
import './LoadingAnimation.css';
import { scoredWordDatabase } from '../../libs/autofillLib';



const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

function randomTwoElements(items) {
  return [items[Math.floor(Math.random()*items.length)], items[Math.floor(Math.random()*items.length)]];
}





export default function LoadingAnimation({ size=20, ...props }) {

  const [cells, setCells] = useState(null);   // list of 10 { visible, letter } objects

  useEffect(() => {
    // Set initial state
    const initialArray = scoredWordDatabase ? (
      randomTwoElements(scoredWordDatabase.get(5).words_array).join('').split('').map(c => ({ visible: true, letter: c.toUpperCase() }))
    ) : (
      new Array(10).fill(0).map(() => ({ visible: true, letter: ALPHABET[Math.floor(Math.random() * ALPHABET.length)] }))
    );
    setCells(initialArray);

    // Swap out a random one of these every so often
    const interval = setInterval(() => {
      setCells(oldCells => oldCells && produce(oldCells, draft => {
        const randomCell = draft[Math.floor(Math.random() * draft.length)];
        if (!randomCell.visible) randomCell.letter = ALPHABET[Math.floor(Math.random() * ALPHABET.length)];
        randomCell.visible = !randomCell.visible;
      }));
    }, 100);

    // Cleanup
    return () => clearInterval(interval);
  }, []);



  return <div className='d-inline-block text-start' {...props}>
    <div className='animated-ellipsis ms-2 mb-4 block-text' style={{fontSize: 34 * size/20}}>Loading</div>

    {cells && <svg viewBox='0 0 100 40' width={size*10}>
      {cells.map(({visible, letter}, idx) => (
        <Cell
          className={visible ? 'fade-visible' : 'fade-invisible'}
          key={'loading-cell-' + idx}
          cellSize={20}
          row={idx <= 4 ? 0 : 1}
          col={idx % 5}
          cellContent={letter}
          strokeWidth={0.5}
        />
      ))}
    </svg>}
  </div>;
}