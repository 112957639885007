import React from 'react';


export default function Privacy() {


  return <div>
    <div className='d-flex m-3'>
      <div className='mx-auto not-too-wide'>
        <h2 className='mt-5'>Our Crossworthy privacy policy</h2>

        <p>
          Hey there! Welcome to Crossworthy.
          We're an independent web app fully focused on making it fun to create and play crosswords.
          Outside of that, we don't track, disclose, or sell any of your data behind the scenes!
        </p>
        <p>
          If you're curious, let's chat privacy details below. For more info about Crossworthy, head to our <a href='/about'>About Us</a> page as well.
        </p>

        <hr />

        <p>
          Updated: February 19, 2024
        </p>

        <strong>What We Collect</strong>
        <p>
          We only collect the basics &ndash; you just need an email to make an account. You may add additional personal information, such as your name, to your account or your puzzles if you so choose.
        </p>
        <p>
          <em>Note: voluntary email subscriptions to be notified about May & Kevin's new puzzles are handled separately on a standard mailing list. They are not associated with your Crossworthy account.</em>
        </p>

        <strong>How We Use Your Info</strong>
        <p>
          We associate your account (and all the puzzles you create or play) with your email.
          That email will be used to receive one-time login codes or notifications if a puzzle is shared with, or requested of, you.
          De-identified play statistics may also be available to constructors who publish their puzzles on Crossworthy.
          If necessary to address an issue with your account or puzzle, or if you contact us first, we may use your email to write to you.
        </p>

        <strong>Sharing Secrets? Nope!</strong>
        <p>
          We don't share, sell, or otherwise disclose the information you provide, unless you ask us to.
          When you share a puzzle you create with collaborators, for instance, they will be able to see your email and chosen display name.
          Please note that if a collaborator adds your email to a shared puzzle, that email will be visible to other collaborators as well.
        </p>

        <strong>How We Keep It Secure</strong>
        <p>
          Our authentication, authorization and storage stack is backed by secure, robust, industry-standard technology.
          However, please note that no method of transmission over the internet or electronic storage is ever guaranteed to be perfectly secure.
        </p>

        <strong>Milk and Cookies</strong>
        <p>
          We only use necessary cookies to maintain your authentication. Other than that, no sneaky trackers &ndash; just a clean and simple Crossworthy experience.
        </p>

        <strong>Future Updates</strong>
        <p>
          We're constantly thinking up ways to improve the Crossworthy experience, but we'll keep this page updated with any changes.
        </p>
        
        <strong>Got questions?</strong>
        <p>
          We're here to answer them! Use our email or our <a href='/feedback'>feedback page</a> to get in touch.
        </p>
      </div>

    </div>
    <div className='text-center my-4'>
      <img src='/android-chrome-256x256.png' alt='crossworthy-logo' width='50px' />
    </div>
  </div>;
}