import React from 'react';
import { PuzzlePreferenceKey, usePuzzlePreferences } from '../BoardInteractionContext';
import CellStylePicker from './CellStylePicker';



export default function WarningOptions({
  ...props
}) {

  const { getPuzzlePreference, setPuzzlePreference } = usePuzzlePreferences();

  return (
    <div className='d-flex justify-content-center'>
      <div style={{width: '210px'}} {...props}>
        <div className='d-flex'><span className='text-muted'>1 or 2-letter words</span></div>
        <div className='d-flex'>
          <CellStylePicker 
            selectedCellStyleName={getPuzzlePreference(PuzzlePreferenceKey.WARNING_STYLE_SHORT_WORDS)}
            onSelect={newCellStyleName => {
              setPuzzlePreference(PuzzlePreferenceKey.WARNING_STYLE_SHORT_WORDS, newCellStyleName);
            }}
          />
        </div>

        <div className='d-flex mt-2'><span className='text-muted'>Duplicate words</span></div>
        <div className='d-flex'>
          <CellStylePicker 
            selectedCellStyleName={getPuzzlePreference(PuzzlePreferenceKey.WARNING_STYLE_DUPLICATE_WORDS)}
            onSelect={newCellStyleName => {
              setPuzzlePreference(PuzzlePreferenceKey.WARNING_STYLE_DUPLICATE_WORDS, newCellStyleName);
            }}
          />
        </div>

        <div className='d-flex mt-2'><span className='text-muted'>No available fill suggestions</span></div>
        <div className='d-flex'>
          <CellStylePicker 
            selectedCellStyleName={getPuzzlePreference(PuzzlePreferenceKey.WARNING_STYLE_NO_SUGGESTIONS)}
            onSelect={newCellStyleName => {
              setPuzzlePreference(PuzzlePreferenceKey.WARNING_STYLE_NO_SUGGESTIONS, newCellStyleName);
            }}
          />
        </div>
      </div>
    </div>
  );
}