import { useEffect, useState, useRef } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);
  return [
    fields,
    function(event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value
      });
    }
  ];
}


export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}


export function useEventListener(target, type, listener, ...options) {
  useEffect(() => {
    // Subscribe to event listener
    target.addEventListener(type, listener, ...options);
    // Unsubscribe to event listener
    return () => {
      target.removeEventListener(type, listener, ...options);
    }
  }, [target, type, listener, options]);
}


function getWindowDimensions() {
  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
  return { windowWidth, windowHeight };
}

/**
 * Returns width and height state values that is up-to-date with any window resizes after parent component mount.
 * Note that this should be used sparingly and in top-level context components (e.g. AppContext) to avoid performance hits of multiple resize listeners.
 * @returns { windowWidth, windowHeight } the most recent width and height of the window.
 */
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  return windowDimensions;
}

