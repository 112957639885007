import React from 'react';
import { Dropdown } from 'react-bootstrap';

const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';
const isApple = /(Mac|iPhone|iPad)/.test(platform);


/**
 * A simple class purely meant to hold the definition of a keyboard shortcut for display purposes.
 */
export class ShortcutDefinition {
  /**
   * @param {Boolean} ctrlKey 
   * @param {Boolean} shiftKey 
   * @param {String} key 
   */
  constructor(ctrlKey, shiftKey, key) {
    this.ctrlKey = ctrlKey;
    this.shiftKey = shiftKey;
    this.key = key;
  }

  /**
   * The display string of this keyboard shortcut, determined by user's OS.
   */
  get displayString() {
    if (isApple) {
      return (this.ctrlKey ? '⌘' : '') + (this.shiftKey ? '⇧' : '') + this.key.toUpperCase();
    }
  
    if (this.ctrlKey && this.shiftKey) return `Ctrl+Shift+${this.key}`;
    if (this.ctrlKey) return `Ctrl+${this.key}`;
    if (this.shiftKey) return `Shift+${this.key}`;
    return this.key;
  }
}

export default function MenuButton({
  className,
  name,
  items = [],
}) {

  const dropdownItems = items.map(({ label, shortcutDefinition, onClick, disabled=false }, idx) => label === 'hr' ? <Dropdown.Divider key={`menu-divider-${idx}`} /> : (
    <Dropdown.Item className='dropdown-item d-flex' onClick={onClick} disabled={disabled} key={`menu-button-${name}-${idx}`}>
      <span className='me-2'>{label}</span>
      {shortcutDefinition && <span className='text-muted ms-auto'>{shortcutDefinition.displayString}</span>}
    </Dropdown.Item>
  ));

  return (
    <Dropdown className={'my-auto ' + className}>
      <Dropdown.Toggle className='menu-button'>{name}</Dropdown.Toggle>
      <Dropdown.Menu className='p-0'>{dropdownItems}</Dropdown.Menu>
    </Dropdown>
  );
}