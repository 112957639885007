import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useParams } from "react-router-dom";
import { get } from 'aws-amplify/api';
import PlayEasel from '../components/play/PlayEasel';
import PlayInteractionContext from '../components/play/PlayInteractionContext';
import PermissionDenied from './PermissionDenied';
import { BiGhost } from 'react-icons/bi';
import { useAppContext } from '../App';
import LoadingAnimation from '../components/reusable/LoadingAnimation';
import { useToastNotifications } from '../libs/toastLib';


/**
 * Component for one playable puzzle.
 */
export default function Play() {

  const { id } = useParams();

  const location = useLocation();
  // When the page is refreshed manually, it should force loading from DynamoDB
  const forceReloadPuzzleItem = sessionStorage.getItem('forceReloadPuzzleItem') === 'true' || false;
  window.addEventListener('beforeunload', () => {     // before refresh, set this guy to true
    sessionStorage.setItem('forceReloadPuzzleItem', 'true');
  });

  const { isAuthenticated } = useAppContext();
  const { postErrorNotification } = useToastNotifications();
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [puzzleDoesNotExist, setPuzzleDoesNotExist] = useState(false);
  const [unknownError, setUnknownError] = useState(false);
  const [initialPuzzleItem, setInitialPuzzleItem] = useState((!forceReloadPuzzleItem && location.state) ? location.state.puzzleItem : null);
  const [isLoading, setIsLoading] = useState(false);

  // Load puzzle using ID
  useEffect(() => {
    if (initialPuzzleItem) return;

    async function onLoad() {
      setIsLoading(true);
      try {
        const response = await get({
          apiName: 'userPuzzles',
          path: `/${isAuthenticated ? 'userPuzzles' : 'publicPuzzles'}/${id}`
        }).response;
        const puzzleItem = await response.body.json();
        setInitialPuzzleItem(puzzleItem);
      } catch (e) {
        if (e.response?.statusCode === 403) {
          setPermissionDenied(true);
        } else if (e.response?.body && JSON.parse(e.response.body).error === 'Item not found.') {
          setPuzzleDoesNotExist(true);
        } else {
          setUnknownError(true);
          postErrorNotification('Oops!', 'We experienced an issue loading your puzzle. Try again later or contact us to let us know.');
        }
      }
      setIsLoading(false);
    }

    onLoad();
  }, [id, initialPuzzleItem, isAuthenticated, postErrorNotification]);


  if (permissionDenied) {
    return <PermissionDenied puzzleId={id} />
  }


  if (puzzleDoesNotExist) {
    return (
      <div className='d-flex justify-content-center my-5'>
        <div className='text-center not-too-wide'>
          <BiGhost className='my-3' size={40} />
          <div className='h2'>Puzzle does not exist!</div>
          <div className='my-4'>Check that the URL is correct.</div>
          <div className='d-flex mt-3'>
            <Button className='mx-auto' href='/'>Crossworthy Home</Button>
          </div>
        </div>
      </div>
    )
  }


  if (unknownError) {
    return (
      <div className='d-flex justify-content-center my-5'>
        <div className='text-center not-too-wide'>
          <BiGhost className='my-3' size={40} />
          <div className='h2'>Error loading puzzle!</div>
          <div className='my-4 balanced-text'>Check that your internet is working. If the problem persists, please let us know <a href='/feedback'>here</a>.</div>
          <div className='d-flex mt-3'>
            <Button className='mx-auto' href='/'>Crossworthy Home</Button>
          </div>
        </div>
      </div>
    );
  }


  if (isLoading) {
    return (
      <div className='text-center mt-5'>
        <LoadingAnimation size={20} />
      </div>
    );
  }

  return (
    <PlayInteractionContext initialPuzzleItem={initialPuzzleItem}>
      <PlayEasel />
    </PlayInteractionContext>
  );

}



/*

export const PUZZLE_INDEX = new Map([
  ['kingdom-of-animals', {title: 'Kingdom of Animals', src: 'https://amuselabs.com/pmm/crossword?id=061298d8&set=1158052475352fb529072fe3bc67f87990be16bd6a30b174f8bbcee7db9b8bef&embed=1'}],
  ['partner-mode', {title: 'Partner Mode', src: 'https://amuselabs.com/pmm/crossword?id=caa0237e&set=1158052475352fb529072fe3bc67f87990be16bd6a30b174f8bbcee7db9b8bef&embed=1'}],
  ['good-cooking', {title: 'Mastering the Elements of Good Cooking', src: 'https://amuselabs.com/pmm/crossword?id=6e708745&set=1158052475352fb529072fe3bc67f87990be16bd6a30b174f8bbcee7db9b8bef&embed=1'}],
  ['the-voice', {title: 'This is The Voice!', src: 'https://amuselabs.com/pmm/crossword?id=3abb3690&set=1158052475352fb529072fe3bc67f87990be16bd6a30b174f8bbcee7db9b8bef&embed=1'}],
  ['smiley', {title: 'Smiles All Around', src: 'https://amuselabs.com/pmm/crossword?id=0d47e25f&set=1158052475352fb529072fe3bc67f87990be16bd6a30b174f8bbcee7db9b8bef&embed=1'}],
  ['crossworthy-special', {title: 'The Crossworthy Special', src: 'https://amuselabs.com/pmm/crossword?id=9f919ad4&set=1158052475352fb529072fe3bc67f87990be16bd6a30b174f8bbcee7db9b8bef&embed=1'}],
  ['dads-time-to-shine', {title: 'Dad\'s Time to Shine', src: 'https://amuselabs.com/pmm/crossword?id=b9c07592&set=1158052475352fb529072fe3bc67f87990be16bd6a30b174f8bbcee7db9b8bef&embed=1'}],
  ['eeesy-peezy', {title: 'Eeesy Peezy!', src: 'https://amuselabs.com/pmm/crossword?id=eb6cc109&set=1158052475352fb529072fe3bc67f87990be16bd6a30b174f8bbcee7db9b8bef&embed=1'}],
  ['valentine-poem', {title: 'Valentine Poem', src: 'https://amuselabs.com/pmm/crossword?id=e2d3ed20&set=1158052475352fb529072fe3bc67f87990be16bd6a30b174f8bbcee7db9b8bef&embed=1'}],
  ['presidents-ponderings', {title: 'A President\'s Ponderings', src: 'https://amuselabs.com/pmm/crossword?id=c1491da0&set=1158052475352fb529072fe3bc67f87990be16bd6a30b174f8bbcee7db9b8bef&embed=1'}],
]);





 * A page displaying one playable crossword, currently through an AmuseLabs iframe.
 
export default function Play() {

  const { id } = useParams();

  const puzzleInfo = PUZZLE_INDEX.get(id);

  useEffect(() => {
    document.title = puzzleInfo ? puzzleInfo.title : 'Puzzle not found';
  }, [puzzleInfo]);

  // Handle unrecognized URLs
  if (!puzzleInfo) {
    return (
      <Row>
        <Col md={3} className='border-right'>
          <h3>
            Oops! We couldn't find any playable puzzle at this URL.
          </h3>
          <Button href='/' className='m-auto'>Return home</Button>
          <p>{'(You can play some snake here instead -->)'}</p>
        </Col>
        <Col md={9}>
          <Snake />
        </Col>
      </Row>
    )
  }



  return (
    <div>
      <iframe
        height="600"
        width="100%"
        title={`amuselabs-${id}`}
        allowfullscreen="true"
        style={{border: 'none', width: '100% !important', position: 'static', display: 'block !important', margin: '0 !important'}}
        src={puzzleInfo.src}
      />
    </div>
  );
}
*/

