import React from 'react';
import { OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import KeyboardEventHandler from '../../libs/keyboardLib';
import { AUTOFILL_STATUS } from '../../libs/autofillLib';
import Board from '../board/Board';
import { useCharGrid, useCursor, usePuzzleInteractionStatus, usePuzzleVersioning,
  useBoardStyle, useSavePuzzle, useUpdateCharGridAtLocs, useRequestNewBlackouts, useSlotStructure, useAutofill,
  PuzzleInteractionStatus, useLiveMode, useClues, useGhostChars, useFurnishings } from './BoardInteractionContext';
import './ConstructorBoard.css';
import { CursorDirective } from '../../libs/cursorLib';
import { getScoreForWord } from '../../libs/autofillLib';
import MobileKeyboard from '../reusable/MobileKeyboard';
import { otherDirection } from '../../libs/directionsLib';


/**
 * A wrapper around Board that adds interaction with BoardInteractionContext and a keyboard listener.
 * Also displays basic stats right below the board.
 */
const ConstructorBoard = React.forwardRef(({showStats=true, showMobileKeyboard=true, ...props}, ref) => {

  // Contexts for interacting with board/cursor
  const charGrid = useCharGrid();
  const { furnishings } = useFurnishings();
  const { clues } = useClues();
  const slotStructure = useSlotStructure();
  const { requestAutofill, cancelAutofill, autofillStatus } = useAutofill();
  const { cursorLoc, cursorDirection, handleClickOnLoc, handleCursorDirective } = useCursor();
  const updateCharGridAtLocs = useUpdateCharGridAtLocs();
  const { ghostChars, acceptGhostChars } = useGhostChars();
  const requestNewBlackouts = useRequestNewBlackouts();
  const { puzzleInteractionStatus } = usePuzzleInteractionStatus();
  const { requestPuzzleSave } = useSavePuzzle();
  const { requestUndo, requestRedo } = usePuzzleVersioning();
  const boardStyle = useBoardStyle();
  const { liveConnectionInfo } = useLiveMode();

  const percentFilled = 100 * charGrid.reduce((acc, cur) => acc + cur.reduce((accR, curR) => accR + Number(curR !== ''), 0), 0) / (charGrid.length * charGrid[0].length);
  const percentClued = 100 * Array.from(clues.values()).reduce((acc, cur) => acc + Number(cur !== ''), 0) / clues.size;

  const board = <Board 
    ref={ref}
    charGrid={charGrid}
    furnishings={furnishings}
    handleClickOnLoc={handleClickOnLoc}
    cursorLoc={cursorLoc}
    cursorDirection={cursorDirection}
    liveConnectionInfo={liveConnectionInfo}
    puzzleInteractionStatus={puzzleInteractionStatus}
    boardStyle={boardStyle}
    ghostChars={ghostChars}
    {...props}
  />;


  // Handle key events for entering letters in the board, etc.
  function handleKeyEvent(key, event) {
    /* Note that other toolbar-related key events are handled in the ConstructorToolbar component. */
    if (event) event.preventDefault();
    
    // Except for some very specific actions, don't do anything if the puzzle doesn't have the right interaction status
    if (puzzleInteractionStatus === PuzzleInteractionStatus.THINKING && !(key === 'ctrl+k' || key === 'meta+k')) {
      return;
    }

    /* General key interactions (do not require grid focus) */

    // Ctrl/Cmd-Z: undo
    if (key === 'ctrl+z' || key === 'meta+z') {
      if (puzzleInteractionStatus !== PuzzleInteractionStatus.THINKING) {
        requestUndo();
      }
    }

    // Ctrl-Y/Cmd-Y/Ctrl-Shift-Z/Cmd-Shift-Z: redo
    else if (key === 'ctrl+y' || key === 'meta+y' || key === 'ctrl+shift+z' || key === 'meta+shift+z') {
      if (puzzleInteractionStatus !== PuzzleInteractionStatus.THINKING) {
        requestRedo();
      }
    }

    // Ctrl-S/Cmd-S: save
    else if (key === 'ctrl+s' || key === 'meta+s') {
      if (puzzleInteractionStatus !== PuzzleInteractionStatus.THINKING) {
        requestPuzzleSave();
      }
    }

    // Ctrl-B/Cmd-B: generate new blackouts
    else if (key === 'ctrl+b' || key === 'meta+b') {
      if (puzzleInteractionStatus !== PuzzleInteractionStatus.THINKING) {
        requestNewBlackouts();
      }
    }

    // Ctrl/Cmd[+shift]+enter: accept ghost chars
    else if (key === 'ctrl+enter' || key === 'meta+enter' || key === 'ctrl+shift+enter' || key === 'meta+shift+enter') {
      acceptGhostChars();
    }

    // Ctrl/Cmd+K: autofill grid
    else if (key === 'ctrl+k' || key === 'meta+k') {
      if (autofillStatus !== AUTOFILL_STATUS.SEARCHING) {
        if (puzzleInteractionStatus !== PuzzleInteractionStatus.THINKING) {
          requestAutofill();
        }
      } else {
        cancelAutofill();
      }
    }




    /* Grid-specific key interactions (require grid focus) */

    else if (cursorLoc) {

      // Alphabetic character: enter in cursor location and advance cursor
      if (/^[a-zA-Z]$/.test(key)) {
        if (puzzleInteractionStatus !== PuzzleInteractionStatus.THINKING) {
          updateCharGridAtLocs([cursorLoc], key.toUpperCase());
          handleCursorDirective(CursorDirective.NEXT_SQUARE);
        }
      }

      // Space: blackout
      else if (key === 'space' || key === '.') {
        updateCharGridAtLocs([cursorLoc], 'blackout');
        handleCursorDirective(CursorDirective.NEXT_SQUARE);
      }

      // Arrow keys: move cursor or change direction
      else if (key === 'left') {
        handleCursorDirective(CursorDirective.LEFT);
      } else if (key === 'up') {
        handleCursorDirective(CursorDirective.UP);
      } else if (key === 'right') {
        handleCursorDirective(CursorDirective.RIGHT);
      } else if (key === 'down') {
        handleCursorDirective(CursorDirective.DOWN);
      }

      // Ctrl+arrow keys: move cursor but keep direction
      else if (key === 'ctrl+left' || key === 'meta+left' || key === 'shift+left') {
        handleCursorDirective(CursorDirective.LEFT_PRESERVE_DIRECTION);
      } else if (key === 'ctrl+up' || key === 'meta+up' || key === 'shift+up') {
        handleCursorDirective(CursorDirective.UP_PRESERVE_DIRECTION);
      } else if (key === 'ctrl+right' || key === 'meta+right' || key === 'shift+right') {
        handleCursorDirective(CursorDirective.RIGHT_PRESERVE_DIRECTION);
      } else if (key === 'ctrl+down' || key === 'meta+down' || key === 'shift+down') {
        handleCursorDirective(CursorDirective.DOWN_PRESERVE_DIRECTION);
      }

      // Tab (or shift+tab): move cursor to next (or prev) word; if last word, wrap to beginning with changed direction
      else if (key === 'tab') {
        handleCursorDirective(CursorDirective.NEXT_WORD);
      } else if (key === 'shift+tab') {
        handleCursorDirective(CursorDirective.PREVIOUS_WORD);
      }

      // Space and Enter: change directions (simulate a click on the cursorLoc)
      else if (key === 'enter') {
        handleClickOnLoc(cursorLoc);
      }

      // ESC: unfocus grid (simulate a click outside the board)
      else if (key === 'esc') {
        handleClickOnLoc(null);
      }

      // Delete (or backspace): clear cell (and move back one)
      else if (key === 'del' || key === 'backspace') {
        updateCharGridAtLocs([cursorLoc], '');

        if (key === 'backspace') {
          // Move back one if possible
          handleCursorDirective(CursorDirective.PREVIOUS_SQUARE);
        }
      }

      // Ctrl/Cmd+D: delete the whole word, except for where the intersecting cross-slot is a complete word
      else if (key === 'ctrl+d' || key === 'meta+d') {
        const slotName = slotStructure.getSlotNameContainingCoordinates(cursorLoc, cursorDirection);
        if (slotName) {
          const crossSlots = slotStructure.getCrossSlotsForSlotName(slotName);
          const locsToClear = slotStructure.getLocsInSlot(slotName).filter((loc, idx) => !crossSlots[idx].isFull);
          updateCharGridAtLocs(locsToClear, new Array(locsToClear.length).fill(''));
        }
      }

      // Ctrl/Cmd+Shift+D: delete the whole word, including intersecting, complete words
      else if (key === 'ctrl+shift+d' || key === 'meta+shift+d') {
        const slotName = slotStructure.getSlotNameContainingCoordinates(cursorLoc, cursorDirection);
        if (slotName) {
          const locsToClear = slotStructure.getLocsInSlot(slotName);
          updateCharGridAtLocs(locsToClear, new Array(locsToClear.length).fill(''));
        }
      }

    }
  }



  const currentSlotName = cursorLoc ? slotStructure.getSlotNameContainingCoordinates(cursorLoc, cursorDirection) : null;


  return (
    <>
      {currentSlotName && showMobileKeyboard && <MobileKeyboard
        currentSlotName={currentSlotName}
        showCurrentClue={false}
        clues={clues}
        onKeyPress={key => {
          if (key === '{rotate}') {
            handleClickOnLoc(cursorLoc, otherDirection(cursorDirection));
          } else {
            handleKeyEvent(key.toLowerCase());
          }
        }}
      />}

      <KeyboardEventHandler
        handleKeys={['alphabetic', '.',
                    'left', 'up', 'right', 'down',
                    'ctrl+left', 'meta+left', 'ctrl+right', 'meta+right',
                    'ctrl+up', 'meta+up', 'ctrl+down', 'meta+down',
                    'shift+left', 'shift+up', 'shift+down', 'shift+right',
                    'tab', 'shift+tab', 'space', 'enter', 
                    'esc', 
                    'del', 'backspace',
                    'ctrl+j', 'meta+j', 'ctrl+shift+j', 'meta+shift+j',
                    'ctrl+k', 'meta+k',
                    'ctrl+enter', 'meta+enter', 'ctrl+shift+enter', 'meta+shift+enter',
                    'ctrl+backspace', 'meta+backspace', 'ctrl+shift+backspace', 'meta+shift+backspace',
                    'ctrl+d', 'meta+d', 'ctrl+shift+d', 'meta+shift+d',
                    'ctrl+s', 'meta+s',
                    'ctrl+z', 'meta+z', 'ctrl+y', 'meta+y', 'ctrl+shift+z', 'meta+shift+z',
                    'ctrl+b', 'meta+b',
                  ]}
        onKeyEvent={handleKeyEvent}
      />

      {board}

      {showStats && (
        <>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement='bottom'
            overlay={<Tooltip>{Math.round(percentFilled)}% filled</Tooltip>}
          >
            <ProgressBar
              className='mt-2 mx-1'
              now={percentFilled}
              style={{height: '4px', }}
            />
          </OverlayTrigger>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement='bottom'
            overlay={<Tooltip>{Math.round(percentClued)}% clued</Tooltip>}
          >
            <ProgressBar
              className='mt-1 mx-1'
              variant='success'
              now={percentClued}
              style={{height: '4px', }}
            />
          </OverlayTrigger>

          <div className='text-center fst-italic text-muted small pt-1'>
            <div>
              <strong>{slotStructure.slotStructureMap.size}</strong> words 
              in {charGrid.length === 8 || charGrid.length === 11 || charGrid.length === 18 ? 'an' : 'a'} <strong>{charGrid.length}x{charGrid[0].length}</strong> board
            </div>
            <div>
              <strong>{slotStructure.getSlotsWithLength(3).length}</strong> threes &middot;{' '}
              <strong>{slotStructure.getSlotsWithLength(4).length}</strong> fours &middot;{' '}
              <strong>{slotStructure.getAllCompleteWords().map(word => getScoreForWord(word)).filter(word => word !== null && word !== undefined).reduce((acc, cur, idx, arr) => acc + (cur / arr.length), 0).toFixed(2)}</strong> avg score
            </div>
          </div>
        </>
      )}

    </>
  );

});


export default ConstructorBoard;