import React from 'react';
import { ShortcutDefinition } from '../../reusable/MenuButton';




function KeyboardShortcutRow({
  actionText,
  shortcutDefinition,
  className='',
  ...props
}) {
  return (
    <div className={className + ' d-flex me-0 ms-0'} {...props}>
      <span className='me-3'>{actionText}</span>
      <span className='ms-auto text-muted'>{shortcutDefinition.displayString}</span>
    </div>
  );
}

function KeyboardShortcutHeader({
  headerText,
  ...props
}) {
  return (
    <div style={{width: '100%', height: '15px', borderBottom: '1px solid #adadad', textAlign: 'center', marginBottom: '5px', marginTop: '3px'}} {...props}>
      <span style={{fontSize: '13px', color: '#707070', backgroundColor: 'white', padding: '0 10px'}}>
        {headerText}
      </span>
    </div>
  )
}


export default function KeyboardShortcutOptions({
  ...props
}) {


  return (
    <div {...props}>
      <KeyboardShortcutHeader headerText='big-picture' />
      <KeyboardShortcutRow actionText='Save' shortcutDefinition={new ShortcutDefinition(true, false, 'S')} />
      <KeyboardShortcutRow actionText='Export' shortcutDefinition={new ShortcutDefinition(true, false, 'P')} />
      <KeyboardShortcutRow actionText='Use live mode...' shortcutDefinition={new ShortcutDefinition(true, false, 'L')} />
      <KeyboardShortcutRow actionText='Undo' shortcutDefinition={new ShortcutDefinition(true, false, 'Z')} />
      <KeyboardShortcutRow actionText='Redo' shortcutDefinition={new ShortcutDefinition(true, false, 'Y')} />

      <KeyboardShortcutHeader headerText='smart constructing' />
      <KeyboardShortcutRow actionText='Start/stop autofill' shortcutDefinition={new ShortcutDefinition(true, false, 'K')} />
      <KeyboardShortcutRow actionText='Find most restricted slot' shortcutDefinition={new ShortcutDefinition(true, false, 'H')} />
      <KeyboardShortcutRow actionText='in current word' shortcutDefinition={new ShortcutDefinition(true, true, 'H')} className='ms-2' />
      <KeyboardShortcutRow actionText='Accept suggestions' shortcutDefinition={new ShortcutDefinition(true, false, 'Enter')} />

      <KeyboardShortcutHeader headerText='quick navigation' />
      <KeyboardShortcutRow actionText='Move' shortcutDefinition={new ShortcutDefinition(false, false, 'Arrow')} />
      <KeyboardShortcutRow actionText='without turning' shortcutDefinition={new ShortcutDefinition(true, false, 'Arrow')} className='ms-2' />
      <KeyboardShortcutRow actionText='Switch cursor direction' shortcutDefinition={new ShortcutDefinition(false, false, 'Enter')} />
      <KeyboardShortcutRow actionText='Delete word (preserve cross-words)' shortcutDefinition={new ShortcutDefinition(true, false, 'D')} />
      <KeyboardShortcutRow actionText={'Delete word (don\'t preserve)'} shortcutDefinition={new ShortcutDefinition(true, true, 'D')} />

      <KeyboardShortcutHeader headerText='grid & blocks' />
      <KeyboardShortcutRow actionText='Change grid size' shortcutDefinition={new ShortcutDefinition(true, false, 'G')} />
      <KeyboardShortcutRow actionText='Insert black tile' shortcutDefinition={new ShortcutDefinition(false, false, 'Period / Space')} />
      <KeyboardShortcutRow actionText='All-new black tiles' shortcutDefinition={new ShortcutDefinition(true, false, 'B')} />
    </div>
  );
}