import intersect from 'intersect';
import { locInList, locsEqual } from './blackoutsLib';
import { useRef, useState } from 'react';
import { charGridAsString } from './directionsLib';



/* ===== word database ==============

Load the scored word database exactly once.
The scored word database is structured as such:
Map {
  3: {
    mapping: [char number][index][indices in words_array],
    words_array: [words],
    scores_array: [numbers],
  },
  4: ...
}
========================== */

export var scoredWordDatabase = null;
export var wordDatabaseIsLoaded = false;  // this flag becomes true to let BoardInteractionContext know it's available

export var wordScoreFrequencies = null;   // this is made available for convenience as well: a Map from score to number of words with that score


var autofillCaptain = new Worker('/autofillCaptain.js');   // "instantiated" (i.e. given the scoredWordDatabase) after scoredWordDatabase is fetched
var lastAutofillRequestId = 0;   // sequential number tracking which autofill request so that it can ignore any late-incoming autofill results

var autofillThrottlerTimeout;
var autofillIsThrottled = false;   // if autofill is called while throttled, it resets the throttler and queues up a new request


var wordSuggestorCaptain = new Worker('/autofillCaptain.js');  // TODO - throttling


export function loadWordlist(url) {
  // First unload any current word list
  scoredWordDatabase = null;
  wordDatabaseIsLoaded = false;

  fetch(url).then(resp => resp.text()).then(content => {
    // Arrange word list into desired format
    const lines = content.split(/[\n\r]+/);
    const swd = new Map();
    const scoreFreqs = new Map();  // map from score to number of words with that score

    // Parse into an intermediate structure: a map from length to list of { word, score }
    const scoredWords = new Map();
    const letterFrequencies = Object.fromEntries(new Array(26).fill(0).map((_, idx) => String.fromCharCode('A'.charCodeAt(0) + idx)).map(val => [val, 0]));
    for (let i = 0; i < lines.length; ++i) {
      var [word, score] = lines[i].split(';');
      word = word.toUpperCase();
      if (!/^[A-Z]+$/.test(word)) continue;  // for now, skip any non-alpha words
      if (!scoredWords.get(word.length)) {
        scoredWords.set(word.length, []);
      }
      let j = word.length;
      const numericScore = parseInt(score, 10);
      scoredWords.get(j).push({ word: word.toUpperCase(), score: numericScore });
      scoreFreqs.set(numericScore, (scoreFreqs.get(numericScore) || 0) + 1);   // also increment the correct score frequency number
      while (j--) {    // also count up letter frequencies
        ++letterFrequencies[word.charAt(j)];
      }
    }

    // Sort each list in an order that will be most conducive for autofill (words with more common letters toward the front)
    scoredWords.forEach((sw, wordLen) => {
      const sortedSw = sw.map(({ word, score }) => {
        const freq = [...word].reduce((acc, curr) => acc + (letterFrequencies[curr] || 0), 0);
        return { word, score, freq };
      })
      sortedSw.sort((a, b) => 100000 * (b.score - a.score) + (b.freq - a.freq));   // word scores rated 100000x more than letter frequency; highest frequency scores first
      swd.set(wordLen, {
        words_array: sortedSw.map(({ word }) => word),
        scores_array: sortedSw.map(({ score }) => score),
      });
    });

    // Create mapping components
    swd.forEach((obj, wordLen) => {
      const charList = [];
      for (let i = 0; i < 26; ++i) {
        const indicesList = [];
        for (let j = 0; j < wordLen; ++j) {
          indicesList.push([]);   // this will be the list of indices in words_array
        }
        charList.push(indicesList);
      }

      for (let k = 0; k < obj.words_array.length; ++k) {
        // Iterate through words and assign them to the correct sub-lists
        const word = obj.words_array[k];
        for (let indexInWord = 0; indexInWord < word.length; ++indexInWord) {
          const charNumber = word.charCodeAt(indexInWord) - 'A'.charCodeAt(0);
          if (charNumber >= 0 && charNumber < 26) {
            charList[charNumber][indexInWord].push(k);   // TODO : numbers like 6PM are still allowed in the database - does this break construct functionality?
          }
        }
      }

      obj.mapping = charList;
    });

    scoredWordDatabase = swd;
    wordDatabaseIsLoaded = true;
    wordScoreFrequencies = scoreFreqs;

    // Also instantiate the autofill and wordSuggestor squads
    autofillCaptain.postMessage({
      action: 'instantiate',
      payload: { scoredWordDatabase },
    });
    wordSuggestorCaptain.postMessage({
      action: 'instantiate',
      payload: { scoredWordDatabase },
    });
  });

}


// Load the first time the app is loaded
loadWordlist('/wordlist-spread-the-wordlist-230731.txt');
// loadWordlist('/wordlist-peter-broda-230731.txt');
// loadWordlist('/peter-broda-wordlist.txt');








/* ======== autofill functionality ==========

Responsible for the basic autofill functions and the continuous autofill feature.
https://www.notion.so/Autofill-and-word-suggestions-4bfa5f57c40e4f1eb5855b79453a30f2?pvs=4
==================== */




/**
 * Returns a possibly empty array of words and their scores that match the given charArray.
 * @param {string} charArray An array of single-char or empty strings representing any restrictions to match to
 * @returns {[{word: string, score: number}]} The list of objects matching the given charArray, or null if scoredWordDatabase is not yet initialized.
 */
export function getScoredWordsMatching(charArray) {
  if (!scoredWordDatabase) return null;

  const swdL = scoredWordDatabase.get(charArray.length);
  if (!swdL) return null;
  const mapping = swdL.mapping;

  if (charArray.every(ch => (ch === ''))) { // no filtering to do if it's an empty word
    return swdL.words_array.map((word, idx) => { return { word: word, score: swdL.scores_array[idx] } });
  } else {
    let arrayOfMatchingIndicesArrays = [];
    // Filter words with each nonempty character
    for (let i = 0; i < charArray.length; ++i) {
      if (charArray[i] !== '') {
        const charNumber = charArray[i].charCodeAt(0) - 'A'.charCodeAt(0);  // a is 0, b is 1, ...
        const submapping = mapping[charNumber];
        if (submapping) {
          const charMatchingIndices = mapping[charNumber][i];
          arrayOfMatchingIndicesArrays.push(charMatchingIndices);
        } else {
          console.log('getScoredWordsMatching: encountered unknown character ' + charArray[i]);
          return [];    // returning an empty array because it encountered an unknown character
        }
      }
    }

    return intersect(arrayOfMatchingIndicesArrays).map(i => { return { word: swdL.words_array[i], score: swdL.scores_array[i] } });
  }
}


/**
 * Looks up the score for the given word and returns it (as a simple number). If not present (or word database is not loaded), returns null.
 * @param {string} word 
 * @returns {number} the score of the word according to the word database
 */
export function getScoreForWord(word) {
  if (!scoredWordDatabase) return null;
  const swdL = scoredWordDatabase.get(word.length);
  if (!swdL) return null;

  const idx = swdL.words_array.indexOf(word.toUpperCase());
  if (idx >= 0) {
    return swdL.scores_array[idx];
  }
  return null;
}


/**
 * Calculates and returns a Map from slotName to the list of possible filler words for that slot.
 * If oldSlotFillOptions and oldSlotStructure are provided, then it will first try to find an unchanged slot from the old slotStructure and
 * copy the fill options. Otherwise, it will re-calculate the fill options for the slot.
 * @param {SlotStructure} slotStructure The board's SlotStructure
 * @param {Map?} oldSlotFillOptions The old slotFillOptions if available, to help eliminate redundant calculations
 * @param {SlotStructure?} oldSlotStructure The old slotStructure if available, to help eliminate redundant calculations
 * @return {Map} A map from the slotName (string) to an array of scored words {word, score} that could fill that slot, or null if word database isn't loaded
 */
export function getSlotFillOptions(slotStructure, oldSlotFillOptions = null, oldSlotStructure = null) {
  if (!wordDatabaseIsLoaded) return null;

  if (oldSlotFillOptions !== null && oldSlotStructure !== null) {
    // For each slotName, first check if there is any matching slot in the oldSlotStructure. If there is, simply copy the old fill options for that slot.
    const sfo = new Map();
    slotStructure.slotStructureMap.forEach((slot, slotName) => {
      const correspondingOldSlotName = oldSlotStructure.getSlotNameContainingCoordinates(slot.locs[0], slot.direction);
      if (correspondingOldSlotName) {
        const correspondingOldSlotLocs = oldSlotStructure.getLocsInSlot(correspondingOldSlotName);
        const correspondingOldSlotChars = oldSlotStructure.getCharsInSlot(correspondingOldSlotName);
        if (locsEqual(correspondingOldSlotLocs[0], slot.locs[0]) && locsEqual(correspondingOldSlotLocs[correspondingOldSlotLocs.length-1], slot.locs[slot.locs.length-1])) {
          if (correspondingOldSlotChars.every((ch, idx) => ch === slot.chars[idx])) {
            // If there is a corresponding old slot name with identical first and last cell locs and identical char arrays, then copy the old slot's fill options
            sfo.set(slotName, oldSlotFillOptions.get(correspondingOldSlotName));
            return;
          }
        }
      }
      // If one of the above conditions fails, then we have to re-calculate the slot fill options
      sfo.set(slotName, getScoredWordsMatching(slot.chars));
    });
    return sfo;
  }

  // If oldSlotFillOptions or oldSlotStructure isn't provided, then just construct from scratch
  return new Map(Array.from(slotStructure.slotStructureMap.entries()).map(([slotName, slot]) => [slotName, getScoredWordsMatching(slot.chars)]));
}

/**
 * Assuming an identical SlotStructure w.r.t blackouts (i.e. unchanged blackouts), returns a new slotFillOptions Map that
 * copies most of the old keys/values, but replaces and re-calculates for the slots that have been modified according to charGridDifferences.
 * @param {Map} slotFillOptions The current slotFillOptions
 * @param {SlotStructure} slotStructure The new SlotStructure
 * @param {[{loc}]} charGridDifferences A list of differences where each difference is { loc: [r,c], ... }
 */
export function modifiedSlotFillOptions(slotFillOptions, slotStructure, charGridDifferences) {
  // Get all the slots that have changed as list of [slotName, slot] arrays
  const changedSlotNames = Array.from(slotStructure.slotStructureMap.entries()).filter(([slotName, slot]) => {
    return charGridDifferences.some(({loc}) => slot.indexOfLoc(loc) !== -1);    // include the slot if any of the charGridDifferences locs appears in it
  });

  const newSlotFillOptions = new Map(slotFillOptions);   // copy
  for (let i = 0; i < changedSlotNames.length; ++i) {
    const [slotName, slot] = changedSlotNames[i];
    newSlotFillOptions.set(slotName, getScoredWordsMatching(slot.chars));
  }
  return newSlotFillOptions;
}



/**
 * Picks and returns a random element from the given scoredWordsList, optionally weighted by score.
 * @param {[{word, score}]} scoredWordsList 
 * @param {number} highScoreBiasFactor A non-negative number; when 1.0, the elements are weighted linearly proportionally to their scores; when 0, completely unbiased
 * @returns {{word, score}} or null if the given list is empty.
 */
export function getRandomScoredWordFromList(scoredWordsList, highScoreBiasFactor = 1.0) {
  if (scoredWordsList.length === 0)
    return null;
  if (highScoreBiasFactor <= 0)
    return scoredWordsList[Math.floor(Math.random() * scoredWordsList.length)];

  const weights = [];
  for (let i = 0; i < scoredWordsList.length; ++i) {
    weights.push(scoredWordsList[i].score * highScoreBiasFactor + (weights[i-1] || 0));
  }

  const random = Math.random() * weights[weights.length-1];
  for (let i = 0; i < weights.length; ++i) {
    if (random < weights[i]) {
      return scoredWordsList[i];
    }
  }
}


  /**
   * Returns the sum total score across all the scored words in the given list.
   * @param {[{word, score}]} scoredWordsList 
   * @returns {number}
   */
  function getTotalScoreFromScoredWordsList(scoredWordsList) {
    return scoredWordsList.reduce((totalScore, currentObj) => totalScore + currentObj.score, 0);
  }


/**
 * Returns the slotName for the partially empty slot with the fewest fill options available.
 * If multiple slots have the same number of fill options, then returns the one with the max total score of said fill options.
 * @param {SlotStructure} slotStructure
 * @param {Map} slotFillOptions map from slotName to the list of fill options [{word, score}]
 * @param {Object} options An object with optional attributes slotNameChoices and excludedSlotNames
 * @returns {string | null} slotName, or null if no empty slots found or slotFillOptions is null
 */
export function getMostRestrictedSlotName(slotStructure, slotFillOptions, options={}) {
  if (slotFillOptions === null) return null;
  
  const { slotNameChoices, excludedSlotNames } = options;
  var winningSlotName = null;
  var minNumFillOptions = 99999999;
  var maxScore = -1;      // tiebreaker for minimum number of fill options is maximum total score of fill options
  for (let [slotName, slot] of slotStructure.slotStructureMap) {
    if (slotNameChoices && !slotNameChoices.includes(slotName)) {
      continue;
    }
    if (excludedSlotNames && excludedSlotNames.includes(slotName)) {
      continue;
    }
    if (!slot.isFull) {
      const fillOptionsList = slotFillOptions.get(slotName);
      if (fillOptionsList.length <= minNumFillOptions) {
        const totalScore = getTotalScoreFromScoredWordsList(fillOptionsList);
        if ((fillOptionsList.length === minNumFillOptions && totalScore > maxScore) || fillOptionsList.length < minNumFillOptions) {
          winningSlotName = slotName;
          minNumFillOptions = fillOptionsList.length;
          maxScore = totalScore;
        }
      }
    }
  }
  return winningSlotName;
}




/**
 * Instantiates a web worker and begins searching for a fill; assumes it's already been throttled appropriately.
 * @param {SlotStructure} slotStructure The SlotStructure object representing the current state of the charGrid.
 * @param {(locsToChange, newChars) => void} onEssentialFill Callback when all of the slots with only one choice are filled; always called unless interrupted.
 * @param {(locsToChange, newChars) => void} onSuccess Callback if/when a complete, successful autofill is found; does not include locs already given in onPartialFill.
 * @param {(message: string) => void} onFailure Callback if/when the search is exhausted with no fill found.
 * @param {number | undefined} minScore The minimum word score allowable for this fill attempt
 * @param {number} myAutofillRequestId Autofill request ID attached to this request for recordkeeping and duplicate prevention
 */
function startThrottledAutofill(slotStructure, onEssentialFill, onSuccess, onFailure, minScore=-Infinity, myAutofillRequestId) {

  // Subscribe to relevant messages from autofillCaptain
  const onReceiveMessage = e => {
    const { isUpward, action, payload, autofillRequestId } = e.data;
    if (!isUpward) return;
    if (!autofillRequestId || autofillRequestId !== lastAutofillRequestId) return;
    
    if (action === 'essentialFill') {
      const { locsToChange, newChars } = payload;

      onEssentialFill(locsToChange, newChars);
    } else if (action === 'finalUpdate') {
      // Unpack data
      const { success, message, locsToChange, newChars, essentialLocs } = payload;

      // Deal with success or failure
      if (success) {
        onSuccess(locsToChange, newChars, essentialLocs);
      } else {
        onFailure(message);
      }
    }
  };

  autofillCaptain.onmessage = onReceiveMessage;

  // Post message to actually start the autofill
  autofillCaptain.postMessage({
    action: 'startAutofill',
    payload: {
      slotStructureMap: slotStructure.slotStructureMap,
      minScore,
    },
    autofillRequestId: myAutofillRequestId,
  });
}


/**
 * In a throttled manner, instantiates a web worker and begins searching for a fill.
 * This function carries logic that will prevent the autofill attempt from occuring if the grid is too big and empty (i.e. if autofill is clearly impossible).
 * @param {SlotStructure} slotStructure The SlotStructure object representing the current state of the charGrid.
 * @param {(locsToChange, newChars) => void} onEssentialFill Callback when all of the slots with only one choice are filled; always called unless interrupted.
 * @param {(locsToChange, newChars) => void} onSuccess Callback if/when a complete, successful autofill is found; does not include locs already given in onPartialFill.
 * @param {(message: string) => void} onFailure Callback if/when the search is exhausted with no fill found.
 * @param {number | undefined} minScore The minimum score to consider for this attempt
 */
export function startAutofill(slotStructure, onEssentialFill, onSuccess, onFailure, minScore=-Infinity) {
  const myAutofillRequestId = ++lastAutofillRequestId;

  if (autofillIsThrottled) {
    // Cancel any queued autofill, and set up a new throttled autofill timer
    clearTimeout(autofillThrottlerTimeout);
    autofillThrottlerTimeout = setTimeout(() => {
      startThrottledAutofill(slotStructure, onEssentialFill, onSuccess, onFailure, minScore, myAutofillRequestId);
      autofillIsThrottled = false;
    }, 500);

  } else {
    // Autofill isn't throttled; start immediately
    startThrottledAutofill(slotStructure, onEssentialFill, onSuccess, onFailure, minScore, myAutofillRequestId);
    autofillIsThrottled = true;
    autofillThrottlerTimeout = setTimeout(() => {
      autofillIsThrottled = false;
    }, 500);
  }
}


export function stopAutofillOnly() {
  clearTimeout(autofillThrottlerTimeout);
  autofillIsThrottled = false;

  autofillCaptain.postMessage({
    action: 'stopAutofill',
  });
}



export function stopAutofillAndWordSuggestor() {
  clearTimeout(autofillThrottlerTimeout);
  autofillIsThrottled = false;

  autofillCaptain.postMessage({
    action: 'stopAutofill',
  });

  // Also stop word suggestor
  wordSuggestorCaptain.postMessage({
    action: 'stopAutofill',
  });
}



function startWordSuggestor(callId, slotStructureMap, currentSlotName, wordOptions, onReceiveWordUpdates, minScore=-Infinity) {
  //TODO - throttle
  startThrottledWordSuggestor(callId, slotStructureMap, currentSlotName, wordOptions, onReceiveWordUpdates, minScore);
}
function startThrottledWordSuggestor(callId, slotStructureMap, currentSlotName, wordOptions, onReceiveWordUpdates, minScore=-Infinity) {
  
  // Subscribe to relevant messages from autofillCaptain
  const onReceiveMessage = e => {
    const { isUpward, action, payload } = e.data;
    if (!isUpward) return;
    
    if (action === 'wordUpdate') {
      const { callId: returnedCallId, wordUpdates } = payload;
      if (!returnedCallId || returnedCallId !== callId) {
        return;
      }

      onReceiveWordUpdates(wordUpdates, returnedCallId);
    }
  };
  wordSuggestorCaptain.onmessage = onReceiveMessage;

  // Post message to start the word suggestor
  wordSuggestorCaptain.postMessage({
    action: 'startWordSuggestor',
    payload: {
      slotStructureMap,
      currentSlotName,
      wordOptions,
      minScore,
      callId,
    },
  });

}




/* ==== wordSuggestor functionality =====

wordsMap takes the form of a Map with the following format (or null if no slot is selected).
word: {
  score,            // the numeric score of the word according to the wordlist
  isDisplayed,      // true or false, if the word is currently displayed (a subset may be displayed to avoid rendering issues)
  rankPriority,     // a numeric value that determines the word's placement (within the displayed words subset), with higher values appearing toward the top
  status,           // one of the WORD_SUGGESTION_STATUS values indicating whether this word has been evaluated & whether a fill was found
  possibleFill,     // {locsToChange, newChars, essentialLocs} (treat atomically) describing the possible fill if this word was selected, or null if not yet evaluated or nonviable
}

As such, there are a few different actions that can influence wordsMap, such as focusing on a new slot, receiving new word evaluations, changing the sorting criteria, etc.
==============
*/


// Way faster to deep copy according to exact structure
function deepCopyWordsMap(wordsMap) {
  const clone = new Map();
  wordsMap.forEach(({ score, isDisplayed, rankPriority, status, possibleFill }, word) => clone.set(word, { score, isDisplayed, rankPriority, status, possibleFill }));
  return clone;
}

const DEFAULT_MAX_DISPLAYED_WORDS = 50; // default num. words displayed/evaluated to begin with
const DEFAULT_MIN_DISPLAYED_WORDS = 13; // when there are fewer than this number of evaluated, possibly-fillable words, it will automatically attempt to display more
const WORD_SUGGESTOR_TIMEOUT_SECS = 60; // min. num. seconds since the last user interaction that the word suggestor will attempt to find words for before pausing

export const WORD_SUGGESTION_STATUS = {
  NOT_EVALUATED: 'unknown',
  FILLABLE: 'fillable',
  UNFILLABLE: 'unfillable',
  TIMEOUT: 'timeout',
  IGNORED: 'ignored',    // e.g. the word score was below the minimum threshold
};

/**
 * A hook into the wordsMap functionality. Essentially a "window" into the BoardInteractionContext class.
 */
export function useWordsMap(onSuccessfulSuggestion) {

  // The main cache of possible words used; nested map from charGridString to currentSlotName to wordsMap
  const wordSuggestorCache = useRef(new Map());
  // An ordered list of maximum five charGrid strings defining the only charGrid strings that the wordSuggestorCache will hold on to (mechanism for clearing the cache)
  const lastFiveCharGridStrings = useRef([]);
  // Just keep track of the call ID to make sure the state isn't outdated on return
  const mostRecentCallId = useRef({ callId: 0, lastUserInputTimestamp: 0 });

  // The CURRENT wordsMap (should be a copy of one of the wordsMaps that is in the wordSuggestorCache)
  // Note that to control the flow of data, this is always set FROM a DEEP COPY of the cached words map (i.e. update the cache first and then copy it into here)!
  const [wordsMap, setWordsMap] = useState(null);    // the current wordsMap for display purposes (not the underlying source of truth)
  // Whether or not it's currently evaluating more words in the background
  const [isCurrentlyEvaluatingMore, setIsCurrentlyEvaluatingMore] = useState(false);

  // For internal use only, keep the charGridString and currentSlotName for easy reference of the wordsMap object within the cache
  const currentValues = useRef({ 
    charGrid: null, charGridString: '', slotStructure: null, slotFillOptions: null,
    cursorLoc: null, cursorDirection: '',
    minScore: -Infinity,
    currentTimeout: undefined,    // used for throttling word suggestor updates
    wordSuggestorIsOn: true,      // false when the user turns off entirely (e.g. to save resources)
  });

  /**
   * Customer-exposed function to be called any time the charGrid changes to inform the word suggestor.
   * May trigger a new word suggestor effort.
   * @param {[[string]]} newCharGrid 
   * @param {SlotStructure} newSlotStructure 
   * @param {Map} newSlotFillOptions 
   */
  function updateCharGridForWordSuggestor(newCharGrid, newSlotStructure, newSlotFillOptions) {
    if (!newCharGrid || !newSlotStructure || !newSlotFillOptions) return;

    // Update current values
    currentValues.current.charGrid = newCharGrid;
    currentValues.current.charGridString = charGridAsString(newCharGrid);
    currentValues.current.slotStructure = newSlotStructure;
    currentValues.current.slotFillOptions = newSlotFillOptions;
    mostRecentCallId.current.lastUserInputTimestamp = Date.now();

    // Update word suggestor cache; the timeout saves resources by preventing double-update when cursor and charGrid both change at the same time
    clearTimeout(currentValues.current.currentTimeout);
    currentValues.current.currentTimeout = setTimeout(updateWordSuggestorCache, 5);  
  }

  /**
   * Customer-exposed function to be called any time the cursor changes to inform the word suggestor.
   * May trigger a new word suggestor effort.
   * @param {[r, c]} newCursorLoc 
   * @param {string} newCursorDirection 
   */
  function updateCursorForWordSuggestor(newCursorLoc, newCursorDirection) {
    if (!newCursorDirection) return;

    // Update current values
    currentValues.current.cursorLoc = newCursorLoc;
    currentValues.current.cursorDirection = newCursorDirection;
    mostRecentCallId.current.lastUserInputTimestamp = Date.now();

    // Update word suggestor cache; the timeout saves resources by preventing double-update when cursor and charGrid both change at the same time
    clearTimeout(currentValues.current.currentTimeout);
    currentValues.current.currentTimeout = setTimeout(updateWordSuggestorCache, 5);
  }

  function updateMinScoreForWordSuggestor(newMinScore) {
    currentValues.current.minScore = newMinScore;
    mostRecentCallId.current.lastUserInputTimestamp = Date.now();

    // Update word suggestor cache; the timeout saves resources by preventing double-update when cursor and charGrid both change at the same time
    clearTimeout(currentValues.current.currentTimeout);
    currentValues.current.currentTimeout = setTimeout(updateWordSuggestorCache, 5);
  }


  function turnOnWordSuggestor() {
    currentValues.current.wordSuggestorIsOn = true;
    mostRecentCallId.current.lastUserInputTimestamp = Date.now();
    
    // Update word suggestor cache; the timeout saves resources by preventing double-update when cursor and charGrid both change at the same time
    clearTimeout(currentValues.current.currentTimeout);
    currentValues.current.currentTimeout = setTimeout(updateWordSuggestorCache, 5);
  }

  function turnOffWordSuggestor() {
    currentValues.current.wordSuggestorIsOn = false;
    clearTimeout(currentValues.current.currentTimeout);
  }


  // Function intended to be called any time the slot name or the char grid changes, or the word suggestor should be started on a parallel thread.
  function updateWordSuggestorCache() {
    const THIS_CALL_ID = ++mostRecentCallId.current.callId;  // first update this, so any incoming calls from the old call will be ignored

    const { charGridString, charGrid, slotStructure, slotFillOptions, cursorLoc, cursorDirection, minScore } = currentValues.current;
    const slotStructureMap = slotStructure?.slotStructureMap;

    // Just be a little defensive
    if (!charGridString || !charGrid || !slotStructureMap || !slotFillOptions) return;  // word database may not be loaded
    if (cursorLoc && (cursorLoc[0] >= charGrid.length || cursorLoc[1] >= charGrid[0].length)) return; // I think there's an unlucky edge case where charGrid and cursor are out-of-sync

    // Calculate the currentSlotName
    const currentSlotName = (cursorLoc && currentValues.current.charGrid?.[cursorLoc[0]]?.[cursorLoc[1]] !== 'blackout') ? (
      currentValues.current.slotStructure.getSlotNameContainingCoordinates(cursorLoc, cursorDirection)
    ) : null;

    // Before we get started, first just update the lastFiveCharGridStrings and delete any old ones from the cache
    // Create a maximum-five-length, unique list of the last five charGridStrings (in order 0->4)
    const newList = [charGridString].concat(lastFiveCharGridStrings.current).filter((item, idx, array) => array.indexOf(item) === idx).slice(0, 5);
    lastFiveCharGridStrings.current = newList;
    // Update the cache by deleting any charGridStrings that are not in the above list
    for (const k of Array.from(wordSuggestorCache.current.keys())) {
      if (!newList.includes(k)) {
        wordSuggestorCache.current.delete(k);
      }
    }

    // Okay, now we can go ahead and actually do the logic to update the wordsMap and cache
    if (!currentSlotName) {
      // No slot selected
      setWordsMap(null);
      setIsCurrentlyEvaluatingMore(false);
    } else {
      // First get an initial wordsMap, assuming that it doesn't already exist in the cache (note that this is unnecessary calculation if it IS in the cache though, oh well)
      var initialWordsMap = new Map((slotFillOptions.get(currentSlotName) || []).sort((a, b) => b.score - a.score).map(({word, score}, idx) => [word, {
        score,
        isDisplayed: idx < DEFAULT_MAX_DISPLAYED_WORDS,
        rankPriority: score,
        status: WORD_SUGGESTION_STATUS.NOT_EVALUATED,
        possibleFill: null,
      }]));

      // To trigger this function, either the current slot or the charGrid has changed; now we check the cache to see if we already have a starting point
      const charGridCache = wordSuggestorCache.current.get(charGridString);
      if (charGridCache) {
        const slotNameCache = charGridCache.get(currentSlotName);
        if (slotNameCache) {
          // This is the only path where there's already a cached wordsMap associated with this charGrid-slotName combo; use that instead
          initialWordsMap = slotNameCache;

        } else {
          charGridCache.set(currentSlotName, initialWordsMap);
        }
      } else {
        wordSuggestorCache.current.set(charGridString, new Map([[currentSlotName, initialWordsMap]]));
      }

      // Now, check if the grid is impossible or isn't worth trying to fill at all, in which case we just set everything to impossible or unevaluated and return
      if (Array.from(slotFillOptions.entries()).some(([sn, fo]) => (!fo || fo.length === 0) && slotStructure.getCharsInSlot(sn).includes(''))) {
        initialWordsMap.forEach(obj => {
          obj.status = WORD_SUGGESTION_STATUS.UNFILLABLE;
          obj.possibleFill = null;
          obj.rankPriority = obj.score - 1000;
        });
        setWordsMap(deepCopyWordsMap(initialWordsMap));
        return;
      }
      if (!isGridWorthAutofilling(slotStructure)) {
        initialWordsMap.forEach(obj => {
          obj.status = WORD_SUGGESTION_STATUS.TIMEOUT;
          obj.possibleFill = null;
        });
        setWordsMap(deepCopyWordsMap(initialWordsMap));
        return;
      }

      // Now pre-set all of the words that don't make the minScore to this initialWordsMap
      initialWordsMap.forEach(obj => {
        if (obj.score < minScore) {
          obj.status = WORD_SUGGESTION_STATUS.IGNORED;
          obj.possibleFill = null;
          obj.rankPriority -= 500;
        } else if (obj.status === WORD_SUGGESTION_STATUS.IGNORED) {
          // Edge case for if the user re-adjusts the minScore back down while looking at the same grid, want to un-ignore these words
          obj.status = WORD_SUGGESTION_STATUS.NOT_EVALUATED;
        }
      });

      // The cache is now updated; set the wordsMap state value to update the rendering
      setWordsMap(deepCopyWordsMap(initialWordsMap));

      // Also start the wordSuggestor process in the background
      if (currentValues.current.wordSuggestorIsOn) {
        const wordOptions = Array.from(initialWordsMap.entries()).filter(([_, obj]) => obj.isDisplayed && obj.status === WORD_SUGGESTION_STATUS.NOT_EVALUATED).map(([k, v]) => k);
        if (wordOptions.length > 0) {
          const lastWord = wordOptions[wordOptions.length - 1];
          const onReceiveWordUpdates = (wordUpdates, callId) => {
            // Sometimes this function is called late (after the slot name or board state has changed), so make sure that the state isn't outdated
            if (callId !== mostRecentCallId.current.callId) {
              return;
            }

            const cachedWordsMap = wordSuggestorCache.current.get(charGridString)?.get(currentSlotName);
            if (!cachedWordsMap) return;
            for (const { word, timeout, locsToChange, newChars, essentialLocs } of wordUpdates) {
              const wordSuggestionObj = cachedWordsMap.get(word);
              if (timeout) {
                wordSuggestionObj.status = WORD_SUGGESTION_STATUS.TIMEOUT;
              } else if (locsToChange) {
                wordSuggestionObj.rankPriority += 1000;
                wordSuggestionObj.status = WORD_SUGGESTION_STATUS.FILLABLE;
                wordSuggestionObj.possibleFill = { locsToChange, newChars, essentialLocs };

                onSuccessfulSuggestion(currentSlotName, word, { locsToChange, newChars, essentialLocs });
              } else {
                wordSuggestionObj.rankPriority -= 1000;
                wordSuggestionObj.status = WORD_SUGGESTION_STATUS.UNFILLABLE;
                wordSuggestionObj.possibleFill = null;
              }
            }

            // Check if it's the last expected word update
            if (wordUpdates.map(o => o.word).includes(lastWord)) {
              setIsCurrentlyEvaluatingMore(false);
            }

            // Check if there are too few fillable words displayed (in which case we'll want to automatically display some more)
            if (
              Date.now() - mostRecentCallId.current.lastUserInputTimestamp < WORD_SUGGESTOR_TIMEOUT_SECS * 1000 &&   // for difficult fills, don't keep adding words forever
              !Array.from(cachedWordsMap.values()).some(obj => obj.isDisplayed && obj.status === WORD_SUGGESTION_STATUS.NOT_EVALUATED) &&
              Array.from(cachedWordsMap.values()).filter(obj => obj.status === WORD_SUGGESTION_STATUS.FILLABLE).length < DEFAULT_MIN_DISPLAYED_WORDS
            ) {
              // Tack more words onto the displayed words
              Array.from(cachedWordsMap.values())
                .filter(obj => !obj.isDisplayed)
                .sort((obj1, obj2) => obj2.rankPriority - obj1.rankPriority)
                .slice(0, DEFAULT_MIN_DISPLAYED_WORDS)
                .forEach(obj => obj.isDisplayed = true);
              updateWordSuggestorCache();  // recursively call this guy to restart the process
            }

            // Finally, copy the updated words map into the state value
            setWordsMap(deepCopyWordsMap(cachedWordsMap));
          };

          startWordSuggestor(THIS_CALL_ID, slotStructureMap, currentSlotName, wordOptions, onReceiveWordUpdates, minScore);
          setIsCurrentlyEvaluatingMore(true);
        } else {
          setIsCurrentlyEvaluatingMore(false);
        }

      }
    }
  }



  /**
   * A function that can be called to automatically set all wordsMap objects to have no possible fill (e.g. if the other autofill mechanism returns unfillable).
   */
  function declareFillImpossible() {
    stopAutofillAndWordSuggestor();

    const charGridCache = wordSuggestorCache.current.get(currentValues.current.charGridString);
    if (charGridCache) {
      // Iterate through all slot names, updating each wordsMap to be impossible; don't even check if it already exists
      for (const sn of currentValues.current.slotStructure.slotNames) {
        let slotWordsMap = new Map((currentValues.current.slotFillOptions.get(sn) || []).sort((a, b) => b.score - a.score).map(({word, score}, idx) => [word, {
          score,
          isDisplayed: idx < DEFAULT_MAX_DISPLAYED_WORDS,
          rankPriority: score - 1000,
          status: WORD_SUGGESTION_STATUS.UNFILLABLE,
          possibleFill: null,
        }]));
        charGridCache.set(sn, slotWordsMap);
      }

      // Finally, update the words map if the currentSlotName exists
      const { cursorLoc, cursorDirection } = currentValues.current;
      const currentSlotName = (cursorLoc && currentValues.current.charGrid?.[cursorLoc[0]]?.[cursorLoc[1]] !== 'blackout') ? (
        currentValues.current.slotStructure.getSlotNameContainingCoordinates(cursorLoc, cursorDirection)
      ) : null;
      if (currentSlotName) {
        const currentWordsMap = charGridCache.get(currentSlotName);
        if (currentWordsMap) {
          setWordsMap(deepCopyWordsMap(currentWordsMap));
        }
      }
    }
  }


  /**
   * A function that can be called to add a known fill (e.g. if the other autofill mechanism returns a fill first).
   * When this function is called, it adds the known fill to all the possible wordsMap objects for every slot in the grid,
   * frequently creating wordsMap objects where they don't yet exist (in slots that haven't been examined yet by the user).
   * This serves to "bypass" the word suggestor and add in the known fill.
   * @param {{locsToChange, newChars, essentialLocs}} possibleFill
   */
  function addKnownFillToWordsMap({ locsToChange, newChars, essentialLocs }) {
    const charGridCache = wordSuggestorCache.current.get(currentValues.current.charGridString);
    if (charGridCache) {
      // Iterate through all slot names, updating each of their respective wordsMaps
      const filledSlotStructure = currentValues.current.slotStructure.withReplacedChars(locsToChange.map((loc, idx) => ({ loc, char1: '', char2: newChars[idx] })));
      for (const sn of currentValues.current.slotStructure.slotNames) {
        let slotWordsMap = charGridCache.get(sn);
        if (!slotWordsMap) {    // create a new wordsMap for this slot if it doesn't exist
          slotWordsMap = new Map((currentValues.current.slotFillOptions.get(sn) || []).sort((a, b) => b.score - a.score).map(({word, score}, idx) => [word, {
            score,
            isDisplayed: idx < DEFAULT_MAX_DISPLAYED_WORDS,
            rankPriority: score,
            status: WORD_SUGGESTION_STATUS.NOT_EVALUATED,
            possibleFill: null,
          }]));
          charGridCache.set(sn, slotWordsMap);
        }

        const slotWord = filledSlotStructure.getWordInSlotName(sn);
        const slotLocs = filledSlotStructure.getLocsInSlot(sn);
        const suggestionObj = slotWordsMap.get(slotWord);
        if (suggestionObj && suggestionObj.status !== WORD_SUGGESTION_STATUS.FILLABLE) {
          suggestionObj.isDisplayed = true;
          suggestionObj.status = WORD_SUGGESTION_STATUS.FILLABLE;
          suggestionObj.rankPriority += 1000;
          const idxsToRemove = locsToChange.map((l, i) => locInList(l, slotLocs) ? i : -1).filter(e => e > -1);   // remove the locs from the current slot from the suggested fill
          suggestionObj.possibleFill = {
            locsToChange: locsToChange.filter((_, i) => !idxsToRemove.includes(i)),
            newChars: newChars.filter((_, i) => !idxsToRemove.includes(i)),
            essentialLocs: essentialLocs.filter(loc => !locInList(loc, locsToChange.filter((_, i) => idxsToRemove.includes(i)))),
          };
        }
      }

      // Finally, update the words map if the currentSlotName exists
      const { cursorLoc, cursorDirection } = currentValues.current;
      const currentSlotName = (cursorLoc && currentValues.current.charGrid?.[cursorLoc[0]]?.[cursorLoc[1]] !== 'blackout') ? (
        currentValues.current.slotStructure.getSlotNameContainingCoordinates(cursorLoc, cursorDirection)
      ) : null;
      if (currentSlotName) {
        const currentWordsMap = charGridCache.get(currentSlotName);
        if (currentWordsMap) {
          setWordsMap(deepCopyWordsMap(currentWordsMap));
        }
      }
    }
    
  }


  function displayMoreWords(numAdditionalWords = DEFAULT_MAX_DISPLAYED_WORDS) {
    mostRecentCallId.current.lastUserInputTimestamp = Date.now();

    // As always, update the cached wordsMap first and then copy this into the wordsMap state value
    const { cursorLoc, cursorDirection, charGrid, charGridString, slotStructure } = currentValues.current;
    const currentSlotName = (cursorLoc && charGrid?.[cursorLoc[0]]?.[cursorLoc[1]] !== 'blackout') ? (
      slotStructure.getSlotNameContainingCoordinates(cursorLoc, cursorDirection)
    ) : null;
    const cachedWordsMap = wordSuggestorCache.current.get(charGridString).get(currentSlotName);

    // Iterate through the word suggestion objects ordered by rank priority and assign a few more as isDisplayed
    const sortedSuggestionObjs = Array.from(cachedWordsMap.values()).sort((a, b) => b.rankPriority - a.rankPriority);
    let numNewDisplayed = 0;
    for (const obj of sortedSuggestionObjs) {
      if (!obj.isDisplayed) {
        obj.isDisplayed = true;
        ++numNewDisplayed;
        if (numNewDisplayed > numAdditionalWords) break;
      }
    }
    setWordsMap(deepCopyWordsMap(cachedWordsMap));

    // Finally, start the word suggestor again since we now have more displayed word options we want to include
    updateWordSuggestorCache();
  }


  return {
    wordSuggestorCache, wordsMap,
    updateCharGridForWordSuggestor, updateCursorForWordSuggestor,
    updateMinScoreForWordSuggestor,
    turnOnWordSuggestor, turnOffWordSuggestor,
    displayMoreWords,
    isCurrentlyEvaluatingMore,
    addKnownFillToWordsMap, declareFillImpossible,
  };
}






/**
 * Returns true iff the grid is small and/or complete enough to possibly get an autofill.
 * E.g. for an empty 15x15 grid, it will return false so as to avoid trying to autofill every time there's a new puzzle.
 * This function is meant to be generous, i.e. only returning False for common grid states (e.g. empty puzzles) that are clearly unfillable.
 * Currently set to return True iff there are less than 10 empty slots of 15 or more squares.
 * @param {SlotStructure} slotStructure 
 */
export function isGridWorthAutofilling(slotStructure) {
  const numEmpty15LetterSlots = Array.from(slotStructure.slotStructureMap.values()).reduce((acc, cur) => acc + (cur.chars.length >= 15 && cur.chars.every(c => c === '')), 0);
  return numEmpty15LetterSlots < 10;
}



// Used for standardizing status updates across components
export const AUTOFILL_STATUS = {
  SEARCHING: 'searching',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
};