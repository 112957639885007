import React, { useEffect, useState } from 'react';
import { post } from 'aws-amplify/api';
import { Button, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { FaRegSave } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toPuzzleContentObject } from '../../../libs/exportLib';
import { useTraversal } from '../../../libs/traversalLib';
import MiniBrowse from '../../browse/MiniBrowse';
import LoaderButton from '../../reusable/LoaderButton';
import { useAppContext } from '../../../App';
import { PuzzleMetadataKey } from '../../../libs/directionsLib';
import { useToastNotifications } from '../../../libs/toastLib';

export default function SaveAsModal({ show, onHide, puzzleData }) {
  const { charGrid, furnishings, clues, puzzleMetadata } = puzzleData;

  const { 
    errorMessage,
    currentDirPath,
    currentDirStructure,
    callbacks,
  } = useTraversal({ modalNavigation: true });

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated } = useAppContext();
  const { postNotification, postErrorNotification, removeNotificationsWithLabels } = useToastNotifications();

  const [newPuzzleTitle, setNewPuzzleTitle] = useState('');

  useEffect(() => {
    if (show) {
      setNewPuzzleTitle(puzzleMetadata.get(PuzzleMetadataKey.TITLE) ? 'Copy of ' + puzzleMetadata.get(PuzzleMetadataKey.TITLE) : '');

      // When modal is opened, remove certain possible warnings https://www.notion.so/Toast-System-235a221e3b6e4953879e9bf545b3855b?pvs=4
      removeNotificationsWithLabels(['request-save-as']);
    }
  }, [show, removeNotificationsWithLabels, puzzleMetadata]);

  async function handleSubmit() {
    try {
      setIsLoading(true);

      // Replace title with new title value
      const newPuzzleMetadata = new Map(puzzleMetadata);
      newPuzzleMetadata.set(PuzzleMetadataKey.TITLE, newPuzzleTitle);

      // Post to database
      const returnedPuzzle = await (await post({
        apiName: 'userPuzzles',
        path: '/userPuzzles',
        options: {
          body: {
            puzzleContent: toPuzzleContentObject(charGrid, furnishings, clues, newPuzzleMetadata),
          },
          headers: {
            'x-dirpathstring': currentDirPath.join('/'),
          },
        },
      }).response).body.json();

      // Navigate to the new URL and force a reload
      sessionStorage.setItem('forceReloadPuzzleItem', 'false');
      postNotification({
        variant: 'chill',
        icon: <FaRegSave />,
        headline: 'Saved a copy!',
        content: <span>You're now editing a new file (with a different URL).</span>,
        labels: ['puzzle-specific'],
      });
      localStorage.setItem('suppressSaveWarning', 'yes');
      navigate(`/construct/${returnedPuzzle.puzzleId}`, { puzzleItem: returnedPuzzle });
      setIsLoading(false);
      onHide();
      window.location.reload();
    } catch (e) {
      postErrorNotification('Error saving', 'We encountered an error saving. Try again or let us know if the error persists.');
      setIsLoading(false);
      onHide();
    }
  }

  return (
    <Modal show={show} onHide={onHide} onShow={() => callbacks.refreshDataFromServer()}>
      <Modal.Header className='teal-2-bkgd' closeButton>
        <strong>Save a copy of your puzzle</strong>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className='mb-3' size='sm'>
          <InputGroup.Text className='purple-2-bkgd fw-bold'>Puzzle title</InputGroup.Text>
          <FormControl
            value={newPuzzleTitle}
            onChange={e => setNewPuzzleTitle(e.target.value)}
          />
        </InputGroup>
        {errorMessage || <MiniBrowse dirPath={currentDirPath} folderStructure={currentDirStructure} callbacks={callbacks} puzzlesDisabled />}
      </Modal.Body>
      <Modal.Footer>
        <div className='me-auto text-muted fst-italic small'>Sharing settings will not be copied.</div>
        <Button size='sm' variant='outline-secondary' onClick={() => onHide()}>Cancel</Button>
        <LoaderButton size='sm' variant='success' isLoading={isLoading} onClick={handleSubmit} disabled={!isAuthenticated || !currentDirPath}>Save here</LoaderButton>
      </Modal.Footer>
    </Modal>
  );
}