/*
This gives a component that allows keyboard interaction.
Copied and pasted from https://github.com/linsight/react-keyboard-event-handler on Apr 10, 2024. I was originally using the npm package,
but it doesn't support latest React versions and hasn't been updated in years so I just took the source code here.
*/




import React from 'react';
import PropTypes from 'prop-types';



const commonKeys = {
  backspace: [8],
  del: [46],
  delete: [46],
  ins: [45],
  insert: [45],
  tab: [9],
  enter: [13],
  return: [13],
  esc: [27],
  space: [32],
  pageup: [33],
  pagedown: [34],
  end: [35],
  home: [36],
  left: [37],
  up: [38],
  right: [39],
  down: [40],
  shift: [16],
  ctrl: [17],
  alt: [18],
  cap: [20],
  num: [144],
  clear: [12],
  meta: [91],
  ';': [186, 59],
  '=': [187, 61],
  ',': [188, 44],
  '-': [189, 45, 173, 109],
  'minus': [189, 45, 173, 109],
  '.': [190, 110],
  '/': [191, 111],
  '`': [192],
  '[': [219],
  '\\': [220],
  ']': [221],
  '*': [106],
  '+': [107],
  'plus': [107],
  '\'': [222],
  'quote': [222],
};

const commonKeysInUpperCases = Object.keys(commonKeys)
  .reduce((accumulator, current) =>
    Object.assign(accumulator, { [current.toUpperCase()]: commonKeys[current] }), {});


const numberKeys = '0123456789'.split('')
  .reduce((accumulator, current, index) =>
    Object.assign(accumulator, { [current]: [index + 48, index + 96] }), {});


const letterKeys = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
  .reduce((accumulator, current, index) =>
    Object.assign(
      accumulator,
      { [current.toLowerCase()]: [index + 65] },
      { [current]: [index + 65] }), {});

const fnKeys = '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19'.split(',')
  .reduce((accumulator, current, index) =>
    Object.assign(accumulator, { [`f${current}`]: [index + 112] }), {});


const modifierKeys = {
  control: 'ctrl',
  ctrl: 'ctrl',
  shift: 'shift',
  meta: 'meta',
  cmd: 'meta',
  command: 'meta',
  option: 'alt',
  alt: 'alt',
};

export const AllKeys = Object.assign({}, commonKeys, commonKeysInUpperCases, numberKeys, letterKeys, fnKeys);
const alphanumericKeys = Object.assign({}, numberKeys, letterKeys);

const aliasKeys = {
  all: Object.keys(AllKeys),
  alphanumeric: Object.keys(alphanumericKeys),
  numeric: Object.keys(numberKeys),
  alphabetic: Object.keys(letterKeys),
  function: Object.keys(fnKeys),
};

export function matchKeyEvent(event, keyName) {
  const eventKeyCode = event.which || event.keyCode;
  const eventType = event.type;
  const eventModifiers = Object.keys(modifierKeys).filter(modKey => event[`${modKey}Key`]).sort();
  const cleanKeyName = keyName.toLowerCase().trim();
  const keyNameParts = cleanKeyName === '+' ? ['+'] : cleanKeyName.split(/\s?\+\s?/); // e.g. 'crtl + a'
  const mainKeyName = keyNameParts.pop();
  const mainKeyCode = AllKeys[mainKeyName];
  const modifierKeyNames = keyNameParts;

  if (eventType === 'keypress') {
    const eventKeyCodeString = String.fromCharCode(eventKeyCode);
    return keyName === eventKeyCodeString.toLowerCase();
  }

  if (modifierKeyNames.length === 0 && eventModifiers.length === 0) {
    return mainKeyCode.indexOf(eventKeyCode) >= 0;
  }

  if (modifierKeyNames.length > 0 && eventModifiers.length > 0) {
    const modifiers = modifierKeyNames.map(modKey => modifierKeys[modKey]).sort();
    const modifiersMatched = modifiers.length === eventModifiers.length &&
      modifiers.every((modKey, index) => eventModifiers[index] === modKey);

    return mainKeyCode.indexOf(eventKeyCode) >= 0 && modifiersMatched;
  }

  if (modifierKeyNames.length === 0 && eventModifiers.length === 1) {
    return mainKeyName === eventModifiers[0];
  }

  return false;
}

function findMatchedKey(event, keys) {
  const lookupAlias = (k) => {
    const lowerK = k.toLowerCase();
    const found = aliasKeys[lowerK];
    return found ? found : [k];
  };

  const expandedKeys = keys.map(lookupAlias).reduce((a, b) => a.concat(b), []);

  let matchedKey = expandedKeys.find(k => matchKeyEvent(event, k));

  if (!matchedKey && keys.includes('all')) {
    matchedKey = 'other';
  }

  return matchedKey;
}





let exclusiveHandlers = [];

export default class KeyboardEventHandler extends React.Component {
  constructor(props) {
    super(props);

    this.handleKeyboardEvent = this.handleKeyboardEvent.bind(this);
    this.registerExclusiveHandler = this.registerExclusiveHandler.bind(this);
    this.deregisterExclusiveHandler = this.deregisterExclusiveHandler.bind(this);
  }

  componentDidMount() {
    if (typeof document !== 'undefined') {
      document.addEventListener('keydown', this.handleKeyboardEvent, false);
      document.addEventListener('keyup', this.handleKeyboardEvent, false);
      document.addEventListener('keypress', this.handleKeyboardEvent, false);

      const { isExclusive, isDisabled } = this.props;
      if (isExclusive && !isDisabled) {
        this.registerExclusiveHandler();
      }
    }
  }

  componentWillUnmount() {
    if (typeof document !== 'undefined') {
      document.removeEventListener('keydown', this.handleKeyboardEvent, false);
      document.removeEventListener('keyup', this.handleKeyboardEvent, false);
      document.removeEventListener('keypress', this.handleKeyboardEvent, false);
    }

    this.deregisterExclusiveHandler();
  }

  componentDidUpdate(prevProps) {
    const { isExclusive, isDisabled } = prevProps;
    const hasChanged = this.props.isExclusive !== isExclusive ||
      this.props.isDisabled !== isDisabled;

    if (hasChanged) {
      if (this.props.isExclusive && !this.props.isDisabled) {
        this.registerExclusiveHandler();
      } else {
        this.deregisterExclusiveHandler();
      }
    }
  }

  registerExclusiveHandler() {
    this.deregisterExclusiveHandler();
    exclusiveHandlers.unshift(this);
  }

  deregisterExclusiveHandler() {
    if (exclusiveHandlers.includes(this)) {
      exclusiveHandlers = exclusiveHandlers.filter(h => h !== this);
    }
  }

  handleKeyboardEvent(event) {
    const {
      isDisabled, handleKeys, onKeyEvent, handleEventType, children, handleFocusableElements,
    } = this.props;

    if (isDisabled) {
      return false;
    }

    const isEventTypeMatched = handleEventType === event.type;

    if (!isEventTypeMatched) {
      return false;
    }

    const exclusiveHandlerInPlace = exclusiveHandlers.length > 0;
    const isExcluded = exclusiveHandlerInPlace && exclusiveHandlers[0] !== this;

    if (isExcluded) {
      return false;
    }

    const isEligibleEvent = event.target === document.body || handleFocusableElements;
    const isChildrenEvent = this.childrenContainer && this.childrenContainer.contains(event.target);
    const isValidSource = children ? isChildrenEvent : isEligibleEvent;

    if (!isValidSource) {
      return false;
    }

    const matchedKey = findMatchedKey(event, handleKeys);

    if (matchedKey) {
      onKeyEvent(matchedKey, event);
      return true;
    }

    return false;
  }

  render() {
    const { children } = this.props;
    const passProps = Object.assign({}, this.props)
    for (const key of Object.keys(KeyboardEventHandler.myPropTypes || {})) {   // propTypes may be removed in production build
      delete passProps[key]
    }
    return children ? (<span ref={ e => {
        this.childrenContainer = e;
      }} {...passProps}>{children}</span>) : null;
  }
}

KeyboardEventHandler.propTypes = {
  handleKeys: PropTypes.array,
  handleEventType: PropTypes.oneOf(['keydown', 'keyup', 'keypress']),
  handleFocusableElements: PropTypes.bool,
  onKeyEvent: PropTypes.func,
  isDisabled: PropTypes.bool,
  isExclusive: PropTypes.bool,
  children: PropTypes.any,
};

KeyboardEventHandler.myPropTypes = {
  handleKeys: PropTypes.array,
  handleEventType: PropTypes.oneOf(['keydown', 'keyup', 'keypress']),
  handleFocusableElements: PropTypes.bool,
  onKeyEvent: PropTypes.func,
  isDisabled: PropTypes.bool,
  isExclusive: PropTypes.bool,
  children: PropTypes.any,
};

KeyboardEventHandler.defaultProps = {
  handleKeys: [],
  handleFocusableElements: false,
  handleEventType: 'keydown',
  onKeyEvent: () => null,
};