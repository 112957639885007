import React, { useState } from 'react';
import { Badge, Button, Card, ListGroup } from 'react-bootstrap';
import { useCursor, useUpdateCharGridAtLocs, useSlotStructure, PuzzleInteractionStatus, usePuzzleInteractionStatus, useWordSuggestor, useGhostChars, usePuzzlePreferences, PuzzlePreferenceKey } from './BoardInteractionContext';
import './WordSuggestorPanel.css';
import WordSuggestion from './WordSuggestion';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { WORD_SUGGESTION_STATUS } from '../../libs/autofillLib';


/*
wordsMap takes the form of a Map with the following format (or null if no slot is selected).
word: {
  score,            // the numeric score of the word according to the wordlist
  isDisplayed,      // true or false, if the word is currently displayed (a subset may be displayed to avoid rendering issues)
  rankPriority,     // a numeric value that determines the word's placement (within the displayed words subset), with higher values appearing toward the top
  status,           // one of the WORD_SUGGESTION_STATUS values indicating whether this word has been evaluated & whether a fill was found
  possibleFill,     // {locsToChange, newChars, essentialLocs} describing the possible fill if this word was selected, or null if not yet evaluated or nonviable
}
*/


/** A side panel to help constructors by suggesting words that can fill the slot they're looking at. */
export default function WordSuggestorPanel({pxHeight=400, ...props}) {
  pxHeight = Math.max(pxHeight || 250, 250);

  const { wordsMap, displayMoreWords, continuouslyAutofill } = useWordSuggestor();

  const [unfillableWordsExpanded, setUnfillableWordsExpanded] = useState(false);  // whether the displayed words with confirmed no fill are shown
  const [ignoredWordsExpanded, setIgnoredWordsExpanded] = useState(false);        // whether the displayed words that have been ignored due to low scores are shown

  // Get current word slot information (re-calculate at each render)
  const { currentSlotName } = useCursor();
  const slotStructure = useSlotStructure();
  const updateCharGridAtLocs = useUpdateCharGridAtLocs();  // used when a suggestion is clicked to be inserted
  const { puzzleInteractionStatus } = usePuzzleInteractionStatus();
  const { setSuggestedFill } = useGhostChars();
  const { getPuzzlePreference } = usePuzzlePreferences();


  // Mechanism for filling in the suggested fill when it's hovered
  function handleMouseEnter(word) {
    if (continuouslyAutofill && (puzzleInteractionStatus === PuzzleInteractionStatus.EDITABLE_GUEST || puzzleInteractionStatus === PuzzleInteractionStatus.EDITABLE)) {
      if (!/^[a-zA-Z]+$/.test(word)) {
        return;     // ensure it doesn't add any non-alpha characters from the wordlist     // TODO: implement non-alpha character insertion
      }
      if (wordsMap && wordsMap.get(word)) {
        const wordObj = wordsMap.get(word);
        if (wordObj.status === WORD_SUGGESTION_STATUS.FILLABLE) {
          const { locsToChange, newChars, essentialLocs } = wordObj.possibleFill;
          // Add the locs in the slot that aren't already filled in
          const idxsToAdd = slotStructure.getCharsInSlot(currentSlotName).map((c, idx) => c === '' ? idx : '').filter(String);
          setSuggestedFill({
            locsToChange: locsToChange.concat(idxsToAdd.map(i => slotStructure.getLocsInSlot(currentSlotName)[i])),
            newChars: newChars.concat(idxsToAdd.map(i => word.toUpperCase().split('')[i])),
            essentialLocs,
          });
        } else {
          setSuggestedFill(null);    // hovering over a non-evaluated or unfillable word should remove the old suggested fill
        }
      }
    }
  }


  // Mechanism for filling in word when it's clicked
  function handleClickOnWord(word) {
    if (puzzleInteractionStatus === PuzzleInteractionStatus.EDITABLE_GUEST || puzzleInteractionStatus === PuzzleInteractionStatus.EDITABLE) {
      word = word.toUpperCase();
      if (!/^[A-Z]+$/.test(word)) {
        return;     // ensure it doesn't add any non-alpha characters from the wordlist     // TODO: implement non-alpha character insertion
      }

      // Fill in locs with letters
      updateCharGridAtLocs(slotStructure.getLocsInSlot(currentSlotName), word.split(''));
    }
  }


  // A couple of convenience variables to avoid recalculating them a bunch during render
  const unfillableDisplayedWordsMapEntries = wordsMap && Array.from(wordsMap.entries()).filter(([_, obj]) => obj.isDisplayed && obj.status === WORD_SUGGESTION_STATUS.UNFILLABLE);
  const ignoredDisplayedWordsMapEntries = wordsMap && Array.from(wordsMap.entries()).filter(([_, obj]) => obj.isDisplayed && obj.status === WORD_SUGGESTION_STATUS.IGNORED);
  const areThereMoreUndisplayedWords = wordsMap && Array.from(wordsMap.values()).some(o => !o.isDisplayed);
  const currentEvaluatingIndex = wordsMap && Array.from(wordsMap.values())
      .sort((a, b) => b.rankPriority - a.rankPriority)
      .findIndex(obj => obj.isDisplayed && obj.status === WORD_SUGGESTION_STATUS.NOT_EVALUATED);

  return (
    <Card style={{ height: `${pxHeight}px` }} {...props}>
      {!wordsMap ? (
        <div className='fst-italic text-center text-muted p-3 overflow-auto'>
          <p>
            Welcome to Crossworthy Construct!
          </p>
          <p>
            Click on the grid to get started.
            You can insert letters by typing; add squares with the <strong>space bar</strong> or <strong>period</strong> key.
            If you're new to constructing, we recommend starting with a 5x5 grid.
          </p>
          <p>
            Drop us a line with any suggestions or feedback! Happy constructing!
          </p>
        </div>
      ) : wordsMap.size === 0 ? (
        <span className='fst-italic text-center text-muted m-3'>No matching words in wordlist.</span>
      ) : (
        <Card.Body className='p-0 overflow-auto'>
          <ListGroup variant='flush'>
            {Array.from(wordsMap.entries())
                .filter(([_, suggestionObj]) => suggestionObj.isDisplayed && suggestionObj.status !== WORD_SUGGESTION_STATUS.UNFILLABLE && suggestionObj.status !== WORD_SUGGESTION_STATUS.IGNORED)
                .sort((a, b) => b[1].rankPriority - a[1].rankPriority)
                .map(([word, suggestionObj], idx) => (
              <WordSuggestion
                className='wordSuggestion'
                key={'word-' + idx}
                word={word}
                score={suggestionObj.score}
                showIcon={continuouslyAutofill}
                isEvaluating={idx === currentEvaluatingIndex}
                status={suggestionObj.status}
                onClick={() => handleClickOnWord(word)}
                onMouseEnter={() => handleMouseEnter(word)}
              />
            ))}


            {unfillableDisplayedWordsMapEntries && unfillableDisplayedWordsMapEntries.length > 0 && (
              <ListGroup.Item
                className='py-0 d-flex small text-danger'
                role='button'
                style={{backgroundColor: '#ffe0e0'}}
                onClick={() => setUnfillableWordsExpanded(!unfillableWordsExpanded)}
              >
                No Fill
                <Badge className='mx-2 my-auto' bg='danger'>{unfillableDisplayedWordsMapEntries.length}</Badge>
                {unfillableWordsExpanded ? <FaChevronUp className='my-auto' /> : <FaChevronDown className='my-auto' />}
              </ListGroup.Item>
            )}
            {unfillableWordsExpanded && unfillableDisplayedWordsMapEntries && (
              unfillableDisplayedWordsMapEntries.sort((a, b) => b[1].rankPriority - a[1].rankPriority).map(([word, suggestionObj]) => (
                <WordSuggestion
                  className='wordSuggestion small'
                  key={'word-' + word}
                  word={word}
                  score={suggestionObj.score}
                  showIcon={continuouslyAutofill}
                  status={WORD_SUGGESTION_STATUS.UNFILLABLE}
                  onClick={() => handleClickOnWord(word)}
                  onMouseEnter={() => handleMouseEnter(word)}
                />
              ))
            )}

            
            {ignoredDisplayedWordsMapEntries && ignoredDisplayedWordsMapEntries.length > 0 && (
              <ListGroup.Item
                className='py-0 d-flex small text-secondary grey-on-grey'
                role='button'
                onClick={() => setIgnoredWordsExpanded(!ignoredWordsExpanded)}
              >
                Ignored (Score &lt; {getPuzzlePreference(PuzzlePreferenceKey.MINIMUM_WORD_SCORE)})
                <Badge className='mx-2 my-auto' bg='secondary'>{ignoredDisplayedWordsMapEntries.length}</Badge>
                {ignoredWordsExpanded ? <FaChevronUp className='my-auto' /> : <FaChevronDown className='my-auto' />}
              </ListGroup.Item>
            )}
            {ignoredWordsExpanded && ignoredDisplayedWordsMapEntries && (
              ignoredDisplayedWordsMapEntries.sort((a, b) => b[1].rankPriority - a[1].rankPriority).map(([word, suggestionObj]) => (
                <WordSuggestion
                  className='wordSuggestion small'
                  key={'word-' + word}
                  word={word}
                  score={suggestionObj.score}
                  showIcon={continuouslyAutofill}
                  status={WORD_SUGGESTION_STATUS.IGNORED}
                  onClick={() => handleClickOnWord(word)}
                  onMouseEnter={() => handleMouseEnter(word)}
                />
              ))
            )}



            {areThereMoreUndisplayedWords ? (
              <ListGroup.Item className='py-2 d-flex'>
                <Button
                  className='me-2'
                  style={{ minWidth: '6.1rem' }}
                  variant='secondary'
                  size='sm'
                  onClick={() => displayMoreWords()}
                >More words</Button>
                <div className='small text-muted mt-auto ms-auto fst-italic'>Current word list: Spread the Wordlist (default)</div>
              </ListGroup.Item>
            ) : (
              <ListGroup.Item className='py-0 small text-muted mt-auto ms-auto fst-italic'>Current word list: Spread the Wordlist (default)</ListGroup.Item>
            )}
          </ListGroup>
        </Card.Body>
      )}
    </Card>
  );

}
