import React, { useEffect, useRef } from 'react';
import { Card, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { ACROSS, DOWN, otherDirection } from '../../libs/directionsLib';
import { getTop, isEntirelyInside } from '../../libs/miscLib';
import { usePlayCursor, usePlayPuzzleData, usePlayState } from './PlayInteractionContext';
import { removeClueCommands } from '../../libs/puzzleLib';
import './PlayClues.css';




const PlayClues = React.forwardRef(({pxHeight=400, ...props}, ref) => {
  // Calculate heights of the clues panels
  pxHeight = Math.max((pxHeight || 250) - 60, 250);   // subtract some for the header heights
  const dominantBodyPxHeight = Math.round(pxHeight * 0.66);
  const submissiveBodyPxHeight = Math.round(pxHeight * 0.33);

  const { clues, slotStructure } = usePlayPuzzleData();
  const { playStateCharGrid } = usePlayState();
  const { cursorLoc, cursorDirection, handleClickOnLoc, handleCursorDirective } = usePlayCursor();

  const primaryHighlightedSlotName = cursorLoc ? slotStructure.getSlotNameContainingCoordinates(cursorLoc, cursorDirection) : null;
  const secondaryHighlightedSlotName = cursorLoc ? slotStructure.getSlotNameContainingCoordinates(cursorLoc, otherDirection(cursorDirection)) : null;

  // Store refs so we can scroll into view when necessary
  const acrossCluesRef = useRef(null);
  const downCluesRef = useRef(null);
  const clueComponentRefs = Array.from(clues.entries()).reduce((acc, value) => {
    const [slotName, clueText] = value;
    if (!clueText.includes('$hide$')) acc[slotName] = React.createRef();
    return acc;
  }, {});

  // When cursor location changes, make sure the apparent clues are visible by scrolling; if clue is hidden, skip entirely
  useEffect(() => {

    if (primaryHighlightedSlotName && clueComponentRefs[primaryHighlightedSlotName] &&
        clueComponentRefs[primaryHighlightedSlotName].current && acrossCluesRef.current && downCluesRef.current) {
      const target = clueComponentRefs[primaryHighlightedSlotName].current;
      const primaryClueBox = cursorDirection === ACROSS ? acrossCluesRef.current : downCluesRef.current;

      if (!isEntirelyInside(target, primaryClueBox)) {
        primaryClueBox.scrollTo({top: getTop(target) - getTop(primaryClueBox) + primaryClueBox.scrollTop, behavior: 'smooth'});
      }
    }

  }, [clueComponentRefs, primaryHighlightedSlotName, cursorDirection, clues, handleCursorDirective]);
  useEffect(() => {
    // Also scroll the submissive box
    if (secondaryHighlightedSlotName && clueComponentRefs[secondaryHighlightedSlotName] && clueComponentRefs[secondaryHighlightedSlotName].current) {
      const target = clueComponentRefs[secondaryHighlightedSlotName].current;
      const secondaryClueBox = cursorDirection === ACROSS ? downCluesRef.current : acrossCluesRef.current;

      if (!isEntirelyInside(target, secondaryClueBox)) {
        secondaryClueBox.scrollTo({top: getTop(target) - getTop(secondaryClueBox) + secondaryClueBox.scrollTop, behavior: 'smooth'});
      }
    }
  }, [clueComponentRefs, secondaryHighlightedSlotName, cursorDirection]);


  let acrossClueComponents = [];
  let downClueComponents = [];
  for (const [slotName, clueText] of clues.entries()) {
    if (clueText.includes('$hide$')) continue;   // skip/filter out any hidden clues

    const [slotNumber, slotDirection] = slotName.split('-');
    const extraClassName = (slotName === primaryHighlightedSlotName) ? ' primaryHighlighted' : (
      slotName === secondaryHighlightedSlotName ? ' secondaryHighlighted' : ''
    );

    function handleClick() {
      // "Click" on first square of the focused slot, and then advance to the first open slot if it exists
      let [newCursorR, newCursorC] = slotStructure.getLocFromSlotName(slotName);
      let currentR = newCursorR, currentC = newCursorC;

      // Find the first open loc in the word, unless there isn't any open loc
      while (playStateCharGrid[currentR][currentC] !== '') {
        // Attempt to advance one; if it runs into a wall, reset back to the original location
        if (slotDirection === ACROSS) {
          ++currentC;
        } else {
          ++currentR;
        }
        if (currentR >= playStateCharGrid.length || currentC >= playStateCharGrid[0].length || playStateCharGrid[currentR][currentC] === 'blackout') {
          break;
        }
      }
      if (currentR < playStateCharGrid.length && currentC < playStateCharGrid[0].length && playStateCharGrid[currentR][currentC] === '') {
        // Only update the cursor if we found a blank square
        newCursorR = currentR;
        newCursorC = currentC;
      }

      handleClickOnLoc([newCursorR, newCursorC], slotDirection);
    }

    const clueComponent = (
      <ListGroup.Item
        className={'clueRow ' + extraClassName}
        ref={clueComponentRefs[slotName]}
        key={slotName + '-clue'}
        role='button'
        onClick={() => handleClick()}
      >
        <span className='playClueNumberLabel'>{slotNumber}</span>
        <span>{removeClueCommands(clueText, /link:\d+[AD]/)}</span>
      </ListGroup.Item>
    );
    if (slotDirection === ACROSS) {
      acrossClueComponents.push(clueComponent);
    } else if (slotDirection === DOWN) {
      downClueComponents.push(clueComponent);
    }
    
  }



  return (
    <Container className='p-0' ref={ref} {...props}>
      <Row>
        <Col>
          <Card className='playCluesCard'>
            <Card.Header>
              <span className='me-2 block-text'>ACROSS clues</span>
            </Card.Header>

            <Card.Body ref={acrossCluesRef} style={{height: `${cursorDirection === ACROSS ? dominantBodyPxHeight : submissiveBodyPxHeight}px` }}>
              <ListGroup variant='flush'>
                {acrossClueComponents}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className='playCluesCard'>
            <Card.Header>
              <span className='me-2 block-text'>DOWN clues</span>
            </Card.Header>

            <Card.Body ref={downCluesRef} style={{height: `${cursorDirection === DOWN ? dominantBodyPxHeight : submissiveBodyPxHeight}px` }}>
              <ListGroup variant='flush'>
                {downClueComponents}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

});


export default PlayClues;