import React, { useState } from 'react';
import Cell from './Cell';
import { ImPencil2 } from 'react-icons/im';
import { DOWN, ACROSS } from '../../libs/directionsLib';
import { locInList } from '../../libs/blackoutsLib';
import { PuzzleInteractionStatus } from '../construct/BoardInteractionContext';
import 'animate.css'
import { BoardStyle, CellFontStyle, CellStyleName, Color } from '../../libs/formatLib';
import { emptyFurnishingsObject, FurnishingType } from '../../libs/furnishingsLib';


const defaultBoardStyle = BoardStyle.default();

const defaultFurnishings = emptyFurnishingsObject();


const Board = React.forwardRef(({
  charGrid,
  furnishings = defaultFurnishings,
  handleClickOnLoc,
  cursorLoc,
  cursorDirection,
  cursorColor = '#5500c4',
  liveConnectionInfo = null,
  puzzleInteractionStatus = PuzzleInteractionStatus.EDITABLE,
  boardStyle = defaultBoardStyle,
  ghostChars = [],
  borderThickness = 0.3,
  ...props
}, ref) => {

  const [displayedDisplayName, setDisplayedDisplayName] = useState(null);

  if (!charGrid) {
    return null;    // TODO deal with errors better (some placeholder image for broken boards?)
  }

  // If there are any invisibility furnishings, don't put a border around the board (because it likely will look weird)
  if (Object.values(furnishings[FurnishingType.INVISIBILITY] || {}).some(v => v)) {
    borderThickness = 0;
  }

  // Create list of Cells that make up the Board
  const numRows = charGrid.length;
  const numCols = charGrid[0].length;    // TODO what if chargrid is empty? (confirm charGrid is what we expect)

  // Define dimensions of board on screen. Based on a fixed boardWidth.
  const boardWidth = 100;   // as a % of svg viewBox
  const cellSize = boardWidth / numCols;
  const boardHeight = numRows * cellSize;

  // Create a thicker frame to outline the cursor cell. This is drawn after all the cells so that it appears above them.
  const cursorOutline = cursorLoc && (
    <rect
      x={cursorLoc[1] * cellSize * (100-2*borderThickness)/100 + borderThickness}
      y={cursorLoc[0] * cellSize * (100-2*borderThickness)/100 + borderThickness}
      width={cellSize * (100-2*borderThickness)/100}
      height={cellSize * (100-2*borderThickness)/100}
      fill="none"
      stroke={cursorColor}
      strokeWidth="0.7"
    />
  );

  // Create colored, thicker frames to outline collaborators' cursors.
  const collaboratorCursorOutlines = (liveConnectionInfo ? Object.entries(liveConnectionInfo) : [])
    .filter(([_, collaboratorConnectionInfo]) => collaboratorConnectionInfo.cursorLoc !== null)  // filter out null-cursors
    .slice(0, 9)     // take only the first few if there are a ton
    .map(([publicConnectionId, { cursorLoc, cursorColor, displayName }], idx) => (
      <>
        {displayedDisplayName === publicConnectionId && (
          <>
            <text x={cursorLoc[1] * cellSize + 0.5} y={(cursorLoc[0] + 1) * cellSize - 0.5} fontSize={3} stroke='#FFFFFF' strokeOpacity={0.9}>
              {displayName}
            </text>
            <text x={cursorLoc[1] * cellSize + 0.5} y={(cursorLoc[0] + 1) * cellSize - 0.5} fontSize={3} fill={cursorColor}>
              {displayName}
            </text>
          </>
        )}
        <rect
          key={"collabCursor" + idx}
          x={cursorLoc[1] * cellSize * (100-2*borderThickness)/100 + borderThickness}
          y={cursorLoc[0] * cellSize * (100-2*borderThickness)/100 + borderThickness}
          width={cellSize * (100-2*borderThickness)/100}
          height={cellSize * (100-2*borderThickness)/100}
          fill="none"
          stroke={cursorColor}
          strokeWidth="0.5"
          onMouseEnter={() => setDisplayedDisplayName(publicConnectionId)}
          onMouseLeave={() => setDisplayedDisplayName(null)}
        />
      </>
    ));

  // Determine secondary/"tangentially" highlighted cells based on the cursor highlight
  var tangentialHighlightLocs = [];
  if (cursorLoc && charGrid[cursorLoc[0]][cursorLoc[1]] !== 'blackout') {
    // Find first loc in word
    let [firstR, firstC] = cursorLoc;
    if (cursorDirection === DOWN) {
      for ( ; firstR > 0 && charGrid[firstR-1][firstC] !== 'blackout'; --firstR) {}
    } else if (cursorDirection === ACROSS) {
      for ( ; firstC > 0 && charGrid[firstR][firstC-1] !== 'blackout'; --firstC) {}
    }
    // Add to tangentialHighlightLocs
    for (let [r, c] = [firstR, firstC]; r < numRows && c < numCols && charGrid[r][c] !== 'blackout'; ) {
      if (r !== cursorLoc[0] || c !== cursorLoc[1]) {  // only if not the main highlight
        tangentialHighlightLocs.push([r, c]);
      }

      // Increment
      if (cursorDirection === ACROSS) {
        ++c;
      } else if (cursorDirection === DOWN) {
        ++r;
      }
    }
  }


  // Incorporate the ghostChars into the charGrid and boardStyle
  charGrid = charGrid.map(row => row.slice());  // make a copy of charGrid to avoid modifying the parameter object
  if (ghostChars.every(({ loc }) => loc[0] < charGrid.length && loc[1] < charGrid[0].length)) {    // had an index out of bounds when grid size was reduced while ghost chars present
    ghostChars.forEach(({ loc, char, ghostLevel }) => {
      if (charGrid[loc[0]][loc[1]] === '') {
        // If ghostChars clash with real chars for whatever reason, don't overwrite the real chars
        charGrid[loc[0]][loc[1]] = char;
        boardStyle = boardStyle.withAdditionalStyledLocs([loc], ghostLevel === 3 ? CellStyleName.GHOST_3 : ghostLevel === 2 ? CellStyleName.GHOST_2 : CellStyleName.GHOST_1);
      }
    });
  }


  // Define all the cell sub-components
  var cells = [[]];
  var currentCornerNumber = 1;
  for (let r = 0; r < numRows; ++r) {
    cells[r] = []
    for (let c = 0; c < numCols; ++c) {
      // If it's the start of an across or down word, assign it a cornerNumber
      let cornerNumber;
      if (charGrid[r][c] !== 'blackout' && (r === 0 || c === 0 || charGrid[r-1][c] === 'blackout' || charGrid[r][c-1] === 'blackout')) {
        cornerNumber = currentCornerNumber;
        currentCornerNumber++;
      } else {
        cornerNumber = null;
      }

      // Cell style
      const { cellFontStyle, cellFontColor, cellBackgroundColor } = {
        cellFontStyle: CellFontStyle.NONE,
        cellFontColor: Color.BLACK,
        cellBackgroundColor: furnishings?.[FurnishingType.COLOR]?.[JSON.stringify([r,c])] || (charGrid[r][c] === 'blackout' ? Color.BLACK : Color.WHITE),
        ...boardStyle.getStyleForLoc([r, c]),    // overwrite defaults if any are actually specified
      };

      cells[r][c] = <Cell
        key={`cell-${r}-${c}`}
        cellSize={cellSize}
        row={r}
        col={c}
        boardBorderAdjustment={borderThickness}
        cellContent={charGrid[r][c]}
        cornerNumber={cornerNumber}
        isCursorCell={cursorLoc && puzzleInteractionStatus !== PuzzleInteractionStatus.STATUESQUE && cursorLoc[0] === r && cursorLoc[1] === c}
        isSoftHighlighted={puzzleInteractionStatus !== PuzzleInteractionStatus.STATUESQUE && locInList([r, c], tangentialHighlightLocs)}
        respondToHover={puzzleInteractionStatus !== PuzzleInteractionStatus.STATUESQUE}
        cellFontStyle={cellFontStyle}
        cellFontColor={cellFontColor}
        cellBackgroundColor={cellBackgroundColor}
        overlaidShape={furnishings[FurnishingType.SHAPE]?.[JSON.stringify([r,c])]}
        onClick={handleClickOnLoc && puzzleInteractionStatus !== PuzzleInteractionStatus.STATUESQUE ? (() => handleClickOnLoc([r, c])) : null}
      />
    }
  }

  // Remove any invisible cells
  for (const [stringifiedLoc, val] of Object.entries(furnishings[FurnishingType.INVISIBILITY] || {})) {
    if (val) {
      const [r, c] = JSON.parse(stringifiedLoc);
      if (charGrid[r][c] === 'blackout') cells[r][c] = null;
    }
  }


  return (
    <svg ref={ref} viewBox={"0 0 100 " + boardHeight/boardWidth*100.0} className='p-0' {...props}>
      {borderThickness > 0 && <rect
        x={0}
        y={0}
        width={boardWidth}
        height={boardHeight}
        fill='transparent'
        stroke='#000000'
        strokeWidth={borderThickness}
      />}
      {cells}
      {collaboratorCursorOutlines}
      {cursorLoc && cursorOutline}
      {puzzleInteractionStatus === PuzzleInteractionStatus.THINKING && (
        <>
          <rect
            x={0}
            y={0}
            width={boardWidth}
            height={boardHeight}
            fill="#caddf0"
            fillOpacity={0.55}
          />
          <ImPencil2 className="animate__animated animate__swing animate__infinite" x={boardWidth/2-8} y={boardHeight/2-10} size={16} />
        </>
      )}
    </svg>
  )
});

export default Board;