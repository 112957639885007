import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { enableMapSet } from 'immer';
import './index.css';
import { Amplify } from 'aws-amplify';
import config from './config';
import crossworthyRouter from './CrossworthyRouter';

enableMapSet();

/* Set the DEV or PROD stage in the following line. */
const STAGE = "PROD";    // "DEV" or "PROD"

export const stageConfig = STAGE === "PROD" ? config.PROD : config.DEV;
// Warn user if in DEV stage
if (STAGE !== "PROD") {
  alert("Warning! You're using the DEV stage. Switch to PROD in index.js before shipping. Remember to check config.js to ensure all PROD endpoints are properly configured.");
}

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: stageConfig.cognito.APP_CLIENT_ID,
      userPoolId: stageConfig.cognito.USER_POOL_ID,
      identityPoolId: stageConfig.cognito.IDENTITY_POOL_ID,
      allowGuestAccess: true,
    },
  },
  API: {
    REST: {
      'loginTable': {
        endpoint: stageConfig.apiGateway.URLs.loginTable,
        region: stageConfig.apiGateway.REGION,
      },
      'userPuzzles': {
        endpoint: stageConfig.apiGateway.URLs.userPuzzles,
        region: stageConfig.apiGateway.REGION,
      },
      'ticketGenerator': {
        endpoint: stageConfig.apiGateway.URLs.ticketGenerator,
        region: stageConfig.apiGateway.REGION,
      },
      'userSupport': {
        endpoint: stageConfig.apiGateway.URLs.userSupport,
        region: stageConfig.apiGateway.REGION,
      },
      'snakeHighscores': {
        endpoint: stageConfig.apiGateway.URLs.snakeHighscores,
        region: stageConfig.apiGateway.REGION,
      },
    },
  },
});









const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <RouterProvider router={crossworthyRouter} />
  </React.StrictMode>
);
