import React from 'react';
import { FaDelicious } from 'react-icons/fa';

export default function CollectionIcon({ size=30, ...props }) {
  return (
    <div {...props} style={{ flexShrink: 0, position: 'relative', width: size, height: size }}>
      <div style={{ position: 'absolute', bottom: 0, left: 0, width: size*0.7, height: size*0.7, backgroundColor: 'white', scale: '0.8' }} />
      <FaDelicious size={size*0.7} style={{ position: 'absolute', bottom: 0, left: 0 }} />
      {/* <div style={{ position: 'absolute', bottom: size*0.2, left: size*0.12, width: size*0.7, height: size*0.7, rotate: '13deg', backgroundColor: 'white', scale: '0.8' }} />
      <FaDelicious size={size*0.7} style={{ position: 'absolute', bottom: size*0.2, left: size*0.12, rotate: '13deg' }} /> */}
      <div style={{ position: 'absolute', bottom: size*0.23, left: size*0.22, width: size*0.7, height: size*0.7, rotate: '25deg', backgroundColor: 'white', scale: '0.8' }} />
      <FaDelicious size={size*0.7} style={{ position: 'absolute', bottom: size*0.23, left: size*0.22, rotate: '25deg' }} />
    </div>
  );
}
