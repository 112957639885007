import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../../App';
import { Badge, Button, FormControl, Modal } from 'react-bootstrap';
import { get, post } from 'aws-amplify/api';
import { MdOpenInNew } from 'react-icons/md';
import LoadingAnimation from '../../reusable/LoadingAnimation';
import LoaderButton from '../../reusable/LoaderButton';
import { GiWalrusHead } from 'react-icons/gi';
import { HiOutlineSparkles } from 'react-icons/hi';
import CollectionLineItem from '../../collections/CollectionLineItem';
import CollectionIcon from '../../reusable/CollectionIcon';
import { BsFillCheckCircleFill } from 'react-icons/bs';


export default function AddToCollectionModal({ puzzleId, show, onHide }) {

  const { isAuthenticated, showLoginPage } = useAppContext();

  const [publishDate, setPublishDate] = useState();   // Date.now() format

  // Load myCollections
  const [myCollections, setMyCollections] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [manualReloadTrigger, setManualReloadTrigger] = useState(0);
  useEffect(() => {
    if (!isAuthenticated || !show) return;

    async function loadMyCollections() {
      try {
        const res = await (await get({
          apiName: 'userPuzzles',
          path: '/collections'
        }).response).body.json();
        setMyCollections(res);
        setSelectedCollectionId(Object.values(res).filter(o => !Object.keys(o.collectionPuzzles).includes(puzzleId)).sort((a, b) => b.modifiedAt - a.modifiedAt)[0]?.collectionId);
      } catch (e) {
        setMyCollections(null);
        console.log(e);
      }
      setIsLoading(false);
    }

    setIsLoading(true);
    loadMyCollections();
  }, [isAuthenticated, puzzleId, manualReloadTrigger, show]);

  const [selectedCollectionId, setSelectedCollectionId] = useState(undefined);   // the collectionId of the currently-selected collection, or undefined
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isError, setIsError] = useState(false);


  function reset() {
    setIsError(false);
    setIsSubmitting(false);
    setIsSubmitted(false);
    setManualReloadTrigger(manualReloadTrigger + 1);
  }


  return (
    <Modal show={show} onHide={onHide} onShow={() => reset()}>
      <Modal.Header className='purple-2-bkgd' closeButton>
        <strong>Add to collection</strong>
      </Modal.Header>

      <Modal.Body>
        <div>
          <div className='d-flex'>
            <Button
              className='mb-3 ms-auto'
              variant='outline-info'
              size='sm'
              href='/manage-collections'
              target='_blank'
            >
              Create or manage collections
              <MdOpenInNew className='ms-2' />
            </Button>
          </div>
          <div className='mb-3'>
            <div className='d-flex'>
              <span className='my-auto ms-auto me-2'>Publish date</span>
              <FormControl
                className='d-inline me-auto'
                style={{ maxWidth: '16rem' }}
                type='datetime-local'
                size='sm'
                autoFocus
                value={!publishDate ? '' : new Date(publishDate).toLocaleString('sv-SE', {   // https://dev.to/mendyberger/input-and-js-dates-2lhc
                  year: 'numeric', month: '2-digit', day: '2-digit',
                  hour: '2-digit', minute: '2-digit', second: '2-digit',
                }).replace(' ', 'T')}
                onChange={e => {
                  if (!e.target.value) {
                    setPublishDate(null);
                  } else {
                    setPublishDate(new Date(e.target.value).getTime());
                  }
                }}
              />
            </div>
            <div className={'text-muted small fst-italic text-center' + (publishDate ? ' fade-invisible' : '')}>Puzzle will be added to collection but not published yet.</div>
          </div>

          {!isAuthenticated ? (
            <div className='h6 text-center w-100'>
              Please <a href='/account' onClick={e => {
                e.preventDefault();
                showLoginPage();
              }}>log in</a> to see your puzzle collections!
            </div>
          ) : isLoading ? (
            <div className='d-flex'>
              <LoadingAnimation className='mx-auto mb-4' size={12} />
            </div>
          ) : isError ? (
            <div className='w-100 d-flex'>
              <GiWalrusHead className='me-4 my-auto' size={66} />
              <p className='text-center my-auto'>
                We're very sorry - something went wrong communicating with the server.
                If the problem persists, please <a href='/feedback'>let us know</a>.
              </p>
            </div>
          ) : isSubmitted ? (
            <div className='fade-visible'>
              <div className='w-100 d-flex'>
                <div className='ms-auto my-auto me-3' style={{ position: 'relative' }}>
                  <CollectionIcon className='ms-auto my-auto' style={{ position: 'absolute', bottom: '0', left: '0' }} />
                  <BsFillCheckCircleFill className='text-success' size={25} style={{ position: 'absolute', top: '12px', left: '14px' }} />
                </div>
                <p className='text-center me-auto my-auto'>
                  Added successfully!
                </p>
              </div>
              <div className='text-center mt-4'>
                <Button className='ms-auto me-2 mb-2' variant='outline-info' size='sm' href={`/collections/${selectedCollectionId}`} target='_blank'>View collection <MdOpenInNew /></Button>
                <Button className='me-auto mb-2' variant='success' size='sm' onClick={() => reset()}>Add puzzle to another collection</Button>
              </div>
            </div>
          ) : !myCollections ? (
            <div className='w-100 d-flex'>
              <GiWalrusHead className='me-4 my-auto' size={66} />
              <p className='text-center my-auto'>
                We're having trouble retrieving your collections at the moment.
                If the problem persists, please <a href='/feedback'>let us know</a>.
              </p>
            </div>
          ) : Object.keys(myCollections).length === 0 ? (
            <div className='w-100 d-flex'>
              <HiOutlineSparkles className='my-auto me-3 ms-auto' size={27} />
              <p className='text-center my-auto me-auto'>
                Blank slate here! You have no collections to add to yet.
              </p>
            </div>
          ) : (
            <div>
              {Object.values(myCollections).sort((a, b) => b.modifiedAt - a.modifiedAt).map(collectionObj => (
                <CollectionLineItem
                  key={`collection-line-item-${collectionObj.collectionId}`}
                  style={selectedCollectionId && collectionObj.collectionId === selectedCollectionId ? { borderColor: 'blue', borderWidth: '2px', boxShadow: '0 0 4px 0 blue' } : {}}
                  collectionObj={collectionObj}
                  small
                  showChevron={false}
                  hoverable={!Object.keys(collectionObj.collectionPuzzles).includes(puzzleId)}
                  badge={!Object.keys(collectionObj.collectionPuzzles).includes(puzzleId) ? null : (
                    <Badge className='my-auto' bg='secondary'>already in</Badge>
                  )}
                  onClick={() => {
                    if (selectedCollectionId && selectedCollectionId === collectionObj.collectionId) {
                      setSelectedCollectionId(null);
                    } else {
                      if (!Object.keys(collectionObj.collectionPuzzles).includes(puzzleId)) {
                        setSelectedCollectionId(collectionObj.collectionId);
                      }
                    }
                  }}
                />
              ))}
              <div className='mt-3 d-flex'>
                <LoaderButton
                  className='ms-auto'
                  isLoading={isSubmitting}
                  variant='success'
                  disabled={!selectedCollectionId}
                  onClick={async () => {
                    setIsSubmitting(true);
                    try {
                      const { status } = await (await post({
                        apiName: 'userPuzzles',
                        path: '/collections/addCollectionPuzzle',
                        options: {
                          body: {
                            collectionId: selectedCollectionId,
                            newCollectionPuzzle: {
                              puzzleId,
                              publishDate,
                            },
                          },
                        },
                      }).response).body.json();
                      if (!status) throw new Error('Not successfully added');
                      setIsSubmitting(false);
                      setIsSubmitted(true);
                    } catch (e) {
                      console.log(e);
                      setIsError(true);
                      setIsSubmitting(false);
                    }
                  }}
                >
                  {publishDate ? 'Add to collection & set publish date' : 'Add to collection'}
                </LoaderButton>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}