import { locsEqual } from "./blackoutsLib";


export const FurnishingType = {
  COLOR: 'color',    // the color of the square
  SHAPE: 'shape',    // an overlaid shape on the square, mostly used to add circles
  INVISIBILITY: 'invisibility',   // boolean furnishing indicating the square should not be visible/interactable at all (e.g. for simulating non-rectangular grids)
};


export const SHAPE = {
  CIRCLE: 'circle',
};



/**
 * Creates and returns a new, empty furnishings object (in the format used by BoardInteractionContext).
 */
export function emptyFurnishingsObject() {
  return Object.fromEntries(Object.values(FurnishingType).map(ft => [ft, {}]));
}

/**
 * Furnishings can often be gotten from server calls etc., so this class is functionally just a reminder for the template that all Furnishing objects should follow.
 */
class Furnishing {
  constructor(loc, furnishingType, value) {
    this.loc = loc;
    this.furnishingType = furnishingType;
    this.value = value;
  }
}

export function newColorFurnishing(loc, hexColor) {
  return new Furnishing(loc, FurnishingType.COLOR, hexColor);
}

export function newCircleFurnishing(loc) {
  return new Furnishing(loc, FurnishingType.SHAPE, SHAPE.CIRCLE);
}

export function newInvisibilityFurnishing(loc, invisible = true) {
  return new Furnishing(loc, FurnishingType.INVISIBILITY, invisible);   // note: there's no real point to having invisible=false (might as well delete the furnishing)
}


/**
 * Decides whether two furnishing objects { loc, furnishingType, value } are identical.
 * @param {Furnishing} furnishing1 
 * @param {Furnishing} furnishing2 
 * @param {boolean?} requireEqualValues Should equal values be required for equivalency? (Counter-use-cases include if you want to remove any Color furnishing from a loc)
 * @returns {boolean} True iff the two furnishings provide identical information.
 */
export function furnishingsEqual(furnishing1, furnishing2, requireEqualValues = true) {
  return locsEqual(furnishing1.loc, furnishing2.loc) && furnishing1.furnishingType === furnishing2.furnishingType && (!requireEqualValues || furnishing1.value === furnishing2.value);
}

/**
 * Decides if the given furnishing (or an equivalent one) exists in a list of furnishings.
 * @param {Furnishing} furnishing 
 * @param {[Furnishing]} listOfFurnishings 
 * @param {boolean?} requireEqualValues Should equal values be required for furnishing equivalency? (Counter-use-cases include if you want to remove any Color furnishing from a loc)
 * @returns {boolean} True iff the furnishing (or an equivalent furnishing) exists in the listOfFurnishings.
 */
export function furnishingInList(furnishing, listOfFurnishings, requireEqualValues = true) {
  for (const f of listOfFurnishings) {
    if (furnishingsEqual(f, furnishing, requireEqualValues)) return true;
  }
  return false;
}


/**
 * Unpacks a furnishings object (as it's stored in React state or in the database) to a list of Furnishing objects.
 * @param {Object} furnishings furnishings of special furnishing state format
 * @returns {[Furnishing]}
 */
export function furnishingsObjectToList(furnishings) {
  const furnishingsList = [];
  for (let [furnishingType, obj] of Object.entries(furnishings)) {
    for (let [stringifiedLoc, value] of Object.entries(obj)) {
      furnishingsList.push(new Furnishing(JSON.parse(stringifiedLoc), furnishingType, value));
    }
  }
  return furnishingsList;
}