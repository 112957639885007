import React, { useRef, useState } from 'react';
import { del } from 'aws-amplify/api';
import { Button, Popover, OverlayTrigger, Tooltip, ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { userFriendlyDateString } from '../../libs/miscLib';
import { HiOutlineArrowCircleRight, HiOutlineTrash } from 'react-icons/hi';
import { TbZoomInArea } from 'react-icons/tb';
import { FaEllipsisH, FaDelicious } from 'react-icons/fa';
import { IoMdOpen } from 'react-icons/io';
import { useRightClickMenu } from './RightClickMenu';
import Board from '../board/Board';
import { fromPuzzleContentObject } from '../../libs/exportLib';
import { PuzzleInteractionStatus } from '../construct/BoardInteractionContext';
import { BsPeopleFill } from 'react-icons/bs';
import TruckLoadingAnimation from '../reusable/TruckLoadingAnimation';
import { emptyFurnishingsObject } from '../../libs/furnishingsLib';
import { useToastNotifications } from '../../libs/toastLib';

const emptyFurnishings = emptyFurnishingsObject();

export default function PuzzleLineItem({
  puzzle,
  dirPathString,
  disabled,
  onDelete,
  openMovePuzzleModal,
  onClick, // ({puzzleId, modifiedAt, puzzleContent, permissions}) => {}: if not provided, defaults to opening that puzzle; if provided, does not navigate to puzzle but instead does the function
  ...props
}) {
  
  const { puzzleId, modifiedAt, puzzleContent, permissions } = puzzle;
  const { title, size, grid, furnishings=emptyFurnishings } = puzzleContent;
  const percentFilled = grid.filter(s => s !== '').length / (size.rows * size.cols) * 100;
  const percentClued = (
    puzzleContent.clues.across.concat(puzzleContent.clues.down).filter(clue => /^\d+\. ./.test(clue)).length / 
    (puzzleContent.clues.across.length + puzzleContent.clues.down.length) * 100
  );

  const { postErrorNotification } = useToastNotifications();
  const navigate = useNavigate();

  const [isDeleting, setIsDeleting] = useState(false);
  const { onRightClick, rightClickMenu } = useRightClickMenu();
  disabled = disabled || isDeleting;

  const lineItemRef = useRef(null);

  const [isHovering, setIsHovering] = useState(false);


  const previewPopover = (
    <Popover id={'preview-' + puzzleId}>
      <Popover.Header className='py-1' style={{ width: '200px' }}>{title || 'untitled puzzle'}</Popover.Header>
      <Popover.Body>
        <Board charGrid={fromPuzzleContentObject(puzzleContent).charGrid} furnishings={furnishings} puzzleInteractionStatus={PuzzleInteractionStatus.STATUESQUE} />
      </Popover.Body>
    </Popover>
  );


  async function handleDelete() {
    if (window.confirm(`Are you sure you want to permanently delete your puzzle "${title}"? This action cannot be undone.`)) {
      setIsDeleting(true);

      try {
        await del({
          apiName: 'userPuzzles',
          path: `/userPuzzles/${puzzleId}`,
          options: { headers: { 'X-dirpathstring': dirPathString } },
        }).response;    // return value unnecessary; not consumed
        setIsDeleting(false);
        onDelete();     // delete the user puzzle from page without having to refresh
      } catch (e) {
        postErrorNotification(
          'Deletion error',
          'Unable to delete your puzzle! Please let me know if this problem continues.'
        );
        setIsDeleting(false);
      }
    }
  }

  function navigateToPuzzle(newTab) {
    if (newTab) {
      window.open(`/construct/${puzzleId}`);
    } else {
      sessionStorage.setItem('forceReloadPuzzleItem', 'false');
      navigate(`/construct/${puzzleId}`, { puzzleItem: puzzle });
    }
  }

  function handleRightClick(e) {
    if (disabled || isDeleting) return;

    onRightClick({
      parentRef: lineItemRef,
      mouseEvent: e,
      menuItems: [
        { title: 'Open in new tab', icon: <IoMdOpen />, onClick: () => navigateToPuzzle(true) },
        { title: 'Move puzzle...', icon: <HiOutlineArrowCircleRight />, onClick: () => openMovePuzzleModal(puzzleId) },
        { title: 'Delete', icon: <HiOutlineTrash />, onClick: handleDelete, className: 'text-danger' },
      ],
    });
  }

  return (
    <div
      className={'d-flex position-relative' + (isHovering ? ' purple-1-bkgd' : '')}
      ref={lineItemRef}
      role={disabled ? undefined : 'button'}
      onClick={() => {
        if (!disabled) {
          if (onClick) {
            onClick(puzzle);
          } else {
            navigateToPuzzle(false);
          }
        }
      }}
      onContextMenu={handleRightClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      {...props}
    >
      <div
        className='d-flex me-auto px-2 py-1 border-0'
      >
        <div className='text-center my-auto me-3' style={{width: '2.3rem'}}>
          <FaDelicious />
          <div className='small text-muted'>{size.rows}x{size.cols}</div>
        </div>

        <div>
          <div className={title ? '' : 'text-muted'}>
            <span className='me-2'>{title || 'untitled puzzle'}</span>
            {permissions && (permissions.public.edit || permissions.public.view || Object.keys(permissions.users).length > 0) && <BsPeopleFill size={14} />}
          </div>
          <div className='small text-muted'>{userFriendlyDateString(modifiedAt)}</div>
        </div>
      </div>

      <div className='my-auto'>
        <div className='d-flex mx-2'>
          <OverlayTrigger trigger={['hover', 'focus']} placement='left' rootClose overlay={previewPopover}>
            <Button className='rounded border-0 me-2 py-0' variant='outline-secondary' size='sm' onClick={e => e.stopPropagation()}>
              <TbZoomInArea />
            </Button>
          </OverlayTrigger>
          <Button className='rounded border-0' disabled={disabled || isDeleting} variant='outline-secondary' size='sm' onClick={e => { handleRightClick(e); e.stopPropagation(); }} onContextMenu={handleRightClick}>
            <FaEllipsisH />
          </Button>
        </div>
        <OverlayTrigger trigger={['hover', 'focus']} placement='left' overlay={<Tooltip>{Math.round(percentFilled)}% filled<br />{Math.round(percentClued)}% clued</Tooltip>}>
          <div>
            <ProgressBar
              className='mt-1 mx-2'
              now={percentFilled}
              style={{height: '4px'}}
            />
            <ProgressBar
              className='mx-2'
              variant='success'
              now={percentClued}
              style={{height: '4px', marginTop: '2px'}}
            />
          </div>
        </OverlayTrigger>
      </div>
      

      {isDeleting && (
        <div className='d-flex' style={{backgroundColor: 'rgb(202, 175, 222, 0.3)', width: '100%', height: '100%', position: 'absolute', top: '0', left: '0'}}>
          <span className='ms-auto my-auto'>Deleting...</span>
          <TruckLoadingAnimation className='me-auto my-auto' />
        </div>
      )}
      
      {rightClickMenu}

    </div>
  );
}