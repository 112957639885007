import React from 'react';
import { GrDeliver } from 'react-icons/gr';
import { BsThreeDots } from 'react-icons/bs';
import 'animate.css';

export default function TruckLoadingAnimation({size=40, ...props}) {

  return (
    <div {...props}>
      <BsThreeDots size={size*3/4} className='animate__animated animate__infinite animate__rubberBand' />
      <GrDeliver size={size} className='ms-2 animate__animated animate__infinite animate__pulse' />
    </div>
  )
}