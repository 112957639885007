import { useEffect, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { get, post, del } from 'aws-amplify/api';
import { produce } from 'immer';
import { emptyPuzzleContentObject } from '../libs/exportLib';
import { useAppContext } from '../App';
import { useToastNotifications } from './toastLib';



export const SORT_STRATEGY = {
  TITLE: 'title',
  DATE_MODIFIED: 'last edited',
  DATE_CREATED: 'date created',
  SIZE: 'size',
  COMPLETENESS: 'completeness',
};


function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
  case 'setErrorMessage':
    return { ...state, errorMessage: payload };
  case 'setDirId':
    return { ...state, dirId: payload };
  case 'setMyConstructions':
    return { ...state, myConstructions: payload };
  case 'setNewDir':
    return { ...state, newDir: payload };
  case 'setNewPuzzle':
    return { ...state, newPuzzle: payload };
  case 'setSortStrategy':
    return { ...state, sortStrategy: payload };
  default:
    console.log(`Warning: unknown message ${type} to reducer in traversalLib.js. Ignoring.`);
  }
}

const INITIAL_STATE = {
  errorMessage: null,
  dirId: '',
  myConstructions: null,      // top-level folder structure & puzzle data of the user's entire "My Constructions" library
  newDir: null,
  newPuzzle: null,
  sortStrategy: SORT_STRATEGY.TITLE,
};



/**
 * Hook in the logic for loading and traversing MyConstructions.
 * I utilize a reducer instead of various states for no particular reason.
 * Each invocation of useTraversal is associated with one independent instantiation (from the server) of the MyConstructions directory structure.
 * This is useful for independent modals such as move-puzzle or save-as features, in addition to the main MyConstructions page.
 */
export function useTraversal({
  modalNavigation = false,      // if true, navigation across directories will not affect things like the URL and history
} = {}) {

  const { isAuthenticated } = useAppContext();
  const { postErrorNotification } = useToastNotifications();

  
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { errorMessage, dirId, myConstructions, newDir, newPuzzle, sortStrategy } = state;


  /*
  Code to get the dir path / my constructions from the URL.
  The idea is to make a currentDirPath "state" that basically calculates
  the dir path "state" (e.g. ['subdirId1', 'subdirId2']) based on the URL,
  and provides a navigateToDir function that with push the next appropriate URL once the "state" changes.
  */
  const { dirId: urlDirId } = useParams();
  const navigate = useNavigate();
  // Update the dirId every time the URL dir ID changes [unless in a modal]
  useEffect(() => {
    if (!modalNavigation) {
      dispatch({ type: 'setDirId', payload: urlDirId });
    }
  }, [urlDirId, modalNavigation]);




  // Build currentDirPath based on structure in myConstructions and dirId - need to traverse myConstructions until dirId is found
  var currentDirPath = [];   // may be null if dirId isn't found anywhere in myConstructions
  if (myConstructions && dirId) {
    const matchingDirPathOfSubdirectory = (currentPath) => {
      // This function is going to traverse the subdirectory until it finds the matching dir path, or return null
      var subdirs = myConstructions;
      for (let sd of currentPath) subdirs = subdirs.subdirectories[sd];
      subdirs = Object.keys(subdirs.subdirectories);

      for (let sd of subdirs) {
        if (sd === dirId) return currentPath.concat([sd]);  // check if any immediate subdirs match desired dirId
        const matchingDirPath = matchingDirPathOfSubdirectory(currentPath.concat([sd]));  // otherwise, traverse each subdirectory...
        if (matchingDirPath) return matchingDirPath;  // ...and return it if the matching path is found
      }

      return null;   // exit case - there are no subdirectories, or the matching path wasn't found
    }

    const matchingDirPath = matchingDirPathOfSubdirectory([]);
    if (matchingDirPath) {
      currentDirPath.push(...matchingDirPath);
    } else {
      console.log('Folder not found in directory tree.');
      currentDirPath = null;
    }
  }

  /**
   * Pushes the component to a new URL, which initiates a traversal of myConstructions to find the given dir.
   * @param {String?} newDirId the dirId of the new dir, or null for the root dir (myConstructions).
   */
  function navigateToDir(newDirId, newTab=false) {
    if (modalNavigation) {
      dispatch({ type: 'setDirId', payload: newDirId });
    } else {
      // Push to a new URL
      const newURL = newDirId ? `/construct/f/${newDirId}` : '/construct/f';
      if (newTab) {
        window.open(newURL);
      } else {
        navigate(newURL);
      }
    }
  }


  useEffect(() => {
    if (isAuthenticated) {
      dispatch({ type: 'setErrorMessage', payload: null });
    } else {
      dispatch({ type: 'setErrorMessage', payload: 'Please log in to view your saved masterpieces!' });
    }
  }, [isAuthenticated]);

  // Finally, a useEffect to load myConstructions if it's null
  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!myConstructions && !errorMessage) {
      async function loadMyConstructions() {
        try {
          const res = await (await get({
            apiName: 'userPuzzles',
            path: '/userPuzzles'
          }).response).body.json();
          dispatch({ type: 'setMyConstructions', payload: res });
          dispatch({ type: 'setErrorMessage', payload: null });
        } catch (e) {
          dispatch({ type: 'setErrorMessage', payload: e.toString() });
        }
      }

      loadMyConstructions();
    }
  }, [isAuthenticated, errorMessage, myConstructions]);



  // Helper - also provide a passthrough "state" that wraps the myConstructions state, but exposes the current subdirectory, to remove repeated directory navigation.
  // This "pseudo-state" may be used to bypass the navigation logic and directly access or modify descendents of myConstructions.
  var currentDirStructure = currentDirPath && myConstructions;   // null if currentDirPath is null
  if (currentDirPath) {
    for (let subdir of currentDirPath) currentDirStructure = currentDirStructure.subdirectories[subdir];
  }
  const setCurrentDirStructure = (newCurrentDirStructure) => {
    if (!currentDirPath) {
      return;
    } else if (currentDirPath.length === 0) {
      dispatch({ type: 'setMyConstructions', payload: newCurrentDirStructure });
    } else {
      dispatch({ type: 'setMyConstructions', payload: produce(myConstructions, draft => {
        // Navigate to current directory
        var draftRef = draft;
        for (let subdir of currentDirPath) draftRef = draftRef.subdirectories[subdir];
        
        // Delete all the old properties
        for (let key in draftRef) {
          if (draftRef.hasOwnProperty(key)) {
            delete draftRef[key];
          }
        }

        // Assign new properties
        Object.assign(draftRef, newCurrentDirStructure);
      }) });
    }
  }





  // Callbacks

  /**
   * Returns the info required to render the Breadcrumbs component at the top of the Browse screen.
   * @returns [{onClick, dirName, active}]
   */
  function getBreadcrumbData() {
    if (!myConstructions || !currentDirPath) return [];   // this may occur in the short space while myConstructions is reloading

    const breadcrumbData = [{
      onClick: () => navigateToDir(null),
      dirName: myConstructions.dirName,
      active: currentDirPath.length === 0,
    }];
    let dir = myConstructions;
    for (let i = 0; i < currentDirPath.length; ++i) {
      dir = dir.subdirectories[currentDirPath[i]];
      breadcrumbData.push({
        onClick: () => navigateToDir(currentDirPath[i]),
        dirName: dir.dirName,
        active: i === currentDirPath.length - 1,
      });
    }
    return breadcrumbData;
  }

  async function onCreateNewDir() {
    if (!currentDirPath) return;
    // Called after the user hits "Enter" on their new directory name

    // Set new dir status to loading while this happens
    dispatch({ type: 'setNewDir', payload: produce(newDir, draft => { draft.status = 'LOADING' }) });

    try {
      const returnedVal = await (await post({
        apiName: 'userPuzzles',
        path: '/folderStructure',
        options: {
          body: {
            action: 'ADD_SUBDIRECTORY',
            payload: newDir,
          },
          headers: {
            'x-dirpathstring': currentDirPath.join('/'),
          },
        },
      }).response).body.json();

      // Extract the new subdir ID and contents, and add it into the current directory
      // Need this step to convert puzzleIds to puzzles
      const [newSubdirId, newSubdirStructure] = Object.entries(returnedVal)[0];
      delete newSubdirStructure.puzzleIds;
      newSubdirStructure.puzzles = { };
      setCurrentDirStructure(produce(currentDirStructure, draft => {
        draft.subdirectories[newSubdirId] = newSubdirStructure;
      }));

      dispatch({ type: 'setNewDir', payload: null });
    } catch (e) {
      postErrorNotification('Database error', 'Could not create subfolder here. Please let me know if this error continues!');
    }
  }

  async function onRenameDir(dirId, newName, onReturn) {
    if (!currentDirPath) return;
    // Called after the user hits "Enter" on their new directory name, while renaming
    try {
      const { success } = await (await post({
        apiName: 'userPuzzles',
        path: '/folderStructure',
        options: {
          body: {
            action: 'RENAME',
            payload: { dirName: newName },
          },
          headers: {
            'x-dirpathstring': currentDirPath.concat([dirId]).join('/'),
          },
        },
      }).response).body.json();
      // Also set in local memory
      setCurrentDirStructure(produce(currentDirStructure, draft => {
        draft.subdirectories[dirId].dirName = newName;
      }));

      onReturn();
      return success;
    } catch (e) {
      postErrorNotification('Database error', 'Could not rename folder at this time. Please let me know if this error persists.');
      onReturn();
      return false;
    }
  }

  async function onDeleteDir(dirId, {onSuccess, onFailure}) {
    if (!currentDirPath) return;
    // Called when the user presses the delete button
    try {
      await del({
        apiName: 'userPuzzles',
        path: '/folderStructure',
        options: {
          headers: {
            'x-dirpathstring': currentDirPath.concat([dirId]).join('/'),
          },
        },
      }).response;    // return value unnecessary; not consumed
      // Also set in local memory
      setCurrentDirStructure(produce(currentDirStructure, draft => {
        delete draft.subdirectories[dirId];
      }));

      if (onSuccess) onSuccess();
      return true;
    } catch (e) {
      console.log('Failed to delete folder structure at ' + currentDirPath.concat([dirId]).join('/'));
      if (onFailure) onFailure();
      return false;
    }
  }

  async function onCreateNewPuzzle() {
    if (!currentDirPath) return;
    // Called after the user hits "Enter" on their new puzzle name

    dispatch({ type: 'setNewPuzzle', payload: produce(newPuzzle, draft => { draft.status = 'LOADING' }) });

    try {
      const returnedPuzzle = await (await post({
        apiName: 'userPuzzles',
        path: '/userPuzzles',
        options: {
          body: {
            puzzleContent: emptyPuzzleContentObject({ numRows: newPuzzle.numRows || 15, numCols: newPuzzle.numCols || 15, title: newPuzzle.puzzleName }),
          },
          headers: {
            'x-dirpathstring': currentDirPath.join('/'),
          },
        },
      }).response).body.json();

      // Add into the current dir structure
      setCurrentDirStructure(produce(currentDirStructure, draft => {
        draft.puzzles[returnedPuzzle.puzzleId] = returnedPuzzle;
      }));

      if (newPuzzle.newTab) {
        window.open(`/construct/${returnedPuzzle.puzzleId}`);
      } else {
        sessionStorage.setItem('forceReloadPuzzleItem', 'false');
        navigate(`/construct/${returnedPuzzle.puzzleId}`, { puzzleItem: returnedPuzzle });
      }
      dispatch({ type: 'setNewPuzzle', payload: null });
    } catch (e) {
      console.log(e);
      dispatch({ type: 'setNewPuzzle', payload: null });
      postErrorNotification('Couldn\'t create puzzle :(', 'If this error continues, please let me know!');
    }
  }

  function removePuzzle(puzzleId) {
    if (!currentDirPath) return;
    // Called AFTER puzzle has been deleted successfully from server database; just to remove the one in local memory
    // puzzleId is assumed to be in the current working (sub)directory
    setCurrentDirStructure(produce(currentDirStructure, draft => {
      delete draft.puzzles[puzzleId];
    }));
  }


  const callbacks = {
    navigateToDir,
    getBreadcrumbData,

    newDir,
    setNewDir: payload => dispatch({ type: 'setNewDir', payload }),
    onCreateNewDir,
    onCancelNewDir: () => dispatch({ type: 'setNewDir', payload: null }),
    newPuzzle,
    setNewPuzzle: payload => dispatch({ type: 'setNewPuzzle', payload }),
    onCreateNewPuzzle,
    onCancelNewPuzzle: () => dispatch({ type: 'setNewPuzzle', payload: null }),

    onRenameDir,
    onDeleteDir,
    removePuzzle,
    sortStrategy,
    setSortStrategy: payload => dispatch({ type: 'setSortStrategy', payload }),

    refreshDataFromServer: () => dispatch({ type: 'setMyConstructions', payload: null }),
  };




  return {
    errorMessage, myConstructions,
    currentDirPath,
    currentDirStructure,
    callbacks,
  };

}