/*
General-purpose error catching class for the app. 
Based on the docs at https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
*/

import React from "react";
import { GiSadCrab } from "react-icons/gi";
import Feedback from "./containers/Feedback";
import { post } from 'aws-amplify/api';


function ErrorPage() {
  return (
    <div className='mt-4'>
      <div className='d-flex'>
        <div className='mx-auto position-relative' style={{ width: '50px', height: '74px' }}>
          <img src='/android-chrome-192x192.png' alt='crossworthy-eaten-by-crab' width={40} style={{ position: 'absolute', top: '0px', left: '-4px', transform: 'rotate(-35deg)' }} />
          <GiSadCrab className='zoomable' size={50} style={{ position: 'absolute', bottom: '2px', right: '4px', color: '#8B0000' }} />
        </div>
      </div>

      <p className='mx-auto text-center not-too-wide'>Ahh! We are so sorry, it looks like something unexpected went wrong. Refresh the page to get back to where you were.</p>
      <p className='mx-auto text-center not-too-wide'>If you're able to give us any context into what happened, we may be able to debug it more quickly!</p>


      <Feedback formOnly />
    </div>
  );
}


export default class ErrorCatcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, info) {

    async function postErrorAsFeedback() {
      await post({
        apiName: 'userSupport',
        path: '/feedback',
        options: {
          body: {
            email: '',
            name: '',
            content: `Note: encountered a front-end bug!
              Error:
              ${JSON.stringify(error)}
              Info:
              ${JSON.stringify(info)}
            `,
          },
        },
      }).response;
    }

    try {
      postErrorAsFeedback();
    } catch (e) {
      console.log('Ha, there was an error submitting this error log.');
      console.log(e);
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}