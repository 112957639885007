import React from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';



export default function SwitchButton({
  onCheck,
  onUncheck,
  isOn=true,
  variant="outline-primary",
  onText="On",
  offText="Off",
  disabled=false,
  ...props
}) {

  return (
    <ButtonGroup toggle {...props}>
      <ToggleButton
        className='pt-0 pb-0'
        type="radio"
        size="sm"
        variant={variant}
        value={true}
        checked={isOn}
        onChange={() => {
          if (!isOn && !disabled) {
            if (onCheck) {
              onCheck();
            }
          }
        }}
      >
        {onText}
      </ToggleButton>

      <ToggleButton
        className='pt-0 pb-0'
        type="radio"
        size="sm"
        variant={variant}
        value={false}
        checked={!isOn}
        onChange={() => {
          if (isOn && !disabled) {
            if (onUncheck) {
              onUncheck();
            }
          }
        }}
      >
        {offText}
      </ToggleButton>
    </ButtonGroup>
  )
}