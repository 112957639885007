import { get, post } from 'aws-amplify/api';



const LOCAL_STORAGE_PREFIX = 'PLAYSESSION:';   // how to identify localStorage keys that correspond to plays

export function submitToLocalStorage(puzzleId, playSession) {
  if (playSession.playEvents.length === 0) return;
  const key = `${LOCAL_STORAGE_PREFIX}${puzzleId}`;
  try {
    if (!localStorage.getItem(key) && Object.keys(localStorage).filter(k => k.startsWith(LOCAL_STORAGE_PREFIX)).length > 30) {
      // If this is a new play session (first time saving) and there's a bunch already from other puzzles, first remove older playSessions so it doesn't get too full
      for (const e of Object.entries(localStorage).filter(([k, _]) => k.startsWith(LOCAL_STORAGE_PREFIX)).sort((a, b) => JSON.parse(a[1]).startTime - JSON.parse(b[1]).startTime).slice(0, 10)) {
        localStorage.removeItem(e[0]);
      }
    }

    localStorage.setItem(key, JSON.stringify(playSession));
  } catch (e) {
    console.warn('Failed to set playState to localStorage');
    console.log(e);
  }
}

export function retrieveFromLocalStorage(puzzleId) {
  const key = `${LOCAL_STORAGE_PREFIX}${puzzleId}`;
  try {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key));
    }
  } catch (e) {
    console.warn('Failed to retrieve from localStorage, even though key exists');
  }
  return null;
}


export async function submitToServer(puzzleId, playSession) {
  if (!puzzleId || !playSession) {
    console.warn('Attempted to submit playSession to server with invalid params, doing nothing');
    return;
  }

  if (playSession.playEvents.length === 0) return;

  try {
    await post({
      apiName: 'userPuzzles',
      path: `/playSessions/${puzzleId}`,
      options: {
        body: {
          playSession,
        },
      },
    }).response;
  } catch (e) {
    console.warn('Server error on submitting playSession to database.');
    console.log(e);
  }
}

export async function retrieveFromServer(puzzleId, onSuccess, onFailure) {
  if (!puzzleId) {
    console.warn('Attempted to retrieve playSession from server with invalid puzzleId, returning null');
    return null;
  }

  try {
    const res = await (await get({
      apiName: 'userPuzzles',
      path: `/playSessions/${puzzleId}`,
    }).response).body.json();
    if (onSuccess) onSuccess(res);
  } catch (e) {
    if (onFailure) onFailure();
  }
}




/**
 * Gets all the play sessions from the database and then executes a callback.
 * @param {string} puzzleId 
 * @param {(object) => {}} onSuccess 
 * @param {() => {}} onFailure 
 */
export async function getAllPlaySessions(puzzleId, onSuccess, onFailure) {
  if (!puzzleId) {
    console.warn('Attempted to retrieve all playSessions with invalid puzzleId, returning null');
    return null;
  }

  try {
    const aps = await (await get({
      apiName: 'userPuzzles',
      path: `/allPlaySessions/${puzzleId}`
    }).response).body.json();
    if (onSuccess) onSuccess(aps);
  } catch (e) {
    if (onFailure) onFailure();
  }
}



