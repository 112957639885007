import React, { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { FaArrowDownLong, FaDelicious } from 'react-icons/fa6';
import { useAppContext } from '../App';
import splashVid from '../assets/splash-vid.mp4';
import { MdOutlineDesignServices, MdOutlineWavingHand } from 'react-icons/md';
import { FaTools } from 'react-icons/fa';
import pinkBlob from '../assets/pink-blob.png';
import blueBlob from '../assets/blue-blob.png';
import greenBlob from '../assets/green-blob.png';
import yellowBlob from '../assets/yellow-blob.png';



/**
 * Returns true iff the whole of the object, minus the number of gracePixels, is visible within the Y-scroll of the current window.
 * @param {*} ref The ref object OR the ref.current object
 * @param {*} gracePixels The amount of pixels that are permitted to be outside the screen while still returning true (may be negative if you want a buffer around the component))
 * @returns 
 */
function isInView(ref, gracePixels = 0) {
  const { top, bottom } = ref?.current?.getBoundingClientRect() || ref?.getBoundingClientRect() || {};
  return top + gracePixels >= 0 && bottom - gracePixels <= window.innerHeight;
}



const SplashHighlight = React.forwardRef(({ src, text, top, right, left, isVisible }, ref) => {
  return (
    <div className={(isVisible ? 'fade-visible-slow' : 'fade-invisible-slow') + ' zoomable'} ref={ref} style={{
      position: 'absolute',
      maxWidth: '130px',
      aspectRatio: 1,
      top,
      right,
      left,
      backgroundImage: `url(${src})`,
      backgroundSize: '100% 100%',
    }}>
      <div className='block-text d-flex w-100 h-100' style={{ zIndex: 130 }}><span className='m-auto p-2 text-center'>{text}</span></div>
    </div>
  )
})



export default function Splash() {

  const navigate = useNavigate();
  const { isMobile, setBrandIsSquished, isAuthenticated } = useAppContext();

  const videoRef = useRef(null);

  const videoDescriptionRowRef = useRef(null);
  const [videoDescriptionShowing, setVideoDescriptionShowing] = useState(false);
  const [lowerIconShowing, setLowerIconShowing] = useState(false);
  
  const highlightRefs = useRef([]);
  const [highlightShowing, setHighlightShowing] = useState(new Array(4).fill(false));

  const buttonsRef = useRef(null);

  // Handle scroll
  useEffect(() => {
    const onScroll = () => {
      // Callback to set brand is squished in App component
      setBrandIsSquished(!isInView(buttonsRef));

      // Handle video and video description
      setLowerIconShowing(window.scrollY > 30);
      const videoIsInView = isInView(videoRef, 20);
      setVideoDescriptionShowing(videoIsInView);
      if (videoIsInView && videoRef.current.paused && !isMobile) {
        videoRef.current.play();
      } else if (!videoIsInView && !videoRef.current.paused) {
        videoRef.current.pause();
      }

      // Handle the highlights around the video
      setHighlightShowing(highlightRefs.current.map(ref => window.scrollY > 0 && isInView(ref, -20)));
    }
    window.removeEventListener('scroll', onScroll);   // clean up previous one if there is one
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [setBrandIsSquished, isMobile]);


  return (
    <Container fluid style={{
      /* graph paper background */
      backgroundColor: 'rgb(236, 236, 236)',
      backgroundImage: 'linear-gradient(90deg, rgb(225, 225, 225) 1px, transparent 1px), linear-gradient(rgb(225, 225, 225) 1px, transparent 1px)',
      backgroundSize: '20px 20px',
    }}>

      <Row className='d-flex' style={{ height: '50vh' }}>
        <div className='mt-auto mx-auto text-center'>
          <div className='block-text fw-bold' style={{ fontSize: isMobile ? 48 : 64 }}>Crossworthy</div>
          <div className='fst-italic block-text h5 balanced-text'>a free crossword construction and solving platform</div>
        </div>
      </Row>

      {isMobile ? (
        <Row className='mt-2 d-flex flex-column gap-2 align-items-center' style={{ height: '30vh' }} ref={buttonsRef}>
          <div
            className='d-flex justify-content-center teal-2-bkgd p-2 text-center mb-3 zoomable clickable'
            style={{ borderRadius: 30, width: 180, fontSize: 20 }}
            onClick={() => navigate('/collections/crossworthy')}
          ><FaDelicious className='my-auto' /><span className='ms-3'>Play</span></div>

          <div
            className='d-flex justify-content-center purple-2-bkgd p-2 text-center zoomable clickable'
            style={{ borderRadius: 30, width: 180, fontSize: 20 }}
            onClick={() => navigate(isAuthenticated ? '/construct/f' : '/construct/scratch')}
          ><FaTools className='my-auto' /><span className='ms-3'>Construct</span></div>
        </Row>
      ) : (
        <Row className='mt-2 d-flex gap-4 justify-content-center' style={{ height: '30vh' }} ref={buttonsRef}>
          <div
            className='d-flex justify-content-center purple-2-bkgd p-2 text-center mb-auto zoomable clickable'
            style={{ borderRadius: 30, width: 180, fontSize: 20 }}
            onClick={() => navigate(isAuthenticated ? '/construct/f' : '/construct/scratch')}
          ><FaTools className='my-auto' /><span className='ms-3'>Construct</span></div>

          <div
            className='d-flex justify-content-center teal-2-bkgd p-2 text-center mb-auto zoomable clickable'
            style={{ borderRadius: 30, width: 180, fontSize: 20 }}
            onClick={() => navigate('/collections/crossworthy')}
          ><FaDelicious className='my-auto' /><span className='ms-3'>Play</span></div>
        </Row>
      )}

      <Row className='d-flex justify-content-center' ref={videoDescriptionRowRef} style={{ height: '68px' }}>
        <div
          className={'h-100 h4 zoomable clickable d-flex align-items-center justify-content-center ' + (videoDescriptionShowing ? 'fade-invisible-slow' : 'fade-visible')}
          style={{ opacity: '70%' }}
          onClick={() => {
            videoRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }}
        >
          Learn more
          <FaArrowDownLong className='ms-3 purple-3' size={30} />
        </div>

        <div
          className={'h-100 d-flex justify-content-center ' + (videoDescriptionShowing ? 'fade-visible-slow' : 'fade-invisible')}
          style={{ marginTop: '-68px' }}
        >
          <span className='block-text fst-italic not-obscenely-wide text-center balanced-text'>
            {isMobile ? (
              <>
                Create, share, and play crossword puzzles for free!
              </>
            ) : (
              <>
                <strong>Crossworthy</strong> lets you create, share, and play your own crossword puzzles!
                It's beginner-friendly, powerful, and all free.
              </>
            )}
          </span>
        </div>
      </Row>
      <Row className='d-flex justify-content-center'>
        <div className='d-flex justify-content-center position-relative' style={{ maxWidth: '1500px', height: (videoRef.current?.clientHeight || 500) }}>
          <div style={{ position: 'absolute', width: isMobile ? '100%' : '70%', maxWidth: '1000px' }}>
            <video ref={videoRef} muted style={{ borderRadius: '40px', width: '100%' }}>
              <source src={splashVid} type='video/mp4' />
            </video>
            <img
              className={lowerIconShowing ? 'fade-visible' : 'fade-invisible'}
              style={{ position: 'absolute', zIndex: 210, top: -30, left: -30 }}
              width={81}
              src={pinkBlob}
              alt='pink-blob'
            />
            <img
              className={lowerIconShowing ? 'fade-visible' : 'fade-invisible'}
              style={{ position: 'absolute', zIndex: 211, top: -18, left: -18 }}
              width={58}
              src='/android-chrome-256x256.png'
              alt='Crossworthy'
            />
          </div>
          
          {!isMobile && <>
            <SplashHighlight ref={e => highlightRefs.current[0] = e} top='25%' right='87%' isVisible={highlightShowing[0]} text='collaborate live!' src={greenBlob} />
            <SplashHighlight ref={e => highlightRefs.current[1] = e} top='65%' right='86%' isVisible={highlightShowing[1]} text='autofill!' src={yellowBlob} />
            <SplashHighlight ref={e => highlightRefs.current[2] = e} top='12%' left='87.5%' isVisible={highlightShowing[2]} text='save & solve in browser!' src={yellowBlob} />
            <SplashHighlight ref={e => highlightRefs.current[3] = e} top='51%' left='86%' isVisible={highlightShowing[3]} text='publish collections!' src={blueBlob} />
          </>}
        </div>
      </Row>

      <Row className='d-flex flex-column align-items-center mt-5'>
        {isMobile ? (
          <>
            <div
              className='d-flex align-items-center justify-content-center purple-2-bkgd zoomable clickable mb-2'
              style={{ borderRadius: 14, width: 280, height: 44, fontSize: 20 }}
              onClick={() => navigate(isAuthenticated ? '/construct/f' : '/construct/scratch')}
            >Make a puzzle</div>
            <div className='text-center balanced-text fst-italic small'>
              Please note that Crossworthy Construct is friendliest on larger screens.
            </div>
          </>
        ) : (
          <div style={{ maxWidth: '1500px' }}>
            <div
              className='d-flex align-items-center justify-content-center purple-2-bkgd zoomable clickable'
              style={{ borderRadius: 14, width: 320, height: 55, fontSize: 24, marginLeft: '55%' }}
              onClick={() => navigate(isAuthenticated ? '/construct/f' : '/construct/scratch')}
            >Make a puzzle</div>
          </div>
        )}
      </Row>

      <Row className='mt-5 d-flex justify-content-center' style={{ height: isMobile ? '50vh' : '70vh' }}>
        {isMobile ? (
          <div className='block-text text-center d-flex flex-column justify-content-center align-items-middle'>
            <div className='balanced-text mx-auto'>
              Solve our puzzles here, too!
            </div>
            <div
              className='d-flex mx-auto align-items-center justify-content-center teal-2-bkgd zoomable clickable'
              style={{ borderRadius: 14, width: 280, height: 44, fontSize: 20 }}
              onClick={() => navigate('/collections/crossworthy')}
            >Play</div>
          </div>
        ) : (
          <div className='not-obscenely-wide d-flex'>
            <div className='d-flex flex-column justify-content-center'>
              <div className='ms-auto me-4 h2 block-text text-end'>Want to play our puzzles?</div>
              <div className='block-text fst-italic balanced-text ms-auto me-4 text-end'>
                We publish new crosswords that we make here, for anyone to play!
              </div>
            </div>
            <div className='d-flex flex-column justify-content-center'>
              <div
                className='d-flex mx-auto align-items-center justify-content-center teal-2-bkgd zoomable clickable'
                style={{ borderRadius: 14, width: 320, height: 55, fontSize: 24 }}
                onClick={() => navigate('/collections/crossworthy')}
              >Solve our puzzles</div>
            </div>
          </div>
        )}
      </Row>


      <Row className='pb-3 d-flex justify-content-center'>
        <Button
          className='mt-2 zoomable d-flex justify-content-center'
          variant='outline-secondary'
          style={{ borderRadius: 12, width: 150, fontSize: isMobile ? 14 : 16 }}
          onClick={() => navigate('/feedback')}
        ><MdOutlineWavingHand className='me-2 my-auto' /> Get in touch</Button>

        <Button
          className='ms-4 mt-2 zoomable d-flex justify-content-center'
          variant='outline-secondary'
          style={{ borderRadius: 12, width: 150, fontSize: isMobile ? 14 : 16 }}
          onClick={() => navigate('/about')}
        ><MdOutlineDesignServices className='me-2 my-auto' /> About us</Button>
      </Row>

    </Container>
  )
}