import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Account from './containers/Account';
import { IoOpenOutline } from 'react-icons/io5';
import { SPACES } from './libs/miscLib';
import { useToastNotifications } from './libs/toastLib';
import { useNavigate } from 'react-router-dom';

export default function AccountModal({ show, onHide, currentSpace }) {

  const navigate = useNavigate();
  const { removeNotificationsWithLabels } = useToastNotifications();

  useEffect(() => {
    if (show) {
      removeNotificationsWithLabels('request-login');
    }
  }, [show, removeNotificationsWithLabels]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header className='grey-1-bkgd' closeButton>
        <strong>Account</strong>
      </Modal.Header>
      <Modal.Body className='text-center'>
        <Account className='mb-4' />

        <hr />

        <Button
          variant='link'
          href='/about'
          onClick={e => {
            e.preventDefault();
            window.open('/about', '_blank');
          }}
        ><IoOpenOutline /> About us</Button><br />
        <Button
          variant='link'
          href='/feedback'
          onClick={e => {
            e.preventDefault();
            window.open('/feedback', '_blank');
          }}
        ><IoOpenOutline /> Feedback or questions?</Button>

        {currentSpace !== SPACES.SPLASH && (<div
          className='mx-auto mt-2 zoomable'
          role='button'
          onClick={() => {
            navigate('/');
            onHide();
          }}
          style={{ position: 'relative', width: '210px', height: '50px'}}
        >
          <div
            className={currentSpace === SPACES.PLAY ? 'teal-3-bkgd' : 'purple-3-bkgd'}
            style={{ position: 'absolute', top: '0px', left: '0px', height: '50px', width: '50px', borderRadius: '50%', boxShadow: '0 0 5px #301934' }}
          />
          <img
            className='pr-2 zoomable'
            style={{ position: 'absolute', top: '6px', left: '6px' }}
            width='38px'
            src='/android-chrome-256x256.png'
            alt='Crossworthy'
          />
          <div
            className='teal-4'
            style={{ position: 'absolute', top: '10px', left: '63px' }}
          >Crossworthy Home</div>
        </div>)}
      </Modal.Body>
    </Modal>
  );

}