/* Library dedicated to providing mobile keyboard functionality, i.e. 
custom keyboard button that pops up in front of the screen when needed.
This is intended for use with custom components, namely crossword boards,
i.e. not fo normal text input fields (which mobile devices will be able to handle natively). */

import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { FaAngleLeft, FaAngleRight, FaKeyboard } from "react-icons/fa";
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { useAppContext } from "../../App";
import { removeClueCommands } from "../../libs/puzzleLib";



export default function MobileKeyboard({
  currentSlotName,
  showCurrentClue=false,
  clues,
  layoutName='play',
  onKeyPress,
}) {

  if (showCurrentClue && clues.get(currentSlotName)?.includes('$hide$')) {
    showCurrentClue = false;
  }

  const [keyboardHidden, setKeyboardHidden] = useState(window.innerWidth > 600);
  const keyboardRef = useRef();   // need it to determine the height of the keyboard when showing
  const [manualRenderTrigger, setManualRenderTrigger] = useState(false);   // render is manually triggered AFTER keyboard is shown, to capture the ref height and feed it to the render

  const { setBottomPadding } = useAppContext();
  useEffect(() => {
    if (keyboardHidden) {
      setBottomPadding(0);
    } else {
      setBottomPadding(keyboardRef?.current?.clientHeight || 0);
    }

    return () => setBottomPadding(0);
  }, [keyboardHidden, currentSlotName, showCurrentClue, setBottomPadding]);

  return (
    currentSlotName && (keyboardHidden ? (
      <Button
        className='grey-on-grey'
        style={{ position: 'fixed', bottom: '10px', left: '10px', zIndex: 80 }}
        variant='outline-secondary'
        size='sm'
        onClick={e => {
          e.stopPropagation();
          setKeyboardHidden(false);
          setBottomPadding(keyboardRef?.current?.clientHeight ? keyboardRef.current.clientHeight + 10 : 0);
          
          setTimeout(() => setManualRenderTrigger(!manualRenderTrigger), 10);
        }}
      ><FaKeyboard /></Button>
    ) : (
      <div className='teal-1-bkgd' ref={keyboardRef} style={{ width: '100vw', position: 'fixed', bottom: 0, left: 0, zIndex: 80 }} onClick={e => e.stopPropagation()}>
        {showCurrentClue && clues && <div className='py-1 w-100 d-flex'>
          <div className='my-auto' onClick={() => onKeyPress('shift+tab')}><FaAngleLeft className='m-2' size={20} /></div>
          <div className='teal-4 my-auto flex-grow-1' onClick={() => onKeyPress('{rotate}')}>{removeClueCommands(clues.get(currentSlotName), /link:\d+[AD]/)}</div>
          <div className='my-auto' onClick={() => onKeyPress('tab')}><FaAngleRight className='m-2' size={20} /></div>
        </div>}

        <Keyboard
          disableButtonHold={true}
          layoutName={layoutName}
          layout={{
            'default': [
              'Q W E R T Y U I O P',
              'A S D F G H J K L',
              'Z X C V B N M',
              // TODO:  once non-alpha characters are supported, need to update this as well
            ],
            'play': [
              'Q W E R T Y U I O P',
              '{mid-spacer} A S D F G H J K L {mid-spacer}',
              'hide Z X C V B N M backspace',
            ],
          }}
          display={{
            'backspace': '⌫',
          }}
          buttonTheme={[
            { class: 'keyboard-mid-spacer', buttons: '{mid-spacer}' },
            { class: 'keyboard-letter', buttons: 'Q W E R T Y U I O P A S D F G H J K L Z X C V B N M' },
            { class: 'keyboard-backspace', buttons: 'backspace' },
            { class: 'keyboard-hide', buttons: 'hide' },
            { class: 'grey-2', buttons: 'hide' },
          ]}
          onKeyPress={key => {
            if (key === 'hide') {
              setKeyboardHidden(true);
              setBottomPadding(0);
            } else {
              onKeyPress(key);
            }
          }}
        />
      </div>

    ))
  );

}

