import React from 'react';
import { Button, Row, Col, Container, Breadcrumb } from 'react-bootstrap';
import { MdDriveFolderUpload } from 'react-icons/md';
import BrowseSidebar from './BrowseSidebar';
import BrowseList from './BrowseList';
import { BiGhost } from 'react-icons/bi';



export default function Browse({ dirPath, folderStructure, callbacks, ...props }) {

  const { 
    navigateToDir,
    getBreadcrumbData,
  } = callbacks;


  if (!dirPath) {
    return (
      <div className='w-100 d-flex'>
        <div className='d-flex mx-auto mt-5 not-too-wide'>
          <BiGhost className='my-auto me-2' size={60} />
          <div className='text-center my-auto block-text'>
            This directory doesn't exist (or you don't have access to it). Please double-check the URL.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div
        className='d-flex small purple-1-bkgd'
        style={{position: 'sticky', top: '0', paddingLeft: '70px', paddingRight: '60px', paddingTop: '4px', paddingBottom: '4px', zIndex: 100, boxShadow: '0 0 4px black'}}
      >
        <Button
          className='py-0 px-2 me-2 border-0 d-flex'
          variant='outline-info'
          size='sm'
          disabled={dirPath.length === 0}
          onClick={() => navigateToDir(dirPath.length <= 1 ? null : dirPath[dirPath.length-2])}
        ><MdDriveFolderUpload className='my-auto'/></Button>

        <Breadcrumb listProps={{ className: 'm-0 p-0' }}>
          {getBreadcrumbData().map(({ onClick, dirName, active }, idx) => (
            <Breadcrumb.Item key={`breadcrumb-${idx}`} onClick={onClick} active={active}>
              {dirName || 'untitled folder'}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>

      <Container className='mb-5 mt-3' {...props}>
        <Row>
          <Col xs={12} md={3}>
            <BrowseSidebar dirPath={dirPath} folderStructure={folderStructure} callbacks={callbacks} style={{position: 'sticky', top: '45px'}} />
          </Col>
          <Col xs={12} md={9}>
            <BrowseList dirPath={dirPath} folderStructure={folderStructure} callbacks={callbacks} style={{top: '50px'}} />
          </Col>
        </Row>
      </Container>
    </div>
  );





}