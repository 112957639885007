import { locInList, locsEqual } from "./blackoutsLib";


/**
 * Enum describing the use-cases for common cell styles.
 * For instance, all cells with CellStyleName of WARNING will be formatted a certain way.
 * NOTE: the string values here are also used as display names for these styles.
 */
export const CellStyleName = {
  DEFAULT: 'default',             // no extra formatting applied to cell
  GHOST_1: 'ghost 1',
  GHOST_2: 'ghost 2',
  GHOST_3: 'ghost 3',
  POSITIVE: 'positive',
  EMPHASIS: 'emphasis',
  WARNING: 'warning',
  ERROR: 'error',
  MISTAKE: 'mistake',     // originally intended e.g. for a misfilled letter during Play
  REVEALED: 'revealed',     // originally intended e.g. for a revealed answer letter during Play
};

/**
 * Enum describing the possible font styles for the letter in the cell, e.g. bold or italic.
 */
export const CellFontStyle = {
  NONE: 'none',
  BOLD: 'bold',
  ITALIC: 'italic',
};


export const Color = {
  WHITE: '#ffffff',
  BLACK: '#000000',
  OFF_WHITE: '#f5f5f5',
  LIGHT_GREY: '#e3e3e3',
  MEDIUM_GREY: '#bfbfbf',
  DARK_GREY: '#737373',
  DEEP_SCARLET: '#e30914',
  PEACH: '#ffb691',
  SALMON: '#ff9782',
  PALE_YELLOW: '#fff0b3',
  DARK_YELLOW: '#c9cc04',
  DARK_OLIVE: '#517001',
  DARK_PURPLE: '#55017f',
  LIGHT_PURPLE: '#f1d4ff',
  LIGHT_PINK: '#ffd4db',
  SKY_BLUE: '#d4e1ff',
  LIGHT_CYAN: '#dff7f7',
  LIGHT_GREEN: '#dff5ce',
}





/**
 * Returns an object containing the elements of the cell style based on the given cellStyleName.
 * @param {CellStyleName} cellStyleName 
 * @returns {{cellFontStyle, cellFontColor, cellBackgroundColor}}
 */
export function getStyleFromCellStyleName(cellStyleName) {
  switch (cellStyleName) {
    case CellStyleName.GHOST_1:
      return { cellFontStyle: CellFontStyle.ITALIC, cellFontColor: Color.LIGHT_GREY };
    case CellStyleName.GHOST_2:
      return { cellFontStyle: CellFontStyle.ITALIC, cellFontColor: Color.MEDIUM_GREY };
    case CellStyleName.GHOST_3:
      return { cellFontStyle: CellFontStyle.ITALIC, cellFontColor: Color.DARK_GREY };
    case CellStyleName.POSITIVE:
      return { cellBackgroundColor: Color.LIGHT_GREEN };
    case CellStyleName.EMPHASIS:
      return { cellFontStyle: CellFontStyle.BOLD, cellBackgroundColor: Color.LIGHT_CYAN };
    case CellStyleName.WARNING:
      return { cellBackgroundColor: Color.PALE_YELLOW };
    case CellStyleName.ERROR:
      return { cellBackgroundColor: Color.SALMON };
    case CellStyleName.MISTAKE:
      return { cellFontColor: Color.DEEP_SCARLET, cellBackgroundColor: Color.OFF_WHITE };
    case CellStyleName.REVEALED:
      return { cellFontColor: Color.DARK_PURPLE, cellBackgroundColor: Color.OFF_WHITE };
    case CellStyleName.DEFAULT:
    default:
      return {};   // will return no specific style
  }
}


export class BoardStyle {
  /**
   * Constructs a BoardStyle with the list of all non-default styles.
   * @param {[{loc: [r,c], cellStyleName}]} cellStyleNameList 
   */
  constructor(cellStyleNameList) {
    this.cellStyleList = cellStyleNameList.map(({loc, cellStyleName}) => ({ loc, ...getStyleFromCellStyleName(cellStyleName) }))
  }

  /**
   * Returns a new BoardStyle object with no extra styling, i.e. all cells are default-styled.
   */
  static default() {
    return new BoardStyle([]);
  }

  clone() {
    const newBoardStyle = new BoardStyle([]);
    for (const cellStyle of this.cellStyleList) {
      newBoardStyle.cellStyleList.push(cellStyle);
    }
    return newBoardStyle;
  }

  /**
   * Returns a new BoardStyle object with the given locs added in with the given cellStyleName.
   * For any locs that already have styles, merges the styles (with priority going to the new styles).
   * @param {[[r,c]]} listOfLocs 
   * @param {CellStyleName} cellStyleName 
   * @returns {BoardStyle}
   */
  withAdditionalStyledLocs(listOfLocs, cellStyleName) {
    var newBoardStyle = this.clone();
    for (const loc of listOfLocs) {
      if (locInList(loc, newBoardStyle.cellStyleList.map(e => e.loc))) {
        // Merge the two styles
        newBoardStyle.setStyleForLoc({ ...newBoardStyle.getStyleForLoc(loc), ...getStyleFromCellStyleName(cellStyleName) }, loc);
      } else {
        // Append the style as a new loc
        newBoardStyle.cellStyleList.push({ loc, ...getStyleFromCellStyleName(cellStyleName) });
      }
    }
    return newBoardStyle;
  }

  /**
   * Returns an object with optional properties { cellFontStyle, cellFontColor, cellBackgroundColor } for the given loc.
   * @param {[number]} loc [r, c]
   * @returns {{cellFontStyle?, cellFontColor?, cellBackgroundColor?}}
   */
  getStyleForLoc(loc) {
    for (let i = 0; i < this.cellStyleList.length; ++i) {
      if (locsEqual(this.cellStyleList[i].loc, loc)) {
        const { loc, ...obj } = this.cellStyleList[i];
        return obj;
      }
    }
    return { };
  }


  /**
   * Intended for internal use; directly modifies this cell style object.
   * Ideally not used externally since most components will treat one BoardStyle object as immutable.
   * @param {Object} cellStyleObject 
   * @param {[r,c]} loc 
   */
  setStyleForLoc(cellStyleObject, loc) {
    for (let i = 0; i < this.cellStyleList.length; ++i) {
      if (locsEqual(this.cellStyleList[i].loc, loc)) {
        this.cellStyleList[i] = { loc, ...cellStyleObject };
        return;
      }
    }
    this.cellStyleList.push({ loc, ...cellStyleObject });
  }
}