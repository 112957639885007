import React from 'react';
import { FormControl } from 'react-bootstrap';
import { FaDelicious } from 'react-icons/fa';
import { FiFolderPlus } from 'react-icons/fi';
import { WiStars } from 'react-icons/wi';
import { produce } from 'immer';
import FolderLineItem from './FolderLineItem';
import PuzzleLineItem from './PuzzleLineItem';
import { SORT_STRATEGY } from '../../libs/traversalLib';
import LoadingAnimation from '../reusable/LoadingAnimation';



/**
 * @param {Object} folderStructure 
 * @returns latest date of any puzzle modification in folder & all recursive subdirectories; or null if empty
 */
function getLastModifiedDate(folderStructure) {
  // Base case - no puzzles or subdirectories
  if (Object.keys(folderStructure.puzzles).length === 0 && Object.keys(folderStructure.subdirectories).length === 0) {
    return null;
  }

  // Recursive case - take last modified date of each puzzle/subdirectory
  var lastModifiedDate = null;
  for (let puzzle of Object.values(folderStructure.puzzles)) {
    if (puzzle.modifiedAt && (!lastModifiedDate || lastModifiedDate < puzzle.modifiedAt)) {
      lastModifiedDate = puzzle.modifiedAt;
    }
  }
  for (let subdirStructure of Object.values(folderStructure.subdirectories)) {
    const modifiedAt = getLastModifiedDate(subdirStructure);
    if (modifiedAt && (!lastModifiedDate || lastModifiedDate < modifiedAt)) {
      lastModifiedDate = modifiedAt;
    }
  }

  return lastModifiedDate;
}




/**
 * The component that has the actual folders/puzzles as two ListGroups in the Browse screen
 */
export default function BrowseList({
  dirPath,
  folderStructure,    // if falsy, the component displays a LoadingAnimation
  callbacks,
  puzzlesDisabled = false,
  onClickOnPuzzle, // ({puzzleId, modifiedAt, puzzleContent, permissions}) => {}: if not provided, defaults to opening that puzzle; if provided, executes instead
  compressPuzzles = false,   // restrict length of puzzles showed? (will overflow with scroll bar; applicable for small modal windows, etc.)
  ...props
}) {

  if (!folderStructure) {
    return (
      <div className='text-center mt-5'>
        <LoadingAnimation size={10} />
      </div>
    );
  }


  const { 
    newDir, setNewDir, onCreateNewDir, onCancelNewDir,
    newPuzzle, setNewPuzzle, onCreateNewPuzzle, onCancelNewPuzzle,
    removePuzzle,
    sortStrategy,
    setPuzzleBeingMoved,
  } = callbacks;

  
  // Create a folderStructure copy with a puzzlesLastModified attribute on each subdirectory
  const sortedSubdirInfo = Object.entries(folderStructure.subdirectories).map(([subdirId, subdirStructure]) => {
    return { subdirId, subdirStructure, modifiedAt: getLastModifiedDate(subdirStructure) };
  });
  sortedSubdirInfo.sort((info1, info2) => {
    switch (sortStrategy) {
    case SORT_STRATEGY.DATE_MODIFIED:
    case SORT_STRATEGY.DATE_CREATED:
      return info2.modifiedAt - info1.modifiedAt;
    case SORT_STRATEGY.TITLE:
    case SORT_STRATEGY.SIZE:
    case SORT_STRATEGY.COMPLETENESS:
    default:
      return info1.subdirStructure.dirName.localeCompare(info2.subdirStructure.dirName, 'en', { sensitivity: 'base' });  // sort alphabetically
    }
  });


  return (
    <div {...props}>
      <div className={'w-auto mb-3 rounded' + (sortedSubdirInfo.length > 0 ? ' border' : '')} id='folders'>
        {newDir && newDir.status === 'CREATING' ? (
          <div className='d-flex p-0 pl-2'>
            <FiFolderPlus className='my-auto me-2' />
            <FormControl
              autoFocus
              size='sm'
              placeholder='new folder name'
              onChange={e => {
                setNewDir(produce(newDir, draft => { draft.dirName = e.target.value }));
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  onCreateNewDir();
                } else if (e.key === 'Escape') {
                  onCancelNewDir();
                }
              }}
            />
          </div>
        ) : newDir && newDir.status === 'LOADING' ? (
          <div className='d-flex p-0 pl-2'>
            <FiFolderPlus className='my-auto me-2 spinning' />
            <div>{newDir.dirName}</div>
          </div>
        ) : null}

        {sortedSubdirInfo.map(({ subdirId, subdirStructure, modifiedAt }) => (
          <FolderLineItem
            key={`subfolder-${subdirId}`}
            dirId={subdirId}
            dirStructure={subdirStructure}
            modifiedAt={modifiedAt}
            callbacks={callbacks}
          />
        ))}
      </div>
      
      <div
        className={'w-auto rounded' + (Object.keys(folderStructure.puzzles).length > 0 ? ' border' : '')}
        id='puzzles'
        style={compressPuzzles ? { maxHeight: '30vh', overflow: 'auto' } : {}}
      >
        {newPuzzle && newPuzzle.status === 'CREATING' ? (
          <div className='d-flex p-0 pl-2'>
            <div className='d-flex' style={{width: '2.3rem'}}>
              <FaDelicious className='m-auto me-2' />
            </div>
            <FormControl
              autoFocus
              size='sm'
              placeholder='new puzzle name'
              onChange={e => {
                setNewPuzzle(produce(newPuzzle, draft => { draft.puzzleName = e.target.value }));
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  onCreateNewPuzzle();
                } else if (e.key === 'Escape') {
                  onCancelNewPuzzle();
                }
              }}
            />
          </div>
        ) : newPuzzle && newPuzzle.status === 'LOADING' ? (
          <div className='d-flex p-0 pl-2'>
            <div className='d-flex' style={{width: '2.3rem'}}>
              <FaDelicious className='m-auto me-2 spinning' />
            </div>
            <div>{newPuzzle.puzzleName}</div>
          </div>
        ) : null}

        {
          Object.entries(folderStructure.puzzles)
            .sort((entry1, entry2) => {
              const puz1 = entry1[1];
              const puz2 = entry2[1];
              switch (sortStrategy) {
              case SORT_STRATEGY.DATE_CREATED:
                return puz2.createdAt - puz1.createdAt;
              case SORT_STRATEGY.DATE_MODIFIED:
                return puz2.modifiedAt - puz1.modifiedAt;
              case SORT_STRATEGY.SIZE:
                return puz1.puzzleContent.size.rows * puz1.puzzleContent.size.cols - puz2.puzzleContent.size.rows * puz2.puzzleContent.size.cols;
              case SORT_STRATEGY.COMPLETENESS:
                return (
                  (
                    puz2.puzzleContent.grid.filter(sq => sq.length > 0).length / puz2.puzzleContent.grid.length * 10000 + 
                        puz2.puzzleContent.clues.across.concat(puz2.puzzleContent.clues.down).filter(clue => /^\d+\. ./.test(clue)).length / 
                            (puz2.puzzleContent.clues.across.length + puz2.puzzleContent.clues.down.length) 
                  ) - (
                    puz1.puzzleContent.grid.filter(sq => sq.length > 0).length / puz1.puzzleContent.grid.length * 10000 + 
                        puz1.puzzleContent.clues.across.concat(puz1.puzzleContent.clues.down).filter(clue => /^\d+\. ./.test(clue)).length / 
                            (puz1.puzzleContent.clues.across.length + puz1.puzzleContent.clues.down.length)
                  )
                );
              case SORT_STRATEGY.TITLE:
              default:
                return puz1.puzzleContent.title.localeCompare(puz2.puzzleContent.title, 'en', { sensitivity: 'base' });
              }
            })
            .map(([puzzleId, puzzle]) => (
              <PuzzleLineItem 
                key={`puzzle-${puzzleId}`}
                puzzle={puzzle}
                dirPathString={dirPath.join('/')}
                disabled={puzzlesDisabled}
                onDelete={() => removePuzzle(puzzleId)}
                openMovePuzzleModal={() => setPuzzleBeingMoved({ puzzleId, puzzleTitle: puzzle.puzzleContent.title, dirPath })}
                onClick={onClickOnPuzzle}
              />
            ))
        }
      </div>
      
      {Object.keys(folderStructure.puzzles).length === 0 && Object.keys(folderStructure.subdirectories).length === 0 && !newDir && !newPuzzle && (
        <div className='d-flex text-muted'>
          <div className='ms-auto my-auto'>
            <WiStars size={25} />
          </div>
          <div className='fst-italic me-auto'>This folder is empty.</div>
        </div>
      )}
    </div>
  );
}