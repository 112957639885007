






//#region blackouts util functions and legality  ================


/**
 * Do the two locs have the same coordinates?
 * @param {[r, c]} loc1 
 * @param {[r, c]} loc2 
 * @returns {bool}
 */
export function locsEqual(loc1, loc2) {
  return loc1[0] === loc2[0] && loc1[1] === loc2[1];
}

/**
 * Is the specified loc present in the given listOfLocs?
 * @param {[number, number]} loc 
 * @param {[[number, number]]} listOfLocs 
 * @returns {bool}
 */
export function locInList(loc, listOfLocs) {
  for (var i = 0; i < listOfLocs.length; ++i) {
    if (locsEqual(loc, listOfLocs[i])) {
      return true;
    }
  }
  return false;
}

/**
 * Returns a copy of the listOfLocs without any that is the same as the give loc.
 * @param {[number, number]} loc 
 * @param {[[number, number]]} listOfLocs 
 * @returns {[[number, number]]}
 */
export function removeLoc(loc, listOfLocs) {
  var remainingLocs = [];
  for (var i = 0; i < listOfLocs.length; ++i) {
    if (!locsEqual(loc, listOfLocs[i])) {
      remainingLocs.push(listOfLocs[i]);
    }
  }
  return remainingLocs;
}

/**
 * Returns a copy of the listOfLocs without any that are contained in locsToRemove.
 * @param {[[number, number]]} locsToRemove
 * @param {[[number, number]]} listOfLocs 
 * @returns {[[number, number]]}
 */
export function removeAllLocs(locsToRemove, listOfLocs) {
  for (var i = 0; i < locsToRemove.length; ++i) {
    listOfLocs = removeLoc(locsToRemove[i], listOfLocs)
  }
  return listOfLocs;
}


/**
 * Returns a list of all the outer blackouts in the grid, including any blackouts that can be traced via other blackouts to a border of the charGrid.
 * This function was written by ChatGPT...
 * @param {[[string]]} charGrid 
 * @returns {[[number, number]]} List of all the blackouts that are contiguous with the outer border of the charGrid, i.e. all blackouts except the islands
 */
export function getAllOuterBlackouts(charGrid) {
  const rows = charGrid.length;
  if (rows === 0) return [];

  const cols = charGrid[0].length;
  const visited = new Array(rows).fill(false).map(() => new Array(cols).fill(false));

  function isValid(row, col) {
    return row >= 0 && row < rows && col >= 0 && col < cols;
  }

  function dfs(row, col) {
    if (!isValid(row, col) || charGrid[row][col] !== 'blackout' || visited[row][col]) {
      return;
    }

    visited[row][col] = true;

    // Explore neighboring cells
    dfs(row - 1, col); // Up
    dfs(row + 1, col); // Down
    dfs(row, col - 1); // Left
    dfs(row, col + 1); // Right
  }

  // Start DFS from the border cells
  for (let i = 0; i < rows; i++) {
    dfs(i, 0); // Left border
    dfs(i, cols - 1); // Right border
  }

  for (let j = 0; j < cols; j++) {
    dfs(0, j); // Top border
    dfs(rows - 1, j); // Bottom border
  }

  // Collect connected border locations
  const connectedLocations = [];
  for (let r = 0; r < rows; r++) {
    for (let c = 0; c < cols; c++) {
      if (charGrid[r][c] && visited[r][c]) {
        connectedLocations.push([r, c]);
      }
    }
  }

  return connectedLocations;

}



/**
 * Recursive function that returns a subset of the given list, that is contiguous (directly or indirectly) with the given startLoc
 * @param {[[number, number]]} listOfLocs List of all non-blackout locations by their row/col numbers
 * @param {[number, number]} startLoc The [row, col] to evaluate contiguity with
 * @returns {[[number, number]]} Subset of listOfLocs
 */
export function getContiguousLocations(listOfLocs, startLoc) {
  // Base case: if startLoc not in listOfLocs, don't count any contiguous locs.
  if (!locInList(startLoc, listOfLocs)) {
    return [];
  }

  // Add startLoc to the contiguousLocs and remove it from the list.
  var contiguousLocs = [startLoc];
  listOfLocs = removeLoc(startLoc, listOfLocs);

  // Advance by one square in each direction and append the contiguous locs.
  const [startX, startY] = startLoc;
  
  // Up
  var newContiguousLocs = getContiguousLocations(listOfLocs, [startX, startY-1]);
  contiguousLocs.push(...newContiguousLocs);
  listOfLocs = removeAllLocs(newContiguousLocs, listOfLocs);

  // Down
  newContiguousLocs = getContiguousLocations(listOfLocs, [startX, startY+1]);
  contiguousLocs.push(...newContiguousLocs);
  listOfLocs = removeAllLocs(newContiguousLocs, listOfLocs);

  // Left
  newContiguousLocs = getContiguousLocations(listOfLocs, [startX-1, startY]);
  contiguousLocs.push(...newContiguousLocs);
  listOfLocs = removeAllLocs(newContiguousLocs, listOfLocs);

  // Right 
  newContiguousLocs = getContiguousLocations(listOfLocs, [startX+1, startY]);
  contiguousLocs.push(...newContiguousLocs);
  listOfLocs = removeAllLocs(newContiguousLocs, listOfLocs);

  return contiguousLocs;

}





/**
 * Evaluates whether the given blackouts are legal, i.e.:
 *   - there are no slots of <3 letters, and
 *   - all the non-blackout squares are contiguous, i.e. the blackouts don't completely separate any open squares
 * Does not evaluate rotational symmetry.
 * @param {[[number, number]]} blackouts list of blackout coordinates
 * @param {number} numRows
 * @param {number} numCols
 * @param {bool} requireContiguity should it return false if not all white squares are contiguous? (will take extra time)
 * @returns {bool}
 */
export function blackoutsAreLegal(blackouts, numRows, numCols, requireContiguity) {

  // Convert blackouts list to 2d blackoutGrid array
  var blackoutGrid = [];
  for (var r = 0; r < numRows; ++r) {
    blackoutGrid[r] = [];
    for (var c = 0; c < numCols; ++c) {
      blackoutGrid[r][c] = locInList([r, c], blackouts)
    }
  }


  // First ensure all slots are >=3 letters

  for (let r = 0; r < numRows; ++r) {
    // Iterate across the row and make sure there are at least 3 consecutive non-blackout squares each
    let currentWordLength = 0;
    for (let c = 0; c < numCols; ++c) {
      if (blackoutGrid[r][c]) {
        if (currentWordLength > 0 && currentWordLength < 3) {
          return false;
        }
        currentWordLength = 0;
      } else {
        ++currentWordLength;
      }
    }
  }

  for (let c = 0; c < numCols; ++c) {
    // Iterate down the column and make sure there are at least 3 consecutive non-blackout squares each
    let currentWordLength = 0;
    for (let r = 0; r < numRows; ++r) {
      if (blackoutGrid[r][c]) {
        if (currentWordLength > 0 && currentWordLength < 3) {
          return false;
        }
        currentWordLength = 0;
      } else {
        ++currentWordLength;
      }
    }
  }

  if (!requireContiguity) {
    return true;
  }

  // Ensure that all non-blackout squares are contiguous
  var nonBlackoutSquares = [];
  for (let r = 0; r < numRows; ++r) {
    for (let c = 0; c < numCols; ++c) {
      if (!blackoutGrid[r][c]) {
        nonBlackoutSquares.push([r, c]);
      }
    }
  }
  return nonBlackoutSquares.length === getContiguousLocations(nonBlackoutSquares, nonBlackoutSquares[0]).length;
}

//#endregion




//#region generating random blackouts=============================

/**
 * Calculates and returns the border size, or the number of cells from the perimeter of the grid that should be treated as the "border".
 * Border size is 3 if grid is >= 10 cells across & down, 2 if grid is >= 7 across & down, 1 if grid is 6 across & down, and 0 otherwise.
 * @param {number} numRows 
 * @param {number} numCols 
 * @returns {number}
 */
export function calculateBorderSize(numRows, numCols) {
  return Math.min(numRows, numCols) >= 10 ? 3 : Math.min(numRows, numCols) >= 7 ? 2 : Math.min(numRows, numCols) >= 6 ? 1 : 0;
}

/**
 * Returns a random [r, c] coordinate in the top half that should be legal
 * given current border blackouts (may be illegal for other reasons). Border
 * defined by the three outermost rows/cols of the board. Returns null if can't 
 * find a valid [r, c] coordinate.
 * @param {number} numRows
 * @param {number} numCols
 * @param {[[number, number]]} currentBlackouts 
 * @returns {[number, number]?}
 */
export function randomBorderBlackout(numRows, numCols, currentBlackouts) {
  // Calculate borderSize based on size of grid
  const borderSize = calculateBorderSize(numRows, numCols);
  if (borderSize === 0) {
    return null;
  }

  // Start with all the squares on the outermost row, excluding the two 3x3 corners, including the two 1x1 corner squares
  var options = [
    [0,0], [0, numCols-1],    // 2 corner squares
    ...Array.apply(undefined, Array(numCols-2*borderSize)).map((_, i) => { return [0, i+borderSize] }),     // first row, except 3 leftmost/rightmost
    ...Array.apply(undefined, Array(Math.floor((numRows+1)/2) - borderSize)).map((_, i) => { return [i+borderSize, 0] }),    // top half of leftmost col, except three topmost
    ...Array.apply(undefined, Array(Math.floor((numRows+1)/2) - borderSize)).map((_, i) => { return [i+borderSize, numCols-1] }),  // top half of rightmost col, except three topmost
  ];

  // Exclude blackouts already present
  options = options.filter(loc => !locInList(loc, currentBlackouts));


  // Iterate through existing blackouts in top three rows to add options for adjacent blackouts, and overrepresent these locations
  const OVERREPRESENT = 6;    // the number of extra lottery tickets to locations hanging off existing border blackouts
  for (var i = 0; i < currentBlackouts.length; ++i) {
    const [br, bc] = currentBlackouts[i];

    // Top border region
    if (br < borderSize-1) {
      options.push([br+1, bc])
      if (bc >= borderSize && bc < numCols-borderSize) {
        options.push(...Array(OVERREPRESENT).fill([br+1, bc]));    // overrepresent these locations
      }
    }

    // Left border region
    if (bc < borderSize-1) {
      options.push([br, bc+1])
      if (br >= borderSize) {
        options.push(...Array(OVERREPRESENT).fill([br, bc+1]));
      }
    }

    // Right border region
    if (bc > numCols - borderSize) {
      options.push([br, bc-1])
      if (br >= borderSize) {
        options.push(...Array(OVERREPRESENT).fill([br, bc-1]));
      }
    }
  }

  // Exclude blackouts already present (again)
  options = options.filter(loc => !locInList(loc, currentBlackouts));

  // Return random element from options
  if (options.length === 0) {
    return null;
  }
  return options[Math.floor(Math.random() * options.length)];
}


/**
 * Returns a random [r, c] coordinate in the top half of the grid that isn't in currentBlackouts
 * or in the border zone. Returns null if can't find a valid [r, c] coordinate.
 * @param {number} numRows 
 * @param {number} numCols 
 * @param {[[number, number]]} currentBlackouts 
 * @returns {[number, number]?}
 */
export function randomMidlandsBlackout(numRows, numCols, currentBlackouts) {
  const borderSize = calculateBorderSize(numRows, numCols);

  var options = [];
  for (var r = borderSize; r < Math.floor(numRows/2); ++r) {
    for (var c = borderSize; c < numCols - borderSize; ++c) {
      if (!locInList([r, c], currentBlackouts)) {
        options.push([r, c]);
      }
    }
  }

  if (options.length === 0) {
    return null;
  }
  return options[Math.floor(Math.random() * options.length)];
}


/**
 * Returns the rotationally symmetric location in the grid relative to the given loc.
 * If given loc as null, returns null.
 * @param {[number, number]?} loc 
 * @param {number} numRows 
 * @param {number} numCols 
 * @returns {[number, number]?}
 */
export function getRotationallySymmetricLoc(loc, numRows, numCols) {
  if (loc === null) {
    return null;
  }
  const [origRow, origCol] = loc;
  const newRow = numRows - 1 - origRow;
  const newCol = numCols - 1 - origCol;
  return [newRow, newCol];
}




export function generateBlackouts(numRows, numCols) {
  
  var blackouts = [];
  const borderSize = calculateBorderSize(numRows, numCols);
  const numBorderBlackouts = Math.floor(
    0.1 * (numRows + numCols) * borderSize + 
    Math.random() * 0.2 * (numRows + numCols) * borderSize
  );
  const numMidlandBlackouts = Math.floor(
    (0.11 + Math.random() * 0.29) * (numRows - 2*borderSize) * (numCols - 2*borderSize)
  )

  // Generate border blackouts
  for (let i = 0; i < numBorderBlackouts; ++i) {
    let newBlackouts = [...blackouts];
    const randomNewBlackout = randomBorderBlackout(numRows, numCols, blackouts)
    if (randomNewBlackout !== null) {
      newBlackouts.push(randomNewBlackout);
      newBlackouts.push(getRotationallySymmetricLoc(randomNewBlackout, numRows, numCols));
    }
    if (blackoutsAreLegal(newBlackouts, numRows, numCols, false)) {
      blackouts = newBlackouts;
    }
  }

  // Generate midland blackouts
  for (let i = 0; i < numMidlandBlackouts; ++i) {
    let newBlackouts = [...blackouts];
    const randomNewBlackout = randomMidlandsBlackout(numRows, numCols, blackouts)
    if (randomNewBlackout !== null) {
      newBlackouts.push(randomNewBlackout);
      newBlackouts.push(getRotationallySymmetricLoc(randomNewBlackout, numRows, numCols));
    }
    if (blackoutsAreLegal(newBlackouts, numRows, numCols, false)) {
      blackouts = newBlackouts;
    }
  }

  if (blackoutsAreLegal(blackouts, numRows, numCols, true)) {
    return blackouts;
  } else {
    return generateBlackouts(numRows, numCols);  // CAREFUL - no failsafe here
  }


}





// Human-made blackout patterns

// 15x15
const humanPatterns15x15 = [
  [[0, 4], [0, 9], [1, 4], [1, 9], [2, 4], [2, 9], [3, 4], [4, 3], [4, 7], [4, 11], [4, 12], [4, 13], [4, 14], [5, 6], [5, 10], [6, 0], [6, 1], [7, 5], [7, 9], [8, 13], [8, 14], [9, 4], [9, 8], [10, 0], [10, 1], [10, 2], [10, 3], [10, 7], [10, 11], [11, 10], [12, 5], [12, 10], [13, 5], [13, 10], [14, 5], [14, 10]],
  [[0, 9], [0, 10], [0, 11], [1, 9], [1, 10], [2, 9], [3, 0], [3, 1], [3, 2], [3, 3], [3, 4], [3, 8], [4, 7], [4, 12], [4, 13], [4, 14], [5, 6], [6, 5], [6, 11], [7, 4], [7, 10], [8, 3], [8, 9], [9, 8], [10, 0], [10, 1], [10, 2], [10, 7], [11, 6], [11, 10], [11, 11], [11, 12], [11, 13], [11, 14], [12, 5], [13, 4], [13, 5], [14, 3], [14, 4], [14, 5]],
  [[0, 4], [0, 9], [1, 4], [1, 9], [2, 4], [2, 9], [3, 11], [4, 6], [4, 7], [4, 12], [4, 13], [4, 14], [5, 0], [5, 1], [5, 2], [5, 8], [6, 4], [6, 9], [7, 3], [7, 11], [8, 5], [8, 10], [9, 6], [9, 12], [9, 13], [9, 14], [10, 0], [10, 1], [10, 2], [10, 7], [10, 8], [11, 3], [12, 5], [12, 10], [13, 5], [13, 10], [14, 5], [14, 10]],
  [[0, 4], [0, 9], [1, 4], [1, 9], [2, 4], [3, 6], [3, 11], [4, 0], [4, 1], [4, 2], [4, 7], [4, 8], [4, 13], [4, 14], [5, 5], [5, 9], [6, 4], [7, 3], [7, 11], [8, 10], [9, 5], [9, 9], [10, 0], [10, 1], [10, 6], [10, 7], [10, 12], [10, 13], [10, 14], [11, 3], [11, 8], [12, 10], [13, 5], [13, 10], [14, 5], [14, 10]],
  [[0, 4], [0, 10], [1, 4], [1, 10], [2, 4], [2, 10], [4, 0], [4, 1], [4, 2], [4, 6], [5, 5], [5, 12], [5, 13], [5, 14], [6, 7], [6, 11], [7, 3], [7, 7], [7, 11], [8, 3], [8, 7], [9, 0], [9, 1], [9, 2], [9, 9], [10, 8], [10, 12], [10, 13], [10, 14], [12, 4], [12, 10], [13, 4], [13, 10], [14, 4], [14, 10]],
  [[0, 4], [0, 10], [1, 4], [1, 10], [2, 4], [2, 10], [3, 5], [3, 9], [4, 0], [4, 1], [4, 2], [4, 8], [5, 3], [5, 7], [5, 11], [5, 12], [5, 13], [5, 14], [6, 6], [8, 8], [9, 0], [9, 1], [9, 2], [9, 3], [9, 7], [9, 11], [10, 6], [10, 12], [10, 13], [10, 14], [11, 5], [11, 9], [12, 4], [12, 10], [13, 4], [13, 10], [14, 4], [14, 10]],
  [[0, 4], [0, 10], [1, 4], [1, 10], [2, 4], [2, 10], [3, 5], [3, 10], [4, 0], [4, 1], [4, 2], [4, 8], [5, 3], [5, 7], [5, 11], [5, 12], [5, 13], [5, 14], [6, 6], [7, 5], [7, 9], [8, 8], [9, 0], [9, 1], [9, 2], [9, 3], [9, 7], [9, 11], [10, 6], [10, 12], [10, 13], [10, 14], [11, 4], [11, 9], [12, 4], [12, 10], [13, 4], [13, 10], [14, 4], [14, 10]],
  [[0, 4], [0, 9], [1, 4], [1, 9], [2, 4], [2, 9], [3, 9], [4, 0], [4, 1], [4, 2], [4, 6], [5, 5], [5, 11], [5, 12], [5, 13], [5, 14], [6, 4], [6, 8], [8, 6], [8, 10], [9, 0], [9, 1], [9, 2], [9, 3], [9, 9], [10, 8], [10, 12], [10, 13], [10, 14], [11, 5], [12, 5], [12, 10], [13, 5], [13, 10], [14, 5], [14, 10]],
  [[0, 4], [0, 8], [0, 9], [1, 4], [1, 9], [2, 4], [2, 9], [3, 13], [3, 14], [4, 5], [4, 6], [4, 10], [4, 11], [5, 0], [5, 1], [5, 2], [6, 3], [6, 4], [6, 8], [7, 7], [8, 6], [8, 10], [8, 11], [9, 12], [9, 13], [9, 14], [10, 3], [10, 4], [10, 8], [10, 9], [11, 0], [11, 1], [12, 5], [12, 10], [13, 5], [13, 10], [14, 5], [14, 6], [14, 10]],
  [[0, 4], [0, 9], [1, 4], [1, 9], [2, 4], [2, 9], [3, 5], [3, 9], [4, 0], [4, 1], [4, 2], [4, 8], [5, 3], [5, 7], [5, 11], [5, 12], [5, 13], [5, 14], [6, 10], [7, 5], [7, 9], [8, 4], [9, 0], [9, 1], [9, 2], [9, 3], [9, 7], [9, 11], [10, 6], [10, 12], [10, 13], [10, 14], [11, 5], [11, 9], [12, 5], [12, 10], [13, 5], [13, 10], [14, 5], [14, 10]],
  [[0, 4], [0, 9], [1, 4], [1, 9], [2, 4], [2, 9], [3, 0], [3, 1], [4, 3], [4, 7], [4, 8], [4, 14], [5, 13], [5, 14], [6, 5], [6, 6], [6, 11], [6, 12], [6, 13], [6, 14], [7, 4], [7, 10], [8, 0], [8, 1], [8, 2], [8, 3], [8, 8], [8, 9], [9, 0], [9, 1], [10, 0], [10, 6], [10, 7], [10, 11], [11, 13], [11, 14], [12, 5], [12, 10], [13, 5], [13, 10], [14, 5], [14, 10]],
  [[0, 4], [0, 10], [1, 4], [1, 10], [2, 4], [2, 10], [3, 5], [3, 9], [4, 0], [4, 1], [4, 2], [5, 3], [5, 7], [5, 11], [5, 12], [5, 13], [5, 14], [6, 6], [8, 8], [9, 0], [9, 1], [9, 2], [9, 3], [9, 7], [9, 11], [10, 12], [10, 13], [10, 14], [11, 5], [11, 9], [12, 4], [12, 10], [13, 4], [13, 10], [14, 4], [14, 10]],
  [[0, 4], [0, 9], [1, 4], [1, 9], [2, 4], [2, 9], [3, 9], [4, 0], [4, 1], [4, 2], [4, 6], [5, 5], [5, 11], [5, 12], [5, 13], [5, 14], [6, 8], [7, 7], [8, 6], [9, 0], [9, 1], [9, 2], [9, 3], [9, 9], [10, 8], [10, 12], [10, 13], [10, 14], [11, 5], [12, 5], [12, 10], [13, 5], [13, 10], [14, 5], [14, 10]],
  [[0, 4], [0, 9], [0, 14], [1, 4], [1, 9], [2, 9], [3, 0], [3, 1], [3, 2], [3, 8], [4, 0], [4, 7], [5, 5], [5, 13], [5, 14], [6, 6], [6, 11], [7, 4], [7, 10], [8, 3], [8, 8], [9, 0], [9, 1], [9, 9], [10, 7], [10, 14], [11, 6], [11, 12], [11, 13], [11, 14], [12, 5], [13, 5], [13, 10], [14, 0], [14, 4], [14, 5], [14, 10]],
  [[0, 3], [0, 7], [0, 11], [1, 3], [1, 7], [1, 11], [2, 3], [2, 11], [4, 5], [4, 10], [5, 4], [5, 9], [6, 3], [6, 8], [7, 0], [7, 1], [7, 2], [7, 7], [7, 12], [7, 13], [7, 14], [8, 6], [8, 11], [9, 5], [9, 10], [10, 4], [10, 9], [12, 3], [12, 11], [13, 3], [13, 7], [13, 11], [14, 3], [14, 7], [14, 11]],
  [[0, 4], [0, 9], [1, 4], [1, 9], [2, 9], [3, 6], [3, 7], [3, 11], [4, 0], [4, 1], [4, 5], [5, 8], [5, 12], [5, 13], [5, 14], [6, 4], [6, 8], [7, 3], [7, 11], [8, 6], [8, 10], [9, 0], [9, 1], [9, 2], [9, 6], [10, 9], [10, 13], [10, 14], [11, 3], [11, 7], [11, 8], [12, 5], [13, 5], [13, 10], [14, 5], [14, 10]],
  [[0, 4], [0, 8], [1, 4], [1, 8], [2, 8], [3, 6], [3, 10], [4, 0], [4, 1], [4, 2], [4, 3], [4, 7], [4, 12], [4, 13], [4, 14], [5, 0], [6, 4], [6, 9], [7, 4], [7, 10], [8, 5], [8, 10], [9, 14], [10, 0], [10, 1], [10, 2], [10, 7], [10, 11], [10, 12], [10, 13], [10, 14], [11, 4], [11, 8], [12, 6], [13, 6], [13, 10], [14, 6], [14, 10]],
  [[0, 5], [0, 9], [0, 10], [0, 11], [1, 5], [1, 10], [2, 5], [3, 0], [3, 5], [3, 12], [3, 13], [3, 14], [4, 0], [4, 1], [4, 14], [5, 0], [5, 9], [6, 3], [6, 4], [6, 9], [6, 10], [7, 3], [7, 10], [8, 3], [9, 0], [9, 6], [9, 7], [10, 0], [10, 1], [10, 5], [10, 6], [10, 11], [10, 12], [10, 13], [10, 14], [11, 0], [12, 3], [12, 10], [13, 3], [13, 10], [14, 3], [14, 4], [14, 9], [14, 10]],
  [[0, 4], [0, 9], [1, 4], [1, 9], [2, 4], [2, 9], [3, 11], [4, 0], [4, 1], [4, 2], [4, 7], [4, 8], [4, 13], [4, 14], [5, 5], [6, 6], [6, 10], [7, 3], [7, 11], [8, 4], [8, 8], [9, 9], [10, 0], [10, 1], [10, 6], [10, 7], [10, 12], [10, 13], [10, 14], [11, 3], [12, 5], [12, 10], [13, 5], [13, 10], [14, 5], [14, 10]],
  [[0, 4], [0, 10], [1, 4], [1, 10], [2, 4], [2, 10], [3, 10], [4, 0], [4, 1], [4, 2], [5, 5], [5, 12], [5, 13], [5, 14], [6, 4], [6, 8], [7, 7], [8, 6], [8, 10], [9, 0], [9, 1], [9, 2], [9, 9], [10, 12], [10, 13], [10, 14], [11, 4], [12, 4], [12, 10], [13, 4], [13, 10], [14, 4], [14, 10]],
  [[0, 4], [0, 10], [1, 4], [1, 10], [2, 4], [2, 10], [3, 5], [3, 6], [3, 7], [4, 0], [4, 1], [4, 2], [4, 8], [5, 9], [5, 13], [5, 14], [6, 4], [6, 9], [7, 3], [7, 4], [7, 9], [7, 10], [8, 4], [8, 9], [9, 0], [9, 1], [9, 5], [9, 9], [10, 6], [10, 7], [10, 8], [10, 12], [10, 13], [10, 14], [12, 4], [12, 10], [13, 4], [13, 10], [14, 4], [14, 10]]
];


export function pickHumanBlackouts(numRows, numCols) {
  if (numRows === 15 && numCols === 15) {
    return humanPatterns15x15[Math.floor(Math.random() * humanPatterns15x15.length)];
  }

  console.log('blackoutsLib readHumanBlackouts: numRows/numCols must be 15 (for now)');
  return [];
}





