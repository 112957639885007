import React, { useEffect, useRef, useState } from 'react';
import { get, post } from 'aws-amplify/api';
import { Row, Col, Container, Modal, Form, Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import { GiSnakeTotem } from 'react-icons/gi';
import SnakeBoard from '../components/snake/SnakeBoard';
import LoaderButton from '../components/reusable/LoaderButton';
import { userFriendlyDateString } from '../libs/miscLib';
import { useAppContext } from '../App';
import { useToastNotifications } from '../libs/toastLib';


export default function Snake() {


  const { windowWidth } = useAppContext();
  const { postErrorNotification } = useToastNotifications();

  // For starting/finishing game
  const [isGameInProgress, setIsGameInProgress] = useState(false);

  // For existing highscores
  const [isLoadingHighscores, setIsLoadingHighscores] = useState(false);
  const [highscores, setHighscores] = useState(null);

  // For new highscores
  const [highscoreModal, setHighscoreModal] = useState(null);  // if null, nothing displaying
  // const [displayName, setDisplayName] = useState('di');
  const displayNameRef = useRef(null);
  const [isSubmittingNewHighscore, setIsSubmittingNewHighscore] = useState(false);



  // Load highscores
  async function loadHighscores() {
    const response = await get({
      apiName: 'snakeHighscores',
      path: '/snakeHighscores',
    }).response;
    return await response.body.json();
  }
  useEffect(() => {
    if (!isSubmittingNewHighscore) {
      async function onLoad() {
        setIsLoadingHighscores(true);
        try {
          const loadedHighscores = await loadHighscores();
          loadedHighscores.sort((a, b) => b.score - a.score);   // sort them for display
          setHighscores(loadedHighscores);
        } catch (e) {
          setHighscores(null);
        }
        setIsLoadingHighscores(false);
      }
      onLoad();
    }
  }, [postErrorNotification, isSubmittingNewHighscore]);

  function onLose(score) {
    setIsGameInProgress(false);

    if (!highscores || score === 0) {  // if there was an issue loading highscores, or if you died immediately
      return;
    }

    const rankNumber = highscores.filter(item => item.score > score).length + 1;
    const rankString = (
      (highscores.filter(item => item.score === score).length > 0 ? 'joint-' : '') +
      rankNumber +
      (rankNumber === 1 ? 'st' : rankNumber === 2 ? 'nd' : rankNumber === 3 ? 'rd' : 'th')
    );

    function handleClose() {
      setHighscoreModal(null);
    }

    async function handleSubmit(newHighscore) {
      // Validate form
      if (!displayNameRef.current || displayNameRef.current.value.length < 1) {
        postErrorNotification('Enter a display name', 'Cannot submit nameless highscores');
        return;
      }

      setIsSubmittingNewHighscore(true);
      try {
        await post({
          apiName: 'snakeHighscores',
          path: '/snakeHighscores',
          options: {
            body: {
              score: newHighscore,
              displayName: displayNameRef.current ? displayNameRef.current.value : 'anonymous',
            },
          },
        }).response;
      } catch (e) {
        postErrorNotification('Oops!', 'There was an error submitting your highscore. Please let me know if you can so I can fix it!');
      }
      setIsSubmittingNewHighscore(false);
      setHighscoreModal(null);
    }

    const modalTitleString = rankNumber === 1 ? (
      'New FIRST-PLACE CHAMPION of CROSSWORTHY SNAKE!!!'
    ) : rankNumber === 2 ? (
      'SO close to #1!!'
    ) : rankNumber === 3 ? (
      'Bronze medal, but you\'re on the podium!'
    ) : rankNumber <= 15 ? (
      'New highscore!'
    ) : (
      'Display score'
    );

    const modal = (
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitleString}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group>
              <Form.Label>Enter your public display name:</Form.Label>
              <Form.Control type='text' defaultValue={displayNameRef.current ? displayNameRef.current.value : ''} ref={displayNameRef} />
              <Form.Text className='text-muted'>You ranked {rankString} with a score of {score}.</Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Nah fam
          </Button>
          <LoaderButton variant='primary' onClick={() => handleSubmit(score)} isLoading={isSubmittingNewHighscore}>
            Submit
          </LoaderButton>
        </Modal.Footer>
      </Modal>
    );
    setHighscoreModal(modal);
  }



  return (
    <div>

      <Container>
        <Row className={'block-text text-center my-4 ' + (windowWidth > 770 ? 'h1' : 'h5')}>
          <span className='zoomable'><GiSnakeTotem /> Crossworthy SNAKE <GiSnakeTotem /></span>
        </Row>
        <Row>
          <Col xs={12} md={6} style={{ position: 'relative' }}>
            {isGameInProgress ? null : (
              <Button
                style={{
                  position: 'absolute',
                  top: '40%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 1000,
                  borderRadius: '15px',
                  borderWidth: '2px',
                  borderColor: '#666666',
                  opacity: '0.86',
                }}
                variant='warning'
                onClick={(e) => setIsGameInProgress(true)}
              >
                Start game
              </Button>
            )}
            <SnakeBoard onLose={onLose} onStart={() => { }} isStarted={isGameInProgress} style={{maxWidth: '500px', marginBottom: '15px'}} />
          </Col>
          <Col xs={12} md={6}>
            <Card>
              <Card.Body>
                <Card.Title className='text-center block-text'>Highscores</Card.Title>
                <Card.Text className='small text-muted fst-italic text-center'>Immortalize your own name among the esteemed ranks below!</Card.Text>

                {isLoadingHighscores ? (
                  <div className='d-block text-center'><GiSnakeTotem className='spinning' size={100} /></div>
                ) : (
                  highscores ? (
                    <ListGroup className='list-group-flush overflow-auto' style={{maxHeight: '300px'}}>
                      {highscores.map(item => {
                        return (
                          <ListGroupItem key={item.id} className='d-flex p-1'>
                            <span className='me-2 mark'>{item.score}</span>
                            <span className='mt-auto mb-auto'>{item.displayName}</span>
                            <span className='ms-auto small text-muted mt-auto mb-auto'>{userFriendlyDateString(item.createdAt)}</span>
                          </ListGroupItem>
                        );
                      })}
                    </ListGroup>
                  ) : (
                    <div className='d-block text-center text-danger'>Highscores unavailable at this moment.</div>
                  )
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Container>

      {highscoreModal}

    </div>
  );
}